import React from "react";
import ICONS from "../../assets/quester-icons";
import "./add-content-popup.scss";
import CommunityUtils from "../../entities/community/community-utils";
import CollectionEditorUtils from "../../entities/collection/collection-editor-utils";

const AddContentPopup = ({ onClose = () => {} }) => {
  const _addResource = () => {
    CommunityUtils.showCommunityAddResourceWizard();
    onClose();
  };

  const _createCollection = () => {
    CollectionEditorUtils.enableCollectionCreator({
        uiMode: "mobile",
    });
    onClose();
  };

  return (
    <div className="addContentPopupWrapper">
      <div className="bigTitle">Which kind of content?</div>

      <div className="addContentButtons">
        <div className="addContentButton" onClick={_addResource}>
          {ICONS.plus} <span>Add a recommendation</span>
        </div>
        <div className="addContentButton" onClick={_createCollection}>
          {ICONS.list2} <span>Create a new collection</span>
        </div>
      </div>
    </div>
  );
};

export default AddContentPopup;
