import cloneDeep from "clone-deep";
import QuickAddCollectionItemActionTypes from "./action-types";

const initialState = {
  isLoaderActive: false,
  pagination: {
    pageNo: 1,
    hasMore: true,
    pageSize: 20,
  },
  searchText: "",
  filteredCollections: [],
  defaultsCollections: {},
  recentlyUsedCollection: {},
  activeResource: {},
};

export const QuickAddCollectionItemReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case QuickAddCollectionItemActionTypes.SET_IS_LOADER_ACTIVE: {
      newState = {
        ...newState,
        isLoaderActive: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.SET_PAGINATION: {
      newState = {
        ...newState,
        pagination: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.SET_SEARCH_TEXT: {
      newState = {
        ...newState,
        searchText: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.SET_FILTERED_COLLECTIONS: {
      newState = {
        ...newState,
        filteredCollections: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.SET_DEFAULTS_COLLECTIONS: {
      newState = {
        ...newState,
        defaultsCollections: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.SET_RECENTLY_USED_COLLECTION: {
      newState = {
        ...newState,
        recentlyUsedCollection: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.SET_ACTIVE_RESOURCE: {
      newState = {
        ...newState,
        activeResource: action.payload,
      };
      break;
    }
    case QuickAddCollectionItemActionTypes.RESET_STORE: {
      newState = cloneDeep(initialState);
      break;
    }
    default: {
    }
  }
  return newState;
};
