import React, { Component, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import TypeFilter from "../../../../components/type-filter/type-filter";
import Button from "../../../../components/buttons/buttons";
import { useSelector } from "react-redux";
import LinkPreviewImage from "../../../../components/core/link-previewer/link-previewer";
import ResourceUtils from "../../resource-utils";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import AIAssitanceFeedback from "../ai-assistance-feedback/ai-assistance-feedback";
import ResourceWizardSteps from "../../constants/resource-wizard-steps";
import ResourceTopicSelector from "../resource-topic-selector/resource-topic-selector";
import ResourceTopic from "../resource-topic/resource-topic";
import ResourceWizardMobileButton from "../resource-wizard-mobile-buttons/resource-wizard-mobile-buttons";

const ResourceTopicEditStep = (
  {
    isAi = true,
    onPublishOverWrite = null,
    submitButtonText = "Publish now!",
  } = {
    isAi: true,
    onPublishOverWrite: null,
    submitButtonText: "Publish now!",
  }
) => {
  const { localResource, imageFile, selectedType, selectedTopics } =
    useSelector((state) => state.resourceWizardStore);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopicSelectorOpen, setIsTopicSelectorOpen] = useState(false);

  const _openTopicSelector = () => {
    setIsTopicSelectorOpen(true);
  };

  const _submit = async () => {
    // Submit the resource
    setIsLoading(true);
    let success = false;
    if (onPublishOverWrite) {
      // Format the localResource
      const formattedResource = ResourceUtils.prepareFinalResourceObject();
      formattedResource.imageFile = imageFile;
      ResourceUtils.recordFeedback({
        entityType: "collection-item",
        feedback: "not-response",
        publishStatus: "published",
      });
      await onPublishOverWrite(formattedResource);
      success = true;
      ResourceUtils.resetWizard();
    } else {
      const data = await ResourceUtils.submitResource();
      if (data) {
        success = true;
      }
      if (success) {
        ResourceUtils.resetWizard();
        // Show success message
        GeneralHelper.showToast({
          primaryText: "Resource added successfully",
          additionalClass: "green",
          icon: ICONS.addFile,
        });
      } else {
        // Show error message
        GeneralHelper.showToast({
          primaryText: "Failed to add the resource",
          additionalClass: "error",
          icon: ICONS.addFile,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="innerWrapper">
        <div className="section">
          <div
            className={
              "sectionInnerWrapper step4 addTopic d-grid" + (isAi ? " ai " : "")
            }
          >
            <div
              className="closeIcon"
              onClick={(e) => ResourceUtils.resetWizard()}
            >
              {ICONS.crossIcon}
            </div>{" "}
            {/* this div will change only */}
            <div className="info">
              <div
                className={
                  "stepTitle " + (isTopicSelectorOpen ? "mobileShow" : "")
                }
              >
                {" "}
                🏷️ Add Topics for this resource{" "}
              </div>
              <ResourceTopicSelector
                isVisible={isTopicSelectorOpen}
                onDismiss={() => setIsTopicSelectorOpen(false)}
                onSave={() => setIsTopicSelectorOpen(false)}
                selectedTopics={selectedTopics}
                renderMobile={true}
              />
              <div
                className={
                  "stepDesc " + (isTopicSelectorOpen ? "mobileShow" : "")
                }
              >
                <div className="mainText">
                  Help others find this resource. Tag with one or more topics,
                  try to be accurate!
                </div>
                <div className="aiAssistedText">
                  <div className="imgWrap">
                    <img
                      src={require("../../../../assets/images/Sparkle_filled.png")}
                      alt=""
                    />
                  </div>{" "}
                  <span>AI assisted</span>
                </div>
              </div>
            </div>
            <div className="editableInputArea">
              <div
                className="imageWrapper"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ResourceUtils.switchStep(ResourceWizardSteps.META_DATA_EDIT);
                }}
              >
                <LinkPreviewImage
                  link={localResource?.sourceUrl}
                  staticImageUrl={imageFile}
                  style={{ width: "56px", height: "100%", objectFit: "cover" }}
                />
              </div>

              <div className="right">
                <div className="innerWrapper">
                  <div className="itemTitleWrapper">
                    <div
                      className="itemTitle"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        ResourceUtils.switchStep(
                          ResourceWizardSteps.META_DATA_EDIT
                        );
                      }}
                    >
                      {localResource?.title ? (
                        localResource?.title
                      ) : (
                        <i>Untitled (Click to change the title)</i>
                      )}
                    </div>
                  </div>

                  <div className="bottomWrapper">
                    <div className="topics p-0 border-0">
                      {selectedType ? (
                        <TypeFilter
                          title={selectedType?.name}
                          color={selectedType?.color}
                          key={selectedType?.id}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            ResourceUtils.switchStep(
                              ResourceWizardSteps.PREVIEW_WITH_TYPE_EDIT
                            );
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      {selectedTopics?.map((label, index) => (
                        <ResourceTopic
                          topicTitle={label?.displayName}
                          isSelected={false}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // _updateTopicSelection(label);
                            _openTopicSelector();
                          }}
                        />
                      ))}
                    </div>

                    <div className="buttons d-flex">
                      {selectedTopics?.length ? (
                        <></>
                      ) : (
                        <>
                          <Button
                            icon={ICONS.plus}
                            iconPos={"left"}
                            size={"extraSmall"}
                            type={"contrast"}
                            text="Add Topics"
                            onClick={(e) => setIsTopicSelectorOpen(true)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="buttons">
                  {/* change the Type to "default" to activate the button */}
                  {isLoading ? (
                    // <Spinner withContainer={false} loaderSize="25px" />
                    <div className="loaderCustom"></div>
                  ) : (
                    <Button
                      size={"small"}
                      type={"brand"}
                      text={submitButtonText}
                      onClick={_submit}
                    />
                  )}
                </div>
              </div>
            </div>
            <ResourceTopicSelector
              isVisible={isTopicSelectorOpen}
              onDismiss={() => setIsTopicSelectorOpen(false)}
              onSave={() => setIsTopicSelectorOpen(false)}
              selectedTopics={selectedTopics}
            />
          </div>
          <AIAssitanceFeedback 
            entityType={onPublishOverWrite ? "collection-item" : "community-resource"}
          />
        </div>
      </div>

      <ResourceWizardMobileButton
        stepNumber={2}
        buttonText={isLoading ? "Publishing..." : submitButtonText}
        buttonType={isLoading ? "disabled" : "brand"}
        onClick={_submit}
      />
    </>
  );
};

export default ResourceTopicEditStep;
