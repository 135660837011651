import axios from "axios";
import ApiCalls from "../../shared/api";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import { store } from "../../stores/store";
import { UserStoreActions } from "../../stores/user-store/actions";
import { EventEmitter } from "../../shared/utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";
import cloneDeep from "clone-deep";

const UserUtils = {
  loadUserOrgs: async () => {
    try {
      const { data } = await ApiCalls.user.private.fetchUserOrgs();
      // Remove org with id 'xYyMG49YiBE'
      if (data?.organisations?.length) {
        const updatedOrgs = data?.organisations?.filter(
          (org) => org.uuid !== "xYyMG49YiBE"
        );
        store.dispatch(
          UserStoreActions.staticActions.setUserOrgs(updatedOrgs)
        );
      }
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  loadMyQuests: async () => {
    try {
      const { data } = await ApiCalls.user.private.fetchMyQuests();
      if (data?.content?.length) {
        store.dispatch(
          UserStoreActions.staticActions.setMyQuests(data?.content)
        );
      }
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  loadFollowingQuests: async () => {
    const { userStore } = store.getState();
    if (userStore.profileData?.uuid) {
      try {
        const { data } = await ApiCalls.user.private.fetchFollowingQuests(
          userStore.profileData?.uuid
        );
        if (data?.length) {
          store.dispatch(
            UserStoreActions.staticActions.setFollowingQuests(data)
          );
        }
      } catch (error) {
        console.log("error :>>", error);
      }
    }
  },
  loadFollowingResources: async () => {
    try {
      const { data } = await ApiCalls.user.private.fetchFollowingResources();
      if (data?.length && data[0].content?.length) {
        store.dispatch(
          UserStoreActions.staticActions.setFollowingMyResources(
            data[0].content
          )
        );
      }
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  loadProfile: async () => {},
  updateProfile: async (profile) => {
    try {
      const { data } = await ApiCalls.user.private.updateProfile(profile);
      if (data) {
        store.dispatch(UserStoreActions.staticActions.setProfileData(data));
      }
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  initiliazeUser: async () => {
    try {
      const { data } = await ApiCalls.user.private.initializeUser();
      if (data) {
        store.dispatch(UserStoreActions.staticActions.setProfileData(data));
      }
      // Check if need to show email opt popup
      if (!data?.allowEmailCommSeen) {
        EventEmitter.dispatch(SiteWiseEvents.SHOW_EMAIL_OPT_IN_POPUP);
      }
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  clearStore: () => {
    store.dispatch(UserStoreActions.staticActions.clearProfileData());
    store.dispatch(UserStoreActions.staticActions.setUserOrgs([]));
  },
  uploadProfileImage: async (file, userId) => {
    const { REACT_APP_AUTH_ENDPOINT_BASE_URL } = window;
    const url = `${REACT_APP_AUTH_ENDPOINT_BASE_URL}/media-management/api/v1/profile-image/me?isOverwrite=true`;
    const formData = new FormData();
    const authToken = AuthHelper.getToken();
    formData.append("profileImage", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const response = await axios.put(url, formData, config);
      return response.data.filename;
    } catch (error) {
      return "";
    }
  },
  unfollowResource: async (resource) => {
    // First remove from store
    const { userStore } = store.getState();
    const { followingMyResources } = userStore;
    const updatedFollowingMyResources = followingMyResources.filter(
      (item) => item.uuid !== resource.uuid
    );
    store.dispatch(
      UserStoreActions.staticActions.setFollowingMyResources(
        updatedFollowingMyResources
      )
    );
    // Then remove from api
    try {
      await ApiCalls.user.private.unfollowResource(resource.uuid);
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  unfollowCollection: async (collection) => {
    // First remove from store
    const { userStore } = store.getState();
    const { followingQuests } = userStore;
    const updatedFollowingQuests = followingQuests.filter(
      (item) => item.uuid !== collection.uuid
    );
    store.dispatch(
      UserStoreActions.staticActions.setFollowingQuests(updatedFollowingQuests)
    );
    // Then remove from api
    try {
      await ApiCalls.user.private.unfollowCollection(collection.uuid);
    } catch (error) {
      console.log("error :>>", error);
    }
  },
  setEmailPreference: async (preference) => {
    // Get profiledata from store
    const { userStore } = store.getState();
    const { profileData } = cloneDeep(userStore);
    // Update profile data
    profileData.newsletterPref = preference;
    profileData.allowEmailComm = preference;
    if (!profileData.allowEmailCommSeen) {
      profileData.allowEmailCommSeen = true;
    }
    // Update store
    store.dispatch(UserStoreActions.staticActions.setProfileData(profileData));
    // Update api
    try {
      await ApiCalls.user.private.updateProfile(profileData);
    } catch (error) {
      console.log("error :>>", error);
    }
  },
};

export default UserUtils;
