import { HttpClient } from "../clients/http-client";

const { ProtectedV2Client, PublicV2Client } = HttpClient;

const CollectionApi = {
  public: {
    getCollection: (collectionUuid) =>
      PublicV2Client.get(`/collections/${collectionUuid}`),
  },
  private: {
    createCollection: (collection) =>
      ProtectedV2Client.post(`/collections`, collection),
    updateCollection: ({ collectionUuid, collection } = {}) =>
      ProtectedV2Client.put(`/collections/${collectionUuid}`, collection),
    deleteCollection: (collectionUuid) =>
      ProtectedV2Client.delete(`/collections/${collectionUuid}`),
    getCollection: (collectionUuid) =>
      ProtectedV2Client.get(`/collections/${collectionUuid}`),
    deleteCollectionItem: ({ collectionUuid, collectionItemUuid }) =>
      ProtectedV2Client.delete(
        `/collections/${collectionUuid}/collection-items/${collectionItemUuid}`
      ),
    addCollectionItem: ({ collectionUuid, collectionItem }) =>
      ProtectedV2Client.post(
        `/collections/${collectionUuid}/collection-items`,
        collectionItem
      ),
    updateCollectionItemsOrder: ({ collectionUuid, collectionItemUuids }) =>
      ProtectedV2Client.put(
        `/collections/${collectionUuid}/collection-items/display-order`,
        collectionItemUuids
      ),
    // To get all collections of a user filtered by access, isPublished, orgId and search query
    searchMyCollections: ({
      query,
      pageNo,
      pageSize,
      orgId,
      access,
      isPublished,
      listname,
      sortBy,
    }) => {
      const params = {
        query,
        pageNo,
        pageSize,
        orgId,
        access,
        listname,
        sortBy,
      };
      return ProtectedV2Client.get(`/collections`, { params });
    },
    // To add a resource to a collection
    addItemToCollection: ({ collectionUuid, resource }) =>
      ProtectedV2Client.post(
        `/collections/${collectionUuid}/collection-items`,
        resource
      ),
  },
};
export default CollectionApi;
