/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import router from "../top-level-declarations/history";

const useCollection = (collection) => {
  const [collectionRoute, setCollectionRoute] = useState("");

  const _generateCollectionRoute = () => {
    if (collection?.uuid && collection?.owningOrganizationId) {
      setCollectionRoute(
        `/c/${collection?.owningOrganizationId}/q/${collection?.uuid}`
      );
    }
  };

  const navigateToCollection = () => {
    router.navigate(collectionRoute);
  };

  useEffect(() => {
    _generateCollectionRoute();
  }, [collection?.uuid]);

  return {
    collectionRoute,
    navigateToCollection,
  };
};

export default useCollection;
