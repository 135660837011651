import React, { useState, useRef } from "react";
import ICONS from "../../assets/quester-icons";
import Button from "../buttons/buttons";
import "./premium-content-popup.scss";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import ApiCalls from "../../shared/api";

const stripePromise = loadStripe(
  "pk_test_51OVE4MGrmmTbTLU5MBr22O59icnhUehQRo3TLR8YzcKv4Iq43M3DEr1im1AxRsURflSEkG5t9aKlXxUXN4kqLKNx004pfbRNnf"
);

const PremiumContentPopup = (props) => {
  const [sessionId, setSessionId] = useState();
  const [clientSecret, setClientSecret] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const _initiatePayment = async () => {
    setIsPaymentLoading(true);
    setIsPaymentModalOpen(true);
    try {
      const {
        data: { sessionId, clientSecret },
      } = await ApiCalls.subscripion.private.getStripeSessionId({
        cancelUrl: "https://swr.dev.quester.io/cancel-subs",
        owningOrganizationId: "0Rr5D27ZN3c",
        priceId: "price_1OhqVzGrmmTbTLU594CJ3fOf",
        successUrl: "https://swr.dev.quester.io/success-subs",
      });
      setSessionId(sessionId);
      setClientSecret(clientSecret);
    } catch (error) {
      console.log("error :>> ", error);
    }
    setIsPaymentLoading(false);
  };

  const handleComplete = () => {};

  return (
    <div className="premiumContentBuyModal">
      <div className="overlay"></div>
      <div className="inner">
        {isPaymentModalOpen ? (
          <>
          {
            isPaymentLoading? (
                <h3>
                    Loading
                </h3>
            ): (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    onComplete: handleComplete,
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
                
            )
          }
          </>
        ) : (
          <>
            <div className="bgDesign">
              <img src={require("../../assets/images/confetti.png")} />
            </div>

            <div className="left">
              <div className="titleSection">
                <div className="bigTitle">IB Students and Tutors Premium</div>
                <div className="bigDesc">
                  Exclusive questions and notes by actual IB examiners and
                  subject experts for the 2025 syllabus
                </div>
              </div>

              <div className="benefits">
                <div className="benefit">
                  <div className="icon">{ICONS.student}</div>
                  <div className="texts">
                    <div className="label">Unlock Exclusive Chats</div>
                    <div className="desc">
                      24/7 access to IB experts (all with 40+ scores) that will
                      answer any questions you have about IB - guaranteed
                      response within a day
                    </div>
                  </div>
                </div>

                <div className="benefit">
                  <div className="icon">{ICONS.diamond}</div>
                  <div className="texts">
                    <div className="label">Unlock Exclusive Content</div>
                    <div className="desc">
                      Access Chemistry realistic exam-like questions
                      and extensive notes written by <strong>actual</strong> IB
                      examiners <strong>Only available on quester</strong>
                    </div>
                  </div>
                </div>

                <div className="benefit">
                  <div className="icon">{ICONS.globeStand}</div>
                  <div className="texts">
                    <div className="label">Reward the Community</div>
                    <div className="desc">
                      Revenue is shared with the content creators, and you get a
                      lovely little badge by your name. You absolute legend,
                      you.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right">
              <div className="titleSection">
                <div className="bigTitle">IB Students and Tutors Premium</div>
                <div className="bigDesc">
                  Exclusive questions and notes by actual IB examiners and
                  subject experts for the 2025 syllabus
                </div>
              </div>

              <div className="pricingTag">
                <div className="userImage">
                  <img
                    src={require("../../assets/images/thumbnail.png")}
                    alt=""
                  />
                </div>
                <div className="price">$9.99/mo</div>
                <div className="small">Premium member</div>
              </div>

              <div className="premiumButton" onClick={_initiatePayment}>
                <div className="btnInner">
                  <span>Become a Premium Member</span>
                </div>
              </div>

              <div className="cancelAnytime">Cancel anytime</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PremiumContentPopup;
