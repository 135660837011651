// import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { UserStoreReducer } from "./user-store/reducer";
import { MetaStoreReducer } from "./meta-store/reducer";
import { CommunityStoreReducer } from "./community-store/community-store-reducer";
import { CollectionStoreReducer } from "./collection-store/collection-store-reducer";
import { commentStoreReducer } from "./active-quest-comment-store/reducer";
import { NotificationStoreReducer } from "./notification-store/notification-store";
import { ResourceWizardReducer } from "./resource-wizard/reducer";
import { QuickAddCollectionItemReducer } from "./quick-add-collection-item-store/reducer";

const persistConfig = {
  key: "quester-v2",
  storage,
  keyPrefix: "",
  stateReconciler: hardSet,
  // blacklist: ["communityStore"],
};

const pReducer = persistReducer(persistConfig, MetaStoreReducer);

const rootReducer = combineReducers({
  metaStore: pReducer,
  userStore: UserStoreReducer,
  communityStore: CommunityStoreReducer,
  collectionStore: CollectionStoreReducer,
  commentStore: commentStoreReducer,
  notificationStore: NotificationStoreReducer,
  resourceWizardStore: ResourceWizardReducer,
  quickAddCollectionItemStore: QuickAddCollectionItemReducer,
});

export const store = configureStore(
  {
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  }
  // composeEnhancers(applyMiddleware())
);
export const persistor = persistStore(store);
