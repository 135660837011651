const commentStoreActionTypes = {
    TOGGLE_LOADER: "TOGGLE_QUEST_COMMENT_LOADER",
    CLEAR: "CLEAR_ACTIVE_QUEST_COMMENT_STORE",
    SET_ALL_COMMENTS: "SET_ALL_COMMENTS->QUEST_COMMENT_STORE",
    SET_ACTIVE_NESTED_COMMENTS: "SET_ACTIVE_NESTED_COMMENTS->QUEST_COMMENT_STORE",
    SET_ROOT_VIEW: "SET_ROOT_VIEW->QUEST_COMMENT_STORE",
    SET_NESTED_LEVEL_ROOT_ADDRESS: "SET_NESTED_LEVEL_ROOT_ADDRESS->QUEST_COMMENT_STORE",
    SET_EXPANDED: "SET_EXPANDED->QUEST_COMMENT_STORE",
    SET_REFERENCE_QUESTITEMID: "SET_REFERENCE_QUESTITEMID->QUEST_COMMENT_STORE",
    SET_REFERENCE_VIEW: "SET_REFERENCE_VIEW->QUEST_COMMENT_STORE",
    SET_REFERENCE_VIEW_COMMENTS: "SET_REFERENCE_VIEW_COMMENTS->QUEST_COMMENT_STORE",
    SET_PAGINATION: "SET_PAGINATION->QUEST_COMMENT_STORE",
    CLEAR_PAGINATION: "CLEAR_PAGINATION->QUEST_COMMENT_STORE",
    CLEAR_STORE: "CLEAR_STORE->QUEST_COMMENT_STORE"
  };
  
  export default commentStoreActionTypes;
  