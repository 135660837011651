import CommunityStoreActionTypes from "./community-store-action-types";

export const CommunityStoreActions = {
  staticActions: {
    setActiveCommunity: (community) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY,
      payload: community,
    }),
    setActiveCommunityId: (communityId) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_ID,
      payload: communityId,
    }),
    setActiveCommunityResources: (resources) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_RESOURCES,
      payload: resources,
    }),
    setActiveCommunityResourcesPagination: (pagination) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_RESOURCES_PAGINATION,
      payload: pagination,
    }),
    setActiveCommunityCollections: (collections) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_COLLECTIONS,
      payload: collections,
    }),
    setActiveCommunityMembers: (members) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_MEMBERS,
      payload: members,
    }),
    clearActiveCommunityData: () => ({
      type: CommunityStoreActionTypes.CLEAR_ACTIVE_COMMUNITY_DATA,
    }),
    toggleCollectionsLoaderStatus: (status) => ({
      type: CommunityStoreActionTypes.TOGGLE_COLLECTIONS_LOADER_STATUS,
      payload: status,
    }),
    toggleCommunityLoaderStatus: (status) => ({
      type: CommunityStoreActionTypes.TOGGLE_COMMUNITY_LOADER_STATUS,
      payload: status,
    }),
    toggleCommunityResourcesLoaderStatus: (status) => ({
      type: CommunityStoreActionTypes.TOGGLE_COMMUNITY_RESOURCES_LOADER_STATUS,
      payload: status,
    }),
    toggleCommunityMembersLoaderStatus: (status) => ({
      type: CommunityStoreActionTypes.TOGGLE_COMMUNITY_MEMBERS_LOADER_STATUS,
      payload: status,
    }),
    setActiveCommunityLabels: (labels) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_LABELS,
      payload: labels,
    }),
    setActiveCommunityTypes: (types) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_TYPES,
      payload: types,
    }),
    setSelectedCommunityLabels: (labels) => ({
      type: CommunityStoreActionTypes.SET_SELECTED_COMMUNITY_LABELS,
      payload: labels,
    }),
    setActiveCommunityCollectionsPagination: (pagination) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_COLLECTIONS_PAGINATION,
      payload: pagination,
    }),
    setActiveCommunityCollectionsSort: (sort) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_COMMUNITY_COLLECTIONS_SORT,
      payload: sort,
    }),
    setActiveTab: (tab) => ({
      type: CommunityStoreActionTypes.SET_ACTIVE_TAB,
      payload: tab,
    }),
    setSelectedCommunityTypes: (types) => ({
      type: CommunityStoreActionTypes.SET_SELECTED_COMMUNITY_TYPES,
      payload: types,
    }),
  },
};
