import cloneDeep from "clone-deep";
import NotificationStoreActionTypes from "./notification-store-action-types";

const initialState = {
  userSettings: null,
  fcm: {
    token: "",
    isNotificationEnabled: false,
    userId: "",
    refreshedOn: "",
    browserType: "",
  },
  notifications: [],
  notificationPagination: {
    currentPage: -1,
    pageSize: 100,
    canLoadMore: true,
  },
  unreadNotificationCount: 0,
  isLoaderActive: false,
};

export const NotificationStoreReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case NotificationStoreActionTypes.TOGGLE_LOADER: {
      newState = {
        ...newState,
        isLoaderActive: action.payload,
      };
      break;
    }
    case NotificationStoreActionTypes.SET_USER_SETTINGS: {
      newState = {
        ...newState,
        userSettings: action.payload,
      };
      break;
    }
    case NotificationStoreActionTypes.SET_FCM_TOKEN: {
      newState = {
        ...newState,
        fcm: {
          ...newState.fcm,
          token: action.payload,
        },
      };
      break;
    }
    case NotificationStoreActionTypes.SET_FCM_NOTIFICATION_ENABLED: {
      newState = {
        ...newState,
        fcm: {
          ...newState.fcm,
          isNotificationEnabled: action.payload,
        },
      };
      break;
    }
    case NotificationStoreActionTypes.SET_FCM_USERID: {
      newState = {
        ...newState,
        fcm: {
          ...newState.fcm,
          userId: action.payload,
        },
      };
      break;
    }
    case NotificationStoreActionTypes.SET_FCM_REFRESHED_ON: {
      newState = {
        ...newState,
        fcm: {
          ...newState.fcm,
          refreshedOn: action.payload,
        },
      };
      break;
    }
    case NotificationStoreActionTypes.SET_FCM_BROWSER_TYPE: {
      newState = {
        ...newState,
        fcm: {
          ...newState.fcm,
          browserType: action.payload,
        },
      };
      break;
    }
    case NotificationStoreActionTypes.SET_NOTIFICATIONS: {
      newState = {
        ...newState,
        notifications: action.payload,
      };
      break;
    }
    case NotificationStoreActionTypes.SET_NOTIFICATION_PAGINATION: {
      newState = {
        ...newState,
        notificationPagination: action.payload,
      };
      break;
    }
    case NotificationStoreActionTypes.SET_UNREAD_NOTIFICATION_COUNT: {
      newState = {
        ...newState,
        unreadNotificationCount: action.payload,
      };
      break;
    }

    case NotificationStoreActionTypes.CLEAR_STORE: {
      newState = cloneDeep(initialState);
      break;
    }

    default: {
    }
  }
  return newState;
};
