const ResourceWizardActionTypes = {
  SET_INPUT_LINK: "RESOURCE_WIZARD->SET_INPUT_LINK",
  SET_LOCAL_RESOURCE: "RESOURCE_WIZARD->SET_LOCAL_RESOURCE",
  SET_SELECTED_TOPICS: "RESOURCE_WIZARD->SET_SELECTED_TOPICS",
  SET_SELECTED_TYPE: "RESOURCE_WIZARD->SET_SELECTED_TYPE",
  SET_CURRENT_STEP: "RESOURCE_WIZARD->SET_CURRENT_STEP",
  SET_IMAGE: "RESOURCE_WIZARD->SET_IMAGE",
  RESET_STORE: "RESOURCE_WIZARD->RESET_STORE",
  ENABLE_WIZARD: "RESOURCE_WIZARD->ENABLE_WIZARD",
  SET_IS_AI_LOADING: "RESOURCE_WIZARD->SET_IS_AI_LOADING",
  SET_IS_AI_FEEDBACK_RECORDED: "RESOURCE_WIZARD->SET_IS_AI_FEEDBACK_RECORDED",
};

export default ResourceWizardActionTypes;
