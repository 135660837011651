import React, { useState } from "react";
import ICONS from "../../assets/quester-icons";
import "./type-filter.scss";

const TypeFilter = ({
  title = "Untitled",
  color = "none",
  filterApplied = false,
  featured = false,
  disabled = false,
  onClick = () => {},
  isSelected = false,
}) => {
  return filterApplied ? (
    // view when filter is applied
    <div className={"filterItemType type " + color}>
      <div className="text">
        <div className="circle"></div>
        <span>{title}</span>
      </div>
      <div className="remove"> {ICONS.crossIcon} </div>
    </div>
  ) : (
    // view mode
    <div
      className={
        "type " +
        (isSelected ? "active" : "") +
        " " +
        (featured ? "featured" : "") +
        " " +
        (disabled ? "disabled" : "") +
        " " +
        color +
        " "
      }
      
      title={title}
      onClick={onClick}
    >
      <div className="circle"
      style={{
        backgroundColor: color,
      
      }}
      ></div>
      <span>{title}</span>
    </div>
  );
};

export default TypeFilter;
