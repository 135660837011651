import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import DatapointEditor from "../../../datapoint/component/datapoint-editor/datapoint-editor";
import { useSelector } from "react-redux";
import CommunityEditorUtils from "../../../community/community-editor-utils";

const DatapointsManager = ({ typeId }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const types = useSelector(
    (state) => state?.communityStore?.communityEditor?.types
  );
  const type = types?.find((type) => type.id === typeId);

  const _createNewDatapoint = () => {
    CommunityEditorUtils.createNewDatapointLocally({
      typeId,
    });
  };
  
  // const _confirmChanges = () => {
  //   if (type?.columnDefinitions?.length === 0) {
  //     setErrorMessage("Please add at least one datapoint");
  //     return;
  //   }
  // };

  return (
    <>
      <div className="datapointsSection">
        <div className="heading">
          {/* Edit Ratings - Selected (2/2) - Hidden (0) */}
        </div>

        <div className="quickControlContentWrapper">
          <div className="quickControlDatapoints">
            <div className="inner">
              {type?.columnDefinitions?.map((columnDefinition) => (
                <DatapointEditor
                  key={columnDefinition.columnId}
                  columnDefinition={columnDefinition}
                  typeId={typeId}
                />
              ))}

              <div className="addNewQuickControlCard">
                <div
                  className="text-center"
                  style={
                    type?.columnDefinitions?.length >= 2
                      ? {
                          opacity: 0.5,
                          cursor: "not-allowed",
                          pointerEvents: "none",
                        }
                      : {}
                  }
                >
                  {/* <div className="text">Drag & drop</div> */}
                  {/* <div className="text">or</div> */}
                  <Button
                    onClick={_createNewDatapoint}
                    type={"contrast"}
                    size={"extraSmall"}
                    text="Create new one"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <span className="errorText">
              {/* {
                type?.columnDefinitions?.length === 0? "Please add at least one datapoint" : ""
              } */}
            </span>
            {/* <Button
              type={"success"}
              size={"extraSmall"}
              icon={ICONS.checkCircle}
              iconPos={"left"}
              text="Confirm changes"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DatapointsManager;
