import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

const CollectionActivities = {
  addNewCollection: ({
    collectionName,
    collectionDescription,
    collectionResourceCount,
    collectionId = "",
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType: "collection",
      entityId: collectionId,
      entityValue1: collectionName,
      entityValue2: collectionDescription,
      entityValue3: collectionResourceCount,
      activityType: `add-new-collection`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    ActivityRecorder.writeToDB();
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent(`add-new-collection`, activity);
    }, 200);
  },
};

export default CollectionActivities;
