/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

const DebouncedInput = ({
  value: initialValue,
  onBlur,
  onInputChange = (value) => {},
  textArea = false,
  debounceTime = 300,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue] = useDebounce(value, debounceTime);

  // Update the local state and call the debounced onChange handler
  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  // Handle onBlur event and pass the current value
  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(value, event);
    }
  };

  useEffect(() => {
    if (onInputChange) {
      onInputChange(debouncedValue);
    }
  }, [debouncedValue]);

  const InputOrTextArea = textArea ? "textarea" : "input";

  return React.createElement(InputOrTextArea, {
    ...props, // Spread all other props to the input or textarea element
    value: value,
    onChange: handleChange,
    onBlur: handleBlur,
  });
};

export default DebouncedInput;
