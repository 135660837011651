const NotificationStoreActionTypes = {
  TOGGLE_LOADER: "NOTIFICATION_STORE->TOGGLE_LOADER",
  SET_USER_SETTINGS: "NOTIFICATION_STORE->SET_USER_SETTINGS",
  SET_FCM_TOKEN: "NOTIFICATION_STORE->SET_FCM_TOKEN",
  SET_FCM_NOTIFICATION_ENABLED:
    "NOTIFICATION_STORE->SET_FCM_NOTIFICATION_ENABLED",
  SET_FCM_USERID: "NOTIFICATION_STORE->SET_FCM_USERID",
  SET_FCM_REFRESHED_ON: "NOTIFICATION_STORE->SET_FCM_REFRESHED_ON",
  SET_FCM_BROWSER_TYPE: "NOTIFICATION_STORE->SET_FCM_BROWSER_TYPE",
  SET_NOTIFICATIONS: "NOTIFICATION_STORE->SET_NOTIFICATIONS",
  SET_NOTIFICATION_PAGINATION:
    "NOTIFICATION_STORE->SET_NOTIFICATION_PAGINATION",
  SET_UNREAD_NOTIFICATION_COUNT:
    "NOTIFICATION_STORE->SET_UNREAD_NOTIFICATION_COUNT",
  CLEAR_STORE: "NOTIFICATION_STORE->CLEAR_STORE",
};

export default NotificationStoreActionTypes;
