import cloneDeep from "clone-deep";
import { CommunityEditorActions } from "../../stores/community-store/community-editor-actions";
import cuid from "cuid";
import ApiCalls from "../../shared/api";
import GeneralHelper from "../../shared/helper-methods/general-helpers";
import ICONS from "../../assets/quester-icons";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import router from "../../shared/top-level-declarations/history";
import { store } from "../../stores/store";
import CDNUploader from "../../shared/utils/cdn-uploader";
import CommunityUtils from "./community-utils";
import DemoResource from "../../constants/demo-resource";
import NewCommunityDemoData from "../../constants/new-community-demo-data";
import deepEqual from "deep-equal";

const CommunityEditorUtils = {
  /****************** Public methods below *****************/
  initiateCommunityEditor: async (communityId) => {
    if (!AuthHelper.isLoggedIn()) {
      // Redirect to login page
      AuthHelper.login();
    }
    CommunityEditorActions.staticActions.setLoaderStatus(true);
    // Get community and types data
    await Promise.allSettled([
      CommunityEditorUtils._loadCommunityData(communityId),
      CommunityEditorUtils._loadTypesData(communityId),
    ]);
    // Check if has edit access
    const hasEditAccess = CommunityEditorUtils._checkEditAccess();
    if (!hasEditAccess) {
      // Redirect to community page
      // CommunityEditorUtils._redirectToCommunityPage();
    }
    CommunityEditorActions.staticActions.setLoaderStatus(false);
  },
  setActiveTab: (tab) => {
    CommunityEditorActions.staticActions.setActiveTab(tab);
  },
  setCommunityUncommitedChanges: (updatedCommunity) => {
    // In editor community data add a new key '_uncommitedChanges' and set the updated community data
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedEditorCommunity = cloneDeep(editorCommunity);
    updatedEditorCommunity._uncommitedChanges = updatedCommunity;
    CommunityEditorActions.staticActions.setEditorCommunity(
      updatedEditorCommunity
    );
  },
  commitCommunityChanges: () => {
    // Set the uncommited changes to the community data
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedCommunity = cloneDeep({
      ...editorCommunity,
      ...editorCommunity._uncommitedChanges,
    });
    delete updatedCommunity._uncommitedChanges;
    CommunityEditorActions.staticActions.setEditorCommunity(updatedCommunity);
  },
  resetCommunityUncommitedChanges: () => {
    // Remove the uncommited changes from the community data
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedCommunity = cloneDeep(editorCommunity);
    delete updatedCommunity._uncommitedChanges;
    CommunityEditorActions.staticActions.setEditorCommunity(updatedCommunity);
  },
  createNewTypeLocally: () => {
    // Create a new type object and add it to the types array
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const newType = {
      id: cuid(),
      name: "",
      description: "",
      color: "#964B00",
      columnDefinitions: [],
      _accordians: {
        1: true,
        2: false,
        3: false,
      },
      _isNew: true,
      _uncommitedChanges: {
        name: "",
        description: "",
        color: "#964B00",
        columnDefinitions: [],
        _accordians: {
          1: true,
          2: false,
          3: false,
        },
      },
    };
    CommunityEditorActions.staticActions.setTypes([...types, { ...newType }]);
  },
  setTypeGeneralUncommitedChanges: ({ typeId, type }) => {
    // Set the uncommited changes to the type data
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const uncommitedChanges = { ...type };
        delete uncommitedChanges.columnDefinitions;
        delete uncommitedChanges._restorePoint;
        return { ...t, _uncommitedChanges: uncommitedChanges };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  commitTypeGeneralChanges: (typeId) => {
    console.log("typeId :>> ", typeId);
    // Set the uncommited changes to the type data
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        delete t._uncommitedChanges._accordians;
        delete t._uncommitedChanges.columnDefinitions;
        console.log("t._uncommitedChanges :>> ", t._uncommitedChanges);
        const updatedType = { ...t, ...t._uncommitedChanges };
        console.log("updatedType :>> ", updatedType);
        delete updatedType._uncommitedChanges;
        return updatedType;
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  resetTypeGeneralUncommitedChanges: (typeId) => {
    // Remove the uncommited changes from the type data
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedType = { ...t };
        delete updatedType._uncommitedChanges;
        return updatedType;
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  createNewDatapointLocally: ({ typeId }) => {
    // Create a new datapoint object and add it to the columnDefinitions array of the type
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const newDataPointStructure =
          CommunityEditorUtils._getNewDatapointStructure();
        newDataPointStructure._uncommitedChanges = {
          ...newDataPointStructure,
        };
        return {
          ...t,
          columnDefinitions: [...t.columnDefinitions, newDataPointStructure],
        };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  setDatapointUncommitedChanges: ({ typeId, datapointId, datapoint }) => {
    // Set the uncommited changes to the datapoint data
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedDatapoints = t.columnDefinitions.map((d) => {
          if (d.columnId === datapointId) {
            return { ...d, _uncommitedChanges: datapoint };
          }
          return d;
        });
        return { ...t, columnDefinitions: updatedDatapoints };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  commitDatapointChanges: ({ typeId, datapointId }) => {
    // Set the uncommited changes to the datapoint data
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedDatapoints = t.columnDefinitions.map((d) => {
          if (d.columnId === datapointId) {
            const updatedDatapoint = { ...d, ...d._uncommitedChanges };
            delete updatedDatapoint._uncommitedChanges;
            return updatedDatapoint;
          }
          return d;
        });
        return { ...t, columnDefinitions: updatedDatapoints };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  resetDatapointUncommitedChanges: ({ typeId, datapointId }) => {
    // Remove the uncommited changes from the datapoint data
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedDatapoints = t.columnDefinitions.map((d) => {
          if (d.columnId === datapointId) {
            const updatedDatapoint = { ...d };
            delete updatedDatapoint._uncommitedChanges;
            return updatedDatapoint;
          }
          return d;
        });
        return { ...t, columnDefinitions: updatedDatapoints };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  deleteLocalDatapoint: ({ typeId, datapointId }) => {
    // Delete the datapoint locally
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedDatapoints = t.columnDefinitions.filter(
          (d) => d.columnId !== datapointId
        );
        return { ...t, columnDefinitions: updatedDatapoints };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  setTypeAccordianState: ({ typeId, accordianState }) => {
    // Each type will have an accordian state to keep track of the open/close state of the accordians.
    // _accordians: { step1: true, step2: true, step3: false }
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        return { ...t, _accordians: accordianState };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  submitNewCommunity: async (newCommunity) => {
    try {
      console.log('newCommunity :>> ', newCommunity);
      const formattedNewCommunity =
      CommunityEditorUtils._formatNewCommunity(newCommunity);
      console.log('formattedNewCommunity :>> ', formattedNewCommunity);
      CommunityEditorActions.staticActions.setLoaderStatus(true);
      const { data: communityResponse } = await ApiCalls.org.private.createOrg(
        formattedNewCommunity
      );
      if (newCommunity.thumbnail || newCommunity.background) {
        await CommunityEditorUtils._processUploadsForNewCommunity({
          community: communityResponse,
          thumbnail: newCommunity.thumbnail,
          background: newCommunity.background,
        });
      }
      return communityResponse;
    } catch (error) {
      console.log("error :>> ", error);
      throw error;
    }
    CommunityEditorActions.staticActions.setLoaderStatus(false);
  },
  updateCommunityProperty: ({ property, value }) => {
    // Make changes to the community data _uncommitedChanges
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedCommunity = cloneDeep(editorCommunity);
    updatedCommunity._uncommitedChanges[property] = value;
    // Use setEditorCommunityUncommitedChanges to update the community data
    CommunityEditorUtils.setCommunityUncommitedChanges(
      updatedCommunity._uncommitedChanges
    );
  },
  updateTypeProperty: ({ typeId, property, value }) => {
    // Make changes to the type data _uncommitedChanges
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedType = cloneDeep(t);
        if (updatedType._uncommitedChanges) {
          updatedType._uncommitedChanges[property] = value;
        }
        return updatedType;
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  updateDatapointProperty: ({ typeId, datapointId, property, value }) => {
    // Make changes to the datapoint data _uncommitedChanges
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const updatedDatapoints = t.columnDefinitions.map((d) => {
          if (d.columnId === datapointId) {
            const updatedDatapoint = cloneDeep(d);
            if (updatedDatapoint._uncommitedChanges) {
              updatedDatapoint._uncommitedChanges[property] = value;
            }
            return updatedDatapoint;
          }
          return d;
        });
        return { ...t, columnDefinitions: updatedDatapoints };
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  submitCommunityChanges: async () => {
    // Submit the community changes to the server
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    try {
      // Upload the new image if any
      if (editorCommunity?._newImage) {
        editorCommunity.orgIconImageURL =
          await CommunityEditorUtils._getNewImageURL(
            editorCommunity._newImage,
            editorCommunity.uuid
          );
      }
      CommunityEditorActions.staticActions.setLoaderStatus(true);
      await ApiCalls.org.private.updateOrg(
        editorCommunity.uuid,
        editorCommunity
      );
      // Show success message
      GeneralHelper.showToast({
        primaryText: "Community updated successfully",
        additionalClass: "green",
        icon: ICONS.addFile,
      });
      // Commit the changes
      CommunityEditorUtils._loadCommunityData(editorCommunity.uuid, true);
      CommunityUtils._loadCommunity(editorCommunity.uuid);
    } catch (error) {
      // Show error message
      GeneralHelper.showToast({
        primaryText: "Failed to update community",
        additionalClass: "error",
        icon: ICONS.addFile,
      });
      console.log("error :>> ", error);
    }
    CommunityEditorActions.staticActions.setLoaderStatus(false);
  },
  enableNewTypeWizard: () => {
    // Create a new type locally and set the new type wizard status to true
    CommunityEditorUtils.createNewTypeLocally();
    // Enable the new type wizard
    CommunityEditorActions.staticActions.setNewWizardStatus(true);
  },
  disableNewTypeWizard: () => {
    // Disable the new type wizard
    CommunityEditorActions.staticActions.setNewWizardStatus(false);
  },
  checkIfDatapointNameUnique: ({ typeId, datapointId, displayName }) => {
    // Check if the datapoint name is note taken by any other datapoint
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const type = types.find((t) => t.id === typeId);
    let isUnique = true;
    type?.columnDefinitions?.forEach((d) => {
      if (d.columnId !== datapointId && d.displayName === displayName) {
        isUnique = false;
      }
    });
    return isUnique;
  },
  generateResourceFromType: (type) => {
    const resource = { ...DemoResource };
    // Attach dtableSchema to the resource
    resource.dtableSchema = { ...type };
    resource._voteStat = type?.columnDefinitions?.map((columnDefinition) => {
      return {
        cellTitle: columnDefinition.displayName,
        cellDescription: columnDefinition.description,
        columnId: columnDefinition.columnId,
        positiveValue: {
          value: columnDefinition.pickListValues[0].value,
          percentage: 70,
          count: 7,
          hasVoted: false,
        },
        negativeValue: {
          value: columnDefinition.pickListValues[1].value,
          percentage: 30,
          count: 3,
          hasVoted: false,
        },
      };
    });
    return resource;
  },
  validateType: (type) => {
    // Main validations
    // 1. description and color should not be empty
    // 2. Should have at least one columnDefinition
    // 3. There should not be any uncommited changes in the type or any of the columnDefinitions
    const { description, color, columnDefinitions } = type;
    let isValid = true;
    let errorMessage = "";
    if (!description?.length) {
      isValid = false;
      errorMessage = "Name is required";
    } else if (!color?.length) {
      isValid = false;
      errorMessage = "Color is required";
    } else if (!columnDefinitions?.length) {
      isValid = false;
      errorMessage = "At least one column definition is required";
    }
    // Check if type or any of the columnDefinitions have uncommited changes
    if (type._uncommitedChanges) {
      isValid = false;
      errorMessage = "Please save all the changes before submitting";
    } else {
      columnDefinitions.forEach((d) => {
        if (d._uncommitedChanges) {
          isValid = false;
          errorMessage = "Please save all the changes before submitting";
        }
      });
    }
    // Also check if the type name is unique
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const hasDuplicate = types.find(
      (t) =>
        t.id !== type.id &&
        t.description.toLowerCase() === type.description.toLowerCase()
    );
    if (hasDuplicate) {
      isValid = false;
      errorMessage = "Another type exists with the same name";
    }
    return { isValid, errorMessage };
  },
  submitNewType: async (type) => {
    // Submit the new type data to the server
    // Before submitting, it will need some formatting
    const formattedNewType = CommunityEditorUtils._formatNewType(type);
    // Submit the new type to the server
    try {
      CommunityEditorActions.staticActions.setLoaderStatus(true);
      const { data: newlyCreatedType } = await ApiCalls.org.private.createType(
        formattedNewType
      );
      // Show success message
      GeneralHelper.showToast({
        primaryText: "New type created successfully",
        additionalClass: "green",
        icon: ICONS.addFile,
      });
      // Refresh the types data
      const {
        communityEditor: { editorCommunity },
      } = CommunityEditorUtils._getCommunityStore();
      await CommunityEditorUtils._loadTypesData(editorCommunity.uuid);
    } catch (error) {
      // Show error message
      GeneralHelper.showToast({
        primaryText: "Failed to create new type",
        additionalClass: "error",
        icon: ICONS.addFile,
      });
      console.log("error :>> ", error);
      throw error;
    }
    CommunityEditorActions.staticActions.setLoaderStatus(false);
  },
  submitTypeChanges: async (typeId) => {
    // Submit the type changes to the server
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const type = types.find((t) => t.id === typeId);
    try {
      // Prepare the type data before submitting
      const formattedType = CommunityEditorUtils._formatType(type);
      CommunityEditorActions.staticActions.setLoaderStatus(true);
      await ApiCalls.org.private.updateType(typeId, formattedType);
      // Show success message
      GeneralHelper.showToast({
        primaryText: "Type updated successfully",
        additionalClass: "green",
        icon: ICONS.addFile,
      });
      // Refresh the types data
      const {
        communityEditor: { editorCommunity },
      } = CommunityEditorUtils._getCommunityStore();
      await CommunityEditorUtils._loadTypesData(editorCommunity.uuid);
    } catch (error) {
      // Show error message
      GeneralHelper.showToast({
        primaryText: "Failed to update type",
        additionalClass: "error",
        icon: ICONS.addFile,
      });
      console.log("error :>> ", error);
    }
    CommunityEditorActions.staticActions.setLoaderStatus(false);
  },
  restoreType: (typeId) => {
    // Restore the type data to the restore point
    const {
      communityEditor: { types },
    } = CommunityEditorUtils._getCommunityStore();
    const updatedTypes = types.map((t) => {
      if (t.id === typeId) {
        const restoredTyoe = { ...t._restorePoint };
        restoredTyoe._restorePoint = { ...t._restorePoint };
        return restoredTyoe;
      }
      return t;
    });
    CommunityEditorActions.staticActions.setTypes(updatedTypes);
  },
  checkIfTypeHasChanges: (type) => {
    // Check if the type has changes for description, color or any of the columnDefinitions
    const { _restorePoint } = type;
    if (type._uncommitedChanges) {
      return true;
    }
    if (type.description !== _restorePoint.description) {
      return true;
    }
    if (type.color !== _restorePoint.color) {
      return true;
    }
    // Perform a deep comparison of columnDefinitions
    if (!deepEqual(type.columnDefinitions, _restorePoint.columnDefinitions)) {
      return true;
    }
    return false;
  },
  /****************** Private methods below *****************/
  _getCommunityStore: () => {
    const { communityStore } = store.getState();
    return cloneDeep(communityStore);
  },
  _getNewDatapointStructure: () => {
    // At the time of submitting, need to add proper columnId and id for each picklist value in the format 'PyF3MKlzASI_structured_True' and 'PyF3MKlzASI_structured_False'
    // For now, just add a random id as columnId so it can be identified during editing
    return {
      columnId: cuid(),
      displayName: "",
      displayOrder: 0,
      description: "",
      dataPointType: "PICK_LIST",
      pickListValues: [
        {
          value: "True",
          color: "#008000",
        },
        {
          value: "False",
          color: "#ff0000",
        },
      ],
      defaultValue: null,
      isRequired: false,
      fieldValidatorClasses: null,
      isVotable: true,
      isAllowMultiSelect: false,
      rangeFrom: null,
      rangeTo: null,
      stepSize: null,
      _isNew: true,
    };
  },
  _loadCommunityData: async (communityUuid, force = false) => {
    // Try to get from the store first
    const { activeCommunity } = CommunityEditorUtils._getCommunityStore();
    if (activeCommunity?.uuid === communityUuid && !force) {
      CommunityEditorActions.staticActions.setEditorCommunity({
        ...activeCommunity,
      });
      return;
    } else {
      try {
        // Get community data
        console.log("communityUuid :>> ", communityUuid);
        const { data: communityData } = await ApiCalls.org.private.fetchOrgById(
          communityUuid
        );
        console.log("communityData :>> ", communityData);
        CommunityEditorActions.staticActions.setEditorCommunity(communityData);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  },
  _loadTypesData: async (communityUuid) => {
    try {
      // Get types data
      const { data: typesData } = await ApiCalls.org.private.fetchOrgTypes(
        communityUuid
      );
      let types = typesData?.types || [];
      // Attach restore point to each type
      types = types.map((t) => ({
        ...t,
        _restorePoint: { ...t },
      }));
      CommunityEditorActions.staticActions.setTypes(types || []);
    } catch (error) {
      console.log("error :>> ", error);
    }
  },
  _formatNewType: (newType) => {
    // Format the new type data before submitting
    const formattedNewType = cloneDeep(newType);
    delete formattedNewType._isNew;
    delete formattedNewType._uncommitedChanges;
    // Prepare the columnId
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    // Preare the type name from description: All lower case, replace spaces with underscore
    // As in the backend description is used as display name
    formattedNewType.name = formattedNewType.description
      .toLowerCase()
      .replace(/ /g, "_");
    formattedNewType.columnId = `${editorCommunity.uuid}_${formattedNewType.name}`;
    // Now update each columnDefinition with proper columnId, set displayOrder as index, remove _isNew, _uncommitedChanges and also add id to each picklist value
    formattedNewType.columnDefinitions = formattedNewType.columnDefinitions.map(
      (d, index) => {
        const formattedDatapoint = cloneDeep(d);
        // ColumnId will be a same as displayName but in lower case and replace spaces with underscore
        formattedDatapoint.columnId = formattedDatapoint.displayName
          .toLowerCase()
          .replace(/ /g, "_");
        formattedDatapoint.displayOrder = index;
        delete formattedDatapoint._isNew;
        delete formattedDatapoint._uncommitedChanges;
        // Add id to each picklist value
        if (formattedDatapoint.dataPointType === "PICK_LIST") {
          formattedDatapoint.pickListValues =
            formattedDatapoint.pickListValues.map((v, i) => {
              return {
                id: `${editorCommunity.uuid}_${formattedDatapoint.columnId}_${v.value}`,
                ...v,
              };
            });
        }
        return formattedDatapoint;
      }
    );
    formattedNewType.owningOrganizationId = editorCommunity.uuid;
    return formattedNewType;
  },
  _formatType: (type) => {
    // Just delete restore point
    const formattedType = cloneDeep(type);
    delete formattedType._restorePoint;
    return formattedType;
  },
  _checkEditAccess: () => {
    // Check if the user has edit access to the community
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    let hasEditAccess = false;
    if (AuthHelper.isLoggedIn()) {
      const allowedRoles = [
        "org_owner",
        "org_admin",
        "org_moderator",
      ];
      hasEditAccess = editorCommunity.roles.some((role) => {
        return allowedRoles.includes(role);
      });
    }
    return hasEditAccess;
  },
  _redirectToCommunityPage: () => {
    // Redirect to the community page
    const {
      communityEditor: { editorCommunity },
    } = CommunityEditorUtils._getCommunityStore();
    const route = `/c/${editorCommunity.uuid}`;
    router.navigate(route);
  },
  _getNewImageURL: async (newImage, communityId) => {
    // If starts with http, then it is an external image, so return the same url
    if (newImage.startsWith("http")) {
      return newImage;
    } else {
      // Process the upload
      // convert the object url to file
      const file = await fetch(newImage).then((r) => r.blob());
      const cdnLink = await CDNUploader.uploadImage({
        imageFile: file,
        imageFor: "organisation_icon",
        id: communityId,
      });
      return cdnLink;
    }
  },
  _formatNewCommunity: (newCommunity) => {
    const { title, description, thumbnail, background } = newCommunity;
    const formattedNewCommunity = {
      organisationDisplayName: title,
      orgDescription: description,
      // ...NewCommunityDemoData
    };
    return formattedNewCommunity;
  },
  _processUploadsForNewCommunity: async ({
    community,
    thumbnail,
    background,
  }) => {
    let thumbnailURL = "";
    let backgroundURL = "";
    try {
      if (thumbnail) {
        // Convert blob url to file
        const file = await fetch(thumbnail).then((r) => r.blob());
        thumbnailURL = await CDNUploader.uploadImage({
          imageFile: file,
          imageFor: "organisation_icon",
          id: community.uuid,
        });
      }
      if (background) {
        const file = await fetch(background).then((r) => r.blob());

        backgroundURL = await CDNUploader.uploadImage({
          imageFile: file,
          imageFor: "organisation_cover",
          id: community.uuid,
        });
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    // Update the community with the new image urls
    if (thumbnailURL?.length || backgroundURL?.length) {
      await ApiCalls.org.private.updateOrg(community.uuid, {
        ...community,
        orgIconImageURL: thumbnailURL,
        bannerImageURI: backgroundURL,
      });
    }
  },
};

export default CommunityEditorUtils;
