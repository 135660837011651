import CollectionStoreActionTypes from "./collection-store-action-types";
import { store } from "../store";
const dispatch = store.dispatch;

export const CollectionStoreEditorActions = {
  staticActions: {
    toggleEditorLoaderStatus: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_EDITOR_LOADER_STATUS,
        payload: status,
      });
    },
    toggleEditingNew: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_EDITING_NEW,
        payload: status,
      });
    },
    toggleCollectionMetaEditorStatus: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_COLLECTION_META_EDITOR_STATUS,
        payload: status,
      });
    },
    toggleItemsOrderEditorStatus: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_ITEMS_ORDER_EDITOR_STATUS,
        payload: status,
      });
    },
    toggleItemsRemoveEditorStatus: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_ITEMS_REMOVE_EDITOR_STATUS,
        payload: status,
      });
    },
    setEditorCollection: (collection) => {
      dispatch({
        type: CollectionStoreActionTypes.SET_EDITOR_COLLECTION,
        payload: collection,
      });
    },
    setEditorCollectionItems: (items) => {
      dispatch({
        type: CollectionStoreActionTypes.SET_EDITOR_COLLECTION_ITEMS,
        payload: items,
      });
    },
    toggleCollectionAddMobilePopup: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_COLLECTION_ADD_MOBILE_POPUP,
        payload: status,
      });
    },
    toggleEditor: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_EDITOR_STATUS,
        payload: status,
      });
    },
    setItemsEditorMode: (mode) => {
      dispatch({
        type: CollectionStoreActionTypes.SET_ITEMS_EDITOR_MODE,
        payload: mode,
      });
    },
    setEditorItemsToRemove: (items) => {
      dispatch({
        type: CollectionStoreActionTypes.SET_EDITOR_ITEMS_TO_REMOVE,
        payload: items,
      });
    },
    setNewResourcePending: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.SET_NEW_RESOURCE_PENDING,
        payload: status,
      });
    },
    setEditorErrorMessage: (message) => {
      dispatch({
        type: CollectionStoreActionTypes.SET_EDITOR_ERROR_MESSAGE,
        payload: message,
      });
    },
    toggleCancelPopup: (status) => {
      dispatch({
        type: CollectionStoreActionTypes.TOGGLE_CANCEL_POPUP,
        payload: status,
      });
    },
    resetEditor: () => {
      dispatch({
        type: CollectionStoreActionTypes.RESET_EDITOR,
      });
    },
  },
};
