import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons/index";
import { useSelector } from "react-redux";
import CommunityItem from "../../../../components/community-item/community-item";
import CollectionItemsEditorActionButtons from "../collection-items-editor-action-buttons/collection-items-editor-action-buttons";

const CollectionEditorItemsViewer = () => {
  const { editorCollectionItems } = useSelector(
    (state) => state.collectionStore
  );
  return (
    <>
    <CollectionItemsEditorActionButtons
        onCancel={() => {}}
        onConfirm={() => {}}
      />
      <div className="addedResources">
        {editorCollectionItems?.map((item, index) => (
          <div className="communityItemWrapper" key={index}>
            <div className="itemWrapper"
              style={{
                pointerEvents: "none",
              }}
            >
              <CommunityItem
                datapoints={true}
                communityItem={item}
                isQuestItem={true}
              />
            </div>
          </div>
        ))}

        {/* <div className="communityItemWrapper">
          <div className="dragIcon">{ICONS.move}</div>
          <div className="deleteIcon">{ICONS.trash2}</div>
          <div className="itemWrapper"></div>
        </div> */}
      </div>
    </>
  );
};

export default CollectionEditorItemsViewer;
