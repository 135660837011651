import { useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import TypeCreatorWizard from "../../../types/components/type-creator-wizard/type-creator-wizard";
import TypeEditor from "../../../types/components/type-editor/type-editor";
import { useSelector } from "react-redux";

const TypesManager = () => {
  const [heroSmallBlockOpen, setHeroSmallBlockOpen] = useState(false);
  const {types} = useSelector((state) => state?.communityStore?.communityEditor);
  return (
    <>
      <div className="typesTabContent d-none d-md-block">
        {/*************** hero block */}
        <div className="heroSmallBlockWrapper">
          {heroSmallBlockOpen ? (
            <div className="heroBlockExpanded">
              <div className="faqHeader">FAQ</div>

              <div className="faqBody">
                <div className="question">what is a type?</div>
                <div className="answer">
                  <p>
                    A Type tells your community which category an item belongs
                    to - is it a book? A video? A restaurant? By adding Types,
                    your community can search for all instances of that Type. If
                    they want to see all the books in your community, they click
                    on 'Book'; to see all the videos, they click on 'Video'.
                  </p>

                  <p>
                    Think about what items users will recommend in your
                    community - these will be your Types.
                  </p>

                  <p>
                    Each item can only have one Type - so think carefully about
                    which ones you want to create
                  </p>
                </div>
              </div>

              <div
                className="faqClose"
                onClick={(e) => setHeroSmallBlockOpen(false)}
              >
                <span>Close</span> {ICONS.chevronUp}
              </div>
            </div>
          ) : (
            <div
              className="heroSmallBlockToggle"
              onClick={(e) => setHeroSmallBlockOpen(true)}
            >
              <span>FAQ</span> {ICONS.chevronUp}
            </div>
          )}
        </div>

        <div className="heroBlock">
          <div className="heroLeft">
            <div className="titleAndDesc">
              <div className="bigTitle">Types</div>
              <div className="bigDesc">
                Organize your community recommendations like a pro.
              </div>
            </div>

            <div className="types">
              <div className="placeholderType">
                <span className="circle blue"></span>{" "}
                <span className="label">Course</span>
              </div>
              <div className="placeholderType">
                <span className="circle yellow"></span>{" "}
                <span className="label">Book</span>
              </div>
              <div className="placeholderType">
                <span className="circle red"></span>{" "}
                <span className="label">Video</span>
              </div>
              <div className="placeholderType">
                <span className="circle purple"></span>{" "}
                <span className="label">Restaurant</span>
              </div>
              <div className="placeholderType">
                <span className="circle green"></span>{" "}
                <span className="label">Location</span>
              </div>
            </div>
          </div>

          <div className="heroRight">
            <div className="question">what is a type?</div>
            <div className="answer">
              <p>
                A Type tells your community which category an item belongs to -
                is it a book? A video? A restaurant? By adding Types, your
                community can search for all instances of that Type. If they
                want to see all the books in your community, they click on
                'Book'; to see all the videos, they click on 'Video'.
              </p>
              <p>
                Think about what items users will recommend in your community -
                these will be your Types.
              </p>
            </div>
          </div>
        </div>
        {/*************** /hero block */}

        {/*************** create new type */}

        {/*************** /create new type */}
        <TypeCreatorWizard />
        {/*************** quick control */}
        {types?.filter((type) => !type._isNew)?.length ? (
          <div className="quickControlWrapper pb-5 mb-5">
            <div className="titleBlock">
              <div className="left">
                <div className="title">Quick control</div>
                <div className="desc">
                  Change the name, colour, and voteable ratings for your types.
                </div>
              </div>

              <div
                className="right"
                title="Change the details of each type from here"
              >
                {ICONS.infoCircle}
              </div>
            </div>

            {types
              .filter((type) => !type._isNew)
              .map((type) => (
                <TypeEditor key={type.id} type={type} />
              ))}
          </div>
        ) : (
          <></>
        )}
        {/*************** /quick control */}
      </div>
    </>
  );
};

export default TypesManager;
