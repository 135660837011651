import React from "react";
import CommunityUtils from "../../community-utils";

const CommunityType = ({ type, color = "white" }) => {
  const _toggleSelection = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (type?.selected) {
      CommunityUtils.removeFromSelectedCommunityTypes(type);
    } else {
      CommunityUtils.addToSelectedCommunityTypes(type);
    }
  };
  return (
    <div
      className={
        "type " +
        (type?.selected ? "active" : "") +
        " " +
        (false ? "featured" : "") +
        " " +
        (false ? "disabled" : "") +
        " "
      }
      title={type.description}
      onClick={_toggleSelection}
    >
      <div
        className="circle"
        style={{
          backgroundColor: type.color || "white",
        }}
      ></div>
      <span>{type.description}</span>
    </div>
  );
};

export default CommunityType;
