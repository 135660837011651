import React, { useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import DatapointsManager from "../../../collection/components/datapoints-manager/datapoints-manager";
import TypeColorPicker from "../type-color-picker/type-color-picker";
import TypePreviewer from "../type-previewer/type-previewer";
import { useSelector } from "react-redux";
import CommunityEditorUtils from "../../../community/community-editor-utils";
import DebouncedInput from "../../../../components/core/debounce-input/debounce-input";
import useDeepCompareEffect from "use-deep-compare-effect";
import "./type-creator-wizard.scss";

const TypeCreatorWizard = () => {
  const [localType, setLocalType] = useState(null);
  const [isEditingMeta, setIsEditingMeta] = useState(true);
  const [metaError, setMetaError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const { isNewTypeWizardActive, types } = useSelector(
    (state) => state?.communityStore?.communityEditor
  );
  const _showWizard = () => {
    CommunityEditorUtils.enableNewTypeWizard();
  };
  const _hideWizard = () => {
    CommunityEditorUtils.disableNewTypeWizard();
  };
  const _toggleAccordian = (stepCount) => {
    const accordians = { ...localType?._accordians };
    accordians[stepCount] = !accordians[stepCount];
    CommunityEditorUtils.setTypeAccordianState({
      typeId: localType?.id,
      accordianState: accordians,
    });
  };
  const _updateLocalType = () => {
    if (types) {
      const newType = types.find((type) => type._isNew);
      setLocalType(newType);
    }
  };

  const _updateTitle = (value) => {
    CommunityEditorUtils.updateTypeProperty({
      property: "description",
      value: value,
      typeId: localType?.id,
    });
  };

  const _updateColor = (value) => {
    CommunityEditorUtils.updateTypeProperty({
      property: "color",
      value: value,
      typeId: localType?.id,
    });
  };

  const _enableMetaEdit = () => {
    CommunityEditorUtils.setTypeGeneralUncommitedChanges({
      typeId: localType?.id,
      type: localType,
    });
    setIsEditingMeta(true);
  };

  const _onConfirmMeta = () => {
    // Validate the meta data
    // If valid description
    if (!localType?._uncommitedChanges?.description?.length) {
      // Show error
      setMetaError("Type name is required");
      return;
    } else {
      setMetaError("");
      // Commit changes
      CommunityEditorUtils.commitTypeGeneralChanges(localType?.id);
      CommunityEditorUtils.setTypeAccordianState({
        typeId: localType?.id,
        accordianState: {
          1: false,
          2: true,
          3: false,
        },
      });
      setIsEditingMeta(false);
    }
  };
  const _onCancelMeta = () => {
    // Reset the changes
    CommunityEditorUtils.resetTypeGeneralUncommitedChanges(localType?.id);
    setIsEditingMeta(false);
  };

  const _publishType = () => {
    setGeneralError("");
    const {isValid, errorMessage} = CommunityEditorUtils.validateType(localType);
    if(isValid){
      CommunityEditorUtils.submitNewType(localType);
    } else {
      // Show error
      setGeneralError(errorMessage);
    }
  };

  useDeepCompareEffect(() => {
    _updateLocalType();
  }, [types]);
  return (
    <>
      <div className="createTypeWrapper">
        {isNewTypeWizardActive && localType ? (
          <>
            <div className="createNewTypeFlowWrap">
              <div className="topHeader">
                <div className="left">
                  <div className="bigTitle">Creating a new type</div>
                  <div className="desc">
                    To create a new type you need to give it a name, a color and
                    assign it datapoints.
                  </div>
                </div>

                <div className="right">
                  <Button
                    type={"contrast"}
                    size={"extraSmall"}
                    text="Cancel"
                    onClick={_hideWizard}
                  />
                </div>
              </div>

              <div className="createNewTypeFlowToggles">
                <div
                  className={
                    "createNewTypeFlowToggle toggleStep1 disable " +
                    (localType?._accordians[1] ? "open" : "")
                  }
                >
                  <div
                    className="toggleTitle"
                    onClick={(e) => _toggleAccordian(1)}
                  >
                    <div className="left">
                      {ICONS.chevronUp}
                      <span>Step 1. Name and colour</span>
                      <div className="createdType">
                        <span
                          className="circle"
                          style={{
                            backgroundColor: localType?.color,
                          }}
                        ></span>{" "}
                        <span className="label">
                          {localType?.description?.length
                            ? localType?.description
                            : "New type"}
                        </span>
                      </div>
                    </div>

                    <div className="right">
                      {localType?.description?.length ? ICONS.checkCircle : ""}
                    </div>
                  </div>

                  {localType?._accordians[1] ? (
                    <div className="toggleContent">
                      <div className="desc">
                        Choose one simple and short word that helps others to
                        identify clearly the nature of the recommendation.
                      </div>

                      <div
                        className="inputGroup"
                        style={{
                          pointerEvents: isEditingMeta ? "all" : "none",
                        }}
                      >
                        {isEditingMeta ? (
                          <TypeColorPicker
                          colorCode={localType?._uncommitedChanges?.color}
                            onColorCodeChange={_updateColor}
                          />
                        ) : (
                          <TypeColorPicker colorCode={localType?.color} />
                        )}
                        <div className="inputWrapper">
                          {isEditingMeta ? (
                            <DebouncedInput
                              type="text"
                              className="form-control"
                              placeholder="Type name"
                              debounceTime={500}
                              value={
                                localType?._uncommitedChanges?.description ||
                                localType?.description
                              }
                              onInputChange={_updateTitle}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type name"
                              value={localType?.description}
                              disabled
                            />
                          )}
                        </div>
                      </div>

                      <div className="buttons">
                        {isEditingMeta ? (
                          <>
                            <span className="errorText">
                              {metaError ? metaError : ""}
                            </span>
                            <Button
                              type={"danger"}
                              size={"extraSmall"}
                              icon={ICONS.corssCircle}
                              iconPos={"left"}
                              text="Cancel"
                              onClick={_onCancelMeta}
                            />
                            <Button
                              type={"success"}
                              size={"extraSmall"}
                              icon={ICONS.checkCircle}
                              iconPos={"left"}
                              text="Confirm changes"
                              onClick={_onConfirmMeta}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              size={"extraSmall"}
                              icon={ICONS.edit}
                              iconPos={"left"}
                              text="Edit"
                              onClick={_enableMetaEdit}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={
                    "createNewTypeFlowToggle toggleStep2 " +
                    (localType?._accordians[2] ? "open" : "")
                  }
                >
                  <div
                    className="toggleTitle"
                    onClick={(e) => _toggleAccordian(2)}
                  >
                    <div className="left">
                      {ICONS.chevronUp}
                      <span>Step 2. Ratings</span>
                    </div>

                    <div className="right">{
                      localType?.columnDefinitions?.length? ICONS.checkCircle : ""
                    }</div>
                  </div>

                  {localType?._accordians[2] ? (
                    <div className="toggleContent">
                      <div className="desc">
                        These are the votable blocks in each recommendation.
                        They help members to know what is the opinion of the
                        community.
                      </div>

                      <DatapointsManager typeId={localType.id} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className={
                    "createNewTypeFlowToggle toggleStep3 " +
                    (localType?._accordians[3] ? "open" : "")
                  }
                >
                  <div
                    className="toggleTitle"
                    onClick={(e) => _toggleAccordian(3)}
                  >
                    <div className="left">
                      {ICONS.chevronUp}
                      <span>Step 3. Preview & Publish</span>
                    </div>

                    {/* <div className="right">{ICONS.checkCircle}</div> */}
                  </div>

                  {localType?._accordians[3] ? (
                    <div className="toggleContent">
                      <div className="desc">
                        This is how this new type will look like in a community
                        recommendation. Shiny one! If it looks good, publish it!
                      </div>

                      <TypePreviewer 
                        type={localType}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="publishButton text-end">
                  <span className="errorText">
                    {generalError ? generalError : ""}
                  </span>
                  <Button
                    type={"brand"}
                    size={"medium"}
                    text="Ready to publish"
                    onClick={_publishType}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Button
            type={"contrast"}
            size={"medium"}
            icon={ICONS.plus}
            iconPos={"left"}
            text="Create new type"
            onClick={_showWizard}
          />
        )}
      </div>
    </>
  );
};

export default TypeCreatorWizard;
