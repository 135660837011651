import React, { Component, useEffect, useState } from "react";
import "./notifications.scss";
import OutsideClickHandler from "react-outside-click-handler";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import SiteWiseEvents from "../../../../declarations/site-wise-events";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import Notification from "../notification/notification";

const Notifications = ({ showMobile = false, id }) => {
  const [isVisibile, setIsVisibile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { notifications, notificationPagination } = useSelector(
    (state) => state.notificationStore
  );

  const _registerEventListeners = () => {
    EventEmitter.subscribe(SiteWiseEvents.OPEN_NOTIFICATIONS_DESKTOP, () => {
      //   Check if mobile
      if (window.innerWidth < 768 && showMobile) {
        // alert(`Open notifications mobile ${id}`);
        setIsMobile(true);
        setIsVisibile(true);
      }
      if (window.innerWidth > 768 && !showMobile) {
        // alert(`Open notifications desktop ${id}`);
        setIsVisibile(true);
      }
    });
  };

  const _deregisterEventListeners = () => {
    EventEmitter.cancelAll(SiteWiseEvents.OPEN_NOTIFICATIONS_DESKTOP);
  };

  const _close = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsVisibile(false);
    setIsMobile(false);
  };

  const _renderInner = () => {
    return (
      <div className={`notificationPopup ${isVisibile ? "show" : ""}`}>
        <div className="notificationPopupInner">
          <div className="notiHeader">
            {" "}
            <span>NOTIFICATIONS</span>{" "}
          </div>

          <div className="notisWrap">
            {notifications.map((notification, index) => {
              return (
                <Notification
                  key={index}
                  notification={notification}
                  onHideViewer={_close}
                />
              );
            })}
          </div>

          {notifications?.length === 0 ? (
            <>
              <div className="noNotis">
                <div className="inner">
                  <div className="text">No notifications</div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* <div className="seeMore">
            <div className="inner">See more</div>
          </div> */}
        </div>
      </div>
    );
  };

  useEffect(() => {
    _registerEventListeners();
    return () => _deregisterEventListeners();
  }, []);

  if (isMobile) {
    return (
      <MobileDrawer
        isActive={isVisibile}
        onClose={_close}
        headerTitle="Notifications"
        headerIcon={ICONS.usersCommunity}
        extraclassName="notificationDrawer"
      >
        {_renderInner()}
      </MobileDrawer>
    );
  } else {
    if (isVisibile) {
      return (
        <OutsideClickHandler onOutsideClick={_close}>
          {_renderInner()}
        </OutsideClickHandler>
      );
    }
  }

  return <></>;
};

export default Notifications;
