/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DiscoveryBar from "../components/discover-bar/discovery-bar";
import CollectionUtils from "../entities/collection/collection-utils";
import CollectionInfoViewer from "../entities/collection/components/collection-info-viewer/collection-info-viewer";
import CollectionItemsViewer from "../entities/collection/components/collection-items-viewer/collection-items-viewer";
import CommunityUtils from "../entities/community/community-utils";
import CommunityInfoViewer from "../entities/community/components/community-info-viewer/community-info-viewer";
import QuestComments from "../features/quest-comments/components/quest-comments/quest-comments";
import QuestCommentsHelper from "../features/quest-comments/quest-comments-helper";
import useKeyCloakSafe from "../shared/hooks/useSafeKeycloak";
import ActivityRecorder from "../shared/utils/activity-recorder";
import "./communities-page.scss";
import GeneralHelper from "../shared/helper-methods/general-helpers";
import DivWithScrollDirectionListener from "../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";
import CollectionViewer from "../entities/collection/components/collection-viewer/collection-viewer";
import CollectionEditor from "../entities/collection/components/collection-editor/collection-editor";

const CommunityCollectionPage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();

  let { communityId, questId } = useParams();
  const location = useLocation();

  const { activeCollection, activeCollectionItems, isEditorActive } =
    useSelector((state) => state.collectionStore);

  const _loadCommunityReferenceView = () => {
    const urlParams = new URLSearchParams(location.search);
    const reference = urlParams.get("reference");
    if (reference) {
      QuestCommentsHelper.activateReferenceMode(reference);
    }
  };

  useEffect(() => {
    // On load
    // Get community id from url (/c/:id)
    if (initialized) {
      CommunityUtils.initiateCommunityLoad(communityId);
      CollectionUtils.clearCollectionData();
      CollectionUtils.initiateCollectionLoad(questId);
      QuestCommentsHelper.initiateCommentLoad(questId);
    }
  }, [keycloak, initialized, location]);

  useEffect(() => {
    if (activeCollection?.uuid) {
      ActivityRecorder.startRecordingPageActivity({
        pageName: `CollectionPage-${activeCollection.listname}`,
      });
      // Now load reference view if present in query params
      _loadCommunityReferenceView();
    }
    return () => {
      ActivityRecorder.stopRecordingPageActivity();
    };
  }, [activeCollection?.uuid]);

  return (
    <>
      <DivWithScrollDirectionListener
        className="communityPageMainContent"
        onScrollDown={() => {
          GeneralHelper.updateMobileFooterOnScrollDown();
        }}
        onScrollUp={() => {
          GeneralHelper.updateMobileFooterOnScrollUp();
        }}
      >

        <CommunityInfoViewer />
        {
          isEditorActive ? (
            <CollectionEditor />
          ): (
            <CollectionViewer />
          )
        }
      </DivWithScrollDirectionListener>
      {/*************************************************** /COMMUNITY CONTENT */}

      {/*************************************************** COMMENT BAR */}
      {/* <CommentBar open={true} />
       */}
      <QuestComments />
    </>
  );
};

export default CommunityCollectionPage;
