import axios from "axios";

class AxiosRequestCanceller {
  constructor() {
    this.pendingRequests = new Map();
  }

  addRequest(key, cancelFunc) {
    if (this.pendingRequests.has(key)) {
      const cancelToken = this.pendingRequests.get(key);
      cancelToken.cancel("Cancelled due to new request to same endpoint.");
    }
    this.pendingRequests.set(key, cancelFunc);
  }

  removeRequest(key) {
    if (this.pendingRequests.has(key)) {
      this.pendingRequests.delete(key);
    }
  }

  setupInterceptors(axiosInstance) {
    axiosInstance.interceptors.request.use(
      (config) => {
        if (config.method === "get") {
          const key = `${config.method} ${config.url}`;
          config.cancelToken = new axios.CancelToken((cancel) =>
            this.addRequest(key, { cancel })
          );
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.config.method === "get") {
          const key = `${response.config.method} ${response.config.url}`;
          this.removeRequest(key);
        }
        return response;
      },
      (error) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled:", error.message);
        } else {
          // handle other errors
        }
        return Promise.reject(error);
      }
    );
  }
}

export default AxiosRequestCanceller;
