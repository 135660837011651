import React, { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import ICONS from "../../assets/quester-icons";
import Button from "../../components/buttons/buttons";
import DivWithScrollDirectionListener from "../../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";
import CommunityEditorUtils from "../../entities/community/community-editor-utils";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import router from "../../shared/top-level-declarations/history";
import "./create-community-page.scss";
const CreateCommunityPage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [background, setBackground] = useState("");

  const _updateThumbnail = (image) => {
    const imageUrl = URL.createObjectURL(image);
    setThumbnail(imageUrl);
  };
  const _updateBackground = (image) => {
    const imageUrl = URL.createObjectURL(image);
    setBackground(imageUrl);
  };

  const _validate = () => {
    let isValid = true;
    let errorText = "";
    if (!title) {
      isValid = false;
      errorText = "Please provide a valid community name";
    }
    // if (!description && isValid) {
    //   isValid = false;
    //   errorText = "Please enter a description";
    // }
    if (!thumbnail && isValid) {
      isValid = false;
      errorText = "Please upload a thumbnail";
    }
    return {
      isValid,
      errorText,
    };
  };

  const _backToHome = () => {
    router.navigate("/");
  };

  const _submit = async () => {
    const { isValid, errorText } = _validate();
    setErrorText("");
    if (!isValid) {
      console.log(errorText);
      setErrorText(errorText);
      return;
    }
    setIsLoaderActive(true);
    try {
      const newCommunity = await CommunityEditorUtils.submitNewCommunity({
        title,
        description,
        thumbnail,
        background,
      });
      router.navigate(`/c/${newCommunity.uuid}`);
    } catch (error) {
      setErrorText("An error occured. Please try again later");
    }
    setIsLoaderActive(false);
  };
  useDeepCompareEffect(() => {
    if (initialized && !keycloak.authenticated) {
      AuthHelper.login();
    }
  }, [keycloak, initialized]);

  return (
    <>
      <input
        type="file"
        id="iconImageFile"
        accept="image/*"
        onChange={(e) => _updateThumbnail(e.target.files[0])}
        style={{ display: "none" }}
      />
      <input
        type="file"
        id="backgroundImageFile"
        accept="image/*"
        onChange={(e) => _updateBackground(e.target.files[0])}
        style={{ display: "none" }}
      />
      <div className="mainContentWrapper">
        <DivWithScrollDirectionListener className="home-page">
          {initialized && keycloak.authenticated ? (
            <>
              <div className="createCommunityWrapper">
                <div className="createCommunityCover">
                  <div className="inner">
                    <img
                      src={require("../../assets/images/new-collection-illustrations.png")}
                      alt=""
                    />
                    <div className="bigHeading">
                      Welcome to the single-step community creation process of
                      quester
                    </div>
                    <div className="desc">
                      Social databasing - Community collaboration - Monetisation
                      for your recommendations
                    </div>
                  </div>
                </div>

                <div className="createCommunityContent">
                  <div className="goBack">
                    <Button
                      text={"Back"}
                      size={"extraSmall"}
                      type={"default"}
                      icon={ICONS.arrowLeft}
                      iconPos={"left"}
                      onClick={_backToHome}
                    />
                  </div>

                  <div className="communityDetailsWrapper">
                    <div className="row">
                      <div className="col-lg-12 left">
                        <div className="defineCommunity">
                          <div className="title">Define your community</div>

                          <div className="imageAndDetails">
                            <div className="images">
                              <div className="thumbnail">
                                <label htmlFor="">Thumbnail:</label>
                                <div
                                  className="imageWrapper"
                                  onClick={() =>
                                    document
                                      .getElementById("iconImageFile")
                                      .click()
                                  }
                                >
                                  <img
                                    src={
                                      thumbnail
                                        ? thumbnail
                                        : require("../../assets/images/grey_rectangle.png")
                                    }
                                    alt=""
                                  />{" "}
                                  {thumbnail ? "" : ICONS.imageIcon}{" "}
                                </div>
                              </div>
                              {/* <ImagePicker
                              closePopup={() => setIsImagePickerVisible(false)}
                              onUpdateImage={_updateThumbnail}
                              isVisible={isImagePickerVisible}
                            /> */}
                              <div className="backgroundImage">
                                <label htmlFor="">Background:</label>
                                <div
                                  className="imageWrapper"
                                  onClick={() =>
                                    document
                                      .getElementById("backgroundImageFile")
                                      .click()
                                  }
                                >
                                  <img
                                    src={
                                      background
                                        ? background
                                        : require("../../assets/images/grey_rectangle.png")
                                    }
                                    alt=""
                                  />{" "}
                                  {background ? "" : ICONS.imageIcon}{" "}
                                </div>

                                {/* <ImagePicker
                              closePopup={() => setIsImagePickerVisible(false)}
                              onUpdateImage={_updateBackground}
                              isVisible={isImagePickerVisible}
                            /> */}
                              </div>
                            </div>

                            <div className="nameAndDescription">
                              <div className="inputGroup communityNameInput">
                                <label htmlFor="">Community name:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Give your community a name"
                                  value={title}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 50) {
                                      setTitle(e.target.value);
                                    }
                                  }}
                                />

                                <div className="count">{title.length}/50</div>
                              </div>
                              <div className="inputGroup communityDescInput">
                                <label htmlFor="">Description:</label>
                                <textarea
                                  name=""
                                  id=""
                                  className="form-control"
                                  placeholder="Describe what your community is for"
                                  value={description}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 350) {
                                      setDescription(e.target.value);
                                    }
                                  }}
                                />
                                <div className="count">
                                  {description.length}/350
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="buttons">
                          <span className="errorText">{errorText}</span>
                          {isLoaderActive ? (
                            <span className="loaderWrapper">
                              <span class="loader"></span>
                            </span>
                          ) : (
                            <>
                              <Button
                                text={"Create now"}
                                size={"medium"}
                                type={"brand"}
                                onClick={_submit}
                                icon={ICONS.plus}
                                iconPos={"right"}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-3 right">
                        {/* <div className="title">community preview</div>
                    {communities?.map((community, index) => (
                      (index == 0) ? <CommunityCard key={community.uuid} community={community} /> : ""
                    ))} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </DivWithScrollDirectionListener>
      </div>
    </>
  );
};

export default CreateCommunityPage;
