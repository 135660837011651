import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import CommunityEditorUtils from "../../../community/community-editor-utils";
import DebouncedInput from "../../../../components/core/debounce-input/debounce-input";

const DatapointEditor = ({ columnDefinition, typeId }) => {
  const [isEditing, setIsEditing] = useState(
    columnDefinition?._isNew ? true : false
  );
  const [errorMessage, setErrorMessage] = useState("");
  const setDisplayName = (value) => {
    CommunityEditorUtils.updateDatapointProperty({
      typeId,
      datapointId: columnDefinition.columnId,
      property: "displayName",
      value,
    });
  };
  const setDescription = (value) => {
    CommunityEditorUtils.updateDatapointProperty({
      typeId,
      datapointId: columnDefinition.columnId,
      property: "description",
      value,
    });
  };
  const _startEdit = () => {
    CommunityEditorUtils.setDatapointUncommitedChanges({
      typeId,
      datapointId: columnDefinition.columnId,
      datapoint: columnDefinition,
    });
    setIsEditing(true);
  };
  const _cancelChanges = () => {
    CommunityEditorUtils.resetDatapointUncommitedChanges({
      typeId,
      datapointId: columnDefinition.columnId,
    });
    setIsEditing(false);
  };
  const _confirmChanges = () => {
    // Validate
    setErrorMessage("");
    if (!columnDefinition._uncommitedChanges.displayName?.length) {
      setErrorMessage("Title is required");
      return;
    }
    const isNameUnique = CommunityEditorUtils.checkIfDatapointNameUnique({
      typeId,
      datapointId: columnDefinition.columnId,
      displayName: columnDefinition._uncommitedChanges.displayName,
    });
    if (!isNameUnique) {
      setErrorMessage("Duplicate title");
      return;
    }
    CommunityEditorUtils.commitDatapointChanges({
      typeId,
      datapointId: columnDefinition.columnId,
    });
    setIsEditing(false);
  };
  const _deleteDatapoint = () => {
    CommunityEditorUtils.deleteLocalDatapoint({
      typeId,
      datapointId: columnDefinition.columnId,
    });
  };
  console.log("columnDefinition :>> ", columnDefinition);
  return (
    <>
      <div className="quickControlCard">
        <div className="quickControlCardTop">
          <div className="quickControlLabel">Quick control</div>
          {columnDefinition?._isNew ? (
            <div className="close" onClick={_deleteDatapoint}>
              {ICONS.crossIcon}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div
          className="quickControlCardMiddle"
          style={
            isEditing
              ? {}
              : {
                  pointerEvents: "none",
                }
          }
        >
          <div className="form-group">
            <label>
              <span>Title</span>
              <div className="infoWrap">
                {ICONS.infoCircle}
                <div className="hintText">Choose a short and simple name</div>
              </div>
              <span className="warning">
                {errorMessage?.length ? errorMessage : (
                  columnDefinition?._isNew ? "" : "It cannot be changed"
                )}
              </span>
            </label>

            {isEditing ? (
              <DebouncedInput
                type="text"
                className="form-control"
                placeholder="Enter title"
                disabled={!columnDefinition?._isNew}
                style={{
                  backgroundColor: columnDefinition?._isNew
                    ? "transparent"
                    : "#f4f4f4",
                }}
                debounceTime={500}
                value={columnDefinition?._uncommitedChanges?.displayName}
                onInputChange={setDisplayName}
              />
            ) : (
              <input
                type="text"
                disabled
                className="form-control"
                placeholder="Enter title"
                debounceTime={500}
                value={columnDefinition?.displayName}
              />
            )}
          </div>

          <div className="form-group">
            <label>
              <span>Prompt</span>
              <div className="infoWrap">
                {ICONS.infoCircle}
                <div className="hintText">
                  Define the tooltip to help members to understand this Rating
                </div>
              </div>
            </label>

            {isEditing ? (
              <DebouncedInput
                type="text"
                className="form-control"
                placeholder="Enter prompt"
                textArea={true}
                debounceTime={500}
                value={columnDefinition?._uncommitedChanges?.description}
                onInputChange={setDescription}
              />
            ) : (
              <textarea
                type="text"
                disabled
                className="form-control"
                placeholder="Enter prompt"
                textArea={true}
                debounceTime={500}
                value={columnDefinition?.description}
              />
            )}
          </div>

          <div className="previewDatapoint">
            <div className="label">Preview</div>

            <div className="datapoint">
              <div className="datapointTop">
                <span>{columnDefinition?.displayName}</span>
                <div className="datapointRight d-none d-md-block">
                  <div className="info"></div>
                </div>
              </div>

              <div className="voteSection">
                <div className="datapointVoteBar">
                  <div className="voteBarInner">
                    <span
                      className="agreeValue"
                      style={{ width: "50%" }}
                    ></span>
                  </div>
                </div>

                <div className="datapointVoteOptions">
                  <div className="agreeButton " title="Vote if you agree">
                    <div className="icon">
                      <div className="agree">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {" "}
                          <rect
                            x="2"
                            y="10"
                            width="5"
                            height="9"
                            fill="#3E9230"
                          ></rect>{" "}
                          <path
                            d="M7.5 9.75H3C2.80109 9.75 2.61032 9.82902 2.46967 9.96967C2.32902 10.1103 2.25 10.3011 2.25 10.5V18.75C2.25 18.9489 2.32902 19.1397 2.46967 19.2803C2.61032 19.421 2.80109 19.5 3 19.5H7.5M7.5 9.75V19.5M7.5 9.75L11.25 2.25C12.0456 2.25 12.8087 2.56607 13.3713 3.12868C13.9339 3.69129 14.25 4.45435 14.25 5.25V7.5H20.25C20.4628 7.50006 20.6731 7.54539 20.867 7.63297C21.0609 7.72056 21.234 7.8484 21.3747 8.008C21.5154 8.1676 21.6206 8.35532 21.6832 8.55868C21.7458 8.76204 21.7644 8.97639 21.7378 9.1875L20.6128 18.1875C20.5672 18.5499 20.3908 18.8832 20.1169 19.1249C19.843 19.3665 19.4903 19.4999 19.125 19.5H7.5"
                            stroke="#121212"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                        </svg>
                      </div>
                    </div>

                    <div className="voteValue">(0)</div>
                  </div>

                  <div className="disagreeButton " title="Vote if you disagree">
                    <div className="icon">
                      <div className="disagree">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {" "}
                          <rect
                            x="2"
                            y="4"
                            width="5"
                            height="10"
                            fill="#FF7B7B"
                          ></rect>{" "}
                          <path
                            d="M7.5 4.5H3C2.80109 4.5 2.61032 4.57902 2.46967 4.71967C2.32902 4.86032 2.25 5.05109 2.25 5.25V13.5C2.25 13.6989 2.32902 13.8897 2.46967 14.0303C2.61032 14.171 2.80109 14.25 3 14.25H7.5M7.5 4.5V14.25M7.5 4.5H19.125C19.4903 4.5001 19.843 4.63349 20.1169 4.87514C20.3908 5.11679 20.5672 5.45009 20.6128 5.8125L21.7378 14.8125C21.7644 15.0236 21.7458 15.238 21.6832 15.4413C21.6206 15.6447 21.5154 15.8324 21.3747 15.992C21.234 16.1516 21.0609 16.2794 20.867 16.367C20.6731 16.4546 20.4628 16.4999 20.25 16.5H14.25V18.75C14.25 19.5456 13.9339 20.3087 13.3713 20.8713C12.8087 21.4339 12.0456 21.75 11.25 21.75L7.5 14.25"
                            stroke="#121212"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                        </svg>
                      </div>
                    </div>

                    <div className="voteValue">(0)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="quickControlCardButtons">
          {isEditing ? (
            <>
              <Button
                type={"danger"}
                size={"extraSmall"}
                icon={ICONS.corssCircle}
                iconPos={"left"}
                text="Cancel"
                onClick={_cancelChanges}
              />
              <Button
                type={"success"}
                size={"extraSmall"}
                icon={ICONS.checkCircle}
                iconPos={"left"}
                text="Confirm changes"
                onClick={_confirmChanges}
              />
            </>
          ) : (
            <Button
              type={"contrast"}
              size={"extraSmall"}
              icon={ICONS.edit}
              iconPos={"left"}
              text="Edit"
              extraClasses={"w-100"}
              onClick={_startEdit}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DatapointEditor;
