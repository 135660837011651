import React from "react";
import "./toggle-button.scss";

const Toggle = ({ active = false, onChange = () => {} }) => {
  return (
    <div className={"toggle " + (active ? "active" : "")} onClick={onChange}>
      <span className="handle"></span>
    </div>
  );
};

export default Toggle;
