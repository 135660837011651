import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

const ShareActivities = {
  addCollectionShareActivity: ({ entityType, entityId, collectionName }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId,
      entityValue1: collectionName,
      activityType: "share-quest",
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("share-quest", activity);
    }, 200);
  },
};

export default ShareActivities;
