import cloneDeep from "clone-deep";
import ApiCalls from "../../shared/api";

const VotingUtils = {
  prepareFormattedVoteData: ({
    dtableSchema,
    drowStats,
    teamResourceUuid,
    myDRow,
  }) => {
    const clonedDrowStats = cloneDeep(drowStats);
    const voteData = [];
    if (dtableSchema?.columnDefinitions) {
      const columnDefinitions = dtableSchema?.columnDefinitions?.filter(
        (column) => column.pickListValues.length === 2
      );
      const cells = clonedDrowStats?.cells;
      columnDefinitions.forEach((column) => {
        const cellTitle = column.displayName;
        const cellDescription = column.description?.length? column.description : column.displayName;
        const columnId = column.columnId;
        let trueValueIndex = -1;
        let falseValueIndex = -1;

        if (column?.columnId && cells && cells[column.columnId]?.length) {
          trueValueIndex = cells[column.columnId].findIndex(
            (col) => col.value === column.pickListValues[0].value
          );
          falseValueIndex = cells[column.columnId].findIndex(
            (col) => col.value === column.pickListValues[1].value
          );
        }

        const hasVoted = {
          [column.pickListValues[0].value]: false,
          [column.pickListValues[1].value]: false,
        };

        if (myDRow?.cells && myDRow?.cells[column.columnId]?.length) {
          hasVoted[myDRow?.cells[column.columnId][0]] = true;
        }

        let positiveValue = {
          value: column.pickListValues[0].value,
          percentage: 0,
          count: 0,
          hasVoted: !!hasVoted[column.pickListValues[0].value],
        };
        if (trueValueIndex > -1) {
          positiveValue = cells[column.columnId][trueValueIndex];
        }
        let negativeValue = {
          value: column.pickListValues[1].value,
          percentage: 0,
          count: 0,
          hasVoted: !!hasVoted[column.pickListValues[1].value],
        };
        if (falseValueIndex > -1) {
          negativeValue = cells[column.columnId][falseValueIndex];
        }
        positiveValue.hasVoted = !!hasVoted[column.pickListValues[0].value];
        negativeValue.hasVoted = !!hasVoted[column.pickListValues[1].value];
        voteData.push({
          cellTitle,
          cellDescription,
          columnId,
          positiveValue,
          negativeValue,
        });
      });
    }
    return voteData;
  },
  vote: async ({
    dtableSchema,
    columnId,
    teamResourceUuid,
    value,
    drowStats,
    myDRow,
  }) => {
    try {
      // To do: Before calling the API, add or update the my drow based on the selected cell

      let myDRowCells = {};
      const oldMyDRow = cloneDeep(myDRow);
      let hasUnvoted = false;
      if (myDRow) {
        myDRowCells = myDRow.cells;
      }
      // check if the user has already voted on this cell with the same value
      if (myDRowCells[columnId]?.length) {
        if (myDRowCells[columnId][0] === value) {
          // Unvote
          myDRowCells[columnId] = [];
          hasUnvoted = true;
        }
      }

      if (!hasUnvoted) {
        myDRowCells[columnId] = [value];
      }

      const { data } = await ApiCalls.vote.private.updateUserVoteOnTeamResource(
        {
          payload: {
            cells: myDRowCells,
            dtableSchemaId: dtableSchema.id,
          },
          teamResourceUuid,
        }
      );
      const updatedDrowStatsLocally =
        VotingUtils._generateUpdatedDrowStatsLocally({
          drowStats,
          columnId,
          value,
          dtableSchema,
          oldMyDRow,
          hasUnvoted,
        });
      // Update the cell count of the drowstats if unvote
      return {
        updatedDrowStats: updatedDrowStatsLocally,
        myDRow: data?.myDRow,
      };
      // return data;
    } catch (error) {
      console.log("error", error);
    }
  },
  _generateUpdatedDrowStatsLocally: ({
    drowStats,
    columnId,
    value,
    dtableSchema,
    oldMyDRow,
    hasUnvoted = false,
  }) => {
    let clonedDrowStats = cloneDeep(drowStats);
    let doesExist = false;
    if (!clonedDrowStats?.cells) {
      clonedDrowStats.cells = {};
    }
    // Update the drowstat cell vote count as the count does not get updated from the API instantly (Due to eventuate pattern)
    if (clonedDrowStats?.cells) {
      if (hasUnvoted) {
        // Unvoted
        // Reduce count of the cell
        const existingVoteIndex = clonedDrowStats.cells[columnId].findIndex(
          (column) => column.value === value
        );
        if (existingVoteIndex > -1) {
          clonedDrowStats.cells[columnId][existingVoteIndex] = {
            ...clonedDrowStats.cells[columnId][existingVoteIndex],
            count: clonedDrowStats.cells[columnId][existingVoteIndex].count - 1,
          };
        }
      } else {
        // Voted
        const existingVoteIndex = clonedDrowStats.cells[columnId]?.findIndex(
          (column) => column.value === value
        );
        if (existingVoteIndex > -1) {
          doesExist = true;
          clonedDrowStats.cells[columnId][existingVoteIndex] = {
            ...clonedDrowStats.cells[columnId][existingVoteIndex],
            count: clonedDrowStats.cells[columnId][existingVoteIndex].count + 1,
          };
        }
        // Remove the other vote if it exists
        if (oldMyDRow?.cells && oldMyDRow?.cells[columnId]?.length) {
          const otherIndex = clonedDrowStats.cells[columnId].findIndex(
            (column) => column.value === oldMyDRow.cells[columnId][0]
          );
          if (otherIndex > -1) {
            clonedDrowStats.cells[columnId][otherIndex] = {
              ...clonedDrowStats.cells[columnId][otherIndex],
              count: clonedDrowStats.cells[columnId][otherIndex].count - 1,
            };
          }
        }
      }
      if (!doesExist) {
        if (!clonedDrowStats.cells[columnId]) {
          clonedDrowStats.cells = {
            ...clonedDrowStats.cells,
            [columnId]: [],
          };
        }
        clonedDrowStats.cells[columnId] = [
          ...clonedDrowStats.cells[columnId],
          {
            value,
            count: 1,
            freeTextUserImageUrl: null,
            freeTextUserDisplayName: null,
          },
        ];
      }
    }

    // Calculate percentage based on all votes
    const totalVotes = clonedDrowStats.cells[columnId].reduce(
      (acc, column) => acc + column.count,
      0
    );
    clonedDrowStats.cells[columnId] = clonedDrowStats.cells[columnId].map(
      (column) => ({
        ...column,
        percentage: (column.count / totalVotes) * 100,
      })
    );
    clonedDrowStats = {
      ...drowStats,
      ...clonedDrowStats,
    };

    return clonedDrowStats;
    // return updatedDrowStats;
  },
};

export default VotingUtils;
