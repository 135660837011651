import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

const AIActivities = {
  addAIAssistedResource: ({
    link,
    aiAssistedTopics,
    aiAssistedType,
    response,
    publishStatus,
    resourceId = "",
    entityType = "community-resource",
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId: resourceId,
      entityValue1: link,
      entityValue2: aiAssistedTopics,
      entityValue3: aiAssistedType,
      entityValue4: response,
      entityValue5: publishStatus,
      activityType: `add-ai-assisted-resource`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent(`add-ai-assisted-resource`, activity);
    }, 200);
  },
  addAIAssistedResourceSummary: ({
    link,
    summary,
    readTime,
    mediaLinks = [],
    resourceId = "",
    feedback = "positive",
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType: "community-resource",
      entityId: resourceId,
      entityValue1: link,
      entityValue2: summary,
      entityValue3: readTime,
      entityValue4: mediaLinks,
      entityValue5: feedback,
      activityType: `read-ai-assisted-resource-summary`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent(`read-ai-assisted-resource-summary`, activity);
    }, 200);
  },
};

export default AIActivities;
