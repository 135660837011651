import React, { useState, useEffect } from "react";
import "./ai-summary-viewer.scss";
import OutsideClickHandler from "react-outside-click-handler";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import ResourceSummaryHelper from "../../helpers/resource-summary-helper";
import LinkPreviewImage from "../../../../components/core/link-previewer/link-previewer";
import Toast from "../../../../components/toast/toast";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import AIActivities from "../../../../shared/utils/activity-recorder/activity-helpers/ai-activities";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import AiSummaryMediaViewer from "../ai-summary-media-viewer/ai-summary-media-viewer";

const AiSummaryViewer = ({
  onHide = () => {},
  link,
  imageURL = null,
  title = "",
  voteStat = [],
  resourceUuid = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState([]);
  const [readTime, setReadTime] = useState(5); // in minutes
  const [mediaLinks, setMediaLinks] = useState([]); // [ { type: 'image', url: 'https://placehold.co/600x400' } ]
  const [hasError, setHasError] = useState(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  const _generateStatText = () => {
    // Generate text from votestat
    // Final text: The members of our community seems to like this recommendation. Also, the 56% members who voted it thinks is Well organised and 23% think is Insightful.
    if (voteStat.length > 0) {
      let validStatCount = 0;
      let statText =
        "The members of our community seems to like this recommendation. Also, the ";
      voteStat.forEach((item, index) => {
        const positivePercent = item?.positiveValue?.percentage;
        if (positivePercent > 0) {
          const integerPart = parseInt(positivePercent);
          validStatCount++;
          if (index === 0) {
            statText += `${integerPart}% members who voted it thinks is ${item.cellTitle}`;
          } else if (index === voteStat.length - 1) {
            statText += ` and ${integerPart}% members who voted it thinks is ${item.cellTitle}`;
          } else {
            statText += `, ${integerPart}% members who voted it thinks is ${item.cellTitle}`;
          }
        }
      });
      if (validStatCount > 0) {
        return statText;
      }
      return "";
    }
  };

  const _extractInteger = (inputString) => {
    // Use a regular expression to find all digits in the string
    const match = inputString.match(/\d+/);

    // If a match is found, convert it to an integer and return it
    if (match) {
      return parseInt(match[0], 10);
    }

    // If no match is found, return 5
    return 5;
  };

  const _submitPositiveFeedback = () => {
    AIActivities.addAIAssistedResourceSummary({
      link,
      summary,
      readTime,
      resourceId: resourceUuid?.length ? resourceUuid : "",
      feedback: "positive",
    });
    setIsFeedbackSubmitted(true);
  };
  const _submitNegativeFeedback = () => {
    AIActivities.addAIAssistedResourceSummary({
      link,
      summary,
      readTime,
      resourceId: resourceUuid?.length ? resourceUuid : "",
      feedback: "negative",
    });
    setIsFeedbackSubmitted(true);
  };

  useEffect(() => {
    const fetchSummary = async () => {
      setIsLoading(true);
      try {
        const data = await ResourceSummaryHelper.getSummary(link);
        if (!data?.summary?.length) {
          setHasError(true);
          GeneralHelper.showToast({
            primaryText: "Link doesn't allow to generate summary",
            additionalClass: "error",
            icon: ICONS.sparkleFilled,
          });
          onHide();

          setIsLoading(false);
          return;
        }
        if (data.readingTime) {
          const formattedReadingTime = _extractInteger(data.readingTime);
          setReadTime(formattedReadingTime);
        }
        if (data.summary?.length) {
          setSummary(data.summary);
        }
        if (data.mediaLinks?.length) {
          const validLinks = data.mediaLinks.filter(
            (linkObj) =>
              linkObj.src.indexOf("http://") > -1 ||
              linkObj.src.indexOf("https://") > -1
          );
          setMediaLinks(validLinks);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSummary();
  }, [link]);

  const statText = _generateStatText();
  const isMobile = window.innerWidth < 768;

  if (isMobile) {
    return (
      <MobileDrawer
        isActive={true}
        onClose={onHide}
        headerTitle="quester digest"
        headerIcon={ICONS.questerIcon}
        extraclassName="aiAnalysisDrawer"
      >
        <div className="aiAnalysisPopup">
          <div className="aiAnalysisPopupInner">
            {isLoading ? (
              <>
                <div className="ailoaderWrapper mt-5">
                  {/* <div className="ailoaderCustom"></div> */}
                  <div className="gradientLoader"></div>
                </div>
              </>
            ) : (
              <>
                <div className="popupCoverImage">
                  <LinkPreviewImage
                    link={link}
                    staticImageUrl={imageURL}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />

                  <div className="aiTag">
                    {ICONS.sparkleFilledGradient} <span>AI generated</span>{" "}
                  </div>
                </div>

                <div className="titleAndInfo">
                  <div className="title">{title}</div>

                  <div className="infos">
                    <div
                      className="infoItem url"
                      title={link.replace("https://", "")}
                      onClick={(e) => {
                        // open in new tab
                        window.open(link, "_blank");
                      }}
                    >
                      {ICONS.link} <span>{link.replace("https://", "")}</span>
                      <span
                        onClick={(e) => {
                          // copy to clipboard
                          e.stopPropagation();
                          navigator.clipboard.writeText(link);
                          GeneralHelper.showToast({
                            primaryText: "Link copied to clipboard",
                            icon: ICONS.copy,
                            additionalClass: "green",
                          });
                        }}
                      >
                        {ICONS.copy}
                      </span>
                    </div>
                    <div className="infoItem readingTime">
                      {ICONS.timer}{" "}
                      <span>
                        {readTime > 1
                          ? `${readTime} mins read`
                          : `${readTime} min read`}
                      </span>
                    </div>
                    <div className="infoItem label">
                      <span>🇬🇧 Basic English</span>
                    </div>
                  </div>
                </div>

                <div className="keypoints">
                  <div className="keypointsTitle">
                    <div className="keyLeft">Summary</div>
                    {/* <div className="keyRight arrows">
                            <span className="prev">{ICONS.chevronLeft}</span>
                            <span className="next">{ICONS.chevronRight}</span>
                        </div> */}
                  </div>
                  <ul>
                    {summary.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>

                <AiSummaryMediaViewer mediaLinks={mediaLinks} />

                {statText?.length ? (
                  <div className="extraInfo">
                    <div className="extraInfoTitle">
                      What our community thinks about this recommendation?
                    </div>
                    {statText}
                  </div>
                ) : (
                  <></>
                )}

                <div className="footerFeedback">
                  {isFeedbackSubmitted ? (
                    <div className="feedbackLeft">
                      Thank you for your feedback!
                    </div>
                  ) : (
                    <>
                      <div className="feedbackLeft">
                        Help us to improve. Was it useful?
                      </div>

                      <div className="buttons">
                        <Button
                          type={"default"}
                          size={"extraSmall"}
                          icon={ICONS.thumbsDownNew}
                          iconPos={"left"}
                          extraClasses={"thumbsDown"}
                          onClick={_submitNegativeFeedback}
                        />
                        <Button
                          type={"default"}
                          size={"extraSmall"}
                          icon={ICONS.thumbsUpNew}
                          iconPos={"left"}
                          extraClasses={"thumbsUp"}
                          onClick={_submitPositiveFeedback}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </MobileDrawer>
    );
  } else {
    return (
      <>
        <OutsideClickHandler onOutsideClick={onHide}>
          <div className={"aiAnalysisPopupWrapper " + (true ? "show" : "")}>
            {isLoading ? (
              <>
                <div className="ailoaderWrapper">
                  {/* <div className="ailoaderCustom"></div> */}
                  <div className="gradientLoader"></div>
                </div>
              </>
            ) : (
              <>
                {hasError ? (
                  <></>
                ) : (
                  <>
                    <div className="aiAnalysisPopupHeader">
                      <div className="aiAnalysisLeft">
                        {ICONS.questerIcon} <span>quester digest</span>
                      </div>

                      <div className="aiAnalysisRight">
                        {ICONS.sparkleFilledGradient} <span>AI generated</span>
                      </div>
                    </div>

                    <div className="aiAnalysisPopup">
                      <div className="aiAnalysisPopupInner">
                        <div className="popupCoverImage">
                          {/* <img src="https://placehold.co/600x400" alt="" /> */}
                          <LinkPreviewImage
                            link={link}
                            staticImageUrl={imageURL}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />

                          <div className="aiTag">
                            {ICONS.sparkleFilledGradient}{" "}
                            <span>AI generated</span>{" "}
                          </div>
                        </div>

                        <div className="titleAndInfo">
                          <div className="title">{title}</div>

                          <div className="infos">
                            <div
                              className="infoItem url"
                              title={link.replace("https://", "")}
                              onClick={(e) => {
                                // open in new tab
                                window.open(link, "_blank");
                              }}
                            >
                              {ICONS.link}{" "}
                              <span>{link.replace("https://", "")}</span>{" "}
                              <span
                                className="copyIcon"
                                onClick={(e) => {
                                  // copy to clipboard
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(link);
                                  GeneralHelper.showToast({
                                    primaryText: "Link copied to clipboard",
                                    icon: ICONS.copy,
                                    additionalClass: "green",
                                  });
                                }}
                              >
                                {ICONS.copy}
                              </span>
                            </div>
                            <div className="infoItem readingTime">
                              {ICONS.timer}{" "}
                              <span>
                                {readTime > 1
                                  ? `${readTime} mins read`
                                  : `${readTime} min read`}
                              </span>
                            </div>
                            <div className="infoItem label">
                              <span>🇬🇧 Basic English</span>
                            </div>
                          </div>
                        </div>

                        <div className="keypoints">
                          <div className="keypointsTitle">
                            <div className="keyLeft">Summary</div>
                           
                          </div>
                          <ul>
                            {summary.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>

                        <AiSummaryMediaViewer mediaLinks={mediaLinks} />
                        {statText?.length ? (
                          <>
                            <div className="extraInfo">
                              <div className="extraInfoTitle">
                                What our community thinks about this
                                recommendation?
                              </div>
                              <div className="extraInfoDesc">
                                {statText}
                                {/* The members of our community seems to like this
                              recommendation. Also, the <span>56% members</span> who
                              voted it thinks is <span>Well organised</span> and{" "}
                              <span>23%</span> think is <span>Insightful</span>. */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="footerFeedback">
                          {isFeedbackSubmitted ? (
                            <div className="feedbackLeft">
                              Thank you for your feedback!
                            </div>
                          ) : (
                            <>
                              <div className="feedbackLeft">
                                Help us to improve. Was it useful?
                              </div>

                              <div className="buttons">
                                <Button
                                  type={"default"}
                                  size={"extraSmall"}
                                  icon={ICONS.thumbsDownNew}
                                  iconPos={"left"}
                                  extraClasses={"thumbsDown"}
                                  onClick={_submitNegativeFeedback}
                                />
                                <Button
                                  type={"default"}
                                  size={"extraSmall"}
                                  icon={ICONS.thumbsUpNew}
                                  iconPos={"left"}
                                  extraClasses={"thumbsUp"}
                                  onClick={_submitPositiveFeedback}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </OutsideClickHandler>
      </>
    );
  }
};

export default AiSummaryViewer;
