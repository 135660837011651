import React, { useState, useEffect } from "react";
import CommunityEditorUtils from "../../../community/community-editor-utils";
import CommunityItem from "../../../../components/community-item/community-item";
import "./type-previewer.scss";

const TypePreviewer = ({ type }) => {
  const resource = CommunityEditorUtils.generateResourceFromType(type);
  return (
    <>
      <div className="previewSection">
        <div className="title">PREVIEW</div>

        <div className="resourceWrapper">
          <div
            className="resourcePlaceholder"
            style={{
              pointerEvents: "none",
            }}
          >
            <CommunityItem
              communityItem={resource}
              teamResource={true}
              canQuickAdd={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TypePreviewer;
