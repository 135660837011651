import "firebase/analytics";
import moment from "moment";
import { customAlphabet } from "nanoid";

// import { extractQueryParams } from "../helper-methods";
// import HelperMethods from "../helpers";
// import CookieHelper from "../modules/shared/components/cookie-banner/cookie-helper";

import firebase from "../../top-level-providers/firebase-provider/firebase-utils";
import DeviceUtils from "../device-utils";
import GeneralHelper from "../../helper-methods/general-helpers";
import { store } from "../../../stores/store";
import { getFirestore } from "firebase/firestore";
import { setDoc, doc } from "firebase/firestore"; 
import GtagEventLogger from "../gtag-event-logger";

const nanoid = customAlphabet("0123456789", 16);
const generatedSessionId = parseInt(nanoid());



class ActivityRecorder {
  static sessionSpan = 0;
  static isAnalyticsInitialized = false;
  static session = {
    sessionId: generatedSessionId,
    sessionSpan: 0,
    activities: [],
    sessionTimeStamp: +new Date(),
    referrer: "",
    lastModifiedOn: +new Date(),
  };

  static sessionId = generatedSessionId;
  static sessionSpanCounterRef = null;
  static pageActivitySpanCounterRef = null;
  static currentActivityIndex = null;
  static isTabActiveInBrowser = true;
  static currentIframeViewerActivityIndex = null;
  static iframeViewerActivitySpanCounterRef = null;

  static async initiateSession() {
    const isCrawler = GeneralHelper.isCrawler();
    if (window && !isCrawler) {
      try {
        window.onfocus = function () {
          ActivityRecorder.isTabActiveInBrowser = true;
        };
        window.onblur = function () {
          ActivityRecorder.isTabActiveInBrowser = false;
        };
        ActivityRecorder.session.referrer = window.document.referrer;
        const queryParams = GeneralHelper.extractQueryParams();
        // Add additional check for tiktok (manual)
        if (queryParams.hasOwnProperty("t")) {
          ActivityRecorder.session.referrer = "https://tiktok.com";
        } else if (queryParams.hasOwnProperty("twinkl?utm_medium")) {
          ActivityRecorder.session.referrer = "May 2022 Twinkl email";
        } else if (queryParams.hasOwnProperty("ref")) {
          ActivityRecorder.session.referrer = queryParams.ref;
        }
        if ("standalone" in window.navigator && window.navigator.standalone) {
          ActivityRecorder.session.isPwa = true;
        } else {
          ActivityRecorder.session.isPwa = false;
        }
        const deviceId = DeviceUtils.getCustomDeviceId();
        // console.log("deviceId 54555214:>> ", deviceId);
        if (deviceId) {
          ActivityRecorder.session.deviceId = deviceId;
        }
      } catch (error) {}
      setTimeout(() => {
        // Try to initiate
        ActivityRecorder.initiateGoogleAnalytics();
      }, 500);
      const shareId = ActivityRecorder._parseShareId();
      if (shareId) {
        ActivityRecorder.session.shareId = shareId;
        ActivityRecorder._updateShareViews(shareId);
      }
      ActivityRecorder.sessionSpanCounterRef = setInterval(() => {
        ActivityRecorder.session.sessionSpan++;
        ActivityRecorder.session.userId = ActivityRecorder.getUserName();
        const recordInterval = ActivityRecorder.getReocordInterval(
          ActivityRecorder.session.sessionSpan
        );
        if (ActivityRecorder.session.sessionSpan % recordInterval === 0) {
          ActivityRecorder.writeToDB();
        }
      }, 1000);
    }
  }

  static _getPageShare(shareId) {
    return new Promise((resolve, reject) => {
      const isCrawler = GeneralHelper.isCrawler();
      if (!isCrawler) {
        const db = firebase.firestore();
        db.collection("page-shares")
          .doc(shareId)
          .onSnapshot((snapshot) => {
            const data = snapshot.data();
            resolve(data);
          });
      }
    });
  }

  static async _updateShareViews(shareId) {
    const isCrawler = GeneralHelper.isCrawler();
    if (!isCrawler) {
      const db = firebase.firestore();
      const pageShare = await ActivityRecorder._getPageShare(shareId);
      if (pageShare) {
        if (!pageShare.visitCount) {
          pageShare.visitCount = 0;
        }
        pageShare.visitCount = pageShare.visitCount + 1;
        db.collection("page-shares").doc(shareId).update(pageShare);
      }
    }
  }

  static _parseShareId() {
    try {
      const params = GeneralHelper.extractQueryParams();
      if (params && params.s) {
        return params.s;
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  static writeToDB() {
    const isCrawler = GeneralHelper.isCrawler();
    if (!isCrawler) {
      // Try to initiate if not
      ActivityRecorder.initiateGoogleAnalytics();
      ActivityRecorder.session.lastModifiedOn = +new Date();
      const db = getFirestore(firebase);

      // console.log('ActivityRecorder.session :>> ', ActivityRecorder.session);
      // console.log('ActivityRecorder.sessionId :>> ', ActivityRecorder.sessionId);
      if (ActivityRecorder.isTabActiveInBrowser) {
        try {
          setDoc(doc(db, "sessions", ActivityRecorder.sessionId.toString()), ActivityRecorder.session);
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
    }
  }

  static initiateGoogleAnalytics() {
    if (!ActivityRecorder.isAnalyticsInitialized) {
      const storageValue = localStorage.getItem("cookieBannerDisplayed");
      const accepted = storageValue === "true";

      if (accepted) {
        ActivityRecorder.isAnalyticsInitialized = true;
        // firebase.analytics();
      }
    }
  }

  static getReocordInterval(sessionSpan) {
    if (sessionSpan > -1 && sessionSpan < 10) {
      // First 10 secs
      return 1;
    }
    if (sessionSpan >= 10 && sessionSpan < 30) {
      // First 30 secs
      return 5;
    }
    if (sessionSpan >= 30 && sessionSpan < 300) {
      // First 5 mins
      return 10;
    }
    if (sessionSpan >= 300 && sessionSpan < 600) {
      // First 10 mins
      return 30;
    }
    if (sessionSpan >= 600 && sessionSpan < 3600) {
      // First 1 hr
      return 60;
    }
    if (sessionSpan >= 3600) {
      // After first 1 hr
      return 600;
    }
  }

  static getUserName() {
    const { userStore } = store.getState();
    if (userStore?.profileData) {
      if (userStore?.profileData?.ownerId?.length) {
        return userStore.profileData.ownerId;
      }
    }
    return "Anonymous";
  }

  static recordClick(clickMeta) {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      activityType: "link-click",
      link: clickMeta.link,
      linkName: clickMeta.linkName,
      pageName: clickMeta.pageName || "",
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
    }, 200);
  }

  static startRecordingPageActivity(pageMeta) {
    let activity = {
      activityType: "page-stay",
      link: window.location.href,
      activitySpanInSeconds: 0,
      pageName: pageMeta?.pageName || "",
    };
    const metaData = ActivityRecorder.generateActivityMetaData();
    activity = {
      ...activity,
      ...metaData,
    };
    // Reset all org and team info if landing page
    if (pageMeta?.pageName === "Landing Page") {
      activity.orgUuid = null;
      activity.orgName = null;
      activity.teamUuid = null;
      activity.teamName = null;
    }
    // console.log("activity 99999211:>> ", activity);
    ActivityRecorder.pageActivitySpanCounterRef = setInterval(() => {
      ActivityRecorder.currentActivityIndex =
        ActivityRecorder?.session?.activities?.length - 1 || 0;
      if (
        ActivityRecorder?.session?.activities[
          ActivityRecorder.currentActivityIndex
        ]?.activityType === "page-stay"
      ) {
        ActivityRecorder.session.activities[
          ActivityRecorder.currentActivityIndex
        ].activitySpanInSeconds++;
      }
    }, 1000);
    ActivityRecorder.currentActivityIndex =
      ActivityRecorder?.session?.activities?.length || 0;
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("page_stay", {
        page_name: pageMeta?.pageName || "",
      });
    }, 200);
  }

  static updateRecordingPageActivity(pageMeta) {
    ActivityRecorder.session.activities[ActivityRecorder.currentActivityIndex] =
      {
        ...ActivityRecorder.session.activities[
          ActivityRecorder.currentActivityIndex
        ],
        ...pageMeta,
      };
    setTimeout(() => {
      ActivityRecorder.writeToDB();
    }, 200);
  }

  static stopRecordingPageActivity() {
    clearInterval(ActivityRecorder.pageActivitySpanCounterRef);
    ActivityRecorder.pageActivitySpanCounterRef = null;
  }

  /**
   * To start recording activity when a resource is opened in an iframe
   * @param {*} properties of iframe
   */
  static startRecordingIframeViewerActivity({
    teamUuid = null,
    orgUuid = null,
    questUuid = null,
    questItemUuid = null,
    link = "",
    pageName = "Quest Page",
  }) {
    const metaData = ActivityRecorder.generateActivityMetaData();
    // Create activity object
    const activity = {
      teamUuid,
      orgUuid,
      questUuid,
      questItemUuid,
      link,
      openedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
      createdOn: +new Date(),
      iframeOpenSpanInSeconds: 1,
      activityType: "iframe-view",
      pageName,
      ...metaData,
    };
    // Keep updating time span
    ActivityRecorder.iframeViewerActivitySpanCounterRef = setInterval(() => {
      ActivityRecorder.session.activities[
        ActivityRecorder.currentIframeViewerActivityIndex
      ].iframeOpenSpanInSeconds++;
    }, 1000);
    ActivityRecorder.currentIframeViewerActivityIndex =
      ActivityRecorder?.session?.activities?.length || 0;
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
    }, 200);
  }

  /**
   * To stop recording last activity of iframe viewer
   * @param {*} properties of iframe
   */
  static stopRecordingIframeViewerActivity() {
    clearInterval(ActivityRecorder.iframeViewerActivitySpanCounterRef);
    ActivityRecorder.iframeViewerActivitySpanCounterRef = null;
  }

  static recordSearchActivity({ searchTerm = "", hasResults = false }) {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      activityType: "perform-search",
      searchTerm,
      hasResults,
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
    }, 200);
  }

  static recordOpenInNewTab({
    teamUuid = null,
    orgUuid = null,
    questUuid = null,
    questItemUuid = null,
    link = "",
    openType = "auto", // "manual"
  }) {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      teamUuid,
      orgUuid,
      questUuid,
      questItemUuid,
      link,
      openedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
      createdOn: +new Date(),
      activityType: `${openType}-open-new-tab`,
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      GtagEventLogger.logEvent("open_new_tab", {
        ...activity,
      });
      ActivityRecorder.writeToDB();
    }, 200);
  }

  static _getOrgByUuid(orgUuid) {
    const { lofiData } = store.getState();
    return lofiData?.listOfOrgs?.find((org) => org.uuid === orgUuid);
  }

  static generateActivityMetaData() {
    // const { lofiData } = store.getState();
    // let metaData = {
    //   orgUuid: null,
    //   orgName: null,
    //   teamUuid: null,
    //   teamName: null,
    //   createdOn: +new Date(),
    // };
    // if (lofiData?.selectedOrg) {
    //   metaData.orgUuid = lofiData.selectedOrg.uuid || "";
    //   metaData.orgName = lofiData.selectedOrg.organisationDisplayName || "";
    // }
    // if (lofiData?.selectedParty) {
    //   metaData.teamUuid = lofiData.selectedParty.ownerTeamId || "";
    //   metaData.teamName = lofiData.selectedParty.owningTeamDisplayName || "";
    //   // Overwrite org info from selected team
    //   const teamOrg = ActivityRecorder._getOrgByUuid(
    //     lofiData.selectedParty.owningOrganizationId
    //   );
    //   if (teamOrg) {
    //     metaData.orgUuid = teamOrg.uuid || "";
    //     metaData.orgName = teamOrg.organisationDisplayName || "";
    //   }
    // }
    // return metaData;
    const { communityStore } = store.getState();
    let metaData = {
      orgUuid: null,
      orgName: null,
      teamUuid: null,
      teamName: null,
      createdOn: +new Date(),
    };
    if (communityStore?.activeCommunity) {
      metaData.orgUuid = communityStore.activeCommunity.uuid || "";
      metaData.orgName =
        communityStore.activeCommunity.organisationDisplayName || "";
    }
    return metaData;
  }
}

export default ActivityRecorder;
