import React, { Component, useEffect } from "react";
import ICONS from "../../assets/quester-icons";
import OutsideClickHandler from "react-outside-click-handler";
import "./image-picker.scss";


const ImagePicker = ({
  isVisible = false,
  closePopup = () => {},
  onUpdateImage = () => {},
}) => {
  const [imagePreview, setImagePreview] = React.useState(null);
  const [url, setUrl] = React.useState("");

  const _updateImage = (image) => {
    // Create object URL
    const imageUrl = URL.createObjectURL(image);
    setImagePreview(imageUrl);
    onUpdateImage(imageUrl);
    closePopup();
  };

  const _submitUrl = () => {
    if (!url) {
      return;
    }
    onUpdateImage(url);
    closePopup();
  };

  useEffect(() => {
    if (isVisible) {
      setUrl("");
      setImagePreview(null);
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          closePopup();
        }}
      >
        <div className="imagePickerPopup d-none d-md-block">
          <div className="inner">
            <div
              className="item"
              onClick={() => document.getElementById("file").click()}
            >
              {ICONS.imageIcon} <span>Select from my device</span>
            </div>

            <div className="inputWrapper">
              <input
                type="text"
                className="form-control"
                placeholder="or paste an URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    _submitUrl();
                  }
                }}
              />
            </div>

            {/* <div className="colorPickerWrapper">
              <div className="colorPickerTitle">Pick a colour</div>

              <div className="colorGird">
                <div className="color brown"><div className="circle"></div></div>
                <div className="color orange"><div className="circle"></div></div>
                <div className="color granade"><div className="circle"></div></div>
                <div className="color yellow-warm"><div className="circle"></div></div>
                <div className="color yellow"><div className="circle"></div></div>
                <div className="color purple"><div className="circle"></div></div>
                <div className="color purple-mate"><div className="circle"></div></div>
                <div className="color pink"><div className="circle"></div></div>
                <div className="color pink-mate"><div className="circle"></div></div>
                <div className="color violet"><div className="circle"></div></div>
                <div className="color blue"><div className="circle"></div></div>
                <div className="color azure"><div className="circle"></div></div>
                <div className="color mint"><div className="circle"></div></div>
                <div className="color green"><div className="circle"></div></div>
                <div className="color salmon"><div className="circle"></div></div>
              </div>
            </div> */}
          </div>
        </div>
        <input
          type="file"
          id="file"
          accept="image/*"
          onChange={(e) => _updateImage(e.target.files[0])}
          style={{ display: "none" }}
        />
      </OutsideClickHandler>
    </>
  );
};

export default ImagePicker;
