import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import "./quest-comment-user-image.scss";
// Optimization level: 1
const QuestCommentUserImage = React.memo(
  ({ comment = {}, self = false, withWrapper = false }) => {
    let {
      userDisplayName: creatorName = "",
      ownerId: creatorId = "",
      userPhotoUrl: creatorPhoto = "",
    } = comment;
    const userStore = useSelector((state) => state.userStore || {});

    if (self) {
      creatorName = userStore?.profileData?.displayName;
      creatorId = userStore?.profileData?.ownerId;
      creatorPhoto = userStore?.profileData?.photoUrl;
    }

    const [formattedBadgeDetails, setFormattedBadgeDetails] = useState({
      nameInitial: "A",
      userId: "anonymous_user",
      nameAsciSum: 0,
      backgroundColor: "white",
      displayName: "Anonymous User",
    });
    const { displayName, nameInitial, userId, nameAsciSum, backgroundColor } =
      formattedBadgeDetails;
    const _formatAndSetOptions = () => {
      const displayName = _formatDisplayName();
      const nameInitial = _getNameInitial();
      const userId = _formatUserId();
      const nameAsciSum = GeneralHelper.getStringAsciSum(userId);
      const backgroundColor = GeneralHelper.generateRandomColor(nameAsciSum);
      setFormattedBadgeDetails({
        nameInitial,
        userId,
        nameAsciSum,
        backgroundColor,
        displayName,
      });
    };

    useEffect(() => {
      _formatAndSetOptions();
    }, []);

    const _formatDisplayName = () => {
      let displayName = "Anonymous User";
      if (creatorName?.length) {
        displayName = creatorName;
      } else if (creatorId?.length) {
        displayName = creatorId;
      }
      return GeneralHelper.maskUserName(displayName);
    };

    const _formatUserId = () => {
      let userId = "anonymous_user";
      if (creatorId?.length) {
        userId = creatorId;
      }
      return GeneralHelper.maskUserName(userId);
    };

    const _getNameInitial = () => {
      let nameInitial = "A";
      const displayName = _formatDisplayName();
      if (displayName?.length) {
        nameInitial = displayName[0];
      }
      return nameInitial;
    };

    const _renderImage = () => {
      let preparedLink = creatorPhoto;
      if (creatorPhoto?.length && creatorPhoto?.indexOf("http") === -1) {
        preparedLink = `${window.REACT_APP_CDN_BASE_URL}${creatorPhoto}`;
      }
      if (preparedLink?.length) {
        if (comment?.isPremium) {
          return (
            <svg
              viewBox="0 0 100 100"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id="img"
                  patternUnits="userSpaceOnUse"
                  width="100"
                  height="100"
                >
                  <image
                    xlinkHref={preparedLink}
                    x="-25"
                    width="150"
                    height="100"
                  />
                </pattern>
              </defs>
              <polygon
                points="50 1 95 25 95 75 50 99 5 75 5 25"
                fill="url(#img)"
              />
            </svg>
          );
        } else {
          return <img src={preparedLink} alt="" />;
        }
      } else {
        if (comment?.isPremium) {
          // Add the svg with name initial
          return (
            <svg
              viewBox="0 0 100 100"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id="img"
                  patternUnits="userSpaceOnUse"
                  width="100"
                  height="100"
                >
                  <rect width="100" height="100" fill={backgroundColor} />
                </pattern>
              </defs>
              <polygon
                points="50 1 95 25 95 75 50 99 5 75 5 25"
                fill="url(#img)"
              />
              <text
                x="50"
                y="60"
                textAnchor="middle"
                fill="white"
                fontSize="40"
                fontWeight="bold"
              >
                {nameInitial.toUpperCase()}
              </text>
            </svg>
          );
        }
        return (
          <div
            className="questCommentUserInitials"
            style={{
              backgroundColor,
            }}
          >
            {nameInitial}
          </div>
        );
      }
    };

    const _generateWrapperClass = () => {
      let wrapperClass = "userImage ";
      if (comment?.isPremium) {
        wrapperClass += "expertImage ";
      }
      return wrapperClass;
    };

    const wrapperClass = _generateWrapperClass();
    if (withWrapper) {
      return (
        <>
          <div className={wrapperClass}>{_renderImage()}</div>
        </>
      );
    } else {
      return _renderImage();
    }
  }
);

export default QuestCommentUserImage;
