import React from "react";

const ResourceTopic = ({ topicTitle, isSelected, onClick }) => {
  return (
    <div
      className={`topic ${isSelected ? "active" : ""}`}
      title={topicTitle}
      onClick={onClick}
    >
      {topicTitle}
    </div>
  );
};

export default ResourceTopic;
