import React from "react";
import "./maintenance-mode-viewer.scss";

const MaintenanceViewer = () => {
  return (
    <div className="maintenanceViewWrapper">
      <img
        src={require("../../../../assets/images/logo.png")}
        alt="QuesterLogo"
      />
      <h3>{"we'll be back soon"}</h3>
      <p>
        quester is down for scheduled maintenance and <br /> expect to be back
        online in a few minutes
      </p>
    </div>
  );
};

export default MaintenanceViewer;
