import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

const SaveActivities = {
  addSaveItemActivity: ({
    entityType,
    entityId,
    resourceLink,
    resourceName,
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId,
      entityValue1: resourceName,
      entityValue2: resourceLink,
      activityType: "save-item",
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("save-item", activity);
    }, 200);
  },
  addUnsaveItemActivity: ({
    entityType,
    entityId,
    resourceLink,
    resourceName,
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId,
      entityValue1: resourceName,
      entityValue2: resourceLink,
      activityType: "unsave-item",
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("unsave-item", activity);
    }, 200);
  },
  addSaveCollectionActivity: ({
    entityType,
    entityId,
    collectionName,
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId,
      entityValue1: collectionName,
      activityType: "save-collection",
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("save-collection", activity);
    }, 200);
  },
  addUnsaveCollectionActivity: ({
    entityType,
    entityId,
    collectionName,
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId,
      entityValue1: collectionName,
      activityType: "unsave-collection",
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("unsave-collection", activity);
    }, 200);
  },
};

export default SaveActivities;
