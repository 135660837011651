// import HelperMethods from "../helpers";
// import firebase from "../utils/firebase-helper";

// class FirebaseHelper {
//   static _dbRef = null;
//   static _isCrawler = HelperMethods.isCrawler();
//   static initiateFirestore = () => {
//     if (!FirebaseHelper._isCrawler) {
//       FirebaseHelper._dbRef = firebase.firestore();
//     }
//   }
//   static getCollectionRef = (collectionName) => {
//     if (!FirebaseHelper._isCrawler) {
//       if (!FirebaseHelper._dbRef) {
//         FirebaseHelper.initiateFirestore();
//       }
//       return FirebaseHelper._dbRef.collection(collectionName);
//     }
//   }
// }

// export default FirebaseHelper;


import { initializeApp } from "firebase/app";
import "firebase/compat/firestore";
import { getFirestore } from  "firebase/firestore";
import "firebase/analytics";
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";


// import NotificationUtils from "../features/notification/utils/notification-utils";

const FIREBASE_CONFIGS = {
  test: {
    apiKey: "AIzaSyACU6yxRSoOQeXVlhezzJXCgvGTTbByfVg",
    authDomain: "questerio-lofi-fs-test.firebaseapp.com",
    projectId: "questerio-lofi-fs-test",
    storageBucket: "questerio-lofi-fs-test.appspot.com",
    messagingSenderId: "132379349297",
    appId: "1:132379349297:web:b80e6e1374a8a2ef69a010",
    measurementId: "G-G5FP411PGR",
  },
  // dev: {
  //   apiKey: "AIzaSyAfgjUZAI7sVBrVTN-Bp7iqARQIIn3sMSE",
  //   authDomain: "fir-basics-9775b.firebaseapp.com",
  //   databaseURL: "https://fir-basics-9775b.firebaseio.com",
  //   projectId: "fir-basics-9775b",
  //   storageBucket: "fir-basics-9775b.appspot.com",
  //   messagingSenderId: "1079140986017",
  //   appId: "1:1079140986017:web:914e19c0a1201daf9e94e0",
  // },
  dev: {
    apiKey: "AIzaSyCFSh_1PHg0FY99pqWy0ZibR0BnkMvfjow",
    authDomain: "questerio-lofi-fs-dev.firebaseapp.com",
    projectId: "questerio-lofi-fs-dev",
    storageBucket: "questerio-lofi-fs-dev.appspot.com",
    messagingSenderId: "562696778700",
    appId: "1:562696778700:web:2e43205ada3190e6b6a369",
    measurementId: "G-VPHSHC11W1",
  },
  prod: {
    apiKey: "AIzaSyCqggXW9KEaIxE_349OVNncAbFZOv6StVM",
    authDomain: "questerio-lofi-fs-prod.firebaseapp.com",
    projectId: "questerio-lofi-fs-prod",
    storageBucket: "questerio-lofi-fs-prod.appspot.com",
    messagingSenderId: "564486180062",
    appId: "1:564486180062:web:ff41e5c5530767dbc4064f",
    measurementId: "G-VPYH8QSKFR",
  },
  stg: {
    apiKey: "AIzaSyCqggXW9KEaIxE_349OVNncAbFZOv6StVM",
    authDomain: "questerio-lofi-fs-prod.firebaseapp.com",
    projectId: "questerio-lofi-fs-prod",
    storageBucket: "questerio-lofi-fs-prod.appspot.com",
    messagingSenderId: "564486180062",
    appId: "1:564486180062:web:ff41e5c5530767dbc4064f",
    measurementId: "G-VPYH8QSKFR",
  },
};

// const app = firebase.initializeApp(FIREBASE_CONFIGS[window.REACT_APP_ENV]);
// app.analytics();
// // NotificationUtils.initiateMessaging(app);
// export default firebase;

const firebaseApp = initializeApp(FIREBASE_CONFIGS[window.REACT_APP_ENV]);
const analytics = getAnalytics(firebaseApp);
export default firebaseApp;
