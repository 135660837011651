import React, { Component } from "react";
import "./community-icon.scss";
import Image from "../../../../components/core/image/image";

const CommunityIcon = ({
  orgIconImageURL,
  iconWrapperClassName = "orgImageIcon",
}) => {
  return (
    <>
      {orgIconImageURL?.length > 10 ? (
        <Image src={orgIconImageURL} />
      ) : (
        <div className={iconWrapperClassName}>{orgIconImageURL}</div>
      )}
    </>
  );
};

export default CommunityIcon;
