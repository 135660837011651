import cloneDeep from "clone-deep";
import ApiCalls from "../../shared/api";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import { CollectionStoreActions } from "../../stores/collection-store/collection-store-actions";
import { store } from "../../stores/store";
import VotingUtils from "../../features/voting/voting-utils";
import GeneralHelper from "../../shared/helper-methods/general-helpers";
import SaveActivities from "../../shared/utils/activity-recorder/activity-helpers/save-activities";
import { CommunityStoreActions } from "../../stores/community-store/community-store-actions";
import { EventEmitter } from "../../shared/utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";
import ShareActivities from "../../shared/utils/activity-recorder/activity-helpers/share-activities";
import ICONS from "../../assets/quester-icons";
import CommunityUtils from "../community/community-utils";
import router from "../../shared/top-level-declarations/history";

const CollectionUtils = {
  initiateCollectionLoad: (collectionId) => {
    // First clear the collection data
    CollectionUtils.clearCollectionData();
    // Then set the active collection id
    CollectionUtils._setActiveCollectionId(collectionId);
    // Load active collection
    CollectionUtils._loadActiveCollection(collectionId);
    // Load active collection items
    CollectionUtils._loadActiveCollectionItems(collectionId);
  },
  toggleCollectionItemSave: async (collectionItem) => {
    // Get collection from store
    const { collectionStore } = store.getState();
    const { activeCollectionItems } = cloneDeep(collectionStore);
    // Find the collection in the store
    const collectionItemIndex = activeCollectionItems.findIndex(
      (item) => item.uuid === collectionItem.uuid
    );
    // If found
    // First update the collection in the store
    if (collectionItemIndex > -1) {
      activeCollectionItems[collectionItemIndex].isFollowed =
        !activeCollectionItems[collectionItemIndex].isFollowed;
      // Increase or decrease the follow count
      if (activeCollectionItems[collectionItemIndex].isFollowed) {
        // activeCollectionItems[collectionItemIndex].saveCount =
        //   activeCollectionItems[collectionItemIndex].saveCount + 1;
        if (
          activeCollectionItems[collectionItemIndex].stat &&
          activeCollectionItems[collectionItemIndex].stat.hasOwnProperty(
            "saveCount"
          )
        ) {
          activeCollectionItems[collectionItemIndex].stat.saveCount =
            activeCollectionItems[collectionItemIndex].stat.saveCount + 1;
        } else {
          activeCollectionItems[collectionItemIndex].saveCount =
            activeCollectionItems[collectionItemIndex].saveCount + 1;
        }
      } else {
        if (
          activeCollectionItems[collectionItemIndex].stat &&
          activeCollectionItems[collectionItemIndex].stat.hasOwnProperty(
            "saveCount"
          )
        ) {
          activeCollectionItems[collectionItemIndex].stat.saveCount =
            activeCollectionItems[collectionItemIndex].stat.saveCount - 1;
        } else {
          activeCollectionItems[collectionItemIndex].saveCount =
            activeCollectionItems[collectionItemIndex].saveCount - 1;
        }
        // activeCollectionItems[collectionItemIndex].saveCount =
        //   activeCollectionItems[collectionItemIndex].saveCount - 1;
      }
      store.dispatch(
        CollectionStoreActions.staticActions.setActiveCollectionItems(
          activeCollectionItems
        )
      );
      try {
        if (activeCollectionItems[collectionItemIndex].isFollowed) {
          // Follow
          await ApiCalls.org.private.toggleCommunityResourceSave(
            collectionItem.uuid
          );
          GeneralHelper.showToast({
            primaryText: "New item saved in your profile",
            secondaryText: "Click here to check your liked items",
            navigateTo: "/my-profile?tab=saved-resources",
            icon: ICONS.heartSolid,
          });
        } else {
          // Unfollow
          await ApiCalls.org.private.toggleCommunityResourceSave(
            collectionItem.uuid
          );

          // GeneralHelper.showToast({
          //   primaryText:
          //     "You have removed this resource from your saved resources",
          //   secondaryText: "Click here to view your saved resources",
          //   navigateTo: "/my-profile?tab=saved-resources",
          // });
        }
      } catch (error) {
        console.log(error);
        const cloneActiveCollectionItems = cloneDeep(activeCollectionItems);
        // Revert
        if (cloneActiveCollectionItems[collectionItemIndex].isFollowed) {
          cloneActiveCollectionItems[collectionItemIndex].saveCount =
            cloneActiveCollectionItems[collectionItemIndex].saveCount - 1;
        } else {
          cloneActiveCollectionItems[collectionItemIndex].saveCount =
            cloneActiveCollectionItems[collectionItemIndex].saveCount + 1;
        }
        cloneActiveCollectionItems[collectionItemIndex].isFollowed =
          !cloneActiveCollectionItems[collectionItemIndex].isFollowed;
        store.dispatch(
          CollectionStoreActions.staticActions.setActiveCollectionItems(
            cloneDeep(cloneActiveCollectionItems)
          )
        );
      }
    }
  },
  toggleFollow: async (collection) => {
    if (!AuthHelper.isLoggedIn()) {
      AuthHelper.login();
      return;
    }
    // Get collection from store
    const { collectionStore } = store.getState();
    const { activeCollection } = cloneDeep(collectionStore);
    // If found
    // First update the collection in the store
    if (activeCollection.uuid === collection.uuid) {
      activeCollection.isFollowed = !activeCollection.isFollowed;
      // Increase or decrease the follow count
      if (!activeCollection?.stat) {
        activeCollection.stat = {
          saveCount: 0,
        };
      }
      if (activeCollection.isFollowed) {
        activeCollection.stat.saveCount =
          parseInt(activeCollection.stat.saveCount) + 1;
      } else {
        activeCollection.stat.saveCount =
          parseInt(activeCollection.stat.saveCount) - 1;
      }
      store.dispatch(
        CollectionStoreActions.staticActions.setActiveCollection(
          activeCollection
        )
      );
      try {
        if (activeCollection.isFollowed) {
          // Follow
          await ApiCalls.quest.private.followQuest(collection.uuid);
          GeneralHelper.showToast({
            primaryText: "New collection saved in your profile",
            secondaryText: "Click here to check your saved collections",
            navigateTo: "/my-profile?tab=saved-collections",
            icon: ICONS.heartSolid,
          });
          SaveActivities.addSaveCollectionActivity({
            entityType: "quest",
            entityId: collection.uuid,
            collectionName: collection.listname,
          });
        } else {
          // Unfollow
          await ApiCalls.quest.private.unfollowQuest(collection.uuid);
          SaveActivities.addUnsaveCollectionActivity({
            entityType: "quest",
            entityId: collection.uuid,
            collectionName: collection.listname,
          });
        }
        // On success update community store
        const { communityStore } = store.getState();
        const { activeCommunityCollections } = cloneDeep(communityStore);
        const collectionIndex = activeCommunityCollections.findIndex(
          (item) => item.uuid === collection.uuid
        );
        if (collectionIndex > -1) {
          activeCommunityCollections[collectionIndex].isFollowed =
            activeCollection.isFollowed;
          activeCommunityCollections[collectionIndex].saveCount =
            activeCollection.followersCount;
          store.dispatch(
            CommunityStoreActions.staticActions.setActiveCommunityCollections(
              activeCommunityCollections
            )
          );
        }
      } catch (error) {
        console.log(error);
        // Revert
        const cloneActiveCollection = cloneDeep(activeCollection);
        if (cloneActiveCollection.isFollowed) {
          cloneActiveCollection.saveCount = cloneActiveCollection.saveCount - 1;
        } else {
          cloneActiveCollection.saveCount = cloneActiveCollection.saveCount + 1;
        }
        cloneActiveCollection.isFollowed = !cloneActiveCollection.isFollowed;

        store.dispatch(
          CollectionStoreActions.staticActions.setActiveCollection(
            cloneDeep(cloneActiveCollection)
          )
        );
      }
    }
  },
  clearCollectionData: () => {
    store.dispatch(CollectionStoreActions.staticActions.clearCollectionData());
  },
  updateVoteLocally: ({ teamResourceUuid, updatedDrowStats, myDRow }) => {
    // // Get resource from store
    // const { communityStore } = store.getState();
    // const { activeCommunityResources } = cloneDeep(communityStore);
    // // Find the resource in the store
    // const resourceIndex = activeCommunityResources.findIndex(
    //   (item) => item.uuid === teamResourceUuid
    // );
    // if (resourceIndex > -1) {
    //   // From drow stats, generate voteStat
    //   const updatedVoteStat = VotingUtils.prepareFormattedVoteData({
    //     dtableSchema: activeCommunityResources[resourceIndex].dtableSchema,
    //     drowStats: updatedDrowStats,
    //   });
    //   // Update the resource in the store
    //   activeCommunityResources[resourceIndex]._voteStat = updatedVoteStat;
    //   activeCommunityResources[resourceIndex].drowStats = updatedDrowStats;
    //   store.dispatch(
    //     CommunityStoreActions.staticActions.setActiveCommunityResources(
    //       activeCommunityResources
    //     )
    //   );
    // }
    // DO for collection items
    // Get resource from store
    const { collectionStore } = store.getState();
    let { activeCollectionItems } = cloneDeep(collectionStore);
    activeCollectionItems = cloneDeep(activeCollectionItems);
    // Find the resource in the store
    const resourceIndex = activeCollectionItems.findIndex(
      (item) => item.uuid === teamResourceUuid
    );

    if (resourceIndex > -1) {
      // From drow stats, generate voteStat
      const updatedVoteStat = VotingUtils.prepareFormattedVoteData({
        dtableSchema: activeCollectionItems[resourceIndex].dtableSchema,
        drowStats: updatedDrowStats,
        teamResourceUuid,
        myDRow,
      });
      // Update the resource in the store
      const item = activeCollectionItems[resourceIndex];
      item._voteStat = updatedVoteStat;
      item.drowStats = updatedDrowStats;
      item.myDRow = cloneDeep(myDRow);
      // activeCollectionItems[resourceIndex] = cloneDeep(item);
      store.dispatch(
        CollectionStoreActions.staticActions.setActiveCollectionItems(
          activeCollectionItems
        )
      );
    }
  },
  showSubscriptionPopup: () => {
    EventEmitter.dispatch(SiteWiseEvents.showSubscriptionPopup);
  },
  increaseCommentCountInCollectionItems: (questItemUuids) => {
    // Get resource from store
    const { collectionStore } = store.getState();
    const { activeCollectionItems } = cloneDeep(collectionStore);
    // Find the resource in the store
    questItemUuids.forEach((questItemUuid) => {
      const resourceIndex = activeCollectionItems.findIndex(
        (item) => item.questItemId === questItemUuid
      );
      if (resourceIndex > -1) {
        activeCollectionItems[resourceIndex].commentCount =
          activeCollectionItems[resourceIndex].commentCount + 1;
        if (activeCollectionItems[resourceIndex].stat) {
          activeCollectionItems[resourceIndex].stat.commentCount =
            activeCollectionItems[resourceIndex].stat.commentCount + 1;
        }
      }
    });
    store.dispatch(
      CollectionStoreActions.staticActions.setActiveCollectionItems(
        activeCollectionItems
      )
    );
  },
  shareCollection: () => {
    const { collectionStore } = store.getState();
    const { activeCollection } = cloneDeep(collectionStore);
    // Copy current url to clipboard
    if (GeneralHelper.isNativeShareSupported()) {
      // Get collection name
      const collectionName = activeCollection?.listname || "Collection";
      GeneralHelper.triggerNativeShare(
        "Check out this collection",
        collectionName,
        window.location.href
      );
    } else {
      GeneralHelper.copyToClipboard(window.location.href);
      GeneralHelper.showToast({
        primaryText: "Link copied to clipboard",
        secondaryText: "Share it with a friend!",
        icon: ICONS.copy,
        additionalClass: "green",
      });
    }
    ShareActivities.addCollectionShareActivity({
      entityType: "quest",
      entityId: activeCollection.uuid,
      collectionName: activeCollection.listname,
    });
  },
  checkIfOwner: () => {
    const { collectionStore } = store.getState();
    const { activeCollection } = cloneDeep(collectionStore);
    const { userStore } = store.getState();
    let isOwner = false;
    if (activeCollection?.ownerId && userStore.profileData?.ownerId) {
      isOwner = activeCollection.ownerId === userStore.profileData.ownerId;
    }
    return isOwner;
  },
  _setActiveCollectionId: (collectionId) => {
    store.dispatch(
      CollectionStoreActions.staticActions.setActiveCollectionId(collectionId)
    );
  },
  _loadActiveCollection: async (collectionId) => {
    let activeCollection = null;
    // Always fetch from api for now
    // Fetch from api
    try {
      if (AuthHelper.isLoggedIn()) {
        const { data } = await ApiCalls.collection.private.getCollection(
          collectionId
        );
        activeCollection = data;
      } else {
        const { data } = await ApiCalls.collection.public.getCollection(
          collectionId
        );
        activeCollection = data;
      }
    } catch (error) {
      console.error(error);
    }
    // Set isFollowed from store
    const { communityStore } = store.getState();
    store.dispatch(
      CollectionStoreActions.staticActions.toggleCollectionLoaderStatus(true)
    );
    const { activeCommunityCollections } = communityStore;
    let collection = activeCommunityCollections.find(
      (collection) => collection.uuid === collectionId
    );
    if (collection && activeCollection) {
      activeCollection.isFollowed = collection.isFollowed;
      activeCollection.isPremium = collection.isPremium;
    }
    if (activeCollection) {
      store.dispatch(
        CollectionStoreActions.staticActions.setActiveCollection(
          activeCollection
        )
      );
    } else {
      // Unable to fetch collection
      // Redirect to community
      GeneralHelper.showToast({
        primaryText: "Unable to load collection",
        additionalClass: "error",
        icon: ICONS.questerIconSad,
      });
      router.navigate(`/c/${communityStore.activeCommunityId}`);
    }
    store.dispatch(
      CollectionStoreActions.staticActions.toggleCollectionLoaderStatus(false)
    );
  },
  _loadActiveCollectionItems: async (collectionId) => {
    store.dispatch(
      CollectionStoreActions.staticActions.toggleCollectionItemsLoaderStatus(
        true
      )
    );
    try {
      let collectionItems = [];
      if (AuthHelper.isLoggedIn()) {
        const { data } = await ApiCalls.quest.private.fetchQuestItems(
          collectionId
        );
        if (data?.communityItems) {
          collectionItems = data.communityItems;
        }
      } else {
        const { data } = await ApiCalls.quest.public.fetchQuestItems(
          collectionId
        );
        if (data?.communityItems) {
          collectionItems = data.communityItems;
        }
      }
      const formattedCollectionItems = collectionItems.map((collectionItem) =>
        CollectionUtils._formatCollectionItems(collectionItem)
      );
      const collectionItemsWithVotes = formattedCollectionItems.map(
        (resource) => ({
          ...resource,
          _voteStat: VotingUtils.prepareFormattedVoteData({
            dtableSchema: resource.dtableSchema,
            drowStats: resource.drowStats,
            teamResourceUuid: resource.uuid,
            myDRow: resource.myDRow,
          }),
        })
      );
      formattedCollectionItems.forEach((collectionItem) => {
        if (collectionItem?.dtableSchema?.name?.length) {
          collectionItem.dtableSchema.color =
            CommunityUtils._generateColorCodeFromText(
              collectionItem.dtableSchema.name
            );
        }
      });
      if (collectionItems) {
        store.dispatch(
          CollectionStoreActions.staticActions.setActiveCollectionItems(
            collectionItemsWithVotes
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
    store.dispatch(
      CollectionStoreActions.staticActions.toggleCollectionItemsLoaderStatus(
        false
      )
    );
  },
  _formatCollectionItems: (collectionItem) => {
    // Prepare object for voting
    const formattedCollectionItem = cloneDeep(collectionItem);
    let votableDatapoints = {};
    if (formattedCollectionItem?.drow?.cells?.length) {
      formattedCollectionItem.drow.cells.forEach((cell) => {
        // votableDatapoints[cell.uuid] = {
      });
    }
    if (!formattedCollectionItem?.stat) {
      formattedCollectionItem.stat = {
        saveCount: 0,
      };
    }
    // Map stat resourceSaveCount to saveCount
    if (formattedCollectionItem?.stat?.resourceSaveCount) {
      formattedCollectionItem.stat.saveCount =
        formattedCollectionItem.stat.resourceSaveCount;
    }
    // Parse to integer
    formattedCollectionItem.stat.saveCount = parseInt(
      formattedCollectionItem.stat.saveCount || 0
    );
    return formattedCollectionItem;
  },
};

export default CollectionUtils;
