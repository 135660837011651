import React, { Component, useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import QuestCommentUserImage from "../quest-comment-user-image/quest-comment-user-image";
import { useSelector } from "react-redux";
import QuestCommentsHelper from "../../quest-comments-helper";
import QuestCommentEditor from "../quest-comment-editor/quest-comment-editor";

import Button from "../../../../components/buttons/buttons";
import AuthHelper from "../../../../shared/helper-methods/auth-helper";
import { EventEmitter } from "../../../../shared/utils/event-emitter";
import useCurrentUser from "../../../../shared/hooks/useCurrentUser";

const CommentComposer = ({
  isTopLevel = false,
  parentCommentId = null,
  parentAddress = "",
  onSubmit = () => {},
  autoFocus = false,
}) => {
  const [inputText, setInputText] = useState("");
  const isLoggedIn = AuthHelper.isLoggedIn();
  const { currentUserDisplayName } = useCurrentUser();

  const _submitComment = () => {
    if (!isLoggedIn) {
      AuthHelper.login();
      return;
    }
    // Only allow to submit if there is text
    if (inputText.length === 0) {
      return;
    }
    QuestCommentsHelper.saveNewComment({
      value: inputText,
      parentAddress: parentAddress,
    });
    // Clear the input
    setInputText("");
    onSubmit();
  };

  const _listenToEvents = () => {
    EventEmitter.subscribe("ADD_NEW_COMMENT", (inputText) => {
      setInputText(inputText);
      // _submitComment();
    });
  };

  useEffect(() => {
    _listenToEvents();
  }, []);

  if (isTopLevel) {
    return (
      <>
        <div className="newCommentBox">
          <div className="userDetails">
            <div className="imageWrapper">
              <QuestCommentUserImage self={true} comment={{}} />
            </div>
            <div className="userMeta">
              <div className="username">@{currentUserDisplayName}</div>
            </div>
          </div>

          <div className="inputWrapper">
            <div className="icon">{ICONS.comment}</div>
            <QuestCommentEditor
              autoFocus={autoFocus}
              textarea={false}
              onUpdate={(text) => setInputText(text)}
              markDown={inputText}
              onSubmit={_submitComment}
            />
            <div className="submitButton">
              {inputText.length > 0 ? (
                <>
                  <Button
                    size={"small"}
                    icon={ICONS.arrowRight}
                    iconPos={"left"}
                    type={"contrast"}
                    onClick={_submitComment}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="replyBox">
        <div className="userImage">
          <QuestCommentUserImage self={true} />
        </div>
        <QuestCommentEditor
          textarea={false}
          onUpdate={(text) => setInputText(text)}
          markDown={inputText}
          onSubmit={_submitComment}
        />
        <div className="submitButton">
          {inputText.length > 0 ? (
            <>
              <Button
                size={"small"}
                icon={ICONS.arrowRight}
                iconPos={"left"}
                type={"contrast"}
                onClick={_submitComment}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
};

export default CommentComposer;
