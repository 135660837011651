/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import CommunityItem from "../../../../components/community-item/community-item";
import { useDispatch, useSelector } from "react-redux";
import { MetaStoreActions } from "../../../../stores/meta-store/actions";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import PremiumContentPopup from "../../../../components/premium-content-popup/premium-content-popup";
import useDeepCompareEffect from "use-deep-compare-effect";
import CollectionUtils from "../../collection-utils";
import CommunityUtils from "../../../community/community-utils";
import Spinner from "../../../../components/core/spinner/spinner";
import SponsorFooter from "../../../../features/sponsorship/components/sponsor-footer/sponsor-footer";

const CollectionItemsViewer = () => {
  const {
    activeCollection,
    activeCollectionItems,
    isCollectionItemsLoaderActive,
  } = useSelector((state) => state.collectionStore);
  const [lockedItems, setLockedItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  const { isRatingExpanded } = useSelector((state) => state.metaStore);

  const dispatch = useDispatch();
  const _toggleAllRating = () => {
    dispatch(
      MetaStoreActions.staticActions.toggleRatingExpanded(!isRatingExpanded)
    );
  };

  const _generateLockedItemsForPremiumCollection = () => {
    const isPurchased = CommunityUtils.checkIfSubscribed();
    if (isPurchased || !activeCollection?.isPremium) {
      // if user is subscribed, show all items
      setLockedItems([]);
      setVisibleItems(activeCollectionItems);
    } else {
      // make all items locked from the 3rd item
      let lockedItems = [];
      for (let i = 2; i < activeCollectionItems.length; i++) {
        lockedItems.push(activeCollectionItems[i]);
      }
      setLockedItems(lockedItems);
      // set visible items
      setVisibleItems(activeCollectionItems.slice(0, 2));
    }
  };

  useDeepCompareEffect(() => {
    if (activeCollectionItems?.length) {
      _generateLockedItemsForPremiumCollection();
    }
  }, [activeCollectionItems, activeCollection?.isPremium]);

  return (
    <>
      <div className="communityUnderHeader justify-content-between align-items">
        <div className="left">
          {/* <div className="sortBy">
              <div className="icon">{ICONS.ascSort}</div>
              <div className="text">Newest</div>
          </div>

          <span>·</span> */}

          <div className="count">
            Showing all {activeCollectionItems?.length} recommendations
          </div>
        </div>

        <div className="buttons">
          <Button
            size={"extraSmall"}
            icon={ICONS.infoCircle}
            iconPos={"left"}
            type={"default"}
            onClick={_toggleAllRating}
            text={isRatingExpanded ? "Hide all ratings" : "Show all ratings"}
            extraClasses={"showAllRatingsButton"}
          />
        </div>
      </div>
      {isCollectionItemsLoaderActive ? (
        <>
          <div
            className="loading"
            style={{
              height: "100px",
              textAlign: "center",
              marginBottom: "10vh",
              marginTop: "4vh",
            }}
          >
            <Spinner withContainer={false} />
          </div>
        </>
      ) : (
        <>
          <div className="communityItems">
            {visibleItems?.map((item, index) => (
              <CommunityItem
                key={item.uuid}
                datapoints={true}
                communityItem={item}
                isQuestItem={true}
              />
            ))}
            {/* {lockedItems.length === 0 ? <SponsorFooter /> : <></>} */}
          </div>

          <div className="communityItems premiumCommunityItems">
            {lockedItems?.map((item, index) => (
              <CommunityItem
                key={item.uuid}
                datapoints={true}
                communityItem={item}
                isQuestItem={true}
              />
            ))}
            {lockedItems.length > 0 ? (
              <>
                <div className="premiumBannerWrapper">
                  <div className="premiumBanner">
                    <div className="inner">
                      <div className="left">
                        {" "}
                        Unlock exclusive questions <br />
                        and notes by IB examiners for <br /> the 2025 syllabus{" "}
                      </div>
                      <div className="right">
                        <div
                          className="premiumButton"
                          onClick={(e) =>
                            CollectionUtils.showSubscriptionPopup()
                          }
                        >
                          <div className="btnInner">
                            {ICONS.diamondGradient}
                            <span>Become a Premium Member</span>
                          </div>
                        </div>
                        <div className="small">Terms & Conditions apply</div>
                      </div>
                    </div>
                  </div>

                  <div className="premiumPopup d-none">Premium popup</div>
                </div>
                {/* <SponsorFooter /> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {/* dada ekhane */}
      {/* <PremiumContentPopup /> */}

      {/* <MobileDrawer
        isActive={true}
        headerTitle="Become a premium user"
        headerIcon={ICONS.diamond}
        extraclassName="premiumContentBuyDrawer"
      >
        <PremiumContentPopup />
      </MobileDrawer> */}
    </>
  );
};

export default CollectionItemsViewer;
