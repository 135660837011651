import axios from "axios";
import AuthHelper from "../helper-methods/auth-helper";

const CDNUploader = {
  uploadImage: async ({ imageFile, imageFor, id }) => {
    // This will be uploading image to the server
    const { REACT_APP_AUTH_ENDPOINT_BASE_URL } = window;
    const url = `${REACT_APP_AUTH_ENDPOINT_BASE_URL}/media-management/api/v1/upload-image?isOverwrite=true&imageFor=${imageFor}&id=${id}`;
    const formData = new FormData();
    const authToken = AuthHelper.getToken();
    formData.append("imageFile", imageFile);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const response = await axios.put(url, formData, config);
      return response.data.filename;
    } catch (error) {
      return "";
    }
  },
};

export default CDNUploader;
