import React, { useEffect, useState } from "react";
import GeneralHelper from "../../shared/helper-methods/general-helpers";

const UserImageRenderer = ({ userName, photoUrl = null, ...rest }) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  const handleError = (e) => {
    setIsImageBroken(true);
  };

  const _renderImage = () => {
    if (photoUrl?.length && !isImageBroken) {
      let preparedLink = photoUrl;
      if (preparedLink.indexOf("http") === -1) {
        preparedLink = `${window.REACT_APP_CDN_BASE_URL}${photoUrl}`;
      }
      return <img src={preparedLink} alt="" onError={handleError} {...rest} />;
    } else {
      let nameInitial = "A";
      let backgroundColor = "white";
      if (userName?.length) {
        nameInitial = userName[0];
        backgroundColor = GeneralHelper.generateRandomColor(
          GeneralHelper.getStringAsciSum(userName)
        );
      }
      return (
        <div
          className="questCommentUserInitials"
          style={{
            backgroundColor,
          }}
        >
          {nameInitial}
        </div>
      );
    }
  };

  return <>{_renderImage()}</>;
};

export default UserImageRenderer;
