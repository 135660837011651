import SponsorItemActivities from "../../shared/utils/activity-recorder/activity-helpers/sponsor-item-activities";

const SponsorUtils = {
  checkIfSponsorCommunity: () => {
    // Check window location has the following substring 'c/PyF3MKlzASI'
    let isSponsor = false;
    try {
      if (window) {
        isSponsor = window.location.href.indexOf("c/PyF3MKlzASI") > -1;
      }
    } catch (error) {
      console.error("Error checking if user is a sponsor", error);
    }
    return isSponsor;
  },
  onSponsorLinkClick: (type = "header") => {
    // Open the link first in a new tab
    window.open("https://shepherd.study?source=quester", "_blank");
    // Then track the click
    SponsorItemActivities.onItemClick({
      itemType: type,
      link: "https://shepherd.study?source=quester",
      sponsorName: "freeCodeCamp.org",
    });
  },
  seededRandom: (min, max, pageNo, pageSize = 30) => {
    // Simple hash function to generate a pseudo-random seed from parameters
    function hashParams(min, max, pageNo, pageSize) {
      let hash = min * 47 + max * 31 + pageNo * 13 + pageSize * 17;
      return hash;
    }

    // Deterministic pseudo-random number generator within range [min, max]
    function deterministicRandom(min, max, seed) {
      const pseudoRandom = Math.abs((Math.sin(seed) * 10000) % 1);
      return Math.floor(pseudoRandom * (max - min + 1)) + min;
    }

    const result = {};

    for (let page = 1; page <= pageNo; page++) {
      // Calculate the base index for the current page
      const baseIndex = (page - 1) * pageSize;

      // Adjust the min and max for each page based on the specified index range
      const adjustedMin = baseIndex + min;
      const adjustedMax = baseIndex + max;

      // Generate a deterministic "random" number using a hash of parameters
      const seed = hashParams(min, max, page, pageSize);
      const pageNumber = deterministicRandom(adjustedMin, adjustedMax, seed);
      result[pageNumber] = true;
    }

    if (pageNo === 0) {
      // Generate a deterministic "random" number using a hash of parameters
      const seed = hashParams(min, max, pageNo, pageSize);
      const pageNumber = deterministicRandom(min, max, seed);
      result[pageNumber] = true;
    }

    return result;
  },
};

export default SponsorUtils;
