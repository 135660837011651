import React, { useState, useEffect } from "react";
import CollectionItemsEditorActionButtons from "../collection-items-editor-action-buttons/collection-items-editor-action-buttons";
import CollectionEditorItemsViewer from "../collection-editor-items-viewer/collection-editor-items-viewer";
import CollectionEditorItemsRemover from "../collection-editor-items-remover/collection-editor-items-remover";
import CollectionEditorItemsReorderer from "../collection-editor-items-reorderer/collection-editor-items-reorderer";
import CollectionItemCreator from "../collection-item-creator/collection-item-creator";
import { useSelector } from "react-redux";

const CollectionItemsEditor = () => {
  const { itemsEditorMode, editorCollectionItems } = useSelector(
    (state) => state.collectionStore
  );

  const _renderViewMode = () => {
    switch (itemsEditorMode) {
      case "view": {
        return (
          <CollectionEditorItemsViewer />
        );
      }
      case "edit": {
        return (
          <CollectionEditorItemsReorderer />
        );
      }
      case "remove": {
        return (
          <CollectionEditorItemsRemover />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <div className="section addedResourcesWrapper">
        {
          _renderViewMode()
        }
      </div>
      <CollectionItemCreator />
      
    </>
  );
};

export default CollectionItemsEditor;
