import { HttpClient } from "../clients/http-client";

const { ProtectedHttpClient, PublicHttpClient, ProtectedV2Client } = HttpClient;

const VoteApi = {
  public: {
    getTeamResourceVoteStats: (teamResourseUuid) =>
      PublicHttpClient.get(`/team-resources/${teamResourseUuid}`),
  },
  private: {
    updateUserVote: ({ questUuid, questItemUuid, payload }) =>
      ProtectedHttpClient.post(
        `/quests/${questUuid}/quest-items/${questItemUuid}/vote`,
        payload
      ),
    updateUserVoteOnTeamResource: ({ payload, teamResourceUuid }) =>
      ProtectedV2Client.post(
        `/community-items/${teamResourceUuid}/vote`,
        payload
      ),
    getTeamResourceVoteStats: (teamResourseUuid) =>
      ProtectedHttpClient.get(`/team-resources/${teamResourseUuid}`),
  },
};
export default VoteApi;
