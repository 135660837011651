import React from "react";
import Button from "../../../../components/buttons/buttons";

const ResourceWizardMobileButton = ({
  stepNumber = 1,
  buttonType = "contrast",
  buttonText = "Next",
  extraClasses = "w-100",
  size = "small",
  onClick = () => {},
}) => {
  return (
    <>
      <div className="mobileButton d-block d-md-none">
        <div className={`step${stepNumber} d-block`}>
          <Button
            type={buttonType}
            text={buttonText}
            extraClasses={extraClasses}
            size={size}
            onClick={onClick}
          />
        </div>
      </div>
    </>
  );
};

export default ResourceWizardMobileButton;
