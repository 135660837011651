const UserStoreActionTypes = {
  SET_FOLLOWING_MY_RESOURCES: "USER_STORE->SET_FOLLOWING_MY_RESOURCES",
  SET_FOLLOWING_QUESTS: "USER_STORE->SET_FOLLOWING_QUESTS",
  SET_PROFILE_DATA: "USER_STORE->SET_PROFILE_DATA",
  CLEAR_USER_DATA: "USER_STORE->CLEAR_USER_DATA",
  SET_IFRAME_SIZE: "USER_STORE->SET_IFRAME_SIZE",
  TOGGLE_IFRAME_DRAG_STATUS: "USER_STORE->TOGGLE_IFRAME_DRAG_STATUS",
  SET_MY_QUESTS: "USER_STORE->SET_MY_QUESTS",
  SET_MY_QUESTS_PAGINATION: "USER_STORE->SET_MY_QUESTS_PAGINATION",
  SET_USER_ORGS: "USER_STORE->SET_USER_ORGS",
};

export default UserStoreActionTypes;
