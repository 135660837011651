import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ICONS from "../../assets/quester-icons";
import QuestComments from "../../features/quest-comments/components/quest-comments/quest-comments";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import router from "../../shared/top-level-declarations/history";
import DiscoveryBar from "../discover-bar/discovery-bar";
import MobileDrawer from "../mobile-drawer/mobile-drawer";
import CommunityComments from "../../features/quest-comments/components/community-comments/community-comments";
import FeedbackModal from "../../features/feedback-collector/feedback-modal/feedback-modal";
import AddToCollectionPopup from "../add-to-collection-popup/add-to-collection-popup";
import AddContentPopup from "../add-content-popup/add-content-popup";
import AddItemFlow from "../add-item-flow/add-item-flow";
import AddNewCollection from "../add-new-collection/add-new-collection";
import Button from "../buttons/buttons";
import "./mobile-footer.scss";
import ResourceWizard from "../../entities/resource/components/resource-wizard/resource-wizard";
import useDeepCompareEffect from "use-deep-compare-effect";
import CommunityUtils from "../../entities/community/community-utils";
import { useSelector } from "react-redux";
// import AiAnalysePopup from "../ai-analyse-popup/ai-analyse-popup";

const MobileFooter = (props) => {
  const [showDiscoveryDrawer, setShowDiscoveryDrawer] = useState(false);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [showAddButtonDrawer, setShowAddButtonDrawer] = useState(false);
  const { keycloak, initialized } = useKeyCloakSafe();
  const location = useLocation();
  const [buttonVisibilities, setButtonVisibilities] = useState({
    login: false,
    discover: false,
    communityComments: false,
    collectionComments: false,
    addResource: false,
  });
  const { activeCommunity } = useSelector((state) => state.communityStore);
  const [curationAccess, setCurationAccess] = useState(false);

  const _showMobileSidebar = (e) => {
    // console.log('show mobile sidebar');
    document.body.classList.add("sidebarShow");
  };

  const _generateButtonVisibilities = () => {
    let buttonVisibilities = {
      login: false,
      discover: false,
      comments: false,
    };

    if (initialized) {
      if (!keycloak?.authenticated) {
        buttonVisibilities.login = true;
      }
    }

    const path = location.pathname;
    if (path.includes("/c/")) {
      buttonVisibilities.discover = true;
      buttonVisibilities.communityComments = true;
      if (curationAccess) {
        buttonVisibilities.addResource = true;
      }
    }

    if (path.includes("/q/")) {
      buttonVisibilities.communityComments = false;
      buttonVisibilities.collectionComments = true;
      buttonVisibilities.addResource = false;
    }

    if (path.includes("/cq")) {
      buttonVisibilities.communityComments = false;
      buttonVisibilities.collectionComments = false;
      buttonVisibilities.addResource = false;
    }

    // Open the comments drawer if it has params '?replyCommentId='
    if (path.includes("/q/") && location.search.includes("?replyCommentId=")) {
      setTimeout(() => {
        setShowCommentsDrawer(true);
      }, 900);
    }

    setButtonVisibilities(buttonVisibilities);
  };

  useEffect(() => {
    _generateButtonVisibilities();
  }, [location, keycloak, initialized]);

  useDeepCompareEffect(() => {
    const hasCurationAccess = CommunityUtils.checkIfAMember();
    setCurationAccess(hasCurationAccess);
    if (hasCurationAccess) {
      _generateButtonVisibilities();
    }
  }, [activeCommunity || {}]);

  const [headerClassName, setHeaderClassName] = useState("");

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset >= 100) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset < 100) {
      setHeaderClassName("");
    }
  };

  useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  const [feedbackSheetShow, setFeedbackSheetShow] = useState(true);
  const [adminSectionSheetShow, setAdminSectionSheetShow] = useState(true);
  const [showAiAnalysisPopup, setShowAiAnalysisPopup] = useState(true);

  const [showAddToCollectionPopup, setShowAddToCollectionPopup] =
    useState(true);
  const [showAddContentPopup, setShowAddContentPopup] = useState(true);
  const [showAddNewCollectionPopup, setShowAddNewCollectionPopup] =
    useState(true);
  const [showAddItemFlowPopup, setShowAddItemFlowPopup] = useState(true);
  const [showSaveDraftConfirmation, setShowSaveDraftConfirmation] =
    useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(true);
  const [showImagePicker, setShowImagePicker] = useState(true);
  const [showCreateCommunityPopup, setShowCreateCommunityPopup] = useState(true);

  return (
    <>
      <div className={"mobileFooter " + headerClassName}>
        <div className="left" onClick={_showMobileSidebar}>
          <div className="menuToggle">
            {ICONS.threeBars} <span>Dashboard</span>
          </div>
        </div>

        <div className="right">
          {/* <div className="item">{ICONS.userCircle} <span>Login</span></div> */}
          {buttonVisibilities.login ? (
            <div className="item" onClick={(e) => AuthHelper.login()}>
              {ICONS.userCircle} <span>Login</span>
            </div>
          ) : (
            <></>
          )}
          {buttonVisibilities.discover ? (
            <div className="item" onClick={(e) => setShowDiscoveryDrawer(true)}>
              {ICONS.compassIcon} <span>Discover</span>
            </div>
          ) : (
            <></>
          )}
          {buttonVisibilities.addResource ? (
            <div
              className="item"
              onClick={(e) => {
                setShowAddButtonDrawer(true);
              }}
            >
              {ICONS.plus} <span>Add</span>
            </div>
          ) : (
            <></>
          )}
          {buttonVisibilities.collectionComments ||
          buttonVisibilities.communityComments ? (
            <div className="item" onClick={(e) => setShowCommentsDrawer(true)}>
              {ICONS.usersCommunity} <span>Hangout</span>
            </div>
          ) : (
            <></>
          )}
          {!buttonVisibilities.discover &&
          !buttonVisibilities.comments &&
          !buttonVisibilities.login ? (
            <div
              className="item"
              onClick={(e) => {
                router.navigate("/my-profile");
              }}
            >
              {ICONS.userCircle} <span>My profile</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <MobileDrawer
        isActive={showDiscoveryDrawer}
        onClose={(e) => setShowDiscoveryDrawer(false)}
        headerTitle="Discover"
        headerIcon={ICONS.compassIcon}
        extraclassName="discoverDrawer"
      >
        <DiscoveryBar
          open={true}
          closeParentDrawer={() => setShowDiscoveryDrawer(false)}
          withinDrawer={true}
        />
      </MobileDrawer>

      <MobileDrawer
        isActive={showCommentsDrawer}
        onClose={(e) => setShowCommentsDrawer(false)}
        headerTitle="Hangout"
        headerIcon={ICONS.usersCommunity}
        extraclassName="commentsDrawer"
      >
        <QuestComments />
      </MobileDrawer>

      {/* <MobileDrawer
        isActive={true}
        onClose={(e) => setShowAddToCollectionPopup(false)}
        headerTitle="add to collection"
        headerIcon={ICONS.plus}
        extraclassName="addToCollectionDrawer"
      >
        <AddToCollectionPopup />
      </MobileDrawer> */}

      <MobileDrawer
        isActive={showAddButtonDrawer}
        onClose={(e) => setShowAddButtonDrawer(false)}
        headerTitle="Add content"
        headerIcon={ICONS.plus}
        extraclassName="addContentDrawer"
      >
        <AddContentPopup onClose={() => setShowAddButtonDrawer(false)} />
      </MobileDrawer>

      {buttonVisibilities.addResource ? (
        <ResourceWizard isMobile={true} />
      ) : (
        <></>
      )}

      {/* <MobileDrawer
        isActive={showSaveDraftConfirmation}
        onClose={(e) => setShowSaveDraftConfirmation(!showSaveDraftConfirmation)}
        headerTitle="creating a collection"
        headerIcon={ICONS.list2}
        extraclassName="confirmationDrawer"
      >
        <div className="confirmationSheetInner">
          <div className="confirmationHeaderTitle">Do you want to save it as draft?</div>
          <div className="confirmationDesc">Quo repellat alias. Et qui sit quo at omnis rerum ex corporis consectetur. Sed ducimus corrupti ut sapiente atque doloremque eos consectetur assumenda.</div>
          <div className="confirmationButtons">
              <Button
                size={"small"}
                type={"contrast"}
                text={"No, thanks!"}
              />
              <Button
                size={"small"}
                type={"success"}
                text={"Save as draft"}
              />
          </div>
        </div>
      </MobileDrawer>

  



      <MobileDrawer
        isActive={showDeleteConfirmation}
        onClose={(e) => setShowDeleteConfirmation(!showDeleteConfirmation)}
        headerTitle="deleting a collection"
        headerIcon={ICONS.list2}
        extraclassName="confirmationDrawer"
      >
        <div className="confirmationSheetInner">
          <div className="confirmationHeaderTitle">Do you want to delete this collection?</div>
          <div className="confirmationDesc">Quo repellat alias. Et qui sit quo at omnis rerum ex corporis consectetur. Sed ducimus corrupti ut sapiente atque doloremque eos consectetur assumenda.</div>
          <div className="confirmationButtons">
              <Button
                size={"small"}
                type={"contrast"}
                text={"No, thanks!"}
              />
              <Button
                size={"small"}
                type={"danger"}
                text={"Delete"}
              />
          </div>
        </div>
      </MobileDrawer> */}

      {/* <MobileDrawer
        isActive={feedbackSheetShow}
        onClose={(e) => setFeedbackSheetShow(false)}
        headerTitle="Feedback"
        headerIcon={ICONS.megaPhone}
        extraclassName="feedbackDrawer"
      >
        <FeedbackModal />
      </MobileDrawer>  */}
      {/* <MobileDrawer
        isActive={showAiAnalysisPopup}
        onClose={(e) => setShowAiAnalysisPopup(false)}
        headerTitle="quester digest"
        headerIcon={ICONS.questerIcon}
        extraclassName="aiAnalysisDrawer"
      >
        <AiAnalysePopup />
      </MobileDrawer> */}




      <MobileDrawer
        isActive={false}
        onClose={(e) => setAdminSectionSheetShow(false)}
        headerTitle="admin functionalities"
        headerIcon={ICONS.compassIcon}
        extraClass="adminSectionDrawer"
      >
        <img src={require("../../assets/images/board-with-graphics-and-information.png")} alt="" />
        <div className="title">Admin functionalities require a desktop session. </div>
        <div className="text">Please open the community page in a desktop.</div>
      </MobileDrawer>




      <MobileDrawer
        isActive={false}
        onClose={(e) => setShowCreateCommunityPopup(false)}
        headerTitle="Create your space"
        headerIcon={ICONS.plus}
        extraclassName="createCommunityDrawer"
      >
        {/* should go to create-new-community-popup.jsx */}
        <div className={"createNewCommunityModal"}>
            <div className="overlay" onClick={(e) => setShowCreateCommunityPopup(false)}></div>
            <div className="inner">
              <div className="bgDesign">
                <img src={require("../../assets/images/confetti.png")} />
              </div>

              <div className="left">
                <div className="titleSection">
                  <div className="bigTitle">Create your space.</div>
                  <div className="bigDesc">
                    Your recommendations are valuable! They deserve better than a static Notion doc, spreadsheet, or getting lost in Discord.
                  </div>
                </div>

                <div className="benefits">
                  <div className="benefit">
                    <div className="icon">{ICONS.usersCommunity}</div>
                    <div className="texts">
                      <div className="label">Organise</div>
                      <div className="desc">
                        Instantly organise all your recommendations in one beautiful & collaborative space; manage contributions, generate context, and see what's popular.
                      </div>
                    </div>
                  </div>

                  <div className="benefit">
                    <div className="icon">{ICONS.heartOutline}</div>
                    <div className="texts">
                      <div className="label">Grow</div>
                      <div className="desc">
                        Take engagement to the next level - have your community add to, comment, and vote upon your recommendations
                      </div>
                    </div>
                  </div>

                  <div className="benefit">
                    <div className="icon">{ICONS.diamond}</div>
                    <div className="texts">
                      <div className="label">Monetise</div>
                      <div className="desc">
                        Get rewarded for the value of your recommendations; we'll get your space sponsored by leading brands
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                <div className="titleSection">
                  <div className="bigTitle">Create your space.</div>
                  <div className="bigDesc">
                  Your recommendations are valuable! They deserve better than a static Notion doc, spreadsheet, or getting lost in Discord.
                  </div>
                </div>

                <div className="formWrapper">
                  <div className="form-group">
                    <label>Community Name</label>
                    <input type="text" placeholder="Name of your community" className="form-control" />
                  </div>

                  <div className="form-group">
                    <label>Contact email</label>
                    <input type="text" placeholder="Your email address" className="form-control" />
                  </div>

                  <div className="form-group">
                    <label>Link to your current database/community</label>
                    <textarea type="text" placeholder="Notion, Sheets, Airtable, Discord or Slack URL" className="form-control"></textarea>
                  </div>

                  <div className="buttons">
                    <Button
                      size={"medium"}
                      type={"brand"}
                      text={"Apply now"}
                    />
                    <div className="text">Limited slots available</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </MobileDrawer>
    </>
  );
};

export default MobileFooter;
