import cloneDeep from "clone-deep";
import { store } from "../../../stores/store";
import { QuickAddCollectionItemActions } from "../../../stores/quick-add-collection-item-store/actions";
import ApiCalls from "../../../shared/api";
import router from "../../../shared/top-level-declarations/history";
import CollectionEditorUtils from "../../../entities/collection/collection-editor-utils";

const QuickAddCollectionItemUtils = {
  updateSearchText: (searchText) => {
    QuickAddCollectionItemActions.staticActions.setSearchText(searchText);
    QuickAddCollectionItemUtils.loadCollections({ reset: true });
  },
  setActiveResource: (resource) => {
    QuickAddCollectionItemActions.staticActions.setActiveResource(resource);
  },
  loadCollections: async (
    { reset = false } = {
      reset: false,
    }
  ) => {
    // This should load new set of collections and append them in the store
    // But if reset is true, it should replace the existing collections and reset pagination also
    // First check if reset, then no need to read pagination from store
    // Just prepare new pagination object and set it in store
    const store = QuickAddCollectionItemUtils._getStore();
    const { searchText } = store;
    let { pagination } = { ...store };
    const { activeCommunityId } =
      QuickAddCollectionItemUtils._getCommunityStore();
    if (reset) {
      pagination = {
        pageNo: 0,
        hasMore: true,
        pageSize: 20,
      };
      QuickAddCollectionItemActions.staticActions.setPagination(pagination);
      // Also reset the filteredCollections
      QuickAddCollectionItemActions.staticActions.setFilteredCollections([]);
    }
    let newCollections = [];
    QuickAddCollectionItemActions.staticActions.setIsLoaderActive(true);
    try {
      const response = await ApiCalls.collection.private.searchMyCollections({
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize,
        orgId: activeCommunityId,
        access: "WRITE",
        isPublished: false,
        listname: searchText?.length ? searchText : null,
        sortBy: "+modifiedOn",
      });
      // Append or replace the collections in the store
      if (!response?.data?.nextPage) {
        QuickAddCollectionItemActions.staticActions.setPagination({
          ...pagination,
          hasMore: false,
        });
      }
      if (reset) {
        newCollections = response?.data?.quests;
        // Also set this as defaultsCollections for community
        QuickAddCollectionItemUtils._setDefaultsCollections({
          collections: newCollections,
          communityId: activeCommunityId,
        });
      } else {
        newCollections = [
          ...store.filteredCollections,
          ...response?.data?.quests,
        ];
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    QuickAddCollectionItemActions.staticActions.setIsLoaderActive(false);
    QuickAddCollectionItemActions.staticActions.setFilteredCollections(
      newCollections
    );
  },
  addResourceToCollection: async ({ collection }) => {
    const { activeResource } = QuickAddCollectionItemUtils._getStore();
    // This will add the resource to the collection
    try {
      await ApiCalls.collection.private.addItemToCollection({
        collectionUuid: collection.uuid,
        resource: activeResource,
      });
      // Also update the recentlyUsedCollection
      // Get activeCommunityId
      const { activeCommunityId } =
        QuickAddCollectionItemUtils._getCommunityStore();
      QuickAddCollectionItemUtils._updateRecentlyUsedCollection({
        collection,
        communityId: activeCommunityId,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  onCommunityLoad: async () => {
    // This will load the collections for the first time
    QuickAddCollectionItemUtils._resetStore();
    QuickAddCollectionItemUtils.loadCollections({ reset: true });
  },
  resetSearch: () => {
    // Reset the search text and load the collections
    QuickAddCollectionItemActions.staticActions.setSearchText("");
    QuickAddCollectionItemUtils._resetStore();
  },
  createNewCollectionWithItem: () => {
    const store = QuickAddCollectionItemUtils._getStore();
    const { activeResource } = store;
    // Clear store
    CollectionEditorUtils.enableCollectionCreator({
      uiMode: "desktop",
      resource: activeResource,
    });
    QuickAddCollectionItemUtils._resetStore();
  },
  _setDefaultsCollections: ({ collections, communityId }) => {
    const store = QuickAddCollectionItemUtils._getStore();
    const defaultsCollections = { ...store.defaultsCollections };
    defaultsCollections[communityId] = collections;
    QuickAddCollectionItemActions.staticActions.setDefaultsCollections(
      defaultsCollections
    );
  },
  _updateRecentlyUsedCollection: ({ collection, communityId }) => {
    const store = QuickAddCollectionItemUtils._getStore();
    const recentlyUsedCollection = { ...store.recentlyUsedCollection };
    recentlyUsedCollection[communityId] = collection;
    QuickAddCollectionItemActions.staticActions.setRecentlyUsedCollection(
      recentlyUsedCollection
    );
  },
  _getStore: () => {
    const { quickAddCollectionItemStore } = store.getState();
    return cloneDeep(quickAddCollectionItemStore);
  },
  _getCommunityStore: () => {
    const { communityStore } = store.getState();
    return cloneDeep(communityStore);
  },
  _resetStore: () => {
    // Reset everything except the defaultsCollections and recentlyUsedCollection
    // QuickAddCollectionItemActions.staticActions.setSearchText("");
    QuickAddCollectionItemActions.staticActions.setPagination({
      pageNo: 1,
      hasMore: true,
      pageSize: 20,
    });
    QuickAddCollectionItemActions.staticActions.setFilteredCollections([]);
    QuickAddCollectionItemActions.staticActions.setActiveResource({});
  },
};

export default QuickAddCollectionItemUtils;
