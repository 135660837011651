import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import keycloak from "./keycloak";

const KeycloakProvider = ({ children }) => {
  return (
    <>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          onLoad: "check-sso",
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          pkceMethod: "S256",
        }}
      >
        {children}
      </ReactKeycloakProvider>
    </>
  );
};

export default KeycloakProvider;
