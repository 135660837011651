import React from 'react';
import ICONS from '../../assets/quester-icons';
import './static-pages.scss';

const PrivacyPolicy = (props) => {
    return (
        <div className="mainContentWrapper">
            <div className="static-pages privacy-page">
                <div className="container">
                    <div className="staticPageHeader">🔒 Quester privacy and cookie policy</div>

                    <div className="section">
                        <div className="sectionHeading">About this policy</div>

                        <div className="sectionContent">
                            {/* <div className="left">
                                <span>1)</span>
                            </div> */}

                            <div className="right">
                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon stroke">{ICONS.glass}</div>
                                        <div className="text">Who should read this policy?</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>Everyone who accesses our website at <span className="link">https://quester.io/</span> (Platform) including users who register and create a profile (Profile) with us, other general Platform visitors (including students and organisations), and alternative business contacts, such as a suppliers or service providers of our business.</p>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon fill">{ICONS.thumbsUp}</div>
                                        <div className="text">What is covered?</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>This policy sets out what information we collect about you, what we use it for and who we share it with. It also explains your rights and what to do if you have any concerns about your personal data.</p>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon fill">{ICONS.mailBig}</div>
                                        <div className="text">Who to contact about this notice?</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>Who to contact about this notice: If you have any questions about this privacy policy, how we handle your personal data or you are looking to exercise one of your rights, please contact: <span className="link">hello@quester.io</span></p>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon fill">{ICONS.arrowRound}</div>
                                        <div className="text">Latest updates:</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>This policy was last updated on [INSERT DATE]. We may sometimes need to update this policy to reflect changes to the way we provide our services or to comply with updates to data protection law. Where possible, we will notify you of any substantive changes but please check back regularly to see whether any changes have occurred.</p>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon stroke">{ICONS.list}</div>
                                        <div className="text">Who checks this policy is enforced?</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>The Information Commissioner’s Office (ICO) is the UK regulator and is responsible for checking that businesses comply with UK Data Protection Law.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>1)</span></div>

                            <div className="right">
                                <div className="rightHeading">About us</div>

                                <p>We are Quester Limited, registered in England and Wales with company number 13608459 whose registered address is 115 Hampstead Road, London, England, NW1 3EE (Quester / we / us /our).</p>
                                <p>If you visit our Platform or contact us about our services we are the controller of your information (which means we decide what information we collect and how it is used). We are registered with the Information Commissioner’s Office (ICO), the UK regulator for data protection matters, under number ZB031160.</p>
                                <p>If you have any questions about this privacy notice or the way that we use information, please get in touch using the following details:</p>
                                <p>Email address: <span className="link">hello@quester.io</span></p>
                                <p>Postal address: 115 Hampstead Road, London, England, NW1 3EE</p>
                            </div>
                        </div>
                    </div>







                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>2)</span></div>

                            <div className="right">
                                <div className="rightHeading">Information we collect about you</div>

                                <p>Personal data means any information which does (or could be used to) identify a living person. We have grouped together the types of personal data that we collect and where we receive it from below:</p>

                                <div className="tableSection">
                                    <div className="tableTitle">Types of Personal Data</div>
                                    <div className="tableTitle">Received from</div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Identity Data - where you choose to give this to us, your first and last name or title, and your degree title or description.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You, when you register and create a Profile on our Platform.</p>
                                        </div>
                                    </div>
                                    





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Contact Data - your email address, and, if provided, your telephone number(s).</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You, when you register and create a Profile on our Platform.</p>
                                        </div>
                                    </div>

                                    




                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Profile Data - email address, password, username, audit trail of systems used and links accessed.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You (including via cookies and other technologies) when you register and create a Profile on our Platform. Please note that your public Profile is made public on our Platform. All visitors to our Platform can view this data.</p>
                                        </div>
                                    </div>




                                    
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Comments - your comments and notes posted on the platform, if you choose to do this.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You.</p>
                                        </div>
                                    </div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Feedback - information and responses you provide when completing surveys and questionnaires.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You.</p>
                                        </div>
                                    </div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Technical Data - internet protocol (IP) address, browser type and version, time zone setting and generic location, browser plug-in types and versions, operating system and platform on the devices you use to access our Platform.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You (via cookies and other similar technologies)</p>
                                        </div>
                                    </div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Usage Data - information about how you use our Platform.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You (via cookies and other similar technologies)</p>
                                        </div>
                                    </div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Photo and Image Data - your Profile picture, but only where you choose to give this to us. Your Profile picture does not have to be of your likeness.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You, when you register and create a Profile on our Platform.</p>
                                        </div>
                                    </div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Marketing and Communication Data - includes your preferences about receiving marketing from us and our third parties and your communication preferences.</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">You when you sign up to receive our newsletter/other marketing materials.</p>
                                        </div>
                                    </div>
                                </div>

                                <p>Additional data gathered from our browser extension - We currently mark your last saved folder (quest/team), allowing you to quickly save new resources to the last saved place without the need to pick one each time. This is to enhance user experience. This data is stored in your browser. We neither send this data to our server nor to other third parties.</p>

                                <p>The extension requires access to store a temporary OAuth 2.0 token after the user is prompt to login into the quester.io service. This is to enhance user experience, the token is used to securely authenticate and authorize the user interactions with quester.io services, enabling seamless access to additional features and functionalities within the extension. The token is stored locally within the browser and is not shared with any external servers or third parties.</p>
                            </div>
                        </div>
                    </div>







                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>3)</span></div>

                            <div className="right">
                                <div className="rightHeading">How we use your information</div>

                                <p>Under UK data protection law, we need a legal reason (known as a lawful basis) for holding, collecting and using your personal data. There are 6 main legal reasons which organisations can rely on. The most relevant to our business are:</p>

                                <ul>
                                    <li>to enter into and perform our contract with you;</li>
                                    <li>to pursue our legitimate interests (our justifiable business aims) but only if those interests are not outweighed by your other rights and freedoms (e.g. your right to privacy);</li>
                                    <li>to comply with a legal obligation that we have;</li>
                                    <li>where you have consented to us using your personal data a certain way.</li>
                                </ul>

                                <p>The following table sets out when we rely on each lawful basis.</p>

                                <div className="tableSection">
                                    <div className="tableTitle">Lawful Basis</div>
                                    <div className="tableTitle">Purpose for using your personal data</div>





                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Contract</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Where using your information is necessary to perform a contract we have with you (i.e. our Terms of Use), which enables the provision of the Platform to you.</p>
                                        </div>
                                    </div>






                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Legitimate Interests</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Where using your information is necessary to pursue our legitimate business interests to:</p>
                                            <ul>
                                                <li>to take steps to enter into and fulfill contracts with suppliers where sharing personal data is required for that contract and the performance of the services;</li>
                                                <li>improve and optimise our Platform;</li>
                                                <li>monitor and make improvements to our Platform to enhance security and prevent fraud;</li>
                                                <li>perform other routine business operations; and</li>
                                                <li>protect our business and defend ourselves against legal claims.</li>
                                            </ul>
                                            <p>Where we use your information for our legitimate interests, we have assessed whether such use is necessary and that such use will not infringe on your other rights and freedoms.</p>
                                        </div>
                                    </div>






                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Legal Obligation</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Where we send you information to comply with a legal obligation (e.g. where we send you information about your legal rights). Where we retain information to enable us to bring or defend legal claims.</p>
                                        </div>
                                    </div>






                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Consent</p>
                                        </div>
                                    </div>
                                    <div className="card small">
                                        <div className="cardContent">
                                            <p className="m-0">Where you have provided your consent to providing us with information or allowing us to use or share your information.</p>
                                        </div>
                                    </div>
                                </div>

                                <p>Where we need to collect your personal data (for example, in order to fulfil a contract we have with you), failure to provide us with your personal data may mean that we are not able to provide you with our services.</p>
                            </div>
                        </div>
                    </div>






                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>4)</span></div>

                            <div className="right">
                                <div className="rightHeading">When we send you marketing messages</div>

                                <p>If you have consented to receiving our newsletter or any marketing messages from us, we will send these messages and our newsletter to the email address you provide when you register a Profile on our Platform.</p>
                                <p>You can opt out of these at any time. Just let us know by clicking ‘Unsubscribe’ - a button which can be found in the footer of any marketing emails we send you.</p>
                            </div>
                        </div>
                    </div>






                    
                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>5)</span></div>

                            <div className="right">
                                <div className="rightHeading">Who we share your information with</div>

                                <p>Our Platform is split into groups that users can generate. These groups are called Inns. Where the owner of an Inn on our Platform is an organisation, that organisation becomes a separate controller over the personal data they view and access on our Platform in that Inn, and that organisation is bound by its own data protection obligations. We do not conduct any data processing on behalf of separate controllers.</p>
                                <p>If the owner of an Inn is an individual, there are no additional data protection obligations imposed upon that individual, and we remain the controller of that data.</p>
                                <p>All owners of Inns, whether individuals or organisations, will be able to view and access all information about links that are posted into the Inn that they own, as well as Profile data of contributors to/readers of that Inn, and what other users (whether or not these users are registered with a Profile) have clicked on, accessed and/or read from the contents contained/posted in that Inn.</p>
                                <p>In accordance with our normal business, we may also share your personal data with:</p>
                                <ul>
                                    <li>Our personnel: our employees (or other types of workers) who have contracts containing confidentiality and data protection obligations in order to provide our services.</li>
                                    <li>Our supply chain: other organisations that help us provide our services, such as website hosting, cloud services etc. We ensure these organisations only have access to the information required to provide the support we use them for and have a contract with them that contains confidentiality and data protection obligations.</li>
                                    <li>Our business contacts: we may share limited access to some of your personal data where we partner with other businesses to provide our services and the Platform to you, for example, universities or schools that may want to integrate our software into their systems for learning purposes. We ensure these contacts only have access to the information required to provide the support we use them for and have a contract with, or terms that apply to them that contains confidentiality and data protection obligations.</li>
                                    <li>Regulatory authorities: such as HM Revenue & Customs.</li>
                                    <li>Our professional advisers: such as our accountants or legal advisors where we require specialist advice to help us conduct our business.</li>
                                    <li>Any actual or potential buyer of our business.</li>
                                </ul>
                            </div>
                        </div>
                    </div>








                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>6)</span></div>

                            <div className="right">
                                <div className="rightHeading">Where your information is located or transferred to</div>

                                <p>We store company information (including your personal data) on servers located in the United Kingdom.</p>
                                <p>Otherwise, we will only transfer information outside of the UK where we have a valid legal mechanism in place (e.g. by using contracts approved by the European Commission or UK Secretary of State).</p>
                            </div>
                        </div>
                    </div>








                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>7)</span></div>

                            <div className="right">
                                <div className="rightHeading">How we keep your information safe</div>

                                <p>We have put in place appropriate security and safety measures to prevent your personal data from being lost or illegally accessed by those who do not have permission. These measures include:</p>
                                
                                <ul>
                                    <li>access controls and user authentication (including multi-factor authentication);</li>
                                    <li>data encrypted at rest:- Your data is encrypted when stored in our systems. This means that even if unauthorized access occurs, the data remains unreadable without the appropriate decryption key. This extra layer of security ensures that your personal information remains confidential.</li>
                                    <li>encryption in transit:- When you interact with our services, whether through our website or applications, the information you provide is encrypted during transmission. This encryption process prevents any unauthorized parties from intercepting or accessing your data while it's being sent between your device and our servers.</li>
                                    <li>internal IT and network security;</li>
                                    <li>regular testing and review of our security measures;</li>
                                    <li>staff policies and training;</li>
                                    <li>incident and breach reporting processes;</li>
                                    <li>business continuity and disaster recovery processes.</li>
                                </ul>

                                <p>In the event that there is an event or incident affecting your personal data, we will keep you informed. We may also need to notify the regulator (where required under data protection law) and if we make decisions about your data jointly with another party (for example, if a third party marketing provider), we may need to notify them.</p>
                            </div>
                        </div>
                    </div>







                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>8)</span></div>

                            <div className="right">
                                <div className="rightHeading">How long we keep your information</div>

                                <p>Where we are responsible for making decisions about how to collect and use your personal data, we will only keep your personal data for as long as necessary to fulfil the purposes we collected it for or as long as required to fulfil our legal obligations.</p>
                                <p>When we consider how long to keep your personal data, we will consider whether it is still necessary to keep it for the purpose which we collected it or whether the same purpose could be achieved by holding less personal data. We will also consider the volume, nature, and sensitivity of the personal data and the potential harm to you if there was an incident affecting your personal data.</p>
                                <p>If you browse our Platform, we keep personal data collected through our analytics tools for only as long as necessary to fulfil the purposes we collected it for (see our Cookie Policy below for further information).</p>
                            </div>
                        </div>
                    </div>











                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>9)</span></div>

                            <div className="right">
                                <div className="rightHeading">Your legal rights</div>

                                <p>You have specific legal rights in relation to your personal data.</p>
                                <p>It is usually free for you exercise your rights and we aim to respond within one month (although we may ask you if we can extend this deadline up to a maximum of two months if your request is particularly complex or we receive multiple requests at once).</p>
                                <p>We can decide not to take any action in relation to a request where we have been unable to confirm your identity (this is one of our security processes to make sure we keep information safe) or if we feel the request is unfounded or excessive. We may charge a fee where we decide to proceed with a request that we believe is unfounded or excessive. If this happens, we will always inform you in writing.</p>

                                <p>Your legal rights are as follows:</p>

                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon stroke">{ICONS.lock}</div>
                                        <div className="text">Access</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>You must be told if your personal data is being used and you can ask for a copy of your personal data as well as information about how we are using it to make sure we are abiding by the law.</p>
                                    </div>
                                </div>





                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon stroke">{ICONS.checkCircleBig}</div>
                                        <div className="text">Correction</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>You can ask us to correct your personal data if it is inaccurate or incomplete. We might need to verify the new information before we make any changes.</p>
                                    </div>
                                </div>




                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon stroke">{ICONS.trash}</div>
                                        <div className="text">Deletion</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>You can ask us to delete or remove your personal data if there is no good reason for us to continuing holding it or if you have asked us to stop using it. If we think there is a good reason to keep the information you have asked us to delete (e.g. to comply with regulatory requirements), we will let you know and explain our decision.</p>
                                    </div>
                                </div>





                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon stroke">{ICONS.crossBigThin}</div>
                                        <div className="text">Restriction</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>You can ask us to restrict how we use your personal data and temporarily limit the way we use it (e.g. whilst you check that the personal data we hold for you is correct).</p>
                                    </div>
                                </div>




                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon fill">{ICONS.thumbsDown}</div>
                                        <div className="text">Objection</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>You can object to us using your personal data if you want us to stop using it. We always comply with your request if you ask us to stop sending you marketing communications but in other cases, we decide whether we will continue. If we think there is a good reason for us to keep using the information, we will let you know and explain our decision.</p>
                                    </div>
                                </div>




                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon fill">{ICONS.network}</div>
                                        <div className="text">Portability</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>You can ask us to send you or another organisation an electronic copy of your personal data.</p>
                                    </div>
                                </div>




                                <div className="card">
                                    <div className="cardTitle">
                                        <div className="icon fill">{ICONS.sad}</div>
                                        <div className="text">Complaints</div>
                                    </div>

                                    <div className="cardContent">
                                        <p>If you are unhappy with the way we collect and use your personal data, you can complain to the Information Commissioner or another relevant supervisory body, but we hope that we can respond to your concerns before it reaches that stage. Please contact us at hello@quester.io</p>
                                    </div>
                                </div>

                                <p>If you would like to exercise any of your legal rights, please contact us at the email address provided above.</p>
                            </div>
                        </div>
                    </div>










                    <div className="section">
                        <div className="sectionContent">
                            <div className="left"><span>10)</span></div>

                            <div className="right">
                                <div className="rightHeading">Our Cookie Policy</div>

                                <p>Our Platform uses cookies and similar technologies.</p>

                                <p>Cookies are small text files that are downloaded to your device. Cookies contain uniquely generated references which are used to distinguish you from other users. They allow information gathered on one webpage to be stored until it is needed for use on another, allowing our Platform to provide you with a personalised experience (like remembering your favourites) and provide us with statistics about how you interact with our Platform.</p>

                                <p>Cookies are not harmful to your devices (like a virus or malicious code), but some individuals prefer not to share their information (for example, to avoid targeted advertising).</p>

                                <ul>
                                    <li>Different types of Cookies
                                        <ol>
                                            <li>Session vs. persistent cookies: cookies have a limited lifespan. Cookies which only last a short time or end when you close your browser are called session cookies. Cookies which remain on your device for longer are called persistent cookies (these are the type of cookies that allow websites to remember your details when you log back onto them).</li>
                                            <li>First party vs third party cookies: cookies placed on your device by the website owner are called first party cookies. When the website owner uses other businesses’ technology to help them manage and monitor their website, the cookies added by the other business are called third party cookies.</li>
                                            <li>Categories of cookies: cookies can be grouped by what they help the website or website owner do (thePurpose).</li>

                                            <ul>
                                                <li>Necessary cookies are cookies which help the Platform to run properly (when they are strictly necessary cookies it means their only function is to help the website work).</li>
                                                <li>Performance cookies help a Platform owner understand and analyse how Platform visitors use their website.</li>
                                                <li>Analytical cookies are used to understand how visitors interact with the Platform. These cookies help provide information on metrics the number of visitors, bounce rate, etc.</li>
                                                <li>Marketing cookies tailor online adverts to reflect the content you have previously browse and help inform companies about your interests so they can show you relevant adverts.</li>
                                            </ul>
                                        </ol>
                                    </li>

                                    <li>What do we use cookies for?

                                        <p>We use cookies to: </p>
                                        <ul>
                                            <li>to track how visitors use our Platform</li>
                                            <li>to record whether you have seen specific messages we display on our Platform</li>
                                            <li>to keep you signed into our Platform</li>
                                            <li>where we post content and links to content, we use cookies to capture and analyse information such as number of views and shares</li>
                                        </ul>
                                    </li>


                                    <li>The cookies we use are:

                                        <div className="tableSection threeCols">
                                            <div className="tableTitle">Cookie</div>
                                            <div className="tableTitle">Purpose</div>
                                            <div className="tableTitle">What it does</div>


                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>Keycloak</p>
                                                </div>
                                            </div>
                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>Necessary cookie</p>
                                                </div>
                                            </div>
                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>This cookie is essential for allowing users to log in to their Profile/our Platform.</p>
                                                </div>
                                            </div>






                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>Google Analytics</p>
                                                </div>
                                            </div>
                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>Analytical cookie</p>
                                                </div>
                                            </div>
                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>This cookie helps to collect data that allows services to understand how users interact with our platform</p>
                                                </div>
                                            </div>





                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>PostHog</p>
                                                </div>
                                            </div>
                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>Analytical cookie</p>
                                                </div>
                                            </div>
                                            <div className="card small">
                                                <div className="cardContent">
                                                    <p className='m-0'>This cookie helps to collect data that allows services to understand how users interact with our platform</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>


                                <p>We can only use cookies with your permission (you will be prompted by a message when you first visit our Platform, also known as a cookie banner, where you can choose to accept or decline our cookies).</p>

                                <p>You can update your cookie settings on our Platform by using our cookie banner, which you can access using the icon in the bottom left corner of each page.</p>

                                <p>You can choose to decline cookies but if you turn off necessary cookies, some pages and functions on our Platform may not work properly. You can also manage cookies through your browser settings or device settings (your user manual should contain additional information).</p>

                                <p>You can also delete cookies directly with the relevant third parties (for example, you can disable Google Analytics on their website).</p>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;