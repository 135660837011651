import React, { useState, useEffect } from "react";
import "./collection-editor.scss";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import CollectionMetaEditor from "../collection-meta-editor/collection-meta-editor";
import CollectionItemsEditor from "../collection-items-editor/collection-items-editor";
import CollectionEditorActionButtons from "../collection-editor-action-buttons/collection-editor-action-buttons";
import CollectionEditorUtils from "../../collection-editor-utils";
import Spinner from "../../../../components/core/spinner/spinner";
import { useSelector } from "react-redux";

const CollectionEditor = () => {
  const { isEditorLoaderActive, editorErrorMessage } = useSelector(
    (state) => state.collectionStore
  );
  return (
    <div className="addNewCollectionWrapper">
      <div className="inner">
        {/* <div className="backButtonWrapper">
          <Button
            size={"extraSmall"}
            icon={ICONS.arrowLeft}
            iconPos={"left"}
            type={"default"}
            text={"Back"}
            extraClasses={"backButton"}
            onClick={() => {
              CollectionEditorUtils.dismissEditor();
            }}
          />
        </div> */}
        <CollectionMetaEditor />
        <CollectionItemsEditor />
        <p
          style={{
            width: "100%",
            textAlign: "end",
            color: "red",
            fontSize: "1rem",
            paddingRight: "0.3rem",
            marginTop: "-1rem",
          }}
        >
          {editorErrorMessage}
        </p>
        <CollectionEditorActionButtons />
      </div>
      {/* save draft confirmation popup */}
      {isEditorLoaderActive ? (
        <>
          <div
            className="loading"
            style={{
              height: "110vh",
              width: "100vw",
              textAlign: "center",
              marginBottom: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "4vh",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              position: "fixed",
              top: "-3rem",
              left: 0,
              zIndex: 9999,
            }}
          >
            <Spinner withContainer={true} />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CollectionEditor;
