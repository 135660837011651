import React, { useRef } from "react";
import ICONS from "../../../../assets/quester-icons";
import "./ai-summary-media-viewer.scss";
import Image from "../../../../components/core/image/image";

const ImageWrapper = ({ src }) => {
  const [hideImage, setHideImage] = React.useState(false);

  const handleImageError = () => {
    setHideImage(true);
  };

  return (
    <div
      className="imageItem"
      style={{ display: hideImage ? "none" : "block" }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(src, "_blank");
      }}
    >
      <Image
        src={src}
        alt=""
        hideOnError={true}
        onLoadError={handleImageError}
      />
    </div>
  );
};

const AiSummaryMediaViewer = ({ mediaLinks = [] }) => {
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  if (!mediaLinks.length) {
    return <></>;
  }
  return (
    <>
      <div className="multimedia">
        <div className="multimediaTitle">
          <div className="multiLeft">Multimedia</div>
          <div className="multiRight arrows">
            {mediaLinks?.length > 5 ? (
              <>
                <span className="prev" onClick={() => scroll(-120)}>
                  {ICONS.chevronLeft}
                </span>
                <span className="next" onClick={() => scroll(120)}>
                  {ICONS.chevronRight}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="imageItems" ref={ref}>
          {mediaLinks.map((mediaLink, index) => (
            // <div
            //   className="imageItem"
            //   key={index}
            //   onClick={(e) => {
            //     e.preventDefault();
            //     window.open(mediaLink.src, "_blank");
            //   }}
            //   style={{ cursor: "pointer" }}
            // >
            //   <Image src={mediaLink.src} alt="" hideOnError={false} />
            // </div>
            <ImageWrapper key={index} src={mediaLink.src} />
          ))}
        </div>
      </div>
    </>
  );
};

export default AiSummaryMediaViewer;
