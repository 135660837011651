/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import useKeyCloakSafe from "../shared/hooks/useSafeKeycloak";
import "./communities-page.scss";
import GeneralHelper from "../shared/helper-methods/general-helpers";
import DivWithScrollDirectionListener from "../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";
import CommunityUtils from "../entities/community/community-utils";
import CommunityInfoViewer from "../entities/community/components/community-info-viewer/community-info-viewer";
import CollectionEditor from '../entities/collection/components/collection-editor/collection-editor';
import CollectionEditorUtils from "../entities/collection/collection-editor-utils";
import { EventEmitter } from "../shared/utils/event-emitter";
import SiteWiseEvents from "../declarations/site-wise-events";

const CreateCollectionPage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();

  let { communityId, questId } = useParams();
  const location = useLocation();

  const { activeCollection, activeCollectionItems, isCollectionLoaderActive } =
    useSelector((state) => state.collectionStore);

  useEffect(() => {
    // On load
    // Get community id from url (/c/:id)
    if (initialized) {
      CommunityUtils.initiateCommunityLoad(communityId);
    }
  }, [keycloak, initialized, location]);

  useEffect(() => {
    // Check if resource is present in the params
    if (location.search.includes("resource")) {
      const resource = new URLSearchParams(location.search).get("resource");
      EventEmitter.dispatch(SiteWiseEvents.AUTO_PASTE_LINK_IN_RESOURCE_WIZARD, resource);
    }
  }, []);

  return (
    <>
      <DivWithScrollDirectionListener
        className="communityPageMainContent"
        onScrollDown={() => {
          GeneralHelper.updateMobileFooterOnScrollDown();
        }}
        onScrollUp={() => {
          GeneralHelper.updateMobileFooterOnScrollUp();
        }}
      >
        <CommunityInfoViewer />
        <CollectionEditor />
        {/* */}
      </DivWithScrollDirectionListener>
    </>
  );
};

export default CreateCollectionPage;
