import MetaStoreActionTypes from "./action-types";

export const MetaStoreActions = {
  staticActions: {
    enableGifsAnimation: () => ({
      type: MetaStoreActionTypes.ENABLE_ANIMATIONS,
    }),
    toggleDarkMode: (isDarkModeEnabled) => ({
      type: MetaStoreActionTypes.TOGGLE_DARK_MODE,
      payload: isDarkModeEnabled,
    }),
    disableGifsAnimation: () => ({
      type: MetaStoreActionTypes.DISABLE_ANIMATIONS,
    }),
    toggleRatingExpanded: (isRatingExpanded) => ({
      type: MetaStoreActionTypes.TOGGLE_ALL_RATING_EXPANDED,
      payload: isRatingExpanded,
    }),
    setActionForNextAppLoad: (action) => ({
      type: MetaStoreActionTypes.SAVE_ACTIONS_FOR_NEXT_APP_LOAD,
      payload: action,
    }),
  },
};
