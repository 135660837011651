import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

class CommentActivities {
  static intervalRef = null;
  static activeViewActivityIndex = null;

  static addNewCommentActivity({ quest, comment }) {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const formattedTextValue = CommentActivities._formatText(
      comment.commentData
    );
    const taggedQuestItemIds = CommentActivities._getTaggedQuestItemId(
      comment.commentData
    );
    const activity = {
      entityType: "comment",
      entityId: comment.id,
      entityValue1: formattedTextValue,
      entityValue2: taggedQuestItemIds,
      entityValue3: comment.treeLevel,
      entityValue4: comment.parentId || "",
      entityValue5: comment.rootId || comment.id,
      activityType: "comment",
      pageName: `Quest page: ${quest.listname}`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("comment", activity);
    }, 200);
  }
  static addLikeCommentActivity({ quest, comment }) {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const formattedTextValue = CommentActivities._formatText(
      comment.commentData
    );
    const taggedQuestItemIds = CommentActivities._getTaggedQuestItemId(
      comment.commentData
    );
    const activity = {
      entityType: "comment",
      entityId: comment.id,
      entityValue1: formattedTextValue,
      entityValue2: taggedQuestItemIds,
      entityValue3: comment.treeLevel,
      entityValue4: comment.parentId || "",
      entityValue5: comment.rootId || comment.id,
      activityType: "comment-like",
      pageName: `Quest page: ${quest.listname}`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("comment-like", activity);
    }, 200);
  }
  static recordCommentViewActivity({ quest }) {
    ActivityRecorder.currentActivityIndex =
      ActivityRecorder?.session?.activities?.length || 0;

    const metaData = ActivityRecorder.generateActivityMetaData();

    const activity = {
      entityType: "quest",
      entityId: quest.uuid,
      entityValue1: quest.listname,
      activityType: "open-comment-view",
      pageName: `Quest page: ${quest.listname}`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    ActivityRecorder.writeToDB();
  }
  static _formatText(commentData) {
    // "commentData": [
    //             {
    //                 "e": "text",
    //                 "l": "level2 test 123",
    //                 "v": "level2 test 123"
    //             },
    //             {
    //                 "e": "questItem",
    //                 "l": "questtemLabel",
    //                 "v": "questtemUuid"
    //             },
    // {
    //                 "e": "text",
    //                 "l": "level2 test 1234",
    //                 "v": "level2 test 1234"
    //             },
    //         ];
    // After format it should be "level2 test 123 @[questtemLabel](questtemUuid) level2 test 1234"
    let formattedText = "";
    commentData.forEach((item) => {
      if (item.e === "text") {
        formattedText += item.v;
      } else if (item.e === "questItem") {
        formattedText += `@[${item.l}](${item.v})`;
      }
    });
    return formattedText;
  }
  static _getTaggedQuestItemId(commentData) {
    let taggedQuestItemIds = "";
    commentData.forEach((item) => {
      if (item.e === "questItem") {
        taggedQuestItemIds += `${item.v},`;
      }
    });
    return taggedQuestItemIds.slice(0, -1); // remove the last comma
  }
}

export default CommentActivities;
