import React from "react";
import "./buttons.scss";

{
  /*
    Size
        extraSmall
        small
        medium
        large

    Icon - SVG

    IconPos
        left
        right
        null = no icon

    Type
        default = white/black
        brand = yellow
        contrast = black/white
        success
        danger

    Text = "Untitled"

    Disabled = true/false


    extraClasses = "className1 className2"
*/
}

const Button = ({
  size = "medium",
  icon = <></>,
  iconPos = "",
  type = "default",
  text = "",
  disabled = "",
  extraClasses = "",
  onClick = () => {},
  ...rest
}) => {

  return (
    <div
      className={
        "buttonWrapper " +
        size +
        " " +
        type +
        " " +
        disabled +
        " " +
        extraClasses
      }
      onClick={onClick}
      {...rest}
    >
      <div className="buttonInner">
        {iconPos === "left" ? <div className="btnIcon">{icon}</div> : ""}
        {text?.length ? <div className="btnText">{text}</div> : ""}
        {iconPos === "right" ? <div className="btnIcon">{icon}</div> : ""}
      </div>
    </div>
  );
};

export default Button;
