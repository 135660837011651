import { HttpClient } from "../clients/http-client";

const { ProtectedNotifcationHttpClient } = HttpClient;

const NotificationsApi = {
  public: {},
  private: {
    getNotifications: (queryParams = "") =>
      ProtectedNotifcationHttpClient.get(
        `notification-messages/me?${queryParams}`
      ),
    saveToken: (payload) =>
      ProtectedNotifcationHttpClient.post(
        `/notification-settings/me/save-token`,
        payload
      ),
    getNotificationStats: () =>
      ProtectedNotifcationHttpClient.get(`/notification-inbox-stats/me`),
    resetNotificationCount: () =>
      ProtectedNotifcationHttpClient.put(
        `/notification-inbox-stats/me/notification-count/reset`
      ),
    markNotificationAsRead: (notificationId) =>
      ProtectedNotifcationHttpClient.put(`/notification-messages/me/read`, {
        notificationMessageIds: [notificationId],
      }),
  },
};
export default NotificationsApi;
