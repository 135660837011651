import { HttpClient } from "../clients/http-client";

const {
  ProtectedHttpClient,
  PublicHttpClient,
  PublicV2Client,
  ProtectedV2Client,
} = HttpClient;

const UserApi = {
  public: {},
  private: {
    // To fetch all orgs of the user (public + private)
    fetchUserOrgs: () => ProtectedV2Client.get("/organisations/me"),
    fetchMyQuests: (
      { pageNo = 0, pageSize = 1000 } = {
        pageNo: 0,
        pageSize: 1000,
      }
    ) =>
      ProtectedHttpClient.get(`/quests/me?includeQuestItemCount=true&pageSize=${pageSize}&pageNo=${pageNo}
    `),
    fetchFollowingQuests: (profileUuid) =>
      ProtectedHttpClient.get(
        `follow-quest/userprofiles/${profileUuid}/qlist/following?pageSize=1000`
      ),
    fetchFollowingResources: () =>
      ProtectedHttpClient.get("/my-resources?isFollowed=true&pageSize=1000"),
    initializeUser: () => ProtectedHttpClient.post("/userprofiles/initialise"),
    fetchProfile: () => ProtectedHttpClient.get("/userprofiles/me"),
    updateProfile: (profile) =>
      ProtectedHttpClient.put("/userprofiles/me", profile),
      unfollowResource: (resourceUuid) => ProtectedHttpClient.put(`/my-resources/${resourceUuid}/unfollow`),
      unfollowCollection: (collectionUuid) => ProtectedHttpClient.put(`/quests/${collectionUuid}/unfollow`),
  },
};
export default UserApi;
