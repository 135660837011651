import cuid from "cuid";
import ApiCalls from "../../shared/api";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import { ResourceWizardActions } from "../../stores/resource-wizard/actions";
import { store } from "../../stores/store";
import CommunityUtils from "../community/community-utils";
import axios from "axios";
import AIActivities from "../../shared/utils/activity-recorder/activity-helpers/ai-activities";
import CDNUploader from "../../shared/utils/cdn-uploader";
import VotingUtils from "../../features/voting/voting-utils";

const ResourceUtils = {
  generateResourceFromLink: async (link) => {
    // This will be fetching resource from scrapper service
    let resource = null;
    try {
      const { data } = await ApiCalls.resource.private.scrapeLink(link);
      resource = data;
    } catch (error) {
      console.log(error);
    }
    return resource;
  },
  generateTypeAndTopicsFromAI: async (link, topics, types) => {
    // Need to call AI service to get type and topics
    try {
      ResourceWizardActions.staticActions.setIsAILoading(true);
      const { data } = await ApiCalls.resource.public.autoAssignTopicsAndType(
        link,
        topics,
        types
      );
      // Check if it has type
      if (data?.type?.length > 0) {
        const aiType = data.type[0];
        const typeObj = CommunityUtils.getTypeByName(aiType);
        if (typeObj) {
          ResourceUtils.setType(typeObj);
        } else {
          // Add first type
          const firstType = types.split(",")[0];
          const typeObj = CommunityUtils.getTypeByName(firstType);
          if (typeObj) {
            ResourceUtils.setType(typeObj);
          }
        }
      } else {
        // Add first type
        const firstType = types.split(",")[0];
        const typeObj = CommunityUtils.getTypeByName(firstType);
        if (typeObj) {
          ResourceUtils.setType(typeObj);
        }
      }
      // Check if it has topics
      if (data?.topics?.length > 0) {
        const topics = data.topics;
        const topicObjs = topics.map((t) => CommunityUtils.getTopicByName(t));
        topicObjs.forEach((t) => {
          if (t) {
            ResourceUtils.addTopic(t);
          }
        });
      }
      ResourceWizardActions.staticActions.setIsAILoading(false);
    } catch (error) {
      console.log(error);
    }
  },
  setLocalResource: (resource) => {
    // This will be setting the resource in the store
    ResourceWizardActions.staticActions.setLocalResource(resource);
  },
  updateResourceProperty: (propertyName, value) => {
    // Get local resource from store
    const { localResource } = ResourceUtils._getStore();
    const copyOfLocalResource = { ...localResource };
    // Update the property
    copyOfLocalResource[propertyName] = value;
    // Update the store
    ResourceWizardActions.staticActions.setLocalResource(copyOfLocalResource);
  },
  addTopic: (topic) => {
    // Get selected topics from store
    const { selectedTopics } = ResourceUtils._getStore();
    const copyOfSelectedTopics = [...selectedTopics];
    // Add the topic
    copyOfSelectedTopics.push(topic);
    // Update the store
    ResourceWizardActions.staticActions.setSelectedTopics(copyOfSelectedTopics);
  },
  removeTopic: (topic) => {
    // Get selected topics from store
    const { selectedTopics } = ResourceUtils._getStore();
    const copyOfSelectedTopics = [...selectedTopics];
    // Remove the topic
    const updatedTopics = copyOfSelectedTopics.filter(
      (t) => t.uuid !== topic.uuid
    );
    // Update the store
    ResourceWizardActions.staticActions.setSelectedTopics(updatedTopics);
  },
  setTopics: (topics) => {
    // Update the store
    ResourceWizardActions.staticActions.setSelectedTopics(topics);
  },
  setType: (type) => {
    // Update the store
    ResourceWizardActions.staticActions.setSelectedType(type);
  },
  removeType: () => {
    // Update the store
    ResourceWizardActions.staticActions.setSelectedTypes(null);
  },
  addResource: (resource) => {
    // Update the store
    ResourceWizardActions.staticActions.setSelectedResources(resource);
  },
  switchStep: (step) => {
    // Update the store
    ResourceWizardActions.staticActions.setCurrentStep(step);
  },
  generateActivity: ({feedback, publishStatus = "", entityType = "community-resource"} = {
    feedback: "no-response",
    publishStatus: "",
    entityType: "community-resource"
  }) => {
    const { localResource, selectedTopics, selectedType } = ResourceUtils._getStore();
    const activity = {
      link: localResource.sourceUrl,
      aiAssistedTopics: selectedTopics.map((t) => t.displayName),
      aiAssistedType: selectedType.description,
      response: feedback,
      publishStatus,
      entityType,
    };
    return activity;
  },
  recordFeedback: ({feedback, publishStatus = "", entityType = 'community-resource'} = {
    feedback: "no-response",
    publishStatus: "",
    entityType: 'community-resource'
  }) => {
    const {
      isAIFeedbackRecorded,
    } = ResourceUtils._getStore();
    if (isAIFeedbackRecorded) {
      return;
    }
    const activity = ResourceUtils.generateActivity({feedback, publishStatus, entityType});
    ResourceWizardActions.staticActions.setIsAIFeedbackRecorded(true);
    AIActivities.addAIAssistedResource(activity);
  },
  checkIfValidUrl: (url) => {
    return url.match(
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    );
  },
  prepareSafeLink: (link) => {
    // use encodeURIComponent to encode the link
    return encodeURIComponent(link);
  },
  setImage: (image) => {
    // Update the store
    ResourceWizardActions.staticActions.setImage(image);
  },
  submitResource: async () => {
    // This will be submitting the resource to the server
    // First if needed upload image
    const { imageFile } = ResourceUtils._getStore();
    let image = "";
    if (imageFile) {
      // Check if it's a file or a link
      // Check if it's a file or a link
      if (imageFile.indexOf("http") > -1) {
        return imageFile;
      }

      // ImageFile is a createObjectURL, so we need to convert it to a file
      const file = await fetch(imageFile).then((r) => r.blob());
      image = await ResourceUtils._uploadImage(file);
    }
    // Then prepare the final resource object
    const finalObject = ResourceUtils.prepareFinalResourceObject(image);
    // Then submit the resource
    try {
      const { data } = await ApiCalls.org.private.addCommunityResource(
        finalObject
      );
      ResourceUtils.recordFeedback({
        entityType: "community-resource",
        feedback: "no-response",
        publishStatus: "published",
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  resetWizard: () => {
    // Reset the store
    ResourceWizardActions.staticActions.resetStore();
  },
  showWizard: () => {
    ResourceWizardActions.staticActions.enableWizard();
  },
  prepareNewTopic: (topicName, communityId) => {
    // This will prepare a new topic object
    let topicUniqueName = ResourceUtils._generateTopicUniqueName(topicName);
    const newTopic = {
      uuid: `${communityId}_${topicUniqueName}`,
      displayName: topicName,
      uniqueName: topicName,
      owningOrganizationId: communityId,
    };
    return newTopic;
  },
  checkIfTopicExists: (topicName) => {
    // Check if the topic exists in the store
    // Check by uniqueName
    const { activeCommunityLabels } = store.getState().communityStore;
    const topicUniqueName = ResourceUtils._generateTopicUniqueName(topicName);
    const topic = activeCommunityLabels.find(
      (t) => t.uniqueName === topicUniqueName
    );
    return !!topic;
  },
  prepareFinalResourceObject: (image = "") => {
    // This will prepare the final resource object
    const { localResource, selectedTopics, selectedType } =
      ResourceUtils._getStore();
    const { title, sourceUrl } = localResource;
    const finalObject = {
      ...localResource,
      title,
      sourceUrl,
      imageURL: image,
      labels: selectedTopics,
      dtableSchemaId: selectedType?.id,
      owningOrganizationId: selectedType?.owningOrganizationId,
      dtableSchema: selectedType,
      _voteStat: VotingUtils.prepareFormattedVoteData({
        dtableSchema: selectedType,
        drowStats: null,
        teamResourceUuid: null,
        myDRow: null,
      }),
    };
    return finalObject;
  },
  // Private methods
  _generateTopicUniqueName: (topicName) => {
    // It's mainly all lower case and replace spaces with _
    return topicName.replace(/\s/g, "_").toLowerCase();
  },
  _uploadImage: async (image) => {
    const cdnLink = await CDNUploader.uploadImage({
      imageFile: image,
      imageFor: "resource_icon",
      id: cuid(),
    });
    return cdnLink;
  },

  _getStore: () => {
    const { resourceWizardStore } = store.getState();
    return { ...resourceWizardStore };
  },
};

export default ResourceUtils;
