import { useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import CommunityIcon from "../community-icon/community-icon";
import { useSelector } from "react-redux";
import DebouncedInput from "../../../../components/core/debounce-input/debounce-input";
import CommunityEditorUtils from "../../community-editor-utils";
import ImagePicker from "../../../../components/image-picker/image-picker";

const CommunityEditor = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isImagePickerVisible, setIsImagePickerVisible] = useState(false);

  const community = useSelector(
    (state) => state?.communityStore?.communityEditor?.editorCommunity
  );
  const _cancel = () => {
    setIsEditing(false);
  };
  const _startEdit = () => {
    CommunityEditorUtils.setCommunityUncommitedChanges(community);
    setIsEditing(true);
  };
  const setDescription = (value) => {
    CommunityEditorUtils.updateCommunityProperty({
      property: "orgDescription",
      value: value,
    });
  };
  const _updateImage = (image) => {
    const imageUrl = URL.createObjectURL(image);
    CommunityEditorUtils.updateCommunityProperty({
      property: "_newImage",
      value: imageUrl,
    });
    setIsImagePickerVisible(false);
  };
  const _confirmChanges = () => {
    CommunityEditorUtils.commitCommunityChanges();
    CommunityEditorUtils.submitCommunityChanges();
    setIsEditing(false);
  };
  return (
    <>
      <div className="generalTabContent">
        {/* VIEW */}
        {isEditing ? (
          <>
            {/* EDIT */}
            <input
              type="file"
              id="file"
              accept="image/*"
              onChange={(e) => _updateImage(e.target.files[0])}
              style={{ display: "none" }}
            />
            <div className="communityDetailsWrapper edit">
              <div className="title">About this community</div>

              <div className="collectionDetails">
                <div
                  className="imageWrapper"
                  style={{ position: "relative" }}
                  // onClick={() => setIsImagePickerVisible(true)}
                  onClick={() => document.getElementById("file").click()}
                >
                  <CommunityIcon
                    orgIconImageURL={
                      community?._uncommitedChanges?._newImage ||
                      community?._newImage ||
                      community?.orgIconImageURL ||
                      require("../../../../assets/images/grey_rectangle.png")
                    }
                  />

                  {community?._uncommitedChanges?._newImage ||
                  community?._newImage ||
                  community?.orgIconImageURL ? (
                    <></>
                  ) : (
                    <div className="icon">{ICONS.imageIcon}</div>
                  )}
                  <ImagePicker
                    closePopup={() => setIsImagePickerVisible(false)}
                    onUpdateImage={_updateImage}
                    isVisible={isImagePickerVisible}
                  />
                </div>

                <div className="right">
                  <div className="desc">
                    <label htmlFor="">Description:</label>
                    <DebouncedInput
                      type="text"
                      className="form-control"
                      placeholder="Describe what this community is about"
                      textArea={true}
                      debounceTime={500}
                      value={
                        community?._uncommitedChanges?.orgDescription ||
                        community?.orgDescription
                      }
                      onInputChange={setDescription}
                    />
                    {/* <textarea
                      name=""
                      id=""
                      rows="2"
                      className="form-control"
                      placeholder="Describe what this community is about"
                    >
                      We gather resources to pass IB exams. All resources you
                      will find here are free. df dd We gather resources to pass
                      IB exams. All resources you will find here are free. df dd
                    </textarea> */}
                  </div>

                  <div className="buttons">
                    <Button
                      type={"danger"}
                      size={"extraSmall"}
                      icon={ICONS.corssCircle}
                      iconPos={"left"}
                      text="Cancel"
                      onClick={() => _cancel()}
                    />
                    <Button
                      type={"success"}
                      size={"extraSmall"}
                      icon={ICONS.checkCircle}
                      iconPos={"left"}
                      text="Confirm changes"
                      onClick={_confirmChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="communityDetailsWrapper">
              <div className="title">About this community</div>

              <div className="collectionDetails">
                <div className="imageWrapper">
                  {/* <img src="https://placehold.co/600x400" alt="" /> */}
                  <CommunityIcon orgIconImageURL={community?.orgIconImageURL} />
                </div>

                <div className="right">
                  <div className="desc">
                    <label htmlFor="">Description:</label>
                    <div className="text">{community?.orgDescription}</div>
                  </div>

                  <div className="buttons">
                    <Button
                      size={"extraSmall"}
                      icon={ICONS.edit}
                      iconPos={"left"}
                      text="Edit"
                      onClick={_startEdit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CommunityEditor;
