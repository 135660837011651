import { store } from "../store";
import ResourceWizardActionTypes from "./action-types";

export const ResourceWizardActions = {
  staticActions: {
    setInputLink: (inputLink) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_INPUT_LINK,
        payload: inputLink,
      });
    },
    setLocalResource: (localResource) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_LOCAL_RESOURCE,
        payload: localResource,
      });
    },
    setSelectedTopics: (selectedTopics) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_SELECTED_TOPICS,
        payload: selectedTopics,
      });
    },
    setSelectedType: (selectedType) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_SELECTED_TYPE,
        payload: selectedType,
      });
    },
    setImage: (image) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_IMAGE,
        payload: image,
      });
    },
    setCurrentStep: (currentStep) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_CURRENT_STEP,
        payload: currentStep,
      });
    },
    enableWizard: () => {
      store.dispatch({
        type: ResourceWizardActionTypes.ENABLE_WIZARD,
      });
    },
    setIsAILoading: (isAILoading) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_IS_AI_LOADING,
        payload: isAILoading,
      });
    },
    setIsAIFeedbackRecorded: (isAIFeedbackRecorded) => {
      store.dispatch({
        type: ResourceWizardActionTypes.SET_IS_AI_FEEDBACK_RECORDED,
        payload: isAIFeedbackRecorded,
      });
    },
    resetStore: () => {
      store.dispatch({
        type: ResourceWizardActionTypes.RESET_STORE,
      });
    }
  },
};
