import React, { Component, useRef } from "react";
import CollectionCard from "../collection-card/collection-card";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import useCommunityStats from "../../../../shared/hooks/useCommunityStats";
import useDeepCompareEffect from "use-deep-compare-effect";
import CommunityUtils from "../../../community/community-utils";
import COMMUNITY_TABS from "../../../../constants/community-tabs";

const FeaturedCollectionsViewer = () => {
  const [featuredCollections, setFeaturedCollections] = React.useState([]);
  const { activeCommunityCollections, isCollectionsLoaderActive } = useSelector(
    (state) => state.communityStore
  );

  const { totalCollections } = useCommunityStats();


  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  useDeepCompareEffect(() => {
    if (activeCommunityCollections?.length) {
      // Set first 15 collections to show in the slider
      setFeaturedCollections(activeCommunityCollections.slice(0, 15));
    }
  }, [activeCommunityCollections || []]);

  if (isCollectionsLoaderActive) {
    return <></>;
  }

  return (
    <>
      <div className="collectionCardSliderWrapper">
        <div className="sectionHeader">
          <div className="title">Collections ({totalCollections})</div>
          <div className="arrows">
            <span className="prev" onClick={() => scroll(-120)}>
              {ICONS.chevronLeft}
            </span>
            <span className="next" onClick={() => scroll(120)}>
              {ICONS.chevronRight}
            </span>
          </div>
        </div>

        <div className="collectionCardsSlider" ref={ref}>

          {featuredCollections?.map((collection, index) => {
            return <CollectionCard key={index} collection={collection} />;
          })}
          <div className="collectionSeeMore"
            onClick={() => {
              CommunityUtils.setActiveTab(COMMUNITY_TABS.collections);
            }}
          >See more</div>
        </div>
      </div>
    </>
  );
};

export default React.memo(FeaturedCollectionsViewer);
