import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/core/spinner/spinner";
import QuickAddCollectionItemUtils from "../../utils/quick-add-collection-item-utils";
import GeneralHelper from "../../../../shared/helper-methods/general-helpers";
import useInfiniteScroll from "react-infinite-scroll-hook";

const QuickAddList = ({ onDismiss = () => {} }) => {
  const [localLoader, setLocalLoader] = useState(false);
  const { isLoaderActive, pagination, filteredCollections } = useSelector(
    (state) => state?.quickAddCollectionItemStore
  );

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: isLoaderActive,
    hasNextPage: pagination.hasMore,
    onLoadMore: QuickAddCollectionItemUtils.loadCollections,
    disabled: false,
    rootMargin: "0px 0px 100px 0px",
  });

  const _onCollectionSelect = async (e, collection) => {
    e.stopPropagation();
    e.preventDefault();
    setLocalLoader(true);
    const success = await QuickAddCollectionItemUtils.addResourceToCollection({
      collection,
    });
    setLocalLoader(false);
    if (success) {
      GeneralHelper.showToast({
        primaryText: "Resource added to collection",
        additionalClass: "green",
        icon: ICONS.addFile,
      });
      onDismiss();
    } else {
      GeneralHelper.showToast({
        primaryText: "Failed to add resource to collection",
        additionalClass: "error",
        icon: ICONS.addFile,
      });
    }
  };
  return (
    <>
      <div className="collectionItemList" ref={rootRef}>
        {localLoader ? (
          <Spinner withContainer={true} />
        ) : (
          <>
            {filteredCollections.map((collection) => (
              <div
                className="collectionItem"
                key={collection.uuid}
                onClick={(e) => _onCollectionSelect(e, collection)}
              >
                {" "}
                {ICONS.plus}{" "}
                <span title={collection.listname}>{collection.listname}</span>{" "}
              </div>
            ))}
            {pagination.hasMore ? (
              <div
                ref={infiniteRef}
                style={{
                  height: "50px",
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <Spinner withContainer={false} />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default QuickAddList;
