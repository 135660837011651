const QuickAddCollectionItemActionTypes = {
  SET_IS_LOADER_ACTIVE: "QuickAddCollectionItemAction->SET_IS_LOADER_ACTIVE",
  SET_PAGINATION: "QuickAddCollectionItemAction->SET_PAGINATION",
  SET_SEARCH_TEXT: "QuickAddCollectionItemAction->SET_SEARCH_TEXT",
  SET_FILTERED_COLLECTIONS:
    "QuickAddCollectionItemAction->SET_FILTERED_COLLECTIONS",
  SET_DEFAULTS_COLLECTIONS:
    "QuickAddCollectionItemAction->SET_DEFAULTS_COLLECTIONS",
  SET_RECENTLY_USED_COLLECTION:
    "QuickAddCollectionItemAction->SET_RECENTLY_USED_COLLECTION",
  SET_ACTIVE_RESOURCE: "QuickAddCollectionItemAction->SET_ACTIVE_RESOURCE",
  RESET_STORE: "QuickAddCollectionItemAction->RESET_STORE",
};

export default QuickAddCollectionItemActionTypes;
