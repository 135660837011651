import React, { useState, useEffect } from "react";
import ResourceWizard from "../../../resource/components/resource-wizard/resource-wizard";
import CollectionEditorUtils from "../../collection-editor-utils";

const CollectionItemCreator = () => {
  const [showMobileWizard, setShowMobileWizard] = useState(false);
  const _checkIfMobile = () => {
    return window.innerWidth <= 768;
  };
  const isMobile = _checkIfMobile();
  return (
    <>
      <div className="section addItemInCollectionWrapper">
        {isMobile ? (
          // <button onClick={(e) => setShowMobileWizard(true)}>
          //   Add new resource
          // </button>
          <></>
        ) : (
          <></>
        )}
        {/* <AddItemFlow /> */}
        <ResourceWizard
          urlCheck={CollectionEditorUtils.checkIfUrlExistsInCollection}
          alwaysShow={isMobile ? showMobileWizard : true}
          isMobile={isMobile}
          onPublish={CollectionEditorUtils.addNewItemToCollection}
          onDismiss={isMobile? () => setShowMobileWizard(false): null}
          onResourceScraped={CollectionEditorUtils.markPendingResource}
        />
      </div>
    </>
  );
};

export default CollectionItemCreator;
