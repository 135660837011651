import fpPromise from "@fingerprintjs/fingerprintjs";
import cuid from "cuid";

class DeviceUtils {
  static deviceFingerPrint = "";
  static deviceId = "";
  static deviceInfo = null;

  static getCustomDeviceId = () => {
    if (!DeviceUtils.deviceId?.length) {
      DeviceUtils._generateCustomDeviceId();
    }
    return DeviceUtils.deviceId;
  };

  static getDeviceFingerPrint = async () => {
    if (!DeviceUtils.deviceFingerPrint?.length) {
      await DeviceUtils._generateDeviceFingerPrint();
    }
    return DeviceUtils.deviceFingerPrint;
  };

  static getDeviceInfo = async () => {
    if (!DeviceUtils.deviceInfo) {
      await DeviceUtils._generateDeviceInfo();
    }
    return DeviceUtils.deviceInfo;
  };

  static _generateDeviceInfo = () => {
    return new Promise((resolve, reject) => {
      //   fpPromise
      //     .load()
      //     .then((fp) => fp.get())
      //     .then((result) => {
      //       // This is the visitor identifier:
      //       DeviceUtils.deviceFingerPrint = result.visitorId;
      //       resolve();
      //     });
    });
  };

  static _generateDeviceFingerPrint = () => {
    return new Promise((resolve, reject) => {
      fpPromise
        .load()
        .then((fp) => fp.get())
        .then((result) => {
          // This is the visitor identifier:
          DeviceUtils.deviceFingerPrint = result.visitorId;
          resolve();
        });
    });
  };

  static _generateCustomDeviceId = () => {
    // Check if device id is already stored in local storage
    let deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      DeviceUtils.deviceId = deviceId;
      return;
    } else {
      // Generate a new device id
      deviceId = cuid();
      localStorage.setItem("deviceId", deviceId);
      DeviceUtils.deviceId = deviceId;
    }
  };
}

export default DeviceUtils;
