import React, { useState, useEffect } from "react";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import CollectionMetaEditor from "../collection-meta-editor/collection-meta-editor";
import { useSelector } from "react-redux";
import CollectionEditorUtils from "../../collection-editor-utils";

const CreateCollectionMobilePopup = () => {
  const { isCreateCollectionMobilePopupActive, editorCollection } = useSelector(
    (state) => state.collectionStore
  );
  return (
    <MobileDrawer
      isActive={isCreateCollectionMobilePopupActive}
      onClose={(e) => CollectionEditorUtils.dismissCollectionCreator()}
      headerTitle="creating a collection"
      headerIcon={ICONS.plus}
      extraclassName="addNewCollectionsDrawer"
    >
      <div className="addNewCollectionWrapper">
        <div className="inner">
          <CollectionMetaEditor />
        </div>

        <div
          className="createCollectionButtonWrapper"
          style={
            editorCollection?.title?.length > 0
              ? {}
              : { opacity: 0, pointerEvents: "none" }
          }
        >
          <Button
            size={"small"}
            type={"contrast"}
            text={"Create collection"}
            extraClasses={"createCollectionButton"}
            onClick={(e) => CollectionEditorUtils.moveToCollectionCreatePage()}
          />
        </div>
      </div>
    </MobileDrawer>
  );
};

export default CreateCollectionMobilePopup;
