import React from 'react';
import ICONS from '../../assets/quester-icons';
import './static-pages.scss';

const TermsAndConditions = (props) => {
    return (
        <div className="mainContentWrapper">
            <div className="static-pages privacy-page">
                <div className="container">
                    <div className="staticPageHeader">📃 Quester terms of use</div>

                    <> {/* PART-A starts */}
                        <div className="section">
                            <div className="sectionHeading">Part A: General Terms applicable to all users</div>

                            <div className="sectionContent">
                                <div className="left">
                                    <span>1)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Important Notices</div>

                                    <ol>
                                        <li>By using the Quester platform provided at <span className="link">https://quester.io/</span> (Platform) or creating a Profile with us, you are accepting these Terms. These Terms will apply for as long as you use our Platform. If you do not agree to these Terms, you must not use our Platform. Failure to comply with these Terms may result in legal action taken against you</li>

                                        <li>Our Platform aims to promote sharing of knowledge and encourages you and other users to generate content and share third party materials (including links to other websites). We are not responsible for this content and materials (including third party links) and any reliance you place on this content is at your own risk.</li>

                                        <li>We may vary these Terms from time to time. Our updated Terms will be displayed on the Platform and by continuing to use and access the Platform following such changes, you agree to be bound by these changes. If you have a Profile with us, we will email you at the address provided on registration to let you know about any changes to our Terms.</li>

                                        <li>If you are an organisation using the Platform to provide information to students or other users, the additional Terms set out in Section B will also apply.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>






                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>2)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">About our terms</div>

                                    <ol>
                                        <li>These terms and conditions of use (<b>Terms</b>) explain how you may use this Platform, provide links to third party content (Third Party Content) with or without your own comments, use and comments on the content created by users and made available through use of the Platform (<b>User Generated Content</b>), and access (and provide access to) Third Party Content, links to which are shared via the Platform. (<b>Third Party Content</b>).</li>

                                        <li>The Platform is owned and managed by Quester Limited (Quester, we, us or our).</li>

                                        <li>These Terms apply between us and the person accessing or using the Platform (and, if applicable, the organisation on whose behalf such person is accessing or using the Platform) (collectively,you or your).</li>

                                        <li>These Terms apply to all (and every part of) the Platform, its functionality, User Generated Content, any other content, which is provided to you for information purposes only, and free of charge and your access to, or provision of a link to, Third Party Content. You should read these Terms carefully before using the Platform, registering a profile (as further defined below), (Profile) or accessing any content, including User Generated Content and Third Party Content.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>3)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">About us</div>

                                    <ol>
                                        <li>We are Quester Limited, a company registered in England and Wales under company registration number 13608459. Our registered office is at 115 Hampstead Road, London, England, NW1 3EE.</li>
                                        <li>If you have any questions about the Platform, please contact us by sending an email to <span className="link">hello@quester.io</span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>






                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>4)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Using Our Platform: User obligations and restrictions</div>

                                    <ol>
                                        <li>At all times where you access and use our Platform, it shall be in accordance with these Terms. To access and use our Platform:
                                            
                                            <ol>
                                                <li>you must be legally capable of entering into binding contracts;</li>
                                                <li>where you are an individual, you must be thirteen (13) years old or over;</li>
                                                <li>where you are using the Platform on behalf of an organisation, you must have the authority to enter into binding contracts on behalf of that organisation; and</li>
                                                <li>you must not in any way be prohibited by the applicable law in the jurisdiction which you are currently located, (including without limitation any and all consumer law, export control laws and regulations) to enter into these Terms.</li>
                                            </ol>
                                        </li>

                                        <li>You shall:

                                            <ol>
                                                <li>comply with all applicable laws, regulations and legislations in respect of your use of the Platform and all User Generated Content and Third Party Content;</li>
                                                <li>notify us immediately if you suspect that any use of the Platform is, or will lead to activity that is, fraudulent or unlawful;</li>
                                                <li>notify us immediately of any circumstances where any intellectual property rights or any other rights of any third party may have been infringed;</li>
                                                <li>co-operate with reasonable security or other checks or requests for information made by us from time to time;</li>
                                                <li>comply with instructions we may issue from time to time relating to the Platform, User Generated Content and/or Third Party Content;</li>
                                                <li>not re-sell, promote for resale or for any commercial use User Generated Content or Third Party Content, and/or the Platform, or engage in any other similar commercial activities unless otherwise expressly permitted by us;</li>
                                                <li>not do anything that will, or might reasonably be expected to, infringe any intellectual property rights or other rights of any third parties;</li>
                                                <li>not use any information obtained using the Platform otherwise than in accordance with these Terms;</li>
                                                <li>not do or promote anything likely to impair, interfere with or damage our electronic communications network, or cause harm, harassment or distress to any persons;</li>
                                                <li>not do anything which involves the transmission of junk mail, chain letters, unsolicited mass mailing, instant messaging, “spimming” or “spamming”;</li>
                                                <li>not misuse or attack our Platform by knowingly introducing viruses, trojans, worms, logic bombs or any other material which is malicious or technologically harmful (such as by way of a denial-of-service attack);</li>
                                                <li>not solicit passwords or any other personally identifying information from other users of the Platform;</li>
                                                <li>not at any time use the Platform with the purpose of impersonating another user (whether registered or not) or person; and</li>
                                                <li>not attempt to gain unauthorised access to our Platform, User Generated Content, Third Party Content, the server on which our Platform is stored or any server, computer or content base connected to our Platform.</li>
                                            </ol>
                                        </li>

                                        <li>You must not:

                                            <ol>
                                                <li>register the same Profile more than once for the purpose of circumventing Profile limitations which have been created by the Platform;</li>
                                                <li>transmit junk mail, or chain letters, or unsolicited mass mailing, messaging or spamming.</li>
                                            </ol>
                                        </li>


                                        <li>Quester takes actual and potential or threatened breaches of the Terms very seriously, therefore Quester reserves the right to take any action that it deems necessary. This can include, without limitation, suspension or termination of the your use of or access to the Platform. In certain circumstances, Quester may choose to instigate legal proceedings as appropriate if there is any illegal use of the Platform.</li>
                                        <li>You agree that you are solely responsible for all costs and expenses you may incur in relation to your use of the Platform, User Generated Content and/or Third Party Content.</li>
                                        <li>We try to make the Platform as accessible and user-friendly as possible. If you have any difficulties using the Platform, or any questions relating to your use of the Platform, please get in touch using the contact details at the top of this page.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>5)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">User Generated Content</div>

                                    <ol>
                                        <li>The User Generated Content provided by you must not be (or be reasonably considered to be) offensive, illegal, inappropriate, or in any way:

                                            <ol>
                                                <li>promote racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
                                                <li>harass or advocate harassment of another person;</li>
                                                <li>display pornographic or sexually explicit material;</li>
                                                <li>promote any conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                                                <li>promote any illegal activities;</li>
                                                <li>provide instructional information about illegal activities, including violating someone else’s privacy;</li>
                                                <li>create computer viruses or implement any form of software or scripts onto the Website that have the appearance of coming from a user or candidate (for the avoidance of doubt, this shall not apply to API use);</li>
                                                <li>promote or contain information that you know or believe to be inaccurate, false or misleading;</li>
                                                <li>engage in the promotion of contests, sweepstakes and pyramid schemes, without our prior written consent;</li>
                                                <li>exploit people in a sexual or violent manner; or</li>
                                                <li>invade or violate any third party's right to privacy.</li>
                                            </ol>
                                        </li>

                                        <li>You represent and warrant that you own or have the necessary rights and permissions to use and authorise us to use all copyright, trademark or other proprietary rights in and to any User Generated Content to enable inclusion and use on the Platform and in accordance with these Terms.</li>

                                        <li>Whilst, you retain all of your ownership rights in your User Generated Content, by submitting the User Generated Content to us, you hereby grant us, in addition to any other rights which we may have, a worldwide, royalty-free, non- exclusive and transferable license (which is sublicensable) to use, copy, prepare derivative works of (including without limitation, to rename, edit, and use an entire image as part of compilations), display and broadcast the User Generated Content in connection with Platform and our business, including without limitation to grant access to the Platform to third parties to view the User Generated Content (and derivative works thereof) and to distribute the User Generated Content through any and all media or distribution methods now available or later developed.</li>

                                        <li>We reserve the right to refuse to publish any User Generated Content or link to Third Party Content, or to at any time remove or edit User Generated Content (in whole or in part) or any link to Third Party Content, if we have reason to believe that a user has breached these Terms in providing access to the same.</li>

                                        <li>Third Party Content is not created or owned by us or by users of the Platform. These Terms, access and use of our Platform, and/or access and use of User Generated Content or Third Party Content do not purport to grant any licence of any sort to such User Generated Content or Third Party Content.</li>

                                        <li>All User Generated Content and Third Party Content can be accessed by all users and visitors to our Platform (whether or not they have registered a Profile on our Platform), when accessing the Platform and/or a group on the Platform, (an Inn) unless the owner of the Inn elects to make the Inn private, (where it can only be accessed by registered users with an invitation to the Inn).</li>

                                        <li>If you wish to complain about or query any content uploaded by other users, including any User Generated Content or Third Party Content, please contact us at the details provided above.</li>

                                        <li>You understand that whilst we are not obligated to monitor or review User Generated Content or Third Party Content, we may do so at any time at our entire discretion. We may also edit or remove any User Generated Content or Third Party Content at our entire discretion. If you have any complaint regarding the publishing of any User Generated Content or Third Party Content, please refer to our terms below in clause 8.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>



                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>6)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Registering a Profile</div>

                                    <ol>
                                        <li>If you would like to create an Inn on our Platform, you must register your details with us by creating a Profile on our Platform. You do not need to be registered to access Inns or see the content displayed in Inns if they are public. If an Inn is private, you will need to register a Profile on our Platform in order to be invited to, and granted access to view the content displayed in that Inn.</li>
                                        <li>The information you provide to us to create a Profile must be complete, accurate and not misleading and you must inform us promptly if any information that you have provided to us changes or you become aware of any error or omission.</li>
                                        <li>You are responsible for making sure that your password and any other account details used to register are kept secure and confidential. You must not share your password or other account details with any third party. If we have reason to believe there has been, or is likely to be, a breach of security or misuse of the Platform, User Generated Content or Third Party Content through your account or the use of your password, we may notify you and require you to change your password, or we may suspend or terminate your account. You are at all times fully liable for all use of the Platform and/or the provision of or access to User Generated Content and Third Party Content using your login details, irrespective of whether such use was made by you.</li>
                                        <li>We are not obliged to permit anyone to register with or use the Platform, User Generated Content or Third Party Content and we may refuse, suspend or terminate (in accordance with section 13) a Profile or access to any part of the Platform by anyone at any time.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>






                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>7)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Data Protection</div>

                                    <ol>
                                        <li>Any personal information you provide to us as part of the registration process, or your general use of the Platform or User Generated Content will be processed in accordance with our Privacy Policy available at <span className='link'>https://quester.io/privacy</span></li>
                                        <li>Unless you are an organisation using the personal data of other users of the Platform in accordance with Part B section 3, you will only use personal data of other users of the Platform for your own domestic, non-commercial purposes.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>8)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Infringing content</div>

                                    <ol>
                                        <li>We will use reasonable efforts to remove any User Generated Content or access to Third Party Content where we become aware, or reasonably suspect, that it is inappropriate, defamatory, and/or infringes intellectual property rights, or any other rights of any third party.</li>
                                        <li>If you believe that any User Generated Content or other content that is distributed or published by the Platform is inappropriate, defamatory or infringes intellectual property rights, or any other rights of any third party, you must contact us immediately by email to: <span className="link">hello@quester.io</span>.</li>
                                        <li>If you believe that your intellectual property is being violated on the Platform, you can submit a complaint and request for takedown of specific material at: hello@quester.io.</li>
                                        <li>The Digital Millennium Copyright Act of 1998 (the “DMCA”) protects the rights of copyright owners who believe that material appearing on the internet infringes their rights under U.S. copyright law. If you believe in good faith that materials posted on the Services infringe your copyright, you (or your agent) may send us a “Notice of Claimed Infringement” at hello@quester.io requesting that the material be removed. The Notice must include the following information:

                                            <ol>
                                                <li>a physical or electronic signature of a person authorized to act on behalf of the owner of the works that have been allegedly infringed;</li>
                                                <li>identification of the copyrighted work alleged to have been infringed (or if multiple copyrighted works located on the Services are covered by a single notification, a representative list of those works);</li>
                                                <li>identification of the specific material alleged to be infringing or the subject of infringing activity, and information reasonably sufficient to allow us to locate the material on the Platform;</li>
                                                <li>your name, address, telephone number, and email address (if available);</li>
                                                <li>a statement that you have a good faith belief that use of the material in the manner complained of is not authorised by the copyright owner, its agent, or the law; and</li>
                                                <li>a statement that the information in the notification is accurate and that the complaining party is authorised to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                                            </ol>
                                        </li>
                                        <li>If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice. You should consult your legal advisor before filing a notice or counter-notice as there are penalties for false claims under the DMCA. Also, please note that if you knowingly misrepresent that any activity or material on our Platform is infringing, you may be liable to us for costs and damages.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>9)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Intellectual property rights</div>

                                    <ol>
                                        <li>The intellectual property rights in the Platform and in any text, images, video, audio or other multimedia content, software or other information or material that forms part of the Platform, or is otherwise provided by us, excluding User Generated Content and Third Party Content, are owned by us and our licensors</li>
                                        <li>We and our licensors reserve all our intellectual property rights (including, but not limited to, all copyright, trade marks, domain names, design rights, patents and all other intellectual property rights of any kind) whether registered or unregistered anywhere in the world.</li>
                                        <li>Unless otherwise expressly set out to the contrary, nothing in these Terms shall transfer from us to you, any intellectual property rights owned by and/or licensed to us.</li>
                                        <li>Nothing in these Terms grants you any legal rights in the Platform or User Generated Content other than as necessary for you to access it. You agree not to adjust, try to circumvent or delete any notices contained on the Platform, User Generated Content or Third Party Content (including any intellectual property notices) and in particular, in any digital rights or other security technology embedded or contained within the Platform, User Generated Content or Third Party Content.</li>
                                        <li>You must not use any part of the content on our Platform for commercial purposes without obtaining a licence to do so from us or our licensors.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>






                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>10)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Accuracy of information and availability of the site</div>

                                    <ol>
                                        <li>We try to make sure that the Platform is accurate, up-to-date and free from bugs, but we cannot promise that it will be. You are responsible for configuring your information technology, computer programmes and platform to access our Platform. You should use your own virus protection software.</li>
                                        <li>We cannot promise that the Platform, User Generated Content or Third Party Content will be fit or suitable for any purpose. Unless otherwise explicitly stated by us, we do not vet, verify the accuracy, correctness and completeness of, edit or modify any User Generated Content, Third Party Content or any other information, data and materials created, used and/or published by users on the Platform to determine whether they may result in any liability to any other user or any third party.</li>
                                        <li>We do not guarantee that the Platform, User Generated Content or Third Party Content is appropriate for, or will be available for use at all or times or for locations outside the United Kingdom. If you choose to access the Platform, User Generated Content and/or Third Party Content from locations outside the United Kingdom, you are responsible for compliance with the law of that country.</li>
                                        <li><b>Any reliance that you may place on any content or information on the Platform is entirely at your own risk.</b></li>
                                        <li>You should always use your own independent judgment when using the Platform and viewing and/or accessing User Generated Content or any Third Party Content.</li>
                                        <li>We do not promise that your use of the Platform or access to User Generated Content or links to third party sites will be uninterrupted or fault-free.</li>
                                        <li>We reserve the right to make changes, add, remove, modify and/or vary any elements of features and functionalities of the Platform and/or User Generated Content, from time to time.</li>
                                        <li>We may monitor your use of the Platform and/or User Generated Content, from time to time, to ensure that you are complying with these Terms.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>







                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>11)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Accuracy of information and availability of the site</div>

                                    <ol>
                                        <li>The Platform contains hyperlinks and references to third party websites other than the Platform (such as through Third Party Content or links that users input on the Platform, including in an Inn). The display of any Third Party Content or links and reference to any third party website does not mean that we endorse that third party’s website, products or services.</li>
                                        <li>Your use of and access to a third party site is governed by the terms and conditions of that third party site and is entirely at your own risk.</li>
                                        <li>If you click on any third party links, you must not provide your Quester username and/or password to any third party site. If you know or suspect that third party links included on the Platform by another user may cause risk to other users, you must notify us immediately at the contact details provided above.</li>
                                        <li>We have no control over third party websites and accept no legal responsibility or liability for any content, material or information contained in them, nor for the consequences that may arise from you providing your details to third party websites.</li>
                                        <li>You may be required to pay fees to third party sites in order to access Third Party Content. We have no control over such charges and/or subscription requirements, and accept no legal responsibility or liability for your inability to access content, material or information linked to by users of our Platform that requires such payment.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>12)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Limitation on our liability</div>

                                    <ol>
                                        <li>Nothing in these Terms limits or excludes our liability for:

                                            <ol>
                                                <li>death or personal injury caused by our negligence;</li>
                                                <li>fraud or fraudulent misrepresentation; and</li>
                                                <li>any other liabilities which cannot be excluded under relevant law.</li>
                                            </ol>
                                        </li>

                                        <li>If you are an individual, our Platform is intended for academic and personal use only. If you are an organisation, the Terms set out in Section B below will also apply.</li>

                                        <li>Quester will under no circumstances be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the contract for:

                                            <ol>
                                                <li>any loss of profits, sales, business, or revenue;</li>
                                                <li>loss or corruption of data, information or software;</li>
                                                <li>loss of business opportunity;</li>
                                                <li>loss of anticipated savings;</li>
                                                <li>loss of goodwill; or</li>
                                                <li>any indirect or consequential loss,</li>
                                            </ol>
                                        </li>

                                        <li>Quester’s maximum aggregate liability to you under, arising from or in connection with your use of the Platform, or any other matter, whether arising in contract, tort (including negligence), breach of statutory duty, or otherwise, shall not exceed £100.</li>
                                        <li>Except where expressly stated in these Terms, Quester excludes all conditions, warranties, terms and obligations, whether express or implied by statute, common law or otherwise, to the fullest extent permitted by law in respect of the Platform.</li>
                                        <li>In the event that you have a claim or right of action against any other user (including a registered user) arising from their use of the Platform, you agree to pursue such claim or action independently of and without any demands from us, and you fully and completely release us from all claims, liability and damages arising from or in any way connected to such claim or action.</li>
                                        <li>In the event that a claim or action is brought against us in connection with or arising from your activities or use of the Platform, you agree to cooperate as fully as reasonably required in the defence of any claim and allow us to assume the exclusive defence and control of such matter. You agree to indemnify and hold us harmless in respect of all costs, claims, damages and penalties which may arise as a result of any breach of these terms or misuse of our Platform by you.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>







                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>13)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Termination</div>

                                    <ol>
                                        <li>You have no obligation to continue using our Platform and you can discontinue using our Platform at any time. If you would like to delete your Profile, you can do so by emailing <span className="link">hello@quester.io</span></li>
                                        <li>We reserve the right to suspend and/or terminate your access to the Platform immediately on notice if:
                                            
                                            <ol>
                                                <li>we are unable to provide the Platform due availability of the network we host the website on;</li>
                                                <li>we believe that you and/or someone using your login details has failed to comply with one or more of these Terms;</li>
                                                <li>we believe that there has been fraudulent use, misuse or abuse of the Platform including any breach of section 4.2 and/or 6.3;</li>
                                                <li>for any other reason at our entire discretion.</li>
                                            </ol>
                                        </li>
                                        <li>For the avoidance of doubt, if your Profile has been terminated by us in accordance with these Terms, you are not permitted access to the Platform.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>







                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>14)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">General</div>

                                    <ol>
                                        <li><b>Rights of third parties</b>: These Terms do not create any rights under the Contracts (Rights of Third Parties) Act 1999 that are enforceable by any person who is not a party to them.</li>
                                        <li><b>No waiver</b>: No delay in exercising or non-exercise by you and/or us of any of our rights under or in connection with these Terms shall operate as a waiver or release of each of your or our right. Rather, any such waiver or release must be specifically granted in writing signed by the party granting it.</li>
                                        <li><b>Severance</b>: If any part of these Terms is held unenforceable, that part shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties, and the Terms shall continue in full force and effect.</li>
                                        <li><b>Disputes</b>: We will try to resolve any disputes with you quickly and efficiently. If you are unhappy with us, please contact us as soon as possible using the contact details set out at the top of this page.</li>
                                        <li><b>Governing law and jurisdiction</b>: The laws of England and Wales apply to these Terms and any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales.</li>
                                        <li>If you live in Scotland, you can bring legal proceedings in respect of our Platform in either the Scottish or the English courts. If you live in Northern Ireland, you can bring legal proceedings in respect of our Platform in either the Northern Irish or the English courts.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </> {/* PART-A ends */}






                    <> {/* PART-B starts */}
                        <div className="section">
                            <div className="sectionHeading">Part B: Terms applicable to Organisations</div>

                            <div className="sectionContent">
                                <div className="left">
                                    <span>1)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Definitions</div>

                                    <ol>
                                        <li><b>Data Protection Laws</b>: means all applicable data protection and privacy laws and regulations including the UK retained version of the GDPR in each case including all other successor legislation and regulation thereto.</li>
                                        <li><b>Organisation</b>: means any business or other organisation (for example, a school or university) that uses the Platform.</li>
                                        <li><b>User Personal Data</b>: means the personal data (as the same is defined in the Data Protection Laws) processed by Quester as a data processor on behalf of the Organisation - for example, where the Organisation is a school whose students access and use the Platform, and where the school can access via the Platform certain information about individual users' use of the Platform and the Resources provided via the Platform.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>2)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Data protection</div>

                                    <ol>
                                        <li>Both parties shall comply with Data Protection Laws (being, all applicable data protection and privacy laws and regulations including the UK retained version of the GDPR in each case including all other successor legislation and regulation thereto) in all activities carried out by such party in connection with these Terms</li>
                                        <li>Where an Organisation provides Quester with User Personal Data (whether such user is registered with a Profile or not), by inputting User Personal Data on to the Platform, the Organisation hereby confirms that it has a legitimate legal basis for providing such User Personal Data to Quester. Further details of how Quester processes and uses personal data are set out in Quester's Privacy Policy. <span className="link">https://quester.io/privacy</span></li>
                                        <li>Quester and the Organisation are separate and independent data controllers in relation to User Personal Data.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>





                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>3)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Indemnity, Liability and Warranty</div>

                                    <ol>
                                        <li>In addition to the Terms set out in Part A, section 12, the Organisation hereby indemnifies Quester for all losses, liabilities, costs and expenses (including but not limited to legal costs) suffered or incurred by Quester which arise directly or indirectly from a breach by the Organisation of these Terms.</li>
                                        <li>Subject to Part A, section 12.1 above:

                                            <ul>
                                                <li>Quester will under no circumstances be liable to an Organisation, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the contract for:

                                                    <ol>
                                                        <li>any loss of profits, sales, business, or revenue;</li>
                                                        <li>loss or corruption of data, information or software;</li>
                                                        <li>loss of business opportunity;</li>
                                                        <li>loss of anticipated savings;</li>
                                                        <li>loss of goodwill; or</li>
                                                        <li>any indirect or consequential loss,</li>
                                                    </ol>
                                                </li>

                                                <li>Quester’s maximum aggregate liability to an Organisation under, arising from or in connection with a contract Quester has with an Organisation, or an Organisation’s use of the Platform, or any other matter, whether arising in contract, tort (including negligence), breach of statutory duty, or otherwise, shall not exceed £100.</li>
                                            </ul>
                                        </li>
                                        <li>Except where expressly stated in these Terms, Quester excludes all conditions, warranties, terms and obligations, whether express or implied by statute, common law or otherwise, to the fullest extent permitted by law in respect of the Platform.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>




                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>4)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">General</div>

                                    <ol>
                                        <li><b>Assignment</b>: The Organisation shall not, without Quester’s prior written consent, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations (including its data protection rights and obligations set out in section B3), under these Terms. Quester may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under these Terms.</li>
                                        <li><b>Relationship</b>: Nothing in these Terms is intended to or shall operate to create a partnership between Quester and the Organisation, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </> {/* PART-B ends */} 



                    
                    <> {/* PART-C starts */}
                        <div className="section">
                            <div className="sectionHeading">Part C: Terms and conditions for membership</div>
                            <p className="desc">By accessing or using quester.io, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, then you may not access quester.io.</p>

                            <div className="sectionContent">
                                <div className="left">
                                    <span>1)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Subscription and Payments</div>

                                    <ol>
                                        <li>By subscribing to quester.io, you agree to pay the monthly subscription fee of $9.99.</li>
                                        <li>Payment will be charged to your provided payment method at the beginning of each monthly billing cycle.</li>
                                        <li>All payments are non-refundable. We do not provide refunds or credits for any partial-month membership periods.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>2)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Cancellation Policy</div>

                                    <ol>
                                        <li>You may cancel your subscription at any time. However, you will not receive a refund for the current billing period.</li>
                                        <li>Upon cancellation, you will continue to have access to quester.io for the remainder of the current billing period. Your subscription will not renew for the following month.</li>
                                        <li>To cancel your subscription, you must follow the cancellation instructions provided on quester.io.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>3)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Account Termination</div>

                                    <ol>
                                        <li>We reserve the right to terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms and Conditions.</li>
                                        <li>Upon termination, your right to use quester.io will immediately cease. If your account is terminated due to a breach of these Terms, you will not be entitled to any refund of unused subscription fees.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>4)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Changes to Terms and Conditions</div>

                                    <ol>
                                        <li>We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</li>
                                        <li>By continuing to access or use quester.io after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use quester.io.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className="section">
                            <div className="sectionContent">
                                <div className="left">
                                    <span>5)</span>
                                </div>

                                <div className="right">
                                    <div className="rightHeading">Contact Us</div>

                                    <ol>
                                        <li>If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:hello@quester.io" className='link'>hello@quester.io</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </> {/* PART-C ends */}

                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;