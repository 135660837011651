import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CollectionView from "../../../collection/components/collection-view/collection-view";
import UserUtils from "../../user-utils";

const MyCollectionsViewer = () => {
  const { myQuests, profileData } = useSelector((state) => state.userStore);

  const _initiateMyCollectionsLoad = () => {
    UserUtils.loadMyQuests();
  };

  useEffect(() => {
    if (profileData?.uuid) _initiateMyCollectionsLoad();
  }, [profileData?.uuid]);


  return (
    <div
      className="tab-pane fade show active"
      id="collections"
      role="tabpanel"
      aria-labelledby="collections-tab"
    >
      {myQuests?.map((quest) => (
        <CollectionView
          key={quest.id}
          collection={quest}
          hideSaveButton={true}
          hideShareButton={true}
        />
      ))}
    </div>
  );
};

export default MyCollectionsViewer;
