import { store } from "../store";
import QuickAddCollectionItemActionTypes from "./action-types";

export const QuickAddCollectionItemActions = {
  staticActions: {
    setIsLoaderActive: (isLoaderActive) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_IS_LOADER_ACTIVE,
        payload: isLoaderActive,
      });
    },
    setPagination: (pagination) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_PAGINATION,
        payload: pagination,
      });
    },
    setSearchText: (searchText) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_SEARCH_TEXT,
        payload: searchText,
      });
    },
    setFilteredCollections: (filteredCollections) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_FILTERED_COLLECTIONS,
        payload: filteredCollections,
      });
    },
    setDefaultsCollections: (defaultsCollections) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_DEFAULTS_COLLECTIONS,
        payload: defaultsCollections,
      });
    },
    setRecentlyUsedCollection: (recentlyUsedCollection) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_RECENTLY_USED_COLLECTION,
        payload: recentlyUsedCollection,
      });
    },
    setActiveResource: (activeResource) => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.SET_ACTIVE_RESOURCE,
        payload: activeResource,
      });
    },
    resetStore: () => {
      store.dispatch({
        type: QuickAddCollectionItemActionTypes.RESET_STORE,
      });
    },
  },
};
