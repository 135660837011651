import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

const VoteActivities = {
  addVoteActivity: ({
    entityType,
    entityId,
    dataPointLabel,
    dataPointValue,
    dataPointColumnId,
  }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType,
      entityId,
      entityValue1: dataPointLabel,
      entityValue2: dataPointValue,
      entityValue3: dataPointColumnId,
      activityType: "vote",
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent("vote", activity);
    }, 200);
  },
};

export default VoteActivities;
