import React, { useState, useEffect } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import CollectionEditorUtils from "../../collection-editor-utils";

const CollectionItemsEditorActionButtons = ({ onCancel, onConfirm }) => {
  const { itemsEditorMode, editorCollectionItems } = useSelector(
    (state) => state.collectionStore
  );

  if (editorCollectionItems.length === 0) {
    return <></>;
  }

  const _generateButtonsVisibility = () => {
    const buttonsVisibility = {
      edit: false,
      close: false,
      remove: false,
      cancel: false,
      confirm: false,
    };
    switch (itemsEditorMode) {
      case "view": {
        buttonsVisibility.edit = true;
        break;
      }
      case "edit": {
        buttonsVisibility.close = true;
        buttonsVisibility.remove = true;
        buttonsVisibility.cancel = true;
        break;
      }
      case "remove": {
        buttonsVisibility.cancel = true;
        buttonsVisibility.confirm = true;
        break;
      }
      default: {
        break;
      }
    }
    return buttonsVisibility;
  };

  const buttonsVisibility = _generateButtonsVisibility();

  return (
    <>
      <div className="buttons">
        {buttonsVisibility.edit ? (
          <Button
            size={"extraSmall"}
            type={"default"}
            icon={ICONS.edit}
            iconPos={"left"}
            text={"Edit recommendations"}
            onClick={() => CollectionEditorUtils.enableItemsEditor()}
          />
        ) : (
          <></>
        )}
        {buttonsVisibility.close ? (
          <Button
            size={"extraSmall"}
            type={"contrast"}
            text={"Close editing"}
            onClick={() => onConfirm()}
          />
        ) : (
          <></>
        )}
        {buttonsVisibility.remove ? (
          <Button
            size={"extraSmall"}
            type={"default"}
            icon={ICONS.trash2}
            iconPos={"left"}
            text={"Remove recommendations"}
            onClick={() => CollectionEditorUtils.enableItemsRemover()}
          />
        ) : (
          <></>
        )}
        {buttonsVisibility.cancel ? (
          <Button
            size={"extraSmall"}
            type={"default"}
            text={"Cancel"}
            onClick={() => onCancel()}
          />
        ) : (
          <></>
        )}
        {buttonsVisibility.confirm ? (
          <Button
            size={"extraSmall"}
            type={"danger"}
            icon={ICONS.trash2}
            iconPos={"left"}
            text={"Confirm"}
            onClick={() => onConfirm()}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CollectionItemsEditorActionButtons;
