import React from "react";
import CommunityUtils from "../../community-utils";

const CommunityLabel = ({ label }) => {
  const _toggleLabelSelection = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (label?.selected) {
      CommunityUtils.removeFromSelectedCommunityLabels(label);
    } else {
      CommunityUtils.addToSelectedCommunityLabels(label);
    }
  };
  return (
    <div
      onClick={_toggleLabelSelection}
      className={`topic ${label?.selected ? "active" : ""}`}
      title={label?.displayName}
    >
      {label?.displayName}
    </div>
  );
};

export default CommunityLabel;
