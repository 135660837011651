import React, { Component, useState } from "react";
import ICONS from "../../assets/quester-icons";
import "./fullscreen-popup.scss";
import Button from "../buttons/buttons";

const FullscreenPopup = ({
  isActive = true,
  onClose,
  onConfirm,
  headerTitle = "Untitled",
  headerIcon,
  headerImage,
  extraClass = "",
  maxWidth = "600px",
  maxHeight = "600px",
  successBtnIcon,
  successBtnText = "Positive action",
  cancelBtnIcon,
  cancelBtnText = "Negative action",
  children,
  closeOnOverlayClick = true,
}) => {

  const _onOverlayClick = () => {
    if (closeOnOverlayClick) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={
          "fullscreenPopup " + extraClass + " " + (isActive ? "show" : "")
        }
      >
        <div className="fullscreenPopupOverlay" onClick={_onOverlayClick}></div>

        <div
          className="fullscreenPopupInner"
          style={{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
          }}
        >
          <div className="fullscreenPopupHeader">
            {headerImage ? (
              <div className="headerImage">
                <img src={headerImage} alt="" />
              </div>
            ) : (
              ""
            )}
            <div className="headerTitle">
              {headerIcon} <span>{headerTitle}</span>
            </div>
          </div>

          <div className="fullscreenPopupContent">{children}</div>

          <div className="fullscreenButtons">
            <Button
              size="small"
              type="success"
              text={successBtnText}
              icon={successBtnIcon}
              iconPos={"left"}
              onClick={onConfirm}
            />
            <div className="link" onClick={onClose}>
              {cancelBtnText}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FullscreenPopup;
