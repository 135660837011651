const CommunityStoreActionTypes = {
  SET_ACTIVE_COMMUNITY: "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY",
  SET_ACTIVE_COMMUNITY_ID: "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_ID",
  SET_ACTIVE_COMMUNITY_RESOURCES:
    "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_RESOURCES",
  SET_ACTIVE_COMMUNITY_RESOURCES_PAGINATION:
    "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_RESOURCES_PAGINATION",
  SET_ACTIVE_COMMUNITY_COLLECTIONS:
    "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_COLLECTIONS",
  SET_ACTIVE_COMMUNITY_MEMBERS: "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_MEMBERS",
  CLEAR_ACTIVE_COMMUNITY_DATA: "COMMUNITY_STORE->CLEAR_ACTIVE_COMMUNITY_DATA",
  TOGGLE_COLLECTIONS_LOADER_STATUS:
    "COMMUNITY_STORE->TOGGLE_COLLECTIONS_LOADER_STATUS",
  TOGGLE_COMMUNITY_LOADER_STATUS:
    "COMMUNITY_STORE->TOGGLE_COMMUNITY_LOADER_STATUS",
  TOGGLE_COMMUNITY_RESOURCES_LOADER_STATUS:
    "COMMUNITY_STORE->TOGGLE_COMMUNITY_RESOURCES_LOADER_STATUS",
  TOGGLE_COMMUNITY_MEMBERS_LOADER_STATUS:
    "COMMUNITY_STORE->TOGGLE_COMMUNITY_MEMBERS_LOADER_STATUS",
  SET_ACTIVE_COMMUNITY_LABELS: "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_LABELS",
  SET_SELECTED_COMMUNITY_LABELS:
    "COMMUNITY_STORE->SET_SELECTED_COMMUNITY_LABELS",
  SET_ACTIVE_COMMUNITY_COLLECTIONS_PAGINATION:
    "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_COLLECTIONS_PAGINATION",
  SET_ACTIVE_COMMUNITY_COLLECTIONS_SORT:
    "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_COLLECTIONS_SORT",
  TOGGLE_ALL_RATING_EXPANDED: "COMMUNITY_STORE->TOGGLE_ALL_RATING_EXPANDED",
  SET_ACTIVE_COMMUNITY_TYPES: "COMMUNITY_STORE->SET_ACTIVE_COMMUNITY_TYPES",
  SET_SELECTED_COMMUNITY_TYPES: "COMMUNITY_STORE->SET_SELECTED_COMMUNITY_TYPES",
  SET_ACTIVE_TAB: "COMMUNITY_STORE->SET_ACTIVE_TAB",
  EDITOR_ACTIONS: {
    SET_EDITOR_COMMUNITY:
      "COMMUNITY_STORE->EDITOR_ACTIONS->SET_EDITOR_COMMUNITY",
    SET_LOADER_STATUS: "COMMUNITY_STORE->EDITOR_ACTIONS->SET_LOADER_STATUS",
    SET_TOP_LEVEL_ERROR_MESSAGE:
      "COMMUNITY_STORE->EDITOR_ACTIONS->SET_TOP_LEVEL_ERROR_MESSAGE",
    SET_ACTIVE_TAB: "COMMUNITY_STORE->EDITOR_ACTIONS->SET_ACTIVE_TAB",
    SET_NEW_WIZARD_STATUS:
      "COMMUNITY_STORE->EDITOR_ACTIONS->SET_NEW_WIZARD_STATUS",
    SET_TYPES: "COMMUNITY_STORE->EDITOR_ACTIONS->SET_TYPES",
    RESET_EDITOR: "COMMUNITY_STORE->EDITOR_ACTIONS->RESET_EDITOR",
    SET_NEW_TYPE: "COMMUNITY_STORE->EDITOR_ACTIONS->SET_NEW_TYPE",
  },
};

export default CommunityStoreActionTypes;
