import React, { useEffect, useState } from "react";
import ICONS from "../../assets/quester-icons";
import Button from "../../components/buttons/buttons";
import FollowingCollectionsViewer from "../../entities/user/components/following-collections-viewer/following-collections-viewer";
import FollowingResourcesViewer from "../../entities/user/components/following-resources-viewer/following-resources-viewer";
import MyCollectionsViewer from "../../entities/user/components/my-collections-viewer/my-collections-viewer";
import router from "../../shared/top-level-declarations/history";
import "./profile-page.scss";
import ProfileImage from "../../entities/user/components/profile-image/profile-image";
import { useSelector } from "react-redux";
import ActivityRecorder from "../../shared/utils/activity-recorder";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";

const NAVIGATION_TABS = {
  // MY_COLLECTIONS: "myCollections",
  SAVED_COLLECTIONS: "savedCollections",
  SAVED_ITEMS: "savedItems",
};

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState(NAVIGATION_TABS.SAVED_COLLECTIONS);
  const { keycloak, initialized } = useKeyCloakSafe();

  const { profileData } = useSelector((state) => state.userStore);


  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        ActivityRecorder.startRecordingPageActivity({
          pageName: `Profile page`
        })
      } else {
        router.navigate("/");
      }
    }
    return () => {
      ActivityRecorder.stopRecordingPageActivity()
    };
  }, [keycloak, initialized]);

  return (
    <>
      <div className="mainContentWrapper">
        <div className="profile-page">
          <div className="heroBanner">
            <div className="container">
              <div className="heroInner">
                <div className="userImage">
                  {/* <img
                    src={require("../../assets/images/cat-cute.jpg")}
                    alt=""
                  /> */}
                  <ProfileImage path={profileData?.photoUrl} />
                </div>
                <div className="username">
                  @{" "}
                  {profileData?.displayName?.length
                    ? profileData?.displayName
                    : profileData?.owningUser}
                </div>
                <div className="userDesc">{profileData?.bio || ""}</div>

                <div className="actions text-center">
                  <div
                    className="d-inline-block"
                    onClick={(e) => {
                      router.navigate("/my-settings");
                    }}
                  >
                    <Button
                      size={"small"}
                      icon={ICONS.cog}
                      iconPos={"left"}
                      type={"contrast"}
                      text={"My Settings"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="profileContent">
              <ul
                className="profileTabs nav nav-tabs"
                id="myProfileTabs"
                role="tablist"
              >
                {Object.keys(NAVIGATION_TABS).map((key) => (
                  <li className="nav-item">
                    <div
                      className={`profileTab nav-link ${
                        NAVIGATION_TABS[key] === activeTab ? "active" : ""
                      }`}
                      id={`${NAVIGATION_TABS[key]}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${NAVIGATION_TABS[key]}`}
                      aria-controls={`${NAVIGATION_TABS[key]}`}
                      aria-selected={NAVIGATION_TABS[key] === activeTab}
                      onClick={() => setActiveTab(NAVIGATION_TABS[key])}
                    >
                      {/* {key.replace(/_/g, " ")} */}
                      {
                        key == "SAVED_COLLECTIONS" ? "Liked Collections" : "Liked Items"
                      }
                    </div>
                  </li>
                ))}
              </ul>

              <div className="tab-content" id="myProfileTabsContent">
                {
                  {
                    [NAVIGATION_TABS.MY_COLLECTIONS]: <MyCollectionsViewer />,
                    [NAVIGATION_TABS.SAVED_COLLECTIONS]: (
                      <FollowingCollectionsViewer />
                    ),
                    [NAVIGATION_TABS.SAVED_ITEMS]: <FollowingResourcesViewer />,
                  }[activeTab]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
