import MetaStoreActionTypes from "./action-types";

const initialState = {
  isAnimationEnabled: true,
  isDarkModeEnabled: false,
  isRatingExpanded: false,
  actionForNextAppLoad: null,
};

export const MetaStoreReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case MetaStoreActionTypes.ENABLE_ANIMATIONS: {
      newState = {
        ...newState,
        isAnimationEnabled: true,
      };
      break;
    }
    case MetaStoreActionTypes.TOGGLE_DARK_MODE: {
      newState = {
        ...newState,
        isDarkModeEnabled: action.payload,
      };
      break;
    }
    case MetaStoreActionTypes.DISABLE_ANIMATIONS: {
      newState = {
        ...newState,
        isAnimationEnabled: false,
      };
      break;
    }
    case MetaStoreActionTypes.TOGGLE_ALL_RATING_EXPANDED: {
      newState = {
        ...newState,
        isRatingExpanded: action.payload,
      };
      break;
    }
    case MetaStoreActionTypes.SAVE_ACTIONS_FOR_NEXT_APP_LOAD: {
      newState = {
        ...newState,
        actionForNextAppLoad: action.payload,
      };
      break;
    }
    default: {
    }
  }
  return newState;
};
