import ActiveQuestStoreActionTypes from "./action-types";

export const commentStoreActions = {
  staticActions: {
    toggleLoader: (loaderState) => ({
      type: ActiveQuestStoreActionTypes.TOGGLE_LOADER,
      payload: loaderState ,
    }),
    clear: () => ({
      type: ActiveQuestStoreActionTypes.CLEAR,
    }),
    setAllComments: (allComments) => ({
      type: ActiveQuestStoreActionTypes.SET_ALL_COMMENTS,
      payload: allComments,
    }),
    setActiveNestedComments: (activeNestedComments) => ({
      type: ActiveQuestStoreActionTypes.SET_ACTIVE_NESTED_COMMENTS,
      payload: activeNestedComments,
    }),
    setRootView: (rootView) => ({
      type: ActiveQuestStoreActionTypes.SET_ROOT_VIEW,
      payload: rootView,
    }),
    setReferenceQuestItemId: (referenceQuestItemId) => ({
      type: ActiveQuestStoreActionTypes.SET_REFERENCE_QUESTITEMID,
      payload: referenceQuestItemId,
    }),
    setNestedLevelRootAddress: (nestedLevelRootAddress) => ({
      type: ActiveQuestStoreActionTypes.SET_NESTED_LEVEL_ROOT_ADDRESS,
      payload: nestedLevelRootAddress,
    }),
    setExpanded: (expanded) => ({
      type: ActiveQuestStoreActionTypes.SET_EXPANDED,
      payload: expanded,
    }),
    setReferenceView: (referenceView) => ({
      type: ActiveQuestStoreActionTypes.SET_REFERENCE_VIEW,
      payload: referenceView,
    }),
    setReferenceViewComments: (referenceViewComments) => ({
      type: ActiveQuestStoreActionTypes.SET_REFERENCE_VIEW_COMMENTS,
      payload: referenceViewComments,
    }),
    setPagination: (pagination) => ({
      type: ActiveQuestStoreActionTypes.SET_PAGINATION,
      payload: pagination,
    }),
    clearPagination: () => ({
      type: ActiveQuestStoreActionTypes.CLEAR_PAGINATION,
    }),
    clearStore: () => ({
      type: ActiveQuestStoreActionTypes.CLEAR_STORE,
    }),
  },
};
