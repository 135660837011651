import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const COLORS = {
  brown: "#964B00",
  orange: "#FFA500",
  granade: "#CF5852",
  "yellow-warm": "#FFD700",
  yellow: "#FFFF00",
  purple: "#800080",
  "purple-mate": "#BA55D3",
  pink: "#FFC0CB",
  "pink-mate": "#FF69B4",
  violet: "#EE82EE",
  blue: "#0000FF",
  azure: "#007FFF",
  mint: "#3EB489",
  green: "#008000",
  salmon: "#FA8072",
};

const TypeColorPicker = ({
  colorCode = null,
  onColorCodeChange = (newCode) => {},
  disabled = null
}) => {
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedColorName, setSelectedColorName] = useState("blue");
  const _handleColorPick = (colorname, code) => {
    onColorCodeChange(code);
    setSelectedColorName(colorname);
    setPickerOpen(false);
  };

  const _setSelectedColor = (colorCode) => {
    // Get the color name from the color code
    const colorName = Object.keys(COLORS).find(
      (color) => COLORS[color] === colorCode
    );
    setSelectedColorName(colorName);
  };
  console.log('colorCode :>> ', colorCode);
  useEffect(() => {
    if (!colorCode) return;
    _setSelectedColor(colorCode);
  }, [colorCode]);

  return (
    <>
      <div className="colorPickerWrapper">
        <div className="pickedColor" onClick={(e) => {
          if (!disabled) setPickerOpen(!pickerOpen)
        }}>
          <span className={`colorLed ${selectedColorName}`}></span>
        </div>

        <OutsideClickHandler onOutsideClick={(e) => setPickerOpen(false)}>
          <div
            className={
              "colorPickerPopup " + (pickerOpen ? "d-block" : "d-none")
            }
          >
            <div className="popupTitle">Pick a color</div>

            <div className="colorPickerOptions">
              {Object.keys(COLORS).map((color) => (
                <div
                  className="colorPickerOption"
                  key={color}
                  onClick={(e) => {
                    e.stopPropagation();
                    // console.log("Color picked: ", color, COLORS[color]);
                    _handleColorPick(color, COLORS[color]);
                    setPickerOpen(false);
                  }}
                >
                  <span className={"colorLed " + color}></span>
                </div>
              ))}
              {/* <div className="colorPickerOption">
                <span className="colorLed brown"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed orange"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed granade"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed yellow-warm"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed yellow"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed purple"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed purple-mate"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed pink"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed pink-mate"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed violet"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed blue"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed azure"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed mint"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed green"></span>
              </div>
              <div className="colorPickerOption">
                <span className="colorLed salmon"></span>
              </div> */}
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default TypeColorPicker;
