import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import DatapointsManager from "../../../collection/components/datapoints-manager/datapoints-manager";
import TypeColorPicker from "../type-color-picker/type-color-picker";
import TypePreviewer from "../type-previewer/type-previewer";
import CommunityEditorUtils from "../../../community/community-editor-utils";
import DebouncedInput from "../../../../components/core/debounce-input/debounce-input";
import useDeepCompareEffect from "use-deep-compare-effect";

const TypeEditor = ({ type }) => {
  const [quickControlToggleOpen, setQuickControlToggleOpen] = useState(false);
  const [isEditingMeta, setIsEditingMeta] = useState(false);
  const [metaError, setMetaError] = useState("");
  const [toggleBlockRatingOpen, setToggleBlockRatingOpen] = useState(false);
  const [editNameAndColor, setEditNameAndColor] = useState(false);
  const [hasCommitedChanges, setHasCommitedChanges] = useState(false);
  const _updateColor = (value) => {
    CommunityEditorUtils.updateTypeProperty({
      property: "color",
      value: value,
      typeId: type?.id,
    });
  };
  const _updateTitle = (value) => {
    CommunityEditorUtils.updateTypeProperty({
      property: "description",
      value: value,
      typeId: type?.id,
    });
  };
  const _enableMetaEdit = () => {
    CommunityEditorUtils.setTypeGeneralUncommitedChanges({
      typeId: type?.id,
      type: type,
    });
    setIsEditingMeta(true);
  };
  const _onConfirmMeta = () => {
    // Validate the meta data
    // If valid description
    setMetaError("");
    if (!type?._uncommitedChanges?.description?.length) {
      // Show error
      setMetaError("Type name is required");
      return;
    } else {
      setMetaError("");
      console.log("_onConfirmMeta");
      // Commit changes
      CommunityEditorUtils.commitTypeGeneralChanges(type?.id);
      setIsEditingMeta(false);
    }
  };
  const _onCancelMeta = () => {
    // Reset the changes
    CommunityEditorUtils.resetTypeGeneralUncommitedChanges(type?.id);
    setIsEditingMeta(false);
  };
  const _onSubmit = () => {
    // Validate
    const { isValid, errorMessage } = CommunityEditorUtils.validateType(type);
    if (!isValid) {
      setMetaError(errorMessage);
      return;
    }
    // Submit the changes
    CommunityEditorUtils.submitTypeChanges(type?.id);
    setIsEditingMeta(false);
  };
  const _onCancel = () => {
    // Reset the changes
    CommunityEditorUtils.restoreType(type?.id);
    setIsEditingMeta(false);
  };
  const _checkIfHasCommitedChanges = () => {
    if (CommunityEditorUtils.checkIfTypeHasChanges(type)) {
      setHasCommitedChanges(true);
    } else {
      setHasCommitedChanges(false);
    }
  };
  useDeepCompareEffect(() => {
    _checkIfHasCommitedChanges();
  }, [type]);
  return (
    <div className="quickControlToggles mb-2">
      <div
        className={
          "quickControlToggle " + (quickControlToggleOpen ? "open" : "")
        }
      >
        <div
          className="toggleTop"
          onClick={(e) => setQuickControlToggleOpen(!quickControlToggleOpen)}
        >
          <div className="openIcon">{ICONS.chevronUp}</div>

          <div className="toggleTypeWrap">
            <div className="toggleType">
              <span
                className="circle"
                style={{
                  backgroundColor: type?.color || "#000",
                }}
              ></span>
              <span className="label">{type?.description || "Type"}</span>
            </div>
          </div>

          {/* <div className="newTag">New</div> */}
        </div>

        {quickControlToggleOpen ? (
          <div className="toggleBody">
            <div className="toggleBlock previewBlock">
              <TypePreviewer type={type} />
            </div>

            <div className="toggleBlock ratingBlock">
              {/* <div className="title">
                <span
                  className={toggleBlockRatingOpen ? "normal" : "buttonLike"}
                  onClick={(e) =>
                    setToggleBlockRatingOpen(!toggleBlockRatingOpen)
                  }
                >
                  Name & Colour
                </span>
                <span
                  className={toggleBlockRatingOpen ? "buttonLike" : "normal"}
                  onClick={(e) =>
                    setToggleBlockRatingOpen(!toggleBlockRatingOpen)
                  }
                >
                  Ratings
                </span>
              </div> */}

              <div className="editNameAndColor">
                <div className="label">
                  Name & Color
                  <span className="errorText">{metaError}</span>
                </div>
                <div className="inputGroup">
                  {isEditingMeta ? (
                    <TypeColorPicker
                      colorCode={type?._uncommitedChanges?.color}
                      onColorCodeChange={_updateColor}
                    />
                  ) : (
                    <TypeColorPicker colorCode={type?.color} disabled={true} />
                  )}
                  <div className="inputWrap">
                    {isEditingMeta ? (
                      <DebouncedInput
                        type="text"
                        className="form-control"
                        placeholder="Type name"
                        debounceTime={500}
                        value={
                          type?._uncommitedChanges?.description ||
                          type?.description
                        }
                        onInputChange={_updateTitle}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type name"
                        value={type?.description}
                        disabled
                      />
                    )}
                  </div>
                  <div className="buttons">
                    {isEditingMeta ? (
                      <>
                        <Button
                          type={"success"}
                          size={"extraSmall"}
                          icon={ICONS.checkCircle}
                          iconPos={"left"}
                          text="Confirm"
                          onClick={_onConfirmMeta}
                        />
                        <Button
                          type={"danger"}
                          size={"extraSmall"}
                          icon={ICONS.corssCircle}
                          iconPos={"left"}
                          text="Cancel"
                          onClick={_onCancelMeta}
                        />
                      </>
                    ) : (
                      <Button
                        type={"contrast"}
                        size={"extraSmall"}
                        icon={ICONS.edit}
                        iconPos={"left"}
                        text="Edit"
                        onClick={_enableMetaEdit}
                      />
                    )}
                  </div>
                </div>
              </div>
              <DatapointsManager typeId={type.id} />
              <div className="buttons">
                <span className="errorText"></span>
                {hasCommitedChanges ? (
                  <>
                    <Button
                      type={"danger"}
                      size={"extraSmall"}
                      icon={ICONS.corssCircle}
                      iconPos={"left"}
                      text="Cancel"
                      onClick={_onCancel}
                    />
                    <Button
                      type={"success"}
                      size={"extraSmall"}
                      icon={ICONS.checkCircle}
                      iconPos={"left"}
                      text="Confirm changes"
                      onClick={_onSubmit}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TypeEditor;
