import ApiCalls from "../../../shared/api";

const BLACKLISTED_DOMAINS = ["drive.google.com", "amzn.to"];
class ResourceSummaryHelper {
  static linkSummaryMap = {};
  static async getSummary(link) {
    // Check if blacklisted
    const isBlacklisted = BLACKLISTED_DOMAINS.find(
      (domain) => link.indexOf(domain) > -1
    );
    if (isBlacklisted) {
      return null;
    }
    let summary = await ResourceSummaryHelper._getSummaryFromCache(link);
    if (!summary) {
      summary = await ResourceSummaryHelper._getSummaryFromApi(link);
    }
    return summary;
  }
  static async _getSummaryFromCache(link) {
    return ResourceSummaryHelper.linkSummaryMap[link];
  }
  static async _getSummaryFromApi(link) {
    let summary = null;
    try {
      const { data } = await ApiCalls.resource.public.getSummary(link);
      summary = data;
      ResourceSummaryHelper.linkSummaryMap[link] = summary;
    } catch (error) {
      console.log(error);
    }
    return summary;
  }
}

export default ResourceSummaryHelper;
