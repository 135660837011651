import moment from "moment";
import { MetaStoreActions } from "../../stores/meta-store/actions";
import { store } from "../../stores/store";
import { EventEmitter } from "./event-emitter";

const MetaUtils = {
  saveActionForNextAppLoad: (action) => {
    store.dispatch(
      MetaStoreActions.staticActions.setActionForNextAppLoad(action)
    );
  },
  resetActionForNextAppLoad: () => {
    store.dispatch(
      MetaStoreActions.staticActions.setActionForNextAppLoad(null)
    );
  },
  runActionForNextAppLoad: () => {
    const action = store.getState().metaStore.actionForNextAppLoad;
    if (action) {
      //   Now check if action is expired
      const hasExpired = moment().isBefore(action.expiresAt);
      if (!hasExpired) {
        //  Run the action
        switch (action.type) {
          case "triggerEvent": {
            EventEmitter.dispatch(action.payload.event);
            break;
          }

          default:
            break;
        }
        MetaUtils.resetActionForNextAppLoad();
      }
    }
  },
};

export default MetaUtils;
