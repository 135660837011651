const MetaStoreActionTypes = {
  ENABLE_ANIMATIONS: "MetaStoreActionTypes->ENABLE_GIF_ANIMATIONS",
  DISABLE_ANIMATIONS: "MetaStoreActionTypes->DISABLE_GIF_ANIMATIONS",
  TOGGLE_DARK_MODE: "MetaStoreActionTypes->TOGGLE_DARK_MODE",
  TOGGLE_ALL_RATING_EXPANDED:
    "MetaStoreActionTypes->TOGGLE_ALL_RATING_EXPANDED",
  SAVE_ACTIONS_FOR_NEXT_APP_LOAD:
    "MetaStoreActionTypes->SAVE_ACTIONS_FOR_NEXT_APP_LOAD",
};

export default MetaStoreActionTypes;
