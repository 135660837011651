import React, { useEffect, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import "./quest-comment-editor.scss";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
const questItems = [
  {
    id: "questItemId1",
    display: "questItem1",
    type: "questItem",
  },
  {
    id: "questItemId2",
    display: "questItem2",
    type: "questItem",
  },
  {
    id: "questItemId3",
    display: "questItem3",
    type: "questItem",
  },
];

const QuestCommentEditor = ({
  markDown,
  onUpdate = () => {},
  onSubmit = () => {},
  textarea = true,
  autoFocus = true,
}) => {
  const { activeCollectionItems: questItems } = useSelector((state) => state.collectionStore || {});
  const [formattedQuestItems, setFormattedQuestItems] = useState([]);
  const InputComponent = textarea ? "textarea" : "input";

  

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  const _renderSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return <div className="user">{entry.display}</div>;
  };


  const _renderSuggestionWrapper = ({ props }) => {
    const { children } = props;
    
    return (
      <div className="suggestion-wrapper">
        <div className="suggestionPopupTitle">list of recommendations</div>
        <div className="quest-item-wrapper">
          {/* Quest Items */}
          {children}
        </div>
      </div>
    );
  };

  useDeepCompareEffect(() => {
    if (questItems) {
      const formattedQuestItems = questItems.map((questItem) => ({
        id: questItem.questItemId,
        display: questItem.title,
        type: "questItem",
      }));
      setFormattedQuestItems(formattedQuestItems);
    }
  }, [questItems]);

  return (
    <>
    <MentionsInput
        value={markDown}
        onChange={(e) => onUpdate(e.target.value)}
        customSuggestionsContainer={_renderSuggestionWrapper}
        singleLine={false}
        classNames={{
          m__input: 'form-control',
        }}
        onKeyDown={handleKeyDown}
        allowSpaceInQuery={true}
        autoFocus={autoFocus}
        placeholder="Add your comment!"
      >
        <Mention
          trigger="@"
          data={formattedQuestItems}
          renderSuggestion={_renderSuggestion}
          displayTransform={(id, display) => `@${display}`}
        />
      </MentionsInput>
    </>
  );

  return (
    <>
      <InputComponent
        className="form-control"
        value={markDown}
        onChange={(e) => onUpdate(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </>
  );
};

export default QuestCommentEditor;
