import { HttpClient } from "../clients/http-client";
import GeneralHelper from "../helper-methods/general-helpers";


const { ProtectedHttpClient, PublicHttpClient, PremiumV2Client } = HttpClient;

const TeamResourcesApi = {
  public: {
    fetchTeamResources: ({ pageNo = 0, pageSize = 500, teamId, ...rest }) =>
      PublicHttpClient.get(
        `/team-resources?${GeneralHelper.prepareQueryParams({
          pageNo,
          pageSize,
          teamId,
          ...rest
        })}`
      ),
  },

  private: {
    fetchTeamResources: ({ pageNo = 0, pageSize = 500, teamId, ...rest }) =>
      ProtectedHttpClient.get(
        `/team-resources?${GeneralHelper.prepareQueryParams({
          pageNo,
          pageSize,
          teamId,
          ...rest
        })}`
      ),
      getTeamResourceById: ({teamResourceUuid}) => ProtectedHttpClient.get(`/team-resources/${teamResourceUuid}`),
    addTeamResource: (payload) =>
      ProtectedHttpClient.post(`/team-resources`, payload),
    updateTeamResource: ({ payload, teamResourceUuid }) =>
      ProtectedHttpClient.put(`/team-resources/${teamResourceUuid}`, payload),
    getDeleteDependencies: (teamResourceUuid) =>
      ProtectedHttpClient.get(
        `/team-resources/${teamResourceUuid}/check-dependency`
      ),
    deleteTeamResource: ({ teamResourceUuid, deleteDependencies = true }) =>
      ProtectedHttpClient.delete(
        `/team-resources/${teamResourceUuid}?deleteDependencies=${deleteDependencies}`
      ),
    followTeamResource: (teamResourceUuid) =>
      ProtectedHttpClient.post(`/team-resources/${teamResourceUuid}/follow`),
    unfollowTeamResource: (myResourceId) =>
      ProtectedHttpClient.put(`/my-resources/${myResourceId}/unfollow`),
  },
  premium: {
    fetchTeamResources: ({ pageNo = 0, pageSize = 500, teamId, ...rest }) =>
      PremiumV2Client.get(
        `/team-resources?${GeneralHelper.prepareQueryParams({
          pageNo,
          pageSize,
          teamId,
          ...rest
        })}`
      ),
  },
};
export default TeamResourcesApi;
