/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import CommunityType from "../community-type/community-type";

const CommunityTypesViewer = () => {
  const [typesExpand, setTypesExpand] = useState(true);
  const [formattedInitalTypes, setFormattedInitalTypes] = useState([]);
  const [formattedExtendedTypes, setFormattedExtendedTypes] = useState([]);

  const { selectedCommunityTypes, activeCommunityTypes } = useSelector(
    (state) => state.communityStore
  );

  const _prepareFormattedTypes = () => {
    const selectedTypesIdMap = {};
    selectedCommunityTypes?.forEach((type) => {
      selectedTypesIdMap[type?.id] = type;
    });
    const formattedTypes = activeCommunityTypes?.map((type) => {
      return {
        ...type,
        selected: !!selectedTypesIdMap[type?.id],
      };
    });
    // Store first max 6 types in initial types
    const formattedInitalTypes = formattedTypes?.slice(0, 6);
    // Store rest of the types in extended types
    const formattedExtendedTypes = formattedTypes?.slice(6);
    setFormattedInitalTypes(formattedInitalTypes);
    setFormattedExtendedTypes(formattedExtendedTypes);
  };

  useEffect(() => {
    _prepareFormattedTypes();
  }, [selectedCommunityTypes?.length, activeCommunityTypes?.length]);

  return (
    <>
      {formattedInitalTypes?.length === 0 ? (
        <></>
      ) : (
        <>
          <div className="typesSection">
            <div className="sectionHeading">
              <span>Types</span>
              <div className="tooltipWrap">
                <div className="icon">{ICONS.infoCircle}</div>
                <div className="tooltip">
                  Narrow your search with type-specific filters
                </div>
              </div>
            </div>

            <div className="types">
              {formattedInitalTypes?.map((type, index) => (
                <CommunityType type={type} key={type?.id} index={index} />
              ))}
              {typesExpand ? (
                <>
                  {formattedExtendedTypes?.map((type, index) => (
                    <CommunityType type={type} key={type?.id} index={index} />
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
            {formattedExtendedTypes?.length ? (
              <div
                className="seeMore"
                onClick={(e) => setTypesExpand(!typesExpand)}
              >
                {typesExpand ? "See less" : "See more"}{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CommunityTypesViewer;
