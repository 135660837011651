import { store } from "../store";
import CommunityStoreActionTypes from "./community-store-action-types";

export const CommunityEditorActions = {
  staticActions: {
    setEditorCommunity: (editorCommunity) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.SET_EDITOR_COMMUNITY,
        payload: editorCommunity,
      });
    },
    setLoaderStatus: (isLoaderActive) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.SET_LOADER_STATUS,
        payload: isLoaderActive,
      });
    },
    setTopLevelErrorMessage: (topLevelErrorMessage) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS
          .SET_TOP_LEVEL_ERROR_MESSAGE,
        payload: topLevelErrorMessage,
      });
    },
    setActiveTab: (activeTab) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.SET_ACTIVE_TAB,
        payload: activeTab,
      });
    },
    setNewWizardStatus: (isNewTypeWizardActive) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.SET_NEW_WIZARD_STATUS,
        payload: isNewTypeWizardActive,
      });
    },
    setTypes: (types) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.SET_TYPES,
        payload: types,
      });
    },
    setNewType: (newType) => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.SET_NEW_TYPE,
        payload: newType,
      });
    },
    resetEditor: () => {
      store.dispatch({
        type: CommunityStoreActionTypes.EDITOR_ACTIONS.RESET_EDITOR,
      });
    },
  },
};
