import { getFirestore } from "firebase/firestore";
import { setDoc, doc } from "firebase/firestore";
import firebase from "../../shared/top-level-providers/firebase-provider/firebase-utils";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import { jwtDecode } from "jwt-decode";
import DeviceUtils from "../../shared/utils/device-utils";
import { store } from "../../stores/store";
import cuid from "cuid";

const db = getFirestore(firebase);

const FeedCollectorUtils = {
  tryToGrabEmailFromProfile: () => {
    let email = null;
    const token = AuthHelper.getToken();
    if (token) {
      const parsedToken = jwtDecode(token);
      if (parsedToken?.email?.length) {
        email = parsedToken?.email;
      }
    }
    return email;
  },
  addFeedbackRequestToFirestore: async ({
    feedbackText,
    email,
    feedbackType,
  }) => {
    const deviceFingerPrint = DeviceUtils.getCustomDeviceId();
    let payload = {
      email: email || null,
      feedbackText,
      feedbackType,
      createdOn: +new Date(),
      userId: null,
      deviceId: deviceFingerPrint,
    };
    if (AuthHelper.isLoggedIn()) {
      const {
        userStore: { profileData },
      } = store.getState();
      if (profileData?.ownerId) payload.userId = profileData.ownerId;
    }
    await setDoc(doc(db, "feedback-v2", cuid()), payload);
  },
  validateEmail: (email) => {
    if (!email?.length) return false;
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
};

export default FeedCollectorUtils;
