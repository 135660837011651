import cloneDeep from "clone-deep";
import UserStoreActionTypes from "./action-types";

const initialState = {
  followingMyResources: [],
  followingQuests: [],
  profileData: {},
  iframeViewerSettings: {
    leftSize: 100,
    rightSize: 200,
    isDragging: false,
  },
  myQuests: [],
  myQuestsPagination: {
    pageNo: 0,
    pageSize: 20,
    hasMore: true,
    totalSize: 0,
  },
  userOrgs: [],
};

export const UserStoreReducer = (state = cloneDeep(initialState), action) => {
  let newState = { ...state };
  switch (action.type) {
    case UserStoreActionTypes.SET_USER_ORGS: {
      newState = {
        ...newState,
        userOrgs: action.payload,
      };
      break;
    }
    case UserStoreActionTypes.SET_FOLLOWING_MY_RESOURCES: {
      newState = {
        ...newState,
        followingMyResources: action.payload,
      };
      break;
    }
    case UserStoreActionTypes.SET_FOLLOWING_QUESTS: {
      newState = {
        ...newState,
        followingQuests: action.payload,
      };
      break;
    }
    case UserStoreActionTypes.SET_PROFILE_DATA: {
      newState = {
        ...newState,
        profileData: action.payload,
      };
      break;
    }
    case UserStoreActionTypes.SET_IFRAME_SIZE: {
      newState = {
        ...newState,
        iframeViewerSettings: {
          ...newState.iframeViewerSettings,
          leftSize: action.payload.leftSize,
          rightSize: action.payload.rightSize,
        },
      };
      break;
    }
    case UserStoreActionTypes.TOGGLE_IFRAME_DRAG_STATUS: {
      newState = {
        ...newState,
        iframeViewerSettings: {
          ...newState.iframeViewerSettings,
          isDragging: action.payload.isDragging,
        },
      };
      break;
    }
    case UserStoreActionTypes.CLEAR_USER_DATA: {
      newState = cloneDeep(initialState);
      break;
    }
    case UserStoreActionTypes.SET_MY_QUESTS: {
      newState = {
        ...newState,
        myQuests: action.payload,
      };
      break;
    }
    case UserStoreActionTypes.SET_MY_QUESTS_PAGINATION: {
      newState = {
        ...newState,
        myQuestsPagination: {
          ...newState.myQuestsPagination,
          ...action.payload,
        },
      };
      break;
    }
    default: {
    }
  }
  return newState;
};
