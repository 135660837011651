import React from "react";
import { useSelector } from "react-redux";

const ThemeProvider = ({ children }) => {
  const metaStore = useSelector((state) => state.metaStore);

  let isLightMode = true;
  if (metaStore?.isDarkModeEnabled) {
    isLightMode = false;
  }

  return (
    <div className="App" theme={isLightMode ? "light" : "dark"}>
      {children}
    </div>
  );
};

export default ThemeProvider;
