import cloneDeep from "clone-deep";

import CollectionStoreActionTypes from "./collection-store-action-types";

const editorState = {
  isEditorActive: false,
  isEditorLoaderActive: false,
  isEditingNew: false,
  isCollectionMetaEditorActive: false,
  isItemsOrderEditorActive: true,
  isItemsRemoveEditorActive: true,
  editorCollection: {},
  editorCollectionItems: [],
  editorItemsToRemove: [],
  isCreateCollectionMobilePopupActive: false,
  isCancelPopupActive: false,
  itemsEditorMode: 'view', // ['view', 'edit', 'remove']
  editorErrorMessage: '',
  newResourcePending: false,
};

const initialState = {
  activeCollection: {},
  activeCollectionId: null,
  activeCollectionItems: [],
  isCollectionLoaderActive: true,
  isCollectionItemsLoaderActive: true,
  ...editorState,
};

export const CollectionStoreReducer = (
  state = cloneDeep(initialState),
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case CollectionStoreActionTypes.SET_ACTIVE_COLLECTION: {
      newState = {
        ...newState,
        activeCollection: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_ACTIVE_COLLECTION_ID: {
      newState = {
        ...newState,
        activeCollectionId: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_ACTIVE_COLLECTION_ITEMS: {
      newState = {
        ...newState,
        activeCollectionItems: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_COLLECTION_LOADER_STATUS: {
      newState = {
        ...newState,
        isCollectionLoaderActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_COLLECTION_ITEMS_LOADER_STATUS: {
      newState = {
        ...newState,
        isCollectionItemsLoaderActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_EDITOR_STATUS: {
      newState = {
        ...newState,
        isEditorActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_EDITOR_LOADER_STATUS: {
      newState = {
        ...newState,
        isEditorLoaderActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_EDITING_NEW: {
      newState = {
        ...newState,
        isEditingNew: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_COLLECTION_META_EDITOR_STATUS: {
      newState = {
        ...newState,
        isCollectionMetaEditorActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_ITEMS_ORDER_EDITOR_STATUS: {
      newState = {
        ...newState,
        isItemsOrderEditorActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_ITEMS_REMOVE_EDITOR_STATUS: {
      newState = {
        ...newState,
        isItemsRemoveEditorActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_EDITOR_COLLECTION: {
      newState = {
        ...newState,
        editorCollection: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_EDITOR_COLLECTION_ITEMS: {
      newState = {
        ...newState,
        editorCollectionItems: action.payload,
      };
      break;
    }
    //TOGGLE_CANCEL_POPUP
    case CollectionStoreActionTypes.TOGGLE_CANCEL_POPUP: {
      newState = {
        ...newState,
        isCancelPopupActive: action.payload,
      };
      break;
    }
    // Reset editor
    case CollectionStoreActionTypes.RESET_EDITOR: {
      newState = {
        ...newState,
        ...editorState,
      };
      break;
    }
    case CollectionStoreActionTypes.TOGGLE_COLLECTION_ADD_MOBILE_POPUP: {
      newState = {
        ...newState,
        isCreateCollectionMobilePopupActive: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_ITEMS_EDITOR_MODE: {
      newState = {
        ...newState,
        itemsEditorMode: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_EDITOR_ITEMS_TO_REMOVE: {
      newState = {
        ...newState,
        editorItemsToRemove: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.CLEAR_COLLECTION_DATA: {
      newState = cloneDeep(initialState);
      break;
    }
    case CollectionStoreActionTypes.SET_EDITOR_ERROR_MESSAGE: {
      newState = {
        ...newState,
        editorErrorMessage: action.payload,
      };
      break;
    }
    case CollectionStoreActionTypes.SET_NEW_RESOURCE_PENDING: {
      newState = {
        ...newState,
        newResourcePending: action.payload,
      };
      break;
    }
    default: {
    }
  }
  return newState;
};
