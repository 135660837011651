import React, { Component } from "react";
import ICONS from "../../assets/quester-icons";
import "./mobile-drawer.scss";
import FeedbackModal from "../../features/feedback-collector/feedback-modal/feedback-modal";

const MobileDrawer = ({
  isActive = true,
  onClose,
  headerTitle = "About this community",
  headerIcon = ICONS.infoCircle,
  extraClass = "",
  extraclassName = "",
  children,
}) => {
  return (
    <>
      <div
        className={`mobileDrawerWrapper d-lg-none d-block ${extraClass} ${extraclassName} ${
          isActive ? "show" : ""
        }`}
      >
        <div className="drawerOverlay" onClick={onClose}></div>

        <div className="drawerInner">
          <div className="drawerHeader">
            <div className="left">
              {" "}
              {headerIcon} <span>{headerTitle}</span>{" "}
            </div>
            <div className="right">
              {" "}
              <div className="closeDrawer" onClick={onClose}>
                {ICONS.crossIcon}
              </div>{" "}
            </div>
          </div>

          <div className="drawerContent">{children}</div>
        </div>
      </div>

      {/* this is the same component but put here for better visibility in mobile view */}
    </>
  );
};

export default MobileDrawer;
