import { HttpClient } from "../clients/http-client";

const {
  PublicCommentManagementHttpClient,
  ProtectedCommentManagementHttpClient,
} = HttpClient;

const CommentApi = {
  public: {
    fetchCommunityComments: ({ communityId, pageSize, pageNo }) =>
      PublicCommentManagementHttpClient.get(
        `/comments/community?communityId=${communityId}&pageSize=${pageSize}&pageNo=${pageNo}&treeDepth=1000`
      ),
    fetchReplies: ({ parentId, questId }) =>
      PublicCommentManagementHttpClient.get(
        `/comments?parentId=${parentId}&questId=${questId}&pageSize=1000&pageNo=0&treeDepth=1000`
      ),
  },
  private: {
    fetchCommunityComments: ({ communityId, pageSize, pageNo }) =>
      ProtectedCommentManagementHttpClient.get(
        `/comments/community?communityId=${communityId}&pageSize=${pageSize}&pageNo=${pageNo}&treeDepth=1000`
      ),
    fetchReplies: ({ parentId, questId }) =>
      ProtectedCommentManagementHttpClient.get(
        `/comments?parentId=${parentId}&questId=${questId}&pageSize=1000&pageNo=0&treeDepth=1000`
      ),
    toggleCommentLike: (commentUuid) =>
      ProtectedCommentManagementHttpClient.post(
        `/comments/${commentUuid}/like-toggle`
      ),
  },
};
export default CommentApi;
