import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ICONS from "../../assets/quester-icons";
import Button from "../../components/buttons/buttons";
import DivWithScrollDirectionListener from "../../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";
import CommunityEditorUtils from "../../entities/community/community-editor-utils";
import CommunityEditor from "../../entities/community/components/community-editor/community-editor";
import CommunityInfoViewer from "../../entities/community/components/community-info-viewer/community-info-viewer";
import TypesManager from "../../entities/community/components/types-manager/types-manager";
import { useParams } from "react-router-dom";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import useDeepCompareEffect from "use-deep-compare-effect";
import Spinner from "../../components/core/spinner/spinner";
import "./community-admin-page.scss";
import router from "../../shared/top-level-declarations/history";

const TABS = {
  GENERAL: "General",
  TYPES: "Types",
};

const CommunityAdminPage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();
  // Get community id from the URL
  const { communityId } = useParams();
  const editorActiveTab =
    useSelector(
      (state) => state?.communityStore?.communityEditor?.editorActiveTab
    ) || TABS.GENERAL;
  const activeCommunity = useSelector(
    (state) => state?.communityStore?.activeCommunity
  );
  const isLoaderActive = useSelector(
    (state) => state?.communityStore?.communityEditor?.isLoaderActive
  );
  const _switchToTab = (tab) => {
    CommunityEditorUtils.setActiveTab(tab);
  };
  const _backToCommunityPage = () => {
    router.navigate(`/c/${communityId}`);
  };
  useDeepCompareEffect(() => {
    if (activeCommunity && initialized) {
      CommunityEditorUtils.initiateCommunityEditor(communityId);
    }
  }, [activeCommunity, initialized]);
  
  return (
    <>
      <DivWithScrollDirectionListener className="communityPageMainContent">
        <CommunityInfoViewer 
          hideLeaveButton={true}
          hideAdminButton={true}
          hideAddContent={true}
        />
        {/* *********************************************** ADMIN SECTION */}
        <div className="adminSectionWrapper">
          {isLoaderActive ? (
            <div className="fullpageLoader">
              <Spinner />
            </div>
          ) : (
            <></>
          )}
          <div className="backButtonWrapper">
            <Button
              size={"extraSmall"}
              icon={ICONS.arrowLeft}
              iconPos={"left"}
              text="Back"
              onClick={_backToCommunityPage}
            />
          </div>

          <div className="adminTabs">
            {Object.keys(TABS).map((tabKey) => {
              const tab = TABS[tabKey];
              return (
                <div
                  key={tab}
                  className={`adminTab ${
                    editorActiveTab === tab ? "active" : ""
                  }`}
                  onClick={() => _switchToTab(tab)}
                >
                  {tab}
                </div>
              );
            })}
          </div>
          {
            // Render based on the active tab
            editorActiveTab === TABS.GENERAL ? (
              <CommunityEditor />
            ) : editorActiveTab === TABS.TYPES ? (
              <TypesManager />
            ) : null
          }
        </div>
        {/* *********************************************** /ADMIN SECTION */}
      </DivWithScrollDirectionListener>
    </>
  );
};

export default CommunityAdminPage;
