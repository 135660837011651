import React, { Component, useEffect, useState } from "react";
import FullscreenPopup from "../fullscreen-popup/fullscreen-popup";
import ICONS from "../../assets/quester-icons";
import { EventEmitter } from "../../shared/utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";
import UserUtils from "../../entities/user/user-utils";

/**
 * Should be shown
 * 1) Whenever someone wants to opt out
 * 2) In profile data 'newsletterPref' is null
 */
const EmailOptPopup = () => {
  const [isPopupVisibile, setIsPopupVisible] = useState(false);

  const _onConfirm = () => {
    // Update user's newsletter preference
    UserUtils.setEmailPreference(true);
    // Close the popup
    setIsPopupVisible(false);
  };

  const _onCancel = () => {
    // Update user's newsletter preference
    UserUtils.setEmailPreference(false);
    // Close the popup
    setIsPopupVisible(false);
  };

  const _listenToEvents = () => {
    EventEmitter.subscribe(SiteWiseEvents.SHOW_EMAIL_OPT_IN_POPUP, () => {
      setIsPopupVisible(true);
    });
    EventEmitter.subscribe(SiteWiseEvents.HIDE_EMAIL_OPT_IN_POPUP, () => {
      setIsPopupVisible(false);
    });
  };

  useEffect(() => {
    _listenToEvents();
    return () => {
      EventEmitter.cancelAll(SiteWiseEvents.SHOW_EMAIL_OPT_IN_POPUP);
      EventEmitter.cancelAll(SiteWiseEvents.HIDE_EMAIL_OPT_IN_POPUP);
    };
  }, []);

  return (
    <>
      <FullscreenPopup
        headerTitle="👋 Hey there!"
        // headerImage={require("../assets/images/cookie.png")}
        maxHeight="600px"
        maxWidth="360px"
        successBtnIcon={ICONS.checkCircle}
        successBtnText="Sure, I don't mind being emailed"
        // cancelBtnIcon={ICONS.close}
        cancelBtnText="No, thanks!"
        isActive={isPopupVisibile}
        onClose={_onCancel}
        onConfirm={_onConfirm}
        closeOnOverlayClick={false}
        extraclassName="newsletterPopup"
      >
        <div className="textInner">
          <p>
            First of all, a heartfelt thank you for joining and using quester
            💞. We're working hard to make quester as valuable as possible for
            people like yourself.
          </p>
          <p>
            To help us do that, would you consider allowing us to send you the
            occasional email? These would include opportunities for your
            feedback, along with occasional updates, tips, and insights.
          </p>
          <p>Your voice matters, and we're all ears.</p>
          <div className="questerSignature">{ICONS.questerSignature}</div>
        </div>
      </FullscreenPopup>
    </>
  );
};

export default EmailOptPopup;
