import React, { useState, useEffect } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import ConfirmationPopup from "../../../../components/confirmation-popup/confirmation-popup";
import CollectionEditorUtils from "../../collection-editor-utils";
import { useSelector } from "react-redux";

const CollectionEditorActionButtons = () => {
  const [isDraftPopupActive, setIsDraftPopupActive] = useState(false);
  const [isDeletePopupActive, setIsDeletePopupActive] = useState(false);
  const { isEditingNew, editorCollection, isCancelPopupActive } = useSelector(
    (state) => state.collectionStore
  );

  const canShowDraftButton = () => {
    let canShow = false;
    if (isEditingNew) {
      canShow = true;
    }
    if (
      editorCollection.hasOwnProperty("isPublished") &&
      !editorCollection.isPublished
    ) {
      canShow = true;
    }
    return canShow;
  };

  const _showDraftPopup = () => {
    const errorMessage = CollectionEditorUtils._validateCollection();
    if (!errorMessage?.length) {
      setIsDraftPopupActive(true);
    }
  };

  const _showCancelPopup = () => {
    CollectionEditorUtils.showCancelPopup();
  };

  return (
    <>
      <div className="section collectionActionButtons">
        <div className="left">
          {!isEditingNew ? (
            <Button
              size={"extraSmall"}
              type={"danger"}
              icon={ICONS.trash2}
              iconPos={"left"}
              text={"Delete"}
              onClick={() => setIsDeletePopupActive(true)}
            />
          ) : (
            <></>
          )}
        </div>

        <div className="right">
          <Button
            size={"extraSmall"}
            type={"noBorder"}
            text={"Cancel"}
            onClick={() => _showCancelPopup()}
          />
          {canShowDraftButton() ? (
            <Button
              size={"extraSmall"}
              type={"default"}
              text={"Save as draft"}
              onClick={() => _showDraftPopup()}
            />
          ) : (
            <></>
          )}
          <Button
            size={"extraSmall"}
            type={"brand"}
            text={canShowDraftButton() ? "Publish Collection" : "Save"}
            onClick={() =>
              CollectionEditorUtils.submitCollection({ mode: "publish" })
            }
          />
        </div>
      </div>
      <ConfirmationPopup
        headerTitle={"Do you want to save it as draft?"}
        description={
          "This collection will be saved as a draft and can be accessed later from your profile."
        }
        positiveActionclassName={"success"}
        isVisible={isDraftPopupActive}
        onNegativeAction={() => setIsDraftPopupActive(false)}
        onPositiveAction={() => {
          setIsDraftPopupActive(false);
          CollectionEditorUtils.submitCollection({ mode: "draft" });
        }}
      />

      {/* delete collection concfirmation popup */}
      <ConfirmationPopup
        headerTitle={"Do you want to delete this collection?"}
        description={
          "Warning: This action is permanent. Note that deleting the collection will not automatically remove its items from the community database."
        }
        positiveActionclassName={"danger"}
        positiveButtonText="Delete"
        negativeButtonText="Cancel"
        isVisible={isDeletePopupActive}
        onNegativeAction={() => setIsDeletePopupActive(false)}
        onPositiveAction={() => {
          setIsDeletePopupActive(false);
          CollectionEditorUtils.deleteCollection();
        }}
      />
      {/* isCancelPopupActive */}
      <ConfirmationPopup
        headerTitle={"Discard Changes?"}
        description={
          "You have unsaved changes on this page. Are you sure you want to discard them?"
        }
        positiveActionclassName={"danger"}
        positiveButtonText="Discard Changes"
        negativeButtonText="Keep Editing"
        isVisible={isCancelPopupActive}
        onNegativeAction={() => {
          CollectionEditorUtils.hideCancelPopup();
        }}
        onPositiveAction={() => {
          CollectionEditorUtils.hideCancelPopup();
          CollectionEditorUtils.dismissEditor();
        }}
      />
    </>
  );
};

export default CollectionEditorActionButtons;
