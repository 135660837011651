import React, { Component, useEffect } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import LinkPreviewImage from "../../../../components/core/link-previewer/link-previewer";
import ResourceUtils from "../../resource-utils";
import Spinner from "../../../../components/core/spinner/spinner";
import ResourceWizardSteps from "../../constants/resource-wizard-steps";
import ResourceWizardMobileButton from "../resource-wizard-mobile-buttons/resource-wizard-mobile-buttons";
import ImagePicker from "../../../../components/image-picker/image-picker";

const ResourceEditStep = ({ switchStep = () => {}, isAi = true }) => {
  const [title, setTitle] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [imagePreview, setImagePreview] = React.useState(null);
  const [isImagePickerVisible, setIsImagePickerVisible] = React.useState(false);
  const { localResource, imageFile, isAILoading } = useSelector(
    (state) => state.resourceWizardStore
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const _updateImage = (image) => {
    // Create object URL
    setImagePreview(image);
  };

  useEffect(() => {
    const { title } = { ...localResource };
    if (title?.length) {
      setTitle(title);
    }
    if (imageFile) {
      setImagePreview(imageFile);
    }
  }, []);

  useEffect(() => {
    if (isSubmitting && !isAILoading) {
      ResourceUtils.switchStep(2);
    }
  }, [isSubmitting, isAILoading]);

  const _navigateToNextStep = () => {
    // Check if has title
    if (!title) {
      setErrorMessage("Please enter a title");
      return;
    }
    if (title.length > 125) {
      setErrorMessage("Title should be less than 125 characters");
      return;
    }
    // Update the title
    ResourceUtils.updateResourceProperty("title", title);
    // Update the image if exists
    if (imagePreview) {
      ResourceUtils.setImage(imagePreview);
    }
    if (isAILoading) {
      setIsSubmitting(true);
      return;
    }
    // Switch to next step
    ResourceUtils.switchStep(ResourceWizardSteps.PREVIEW_WITH_TOPIC_EDIT);
  };

  return (
    <>
      <div className="innerWrapper">
        <div className="section">
          <div
            className={
              "sectionInnerWrapper step2 editItem d-grid" + (isAi ? " ai " : "")
            }
          >
            <div
              className="closeIcon"
              onClick={(e) => ResourceUtils.resetWizard()}
            >
              {ICONS.crossIcon}
            </div>{" "}
            {/* this div will change only */}
            <div className="info">
              <div className="stepTitle">✏️ Edit the title and picture</div>
              <div className="stepDesc justify-content-md-end">
                {isAi ? (
                  <>
                    <div className="mainText d-md-none">
                      Try to help everyone understand what this recommendation
                      is about. You can edit the picture too!
                    </div>
                    <div className="aiAssistedText">
                      {ICONS.sparkleFilledGradient2} <span>AI assisted</span>
                    </div>
                  </>
                ) : (
                  <div className="mainText">
                    This is what our system could find about that link but can
                    you write a better title? Sure you can! Try to helps
                    everyone understand what is this recommendation about. You
                    can edit the picture too!
                  </div>
                )}
              </div>
            </div>
            {errorMessage?.length > 0 ? (
              <div className="errorText d-block d-md-none p-0 text-start">
                {errorMessage}
              </div>
            ) : (
              <></>
            )}
            <div
              className={"editableInputArea " + (errorMessage ? "error" : "")}
            >
              <div
                className="imageWrapper"
                // onClick={() => document.getElementById("file").click()}
                onClick={() => setIsImagePickerVisible(true)}
              >
                <LinkPreviewImage
                  link={localResource?.sourceUrl}
                  staticImageUrl={imagePreview || imageFile}
                  style={{ width: "56px", height: "100%", objectFit: "cover" }}
                />
                <span className="icon">{ICONS.imageIcon}</span>

                {/* 
                  <ImagePicker />
                */}
                <ImagePicker 
                  isVisible={isImagePickerVisible}
                  closePopup={() => setIsImagePickerVisible(false)}
                  onUpdateImage={_updateImage}
                />
              </div>

              <div className="right">
                <div className="inputOuterWrapper">
                  <div className="inputWrapper">
                    <input
                      type="text"
                      className="form-control small d-none d-md-block"
                      placeholder="Untitled"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <textarea
                      className="forMobile d-block d-md-none form-control small"
                      placeholder="Untitled"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    >
                      {title}
                    </textarea>

                    {isAILoading && isSubmitting ? (
                      <div className="wordLimit">
                        <div className="loaderCustom"></div>
                      </div>
                    ) : (
                      <span className="wordLimit">
                        {title?.length > 125 ? (
                          <span style={{ color: "red" }}>{title?.length}</span>
                        ) : (
                          title?.length
                        )}
                        /125
                      </span>
                    )}
                  </div>
                  {errorMessage?.length > 0 ? (
                    <div className="errorText d-none d-md-block text-start">
                      {errorMessage}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="buttons">
                  {isAILoading && isSubmitting ? (
                    // <Spinner withContainer={false} loaderSize="16px" />
                    // <div className="loaderCustom"></div>
                    <></>
                  ) : (
                    <Button
                      // icon={ICONS.arrowRight}
                      // iconPos={"right"}
                      size={"extraSmall"}
                      type={"contrast"}
                      text="Done"
                      onClick={_navigateToNextStep}
                    />
                  )}
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <ResourceWizardMobileButton
        stepNumber={1}
        buttonText="Done"
        onClick={() => _navigateToNextStep()}
      />
    </>
  );
};

export default ResourceEditStep;
