// import firebase from "firebase";
import ApiCalls from "../../shared/api";
import { store } from "../../stores/store";
import { NotificationStoreActions } from "../../stores/notification-store/notification-store-actions";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import cloneDeep from "clone-deep";
import router from "../../shared/top-level-declarations/history";
// import cuid from "cuid";

class NotificationUtils {
  static messaging = null;
  static initiateMessaging(firebaseAppInstance) {
    NotificationUtils.initiateNotificationLoad();
    // NotificationUtils.messaging = firebase.messaging(firebaseAppInstance);
    // // Request permission and get token.....
    // NotificationUtils.requestUserPermission();
    // NotificationUtils.messaging.onMessage(
    //   (payload) => {
    //     console.log("payload 888888888881:>> ", payload);
    //     // Push notification received. Add to notification store
    //     // Initiate notification load
    //     setTimeout(() => {
    //       NotificationUtils.initiateNotificationLoad();
    //     }, 3000);
    //   },
    //   (error) => {
    //     console.log("error :>> ", error);
    //   }
    // );
  }
  static async requestUserPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      //   Generate token here
      await NotificationUtils.getToken();
    } else {
      // console.log("User denied the notification permission.");
    }
  }
  static async getToken() {
    try {
      const token = await NotificationUtils.messaging.getToken({
        vapidKey: window.REACT_APP_FCM_VAPIDKEY,
      });
      // console.log(" 212121111111 token :>> ", token);
      if (token) {
        const isNewToken = NotificationUtils._checkIfNewToken(token);
        if (isNewToken) {
          NotificationUtils._setTokenLocally(token);
        }
        return token;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (error) {
      console.log("An error occurred while retrieving token. ", error);
    }
  }

  static async syncToken() {
    // If user is logged in, then set the token
    const loggedIn = AuthHelper.isLoggedIn();
    if (loggedIn) {
      const { fcm } = NotificationUtils._getNotificationStore();
      const { token, userId, browserType } = fcm;
      const {
        userStore: { profileData },
      } = NotificationUtils._getAllStores();
      if (token) {
        // Only save token if the existing user is not the owner of the profile
        if (profileData?.ownerId !== userId) {
          // Get deviceId from local storage
          const deviceId = localStorage.getItem("deviceId");
          try {
            const payload = {
              appType: "Web",
              deviceId: deviceId,
              deviceToken: token,
              deviceType: browserType,
            };
            await ApiCalls.notificationsApi.private.saveToken(payload);
            // Set fcm userId, refreshedOn
            NotificationUtils._dispatchAction(
              NotificationStoreActions.staticActions.setFcmUserId(
                profileData.ownerId
              )
            );
            NotificationUtils._dispatchAction(
              NotificationStoreActions.staticActions.setFcmRefreshedOn(
                new Date().toISOString()
              )
            );
          } catch (error) {
            console.log("error :>> ", error);
          }
        }
      }
    } else {
      // Reset userId, refreshedOn
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setFcmUserId("")
      );
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setFcmRefreshedOn("")
      );
    }
  }

  static async initiateNotificationLoad() {
    await Promise.allSettled([
      NotificationUtils.loadNotificationCount(),
      NotificationUtils.loadNotificationList({
        resetPagination: true,
      }),
    ]);
  }

  static async loadNotificationCount() {
    // const { unreadNotificationCount } = NotificationUtils._getNotificationStore();
    // Fetch notification count from API
    try {
      const { data } =
        await ApiCalls.notificationsApi.private.getNotificationStats();
      if (data?.notificationCount) {
        NotificationUtils._dispatchAction(
          NotificationStoreActions.staticActions.setUnreadNotificationCount(
            data.notificationCount
          )
        );
      } else {
        NotificationUtils._dispatchAction(
          NotificationStoreActions.staticActions.setUnreadNotificationCount(0)
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setUnreadNotificationCount(0)
      );
    }
  }

  static async loadNotificationList(
    { resetPagination = false } = {
      resetPagination: false,
    }
  ) {
    let { notificationPagination, notifications: existingNotifications } =
      NotificationUtils._getNotificationStore();

    const { currentPage, pageSize } = notificationPagination;

    if (resetPagination) {
      notificationPagination.currentPage = -1;
      notificationPagination.canLoadMore = true;
      existingNotifications = [];
    } else if (!notificationPagination.canLoadMore) {
      return;
    }

    const params = {
      page: currentPage + 1,
      pageSize,
      sortBy: "-createdOn",
    };
    const queryParams = new URLSearchParams(params).toString();
    const { data } = await ApiCalls.notificationsApi.private.getNotifications(
      queryParams
    );
    if (data?.notificationMessages) {
      const newNotifications = [
        ...existingNotifications,
        ...data.notificationMessages,
      ];

      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setNotifications(
          newNotifications
        )
      );
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setNotificationPagination({
          currentPage: currentPage + 1,
          pageSize,
          canLoadMore: data.notificationMessages.length === pageSize,
        })
      );
    } else {
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setNotifications([])
      );
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setNotificationPagination({
          currentPage: -1,
          pageSize,
          canLoadMore: false,
        })
      );
    }
  }

  static async onNotificationClick(notification) {
    // Mark as read locally and then call API
    const { notifications: existingNotifications } =
      NotificationUtils._getNotificationStore();
    const notificationIndex = existingNotifications.findIndex(
      (item) => item.id === notification.id
    );
    if (notificationIndex > -1) {
      existingNotifications[notificationIndex].read = true;
      NotificationUtils._dispatchAction(
        NotificationStoreActions.staticActions.setNotifications(
          existingNotifications
        )
      );
    }
    try {
      ApiCalls.notificationsApi.private.markNotificationAsRead(notification.id);
    } catch (error) {
      console.log("error :>> ", error);
    }
    // Navigate to the notification url
    if (router) {
      // Sample nextActionWeb: https://ui.dev.quester.io/q/1721335
      // Remove the domain name and then navigate
      const url = new URL(notification.nextActionWeb);
      // console.log("url :>> ", url);
      if (url?.pathname?.length) {
        // Extract questId from the url example /q/1721335
        const questId = url.pathname.split("/").pop();
        const { data: quest } = await ApiCalls.quest.public.fetchQuest(
          questId
        );
        if (quest) {
          // console.log("quest :>> ", quest);
          // Construct the pathname /c/:communityId/q/:questId?replyCommentId=${notification.replyCommentId}
          const pathname = `/c/${quest.owningOrganizationId}/q/${questId}?replyCommentId=${notification.replyCommentId}`;
          router.navigate(pathname);
        }
      }
      // const pathname = `${url.pathname}?replyCommentId=${notification.replyCommentId}`;
      // history.push(pathname);
    } else {
      window.open(notification.nextActionWeb);
    }
  }

  static async clearUnreadCount() {
    NotificationUtils._dispatchAction(
      NotificationStoreActions.staticActions.setUnreadNotificationCount(0)
    );
    // Call API to clear unread count
    try {
      await ApiCalls.notificationsApi.private.resetNotificationCount();
    } catch (error) {
      console.log("error :>> ", error);
    }
  }

  static async checkForNewNotifications() {
    // Check for new notifications from API and reset the pagination
    await NotificationUtils.initiateNotificationLoad();
  }

  static _setTokenLocally(token) {
    NotificationUtils._dispatchAction(
      NotificationStoreActions.staticActions.setFcmToken(token)
    );
    // Reset userId, refreshedOn and browserType
    NotificationUtils._dispatchAction(
      NotificationStoreActions.staticActions.setFcmUserId("")
    );
    NotificationUtils._dispatchAction(
      NotificationStoreActions.staticActions.setFcmRefreshedOn("")
    );
    const currentBrowserType = NotificationUtils._getBrowserType();
    NotificationUtils._dispatchAction(
      NotificationStoreActions.staticActions.setFcmBrowserType(
        currentBrowserType
      )
    );
  }

  static _checkIfNewToken(token) {
    const { fcm } = NotificationUtils._getNotificationStore();
    const { token: existingToken } = fcm;
    return token !== existingToken;
  }

  static _getBrowserType() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Chrome") > -1) {
      return "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      return "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      return "Firefox";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("rv:") > -1
    ) {
      return "IE";
    } else {
      return "Unknown";
    }
  }

  static _getNotificationStore() {
    const { notificationStore } = store.getState();
    return cloneDeep(notificationStore);
  }
  static _getAllStores() {
    const allStores = store.getState();
    return cloneDeep(allStores);
  }
  static _dispatchAction(action) {
    store.dispatch(action);
  }
}

export default NotificationUtils;
