import React, { Component, useState } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import UserUtils from "../../user-utils";
import cloneDeep from "clone-deep";
import PhotoUploader from "../../../../components/core/photo-uploader/photo-uploader";

const ProfileEditor = ({ onClose = () => {} }) => {
  const { profileData } = useSelector((state) => state.userStore);
  const [userName, setUserName] = useState(profileData?.displayName || "");
  const [description, setDescription] = useState(profileData?.bio || "");
  const [localFile, setLocalFile] = useState(null);

  const _onClose = () => {
    onClose();
    // Reset state
    setUserName(profileData?.displayName || "");
    setDescription(profileData?.bio || "");
  };

  const _onConfirm = async () => {
    const payload = cloneDeep(profileData);
    onClose();
    if (localFile) {
      const photoUrl = await UserUtils.uploadProfileImage(
        localFile,
        profileData?.uuid
      );
      if (photoUrl?.length) {
        payload.photoUrl = photoUrl;
      }
    }
    payload.displayName = userName;
    payload.bio = description;
    UserUtils.updateProfile(payload);
  };

  return (
    <>
      <div className="contentWrapper profileEdit">
        <div className="top">
          <PhotoUploader
            localFile={localFile}
            onFileChange={(file) => {
              setLocalFile(file);
            }}
            onFileClear={() => {
              setLocalFile(null);
            }}
            imagePath={
              profileData?.photoUrl
                ? `${window.REACT_APP_CDN_BASE_URL}${profileData?.photoUrl}`
                : null
            }
          />
          <div className="userDetails">
            <div className="username">
              <label>User Name:</label>
              <input
                type="text"
                className="form-control"
                placeholder="User name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="description">
              <label>Description:</label>
              <textarea
                name=""
                id=""
                rows="2"
                className="form-control"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="action">
          <div className="cancelBtn inline-block" onClick={_onClose}>
            <Button
              text={"Cancel"}
              icon={ICONS.corssCircle}
              iconPos={"left"}
              size={"extraSmall"}
            />
          </div>
          <div className="confirmBtn inline-block" onClick={_onConfirm}>
            <Button
              text={"Confirm changes"}
              icon={ICONS.checkCircle}
              iconPos={"left"}
              size={"extraSmall"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEditor;
