/* eslint-disable react-hooks/rules-of-hooks */
import { useKeycloak } from "@react-keycloak/web";
// import HelperMethods from "../helpers";

const useKeyCloakSafe = () => {
  // const isCrawler = HelperMethods.isCrawler();

  if (true) {
    const { keycloak, initialized } = useKeycloak();
    return { keycloak, initialized };
  } else {
    return { keycloak: null, initialized: true };
  }
};

export default useKeyCloakSafe;
