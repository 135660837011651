import UserStoreActionTypes from "./action-types";

export const UserStoreActions = {
  staticActions: {
    setFollowingMyResources: (myResources) => ({
      type: UserStoreActionTypes.SET_FOLLOWING_MY_RESOURCES,
      payload: myResources,
    }),
    setFollowingQuests: (quests) => ({
      type: UserStoreActionTypes.SET_FOLLOWING_QUESTS,
      payload: quests,
    }),
    setProfileData: (profileData) => ({
      type: UserStoreActionTypes.SET_PROFILE_DATA,
      payload: profileData,
    }),
    setIframeSize: ({ leftSize, rightSize }) => ({
      type: UserStoreActionTypes.SET_IFRAME_SIZE,
      payload: { leftSize, rightSize },
    }),
    toggleIframeDragStatus: ({ isDragging }) => ({
      type: UserStoreActionTypes.TOGGLE_IFRAME_DRAG_STATUS,
      payload: { isDragging },
    }),
    setMyQuests: (myQuests) => ({
      type: UserStoreActionTypes.SET_MY_QUESTS,
      payload: myQuests,
    }),
    setMyQuestsPagination: (pagination) => ({
      type: UserStoreActionTypes.SET_MY_QUESTS_PAGINATION,
      payload: pagination,
    }),
    setUserOrgs: (userOrgs) => ({
      type: UserStoreActionTypes.SET_USER_ORGS,
      payload: userOrgs,
    }),
    clearProfileData: () => ({ type: UserStoreActionTypes.CLEAR_USER_DATA }),
  },
};
