const SiteWiseEvents = {
  showToast: "SHOW_TOAST_EVENT",
  hideToast: "HIDE_TOAST_EVENT",
  openDiscoveryBar: "OPEN_DISCOVERY_BAR_EVENT",
  openCommentBar: "OPEN_COMMENT_BAR_EVENT",
  showSubscriptionPopup: "SHOW_SUBSCRIPTION_POPUP_EVENT",
  OPEN_FEEDBACK_MODAL: "OPEN_FEEDBACK_MODAL_EVENT",
  OPEN_FEEDBACK_MODAL_MOBILE: "OPEN_FEEDBACK_MODAL_MOBILE_EVENT",
  OPEN_NOTIFICATIONS_DESKTOP: "OPEN_NOTIFICATIONS_DESKTOP_EVENT",
  OPEN_NOTIFICATIONS_MOBILE: "OPEN_NOTIFICATIONS_MOBILE_EVENT",
  SHOW_EMAIL_OPT_IN_POPUP: "SHOW_EMAIL_OPT_IN_POPUP_EVENT",
  HIDE_EMAIL_OPT_IN_POPUP: "HIDE_EMAIL_OPT_IN_POPUP_EVENT",
  SHOW_RESOURCE_ADD_WIZARD: "SHOW_RESOURCE_ADD_WIZARD_EVENT",
  AUTO_PASTE_LINK_IN_RESOURCE_WIZARD: "AUTO_PASTE_LINK_IN_RESOURCE_WIZARD_EVENT",
};

export default SiteWiseEvents;
