const CollectionStoreActionTypes = {
  SET_ACTIVE_COLLECTION: "COLLECTION_STORE->SET_ACTIVE_COLLECTION",
  SET_ACTIVE_COLLECTION_ID: "COLLECTION_STORE->SET_ACTIVE_COLLECTION_ID",
  SET_ACTIVE_COLLECTION_ITEMS: "COLLECTION_STORE->SET_ACTIVE_COLLECTION_ITEMS",
  TOGGLE_COLLECTION_LOADER_STATUS:
    "COLLECTION_STORE->TOGGLE_COLLECTION_LOADER_STATUS",
  TOGGLE_COLLECTION_ITEMS_LOADER_STATUS:
    "COLLECTION_STORE->TOGGLE_COLLECTION_ITEMS_LOADER_STATUS",
  CLEAR_COLLECTION_DATA: "COLLECTION_STORE->CLEAR_COLLECTION_DATA",
  TOGGLE_EDITOR_LOADER_STATUS: "COLLECTION_STORE->TOGGLE_EDITOR_LOADER_STATUS",
  TOGGLE_EDITING_NEW: "COLLECTION_STORE->TOGGLE_EDITING_NEW",
  TOGGLE_COLLECTION_META_EDITOR_STATUS:
    "COLLECTION_STORE->TOGGLE_COLLECTION_META_EDITOR_STATUS",
  TOGGLE_ITEMS_ORDER_EDITOR_STATUS:
    "COLLECTION_STORE->TOGGLE_ITEMS_ORDER_EDITOR_STATUS",
  TOGGLE_ITEMS_REMOVE_EDITOR_STATUS:
    "COLLECTION_STORE->TOGGLE_ITEMS_REMOVE_EDITOR_STATUS",
  SET_EDITOR_COLLECTION: "COLLECTION_STORE->SET_EDITOR_COLLECTION",
  SET_EDITOR_COLLECTION_ITEMS: "COLLECTION_STORE->SET_EDITOR_COLLECTION_ITEMS",
  RESET_EDITOR: "COLLECTION_STORE->RESET_EDITOR",
  TOGGLE_COLLECTION_ADD_MOBILE_POPUP:
    "COLLECTION_STORE->TOGGLE_COLLECTION_ADD_MOBILE_POPUP",
  TOGGLE_EDITOR_STATUS: "COLLECTION_STORE->TOGGLE_EDITOR_STATUS",
  SET_ITEMS_EDITOR_MODE: "COLLECTION_STORE->SET_ITEMS_EDITOR_MODE",
  SET_EDITOR_ITEMS_TO_REMOVE: "COLLECTION_STORE->SET_EDITOR_ITEMS_TO_REMOVE",
  SET_EDITOR_ERROR_MESSAGE: "COLLECTION_STORE->SET_EDITOR_ERROR_MESSAGE",
  SET_NEW_RESOURCE_PENDING: "COLLECTION_STORE->SET_NEW_RESOURCE_PENDING",
  TOGGLE_CANCEL_POPUP: "COLLECTION_STORE->TOGGLE_CANCEL_POPUP",
};

export default CollectionStoreActionTypes;
