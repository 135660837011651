import React, { useEffect, useState } from "react";
import TypeFilter from "../../../../components/type-filter/type-filter";
import ICONS from "../../../../assets/quester-icons";
import Button from "../../../../components/buttons/buttons";
import { useSelector } from "react-redux";
import LinkPreviewImage from "../../../../components/core/link-previewer/link-previewer";
import ResourceUtils from "../../resource-utils";
import ResourceWizardSteps from "../../constants/resource-wizard-steps";
import ResourceWizardMobileButton from "../resource-wizard-mobile-buttons/resource-wizard-mobile-buttons";

const ResourceTypeEditStep = ({ isAi = true }) => {
  const {
    localResource,
    imageFile,
    selectedType: storeSelectedType,
  } = useSelector((state) => state.resourceWizardStore);
  const [selectedType, setSelectedType] = useState(null);

  const { activeCommunityTypes } = useSelector((state) => state.communityStore);

  const _updateTypeSelection = (type) => {
    if (selectedType?.id === type?.id) {
      setSelectedType(null);
    } else {
      setSelectedType(type);
    }
  };

  const _onSubmit = () => {
    ResourceUtils.setType(selectedType);
    ResourceUtils.switchStep(ResourceWizardSteps.PREVIEW_WITH_TOPIC_EDIT);
  };

  useEffect(() => {
    if (storeSelectedType) {
      setSelectedType(storeSelectedType);
    }
  }, []);

  return (
    <>
      <div className="innerWrapper">
        <div className="section">
          <div
            className={
              "sectionInnerWrapper step3 addType d-grid" + (isAi ? " ai " : "")
            }
          >
            <div
              className="closeIcon"
              onClick={(e) => ResourceUtils.resetWizard()}
            >
              {ICONS.crossIcon}
            </div>{" "}
            {/* this div will change only */}
            <div className="info">
              <div className="stepTitle">
                🏷️ Select the type of content
              </div>
              <div className="mobileTypeSelector d-block d-md-none">
                <div className="types">
                  {activeCommunityTypes?.map((type, index) => (
                    <TypeFilter
                      title={type?.description}
                      color={type?.color}
                      key={type?.id}
                      index={index}
                      isSelected={selectedType?.id === type?.id}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        _updateTypeSelection(type);
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="stepDesc">
                {isAi ? (
                  <>
                    <div></div>
                    <div className="aiAssistedText">
                      {ICONS.sparkleFilledGradient3} <span>AI assisted</span>
                    </div>
                  </>
                ) : (
                  <div className="mainText">
                    Choose one type from the list of types this community is
                    accepting. Is your recommendation from another type?{" "}
                    <span>Request the admins</span> to create it!
                  </div>
                )}
              </div>
            </div>
            <div className="editableInputArea">
              <div className="imageWrapper">
                <LinkPreviewImage
                  link={localResource?.sourceUrl}
                  staticImageUrl={imageFile}
                  style={{ width: "56px", height: "100%", objectFit: "cover" }}
                />
              </div>

              <div className="right">
                <div className="itemTitleWrapper">
                  <div className="itemTitle">{localResource?.title}</div>
                </div>

                <div className="bottomWrapper">
                  <div className="types d-none d-md-flex">
                    {activeCommunityTypes?.map((type, index) => (
                      <TypeFilter
                        title={type?.description}
                        color={type?.color}
                        key={type?.id}
                        index={index}
                        isSelected={selectedType?.id === type?.id}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          _updateTypeSelection(type);
                        }}
                      />
                    ))}
                    {/* //  */}
                  </div>

                  <div className="buttons">
                    {selectedType ? (
                      <Button
                        // icon={ICONS.arrowRight}
                        // iconPos={"right"}
                        size={"extraSmall"}
                        type={"contrast"}
                        text="Done"
                        onClick={_onSubmit}
                      />
                    ) : (
                      <Button
                        icon={ICONS.circleHollow}
                        iconPos={"left"}
                        size={"extraSmall"}
                        type={"disabled"}
                        text="Select a type"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResourceWizardMobileButton
        stepNumber={1}
        buttonText="Done"
        onClick={() => _onSubmit()}
      />
    </>
  );
};

export default ResourceTypeEditStep;
