import React, { Component, useState, useRef, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ICONS from "../../assets/quester-icons";
import { Player } from "@lottiefiles/react-lottie-player";
import Button from "../../components/buttons/buttons";
import SideBar from "../../components/sidebar/sidebar";
import "./home-page.scss";
import router from "../../shared/top-level-declarations/history";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import ApiCalls from "../../shared/api";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import CommunityUtils from "../../entities/community/community-utils";
import Image from "../../components/core/image/image";
import CommunityIcon from "../../entities/community/components/community-icon/community-icon";
import ActivityRecorder from "../../shared/utils/activity-recorder";
import GeneralHelper from "../../shared/helper-methods/general-helpers";
import DivWithScrollDirectionListener from "../../components/div-with-scroll-direction-listner/div-with-scroll-direction-listner";
import ImagePicker from "../../components/image-picker/image-picker";
import CreateNewCommunityPopup from "../../components/create-new-community-popup/create-new-community-popup";

const CommunityCard = ({
  community: {
    uuid,
    organisationDisplayName,
    orgDescription,
    orgIconImageURL,
    bannerImageURI,
    displayOrder,
    categories,
    membersCount,
    roles,
  },
}) => {
  const [isJoining, setIsJoining] = useState(false);
  const _onClick = (e) => {
    router.navigate(`/c/${uuid}`);
  };
  const _onJoinClick = async (e) => {
    e.stopPropagation();
    if (AuthHelper.isLoggedIn()) {
      try {
        setIsJoining(true);
        await ApiCalls.org.private.joinOrg(uuid);
        await GeneralHelper.sleep(1000);
        setIsJoining(false);
        router.navigate(`/c/${uuid}`);
      } catch (error) {
        console.log("error :>> ", error);
      }
    } else {
      AuthHelper.login();
    }
  };

  return (
    <div className="communityCard" onClick={_onClick}>
      <div className="innerWrapper">
        <div className="topSection">
          <div className="coverImage">
            <img
              src={
                bannerImageURI?.length
                  ? bannerImageURI
                  : require("../../assets/images/cover-pic.png")
              }
              alt=""
            />
          </div>
          <div className="dpImage">
            {/* <img
              src={
                orgIconImageURL?.length
                  ? orgIconImageURL
                  : require("../../assets/images/sushi.png")
              }
              alt=""
            /> */}
            <CommunityIcon orgIconImageURL={orgIconImageURL} />
          </div>
        </div>

        <div className="middleSection">
          <div className="communityName" title={organisationDisplayName}>
            {organisationDisplayName}
          </div>

          <div className="communityDesc" title={orgDescription}>
            {orgDescription}
          </div>
        </div>

        <div className="bottomSection">
          <div className="buttons">
            <div className="members">
              <span className="number">{membersCount}</span>
              {ICONS.usersCommunity}
            </div>
            <Button
              onClick={_onJoinClick}
              disabled={roles?.length || isJoining}
              size={"extraSmall"}
              // icon={ICONS.plus}
              // iconPos={"left"}
              type={"default"}
              text={isJoining ? "Joining" : roles?.length ? "Joined" : "Join"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const { keycloak, initialized } = useKeyCloakSafe();

  const [communities, setCommunities] = useState([]);

  const _fetchCommunities = async () => {
    let fetchedCommunities = [];
    if (initialized) {
      if (keycloak?.authenticated) {
        const { data } = await ApiCalls.org.private.fetchOrgs();
        if (data?.organisations?.length) {
          fetchedCommunities = data.organisations;
        }
      } else {
        const { data } = await ApiCalls.org.public.fetchOrgs();
        if (data?.organisations?.length) {
          fetchedCommunities = data.organisations;
        }
      }
      // Hide community with id "xYyMG49YiBE"
      fetchedCommunities = fetchedCommunities.filter(
        (org) => org.uuid !== "xYyMG49YiBE"
      );
      const sortedCommunities =
        CommunityUtils.sortHomePageCommunities(fetchedCommunities);
      setCommunities(sortedCommunities);
    }
  };

  const _navigateToCreateCommunity = () => {
    router.navigate("/new-community");
  };

  useEffect(() => {
    CommunityUtils.clearStore();
    _fetchCommunities();
  }, [keycloak, initialized]);

  useEffect(() => {
    ActivityRecorder.startRecordingPageActivity({
      pageName: "HomePage",
    });
    return () => {
      ActivityRecorder.stopRecordingPageActivity();
    };
  }, []);

  const [isImagePickerVisible, setIsImagePickerVisible] = useState(false);
  const [showCreateCommunityPopup, setShowCreateCommunityPopup] =
    useState(false);

  return (
    <>
      {/*************************************************** SIDEBAR */}
      {/* <SideBar /> */}
      {/*************************************************** /SIDEBAR */}

      {/*************************************************** MAIN CONTENT */}
      <div className="mainContentWrapper">
        <DivWithScrollDirectionListener
          className="home-page"
          onScrollDown={() => {
            GeneralHelper.updateMobileFooterOnScrollDown();
          }}
          onScrollUp={() => {
            GeneralHelper.updateMobileFooterOnScrollUp();
          }}
        >
          <div className="heroBanner">
            <div className="heroInner">
              <div className="headline">
                Recommendations you trust, <br />
                from communities you love.
              </div>
              <div className="subtitle">
                <span>Explore what your favourite communities </span>

                <div className="vertical-slide-text-container">
                  <div className="vertical-slide-text">
                    <div className="text-item textLove">LOVE 😇</div>
                    <div className="text-item textHate">HATE 😈</div>
                    <div className="text-item textLearn">LEARN FROM 🤓</div>
                    <div className="text-item textLaugh">LAUGH AT 😆</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ************************************** CREATE NEW COMMUNITY */}

          {/* ************************************** /CREATE NEW COMMUNITY */}

          {/* ************************************** CREATE NEW COMMUNITY */}

          {/* ************************************** /CREATE NEW COMMUNITY */}

          <div className="container">
            <div className="communityCardsWrapper">
              {initialized && false ? (
                <>
                  <div className="communityCard addNewCommunityWrapper">
                    <div className="inner">
                      <div className="text">
                        The perfect place to organise all your recommendations;
                        help people find awesome stuff & make money too!
                      </div>

                      <div className="buttons text-center">
                        <Button
                          text={"Create your space"}
                          size={"medium"}
                          type={"default"}
                          icon={ICONS.plus}
                          iconPos={"left"}
                          onClick={(e) => _navigateToCreateCommunity()}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {communities?.map((community, index) => (
                <CommunityCard key={community.uuid} community={community} />
              ))}
            </div>

            {/* should go to create-new-community-popup.jsx */}
            <div
              className={
                "createNewCommunityModal " +
                (showCreateCommunityPopup ? "" : "d-none")
              }
            >
              <div
                className="overlay"
                onClick={(e) => setShowCreateCommunityPopup(false)}
              ></div>
              <div className="inner">
                <div className="bgDesign">
                  <img src={require("../../assets/images/confetti.png")} />
                </div>

                <div className="left">
                  <div className="titleSection">
                    <div className="bigTitle">Create your space.</div>
                    <div className="bigDesc">
                      Your recommendations are valuable! They deserve better
                      than a static Notion doc, spreadsheet, or getting lost in
                      Discord.
                    </div>
                  </div>

                  <div className="benefits">
                    <div className="benefit">
                      <div className="icon">{ICONS.usersCommunity}</div>
                      <div className="texts">
                        <div className="label">Organise</div>
                        <div className="desc">
                          Instantly organise all your recommendations in one
                          beautiful & collaborative space; manage contributions,
                          generate context, and see what's popular.
                        </div>
                      </div>
                    </div>

                    <div className="benefit">
                      <div className="icon">{ICONS.heartOutline}</div>
                      <div className="texts">
                        <div className="label">Grow</div>
                        <div className="desc">
                          Take engagement to the next level - have your
                          community add to, comment, and vote upon your
                          recommendations
                        </div>
                      </div>
                    </div>

                    <div className="benefit">
                      <div className="icon">{ICONS.diamond}</div>
                      <div className="texts">
                        <div className="label">Monetise</div>
                        <div className="desc">
                          Get rewarded for the value of your recommendations;
                          we'll get your space sponsored by leading brands
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <div className="titleSection">
                    <div className="bigTitle">Create your space.</div>
                    <div className="bigDesc">
                      Your recommendations are valuable! They deserve better
                      than a static Notion doc, spreadsheet, or getting lost in
                      Discord.
                    </div>
                  </div>

                  <div className="formWrapper">
                    <div className="form-group">
                      <label>Community Name</label>
                      <input
                        type="text"
                        placeholder="Name of your community"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>Contact email</label>
                      <input
                        type="text"
                        placeholder="Your email address"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>Link to your current database/community</label>
                      <textarea
                        type="text"
                        placeholder="Notion, Sheets, Airtable, Discord or Slack URL"
                        className="form-control"
                      ></textarea>
                    </div>

                    <div className="buttons">
                      <Button
                        size={"medium"}
                        type={"brand"}
                        text={"Apply now"}
                      />
                      <div className="text">Limited slots available</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DivWithScrollDirectionListener>
      </div>
      {/*************************************************** /MAIN CONTENT */}
    </>
  );
};

export default HomePage;
