/* eslint-disable no-unused-expressions */
// import { getKeycloakInstance } from "@react-keycloak/web";

import { jwtDecode } from "jwt-decode";

import router from "../top-level-declarations/history";
import keycloak from "../top-level-providers/keycloak-provider/keycloak";



class AuthHelper {
  static keycloakInstance = null;
  static userInfo = null;
  static getUserDetails = async () => {
    try {
      if (!AuthHelper.isLoggedIn()) {
        return {};
      } else {
        if (AuthHelper.userInfo) {
          return AuthHelper.userInfo;
        } else {
          if (typeof window !== "undefined") {
            const keycloakInstance = keycloak;
            if (keycloakInstance?.loadUserInfo) {
              const userData = await keycloakInstance.loadUserInfo();
              AuthHelper.userInfo = userData; 
              return userData;
            }
          }
        }
      } 
    } catch (error) {}
  };

  static getToken = () => {
    try {
      if (typeof window !== "undefined") {
        const keycloakInstance = keycloak;
        if (keycloakInstance?.token) {
          return keycloakInstance?.token;
        }
      }
    } catch (error) {}
  };

  static checkIfUserHasRoles = (roles) => {
    if (AuthHelper.isLoggedIn()) {
      const token = AuthHelper.getToken();
      const decoded = jwtDecode(token);
      if (decoded?.realm_access?.roles?.length) {
        return roles.some((role) => decoded.realm_access.roles.includes(role));
      }
    }
  };

  static getUserId = () => {
    if (typeof window !== "undefined") {
      let userData = localStorage.getItem("userData");
      if (userData?.length) {
        return JSON.parse(userData).userProfile.uuid;
      }
    }
  };

  static setUserDetails = (userData) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  };

  static init = () => {
    AuthHelper.keycloakInstance = keycloak;
  };

  static isLoggedIn = () => {
    try {
      if (typeof window !== "undefined") {
        const keycloakInstance = keycloak;
        return keycloakInstance?.authenticated;
      }
    } catch (error) {
      console.log("error :>> ", error);
      return false;
    }
  };

  static logout = () => {
    try {
      if (typeof window !== "undefined") {
        const keycloakInstance = keycloak;
        if (keycloakInstance?.logout) {
          // Clear redux only
          localStorage.removeItem('root');
          // localStorage.clear();
          keycloakInstance.logout();
          // setTimeout(() => {
          //   window.location = window.location.origin;
          // }, 50);
          // EventEmitter.dispatch("clear-user-provider");
          // HelperMethods.showLoader(3000);
        }
      }
    } catch (error) {}
  };

  static login = () => {
    try {
      // HelperMethods.showLoader();
      if (typeof window !== "undefined") {
        const keycloakInstance = keycloak;
        if (keycloakInstance?.login) {
            // router.navigate(window.location.pathname);
            keycloakInstance?.login();
          // EventEmitter.dispatch("clear-user-provider");
          // window.location = window.location.origin;
        }
      }
    } catch (error) {
        console.log('error :>> ', error);
    }
  };
}

export default AuthHelper;
