import React, { Component, useEffect } from "react";
import "./notification.scss";
import moment from "moment";
import NotificationUtils from "../../notification-utils";

const Notification = ({ notification, onHideViewer }) => {
  const [formattedNotificationValues, setFormattedNotificationValues] =
    React.useState({});

  const formatNotificationValues = () => {
    let formattedNotificationValues = {};
    formattedNotificationValues.username = notification.actorDisplayName;
    formattedNotificationValues.userimage = notification.actorAvatar;
    formattedNotificationValues.isNew = !notification.read;
    formattedNotificationValues.timeValue = moment(
      notification.createdOn
    ).fromNow();
    setFormattedNotificationValues(formattedNotificationValues);
  };
  useEffect(() => {
    formatNotificationValues();
  }, []);

  if (!formattedNotificationValues) {
    return <></>;
  }
  if (!formattedNotificationValues) {
    return <></>;
  }
  return (
    <>
      <div
        className="noti"
        onClick={(e) => {
          NotificationUtils.onNotificationClick(notification);
          onHideViewer(e);
        }}
      >
        <div className="notiTop">
          <div className="imageWrapper">
            <img src={formattedNotificationValues.userimage} alt="" />
          </div>
          <div className="middle">
            <div className="username">
              @{formattedNotificationValues.username}
            </div>
            <div className="static">replied to your comment on</div>
            <div className="resourceName" title={notification.questTitle}>
              {notification.questTitle}
            </div>
          </div>
          <div className="timestamp">
            {formattedNotificationValues.timeValue}
          </div>
        </div>

        {/* <div className="notiOnlyInMobile">
          replied to your comment on <span>@Studies for IB courses</span>.
        </div> */}

        <div className="notiBottom">
          <div className="inner">{notification.sentMessagePayload}</div>
        </div>
      </div>
    </>
  );
};

export default Notification;
