import React, { useEffect, useState } from "react";
import ICONS from "../../../assets/quester-icons";

// In this component, we will allow to select a photo from the user's local file system. If no photo is selected, we will display a default image. If a photo is selected, we will display the photo. When user will click on the userImage div, we will open the file system dialog. When user will select a photo, we will display the photo and also show a clear button. When user will click on the clear button, we will clear the photo and display the default image.
const PhotoUploader = ({ localFile, onFileChange, onFileClear, imagePath }) => {
  // Don't store in state, use from props
  const [previewImage, setPreviewImage] = useState(null);
  const [onHover, setOnHover] = useState(false);

  useEffect(() => {
    if (localFile) {
      setPreviewImage(URL.createObjectURL(localFile));
    } else if (imagePath) {
      setPreviewImage(imagePath);
    } else {
      setPreviewImage("https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg");
    }
  }, [localFile, imagePath]);

  return (
    <>
      <div
        className="userImage"
        onMouseEnter={() => {
          setOnHover(true);
        }}
        onMouseLeave={() => {
          setOnHover(false);
        }}
        onClick={(e) => {
          document.getElementById("fileInput").click();
        }}
      >
        <img src={previewImage} alt="" />
        {onHover ? <div className="icon">{ICONS.imageIcon}</div> : <></>}
      </div>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={(e) => {
          onFileChange(e.target.files[0]);
        }}
      />
    </>
  );
};

export default PhotoUploader;
