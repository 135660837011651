/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import ICONS from "../../assets/quester-icons";
import SaveButton from "../save-button/save-button";
import Datapoint from "../datapoint/datapoint";
import "./community-item.scss";
import LinkPreviewImage from "../core/link-previewer/link-previewer";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import CommunityUtils from "../../entities/community/community-utils";
import CollectionUtils from "../../entities/collection/collection-utils";
import useResource from "../../shared/hooks/useResource";
import { useSelector } from "react-redux";
import moment from "moment";
import ActivityRecorder from "../../shared/utils/activity-recorder";
import SaveActivities from "../../shared/utils/activity-recorder/activity-helpers/save-activities";
import QuestCommentsHelper from "../../features/quest-comments/quest-comments-helper";
import Button from "../buttons/buttons";
import SponsorUtils from "../../features/sponsorship/sponsor-utils";
import OutsideClickHandler from "react-outside-click-handler";
import AiSummaryViewer from "../../features/resource-ai-summary/components/ai-summary-viewer/ai-summary-viewer";
import QuickAddPopup from "../../features/quick-add-collection-item/components/quick-add-popup/quick-add-popup";
import QuickAddCollectionItemUtils from "../../features/quick-add-collection-item/utils/quick-add-collection-item-utils";

const CommunityItem = ({
  communityItem,
  isMyResource = false,
  onUnfollow = null,
  teamResource = false,
  hideCount = false,
  isQuestItem = false,
  canQuickAdd = false,
  type = null,
}) => {
  const itemRef = useRef(null);
  const { keycloak } = useKeyCloakSafe();
  const { sourceUrl, title, domain, openResource } = useResource({
    resource: communityItem,
    isMyResource,
  });
  const metaStore = useSelector((state) => state.metaStore);

  let isLightMode = true;
  if (metaStore?.isDarkModeEnabled) {
    isLightMode = false;
  }
  const { isRatingExpanded } = useSelector((state) => state.metaStore);
  var ShowDatapoint = communityItem?._voteStat?.length > 0;
  const [datapointExpand, setDatapointExpand] = useState(false);
  const [highlighted, setHighlighted] = useState(false);
  const { isReferenceView, referenceQuestItemId } = useSelector(
    (state) => state.commentStore
  );
  const _toggleSave = (e) => {
    e.stopPropagation();
    if (!keycloak?.authenticated) {
      AuthHelper.login();
      return;
    }
    if (onUnfollow) {
      onUnfollow(e);
      return;
    }
    if (teamResource) {
      CommunityUtils.toggleResourceSave(communityItem);
    } else {
      CollectionUtils.toggleCollectionItemSave(communityItem);
    }
    const activityPayload = {
      entityType: "quest-item",
      entityId: communityItem?.uuid,
      resourceLink: sourceUrl,
      resourceName: communityItem?.title,
    };
    if (communityItem.isFollowed) {
      SaveActivities.addUnsaveItemActivity(activityPayload);
    } else {
      SaveActivities.addSaveItemActivity(activityPayload);
    }
  };

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const _showCommentInReferenceView = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // First expand the comment viewer
    QuestCommentsHelper.showCommentViewer();
    // Then activate the reference mode
    QuestCommentsHelper.activateReferenceMode(communityItem.questItemId);
  };

  const _onClick = () => {
    if (type === "sponsor") {
      SponsorUtils.onSponsorLinkClick("resource");
      return;
    }
    // Open in new tab
    if (!communityItem?.sourceUrl?.length && communityItem?.isPremium) {
      // Premium resource and locked
      // Show modal to purchase
      CollectionUtils.showSubscriptionPopup();
      return;
    }
    ActivityRecorder.recordOpenInNewTab({
      openType: "shortcut",
      // teamUuid: quest.ownerTeamId,
      // orgUuid: quest.owningOrganizationId,
      // questUuid: quest.uuid,
      questItemUuid: communityItem.uuid,
      link: communityItem?.sourceUrl,
    });
    openResource();
  };

  const _scrollToThisItem = () => {
    if (itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
        top: -100,
      });
    }
  };

  useEffect(() => {
    setDatapointExpand(isRatingExpanded);
  }, [isRatingExpanded]);

  useEffect(() => {
    if (
      isReferenceView &&
      referenceQuestItemId === communityItem?.questItemId
    ) {
      setHighlighted(true);
      // Also scroll to this item
      _scrollToThisItem();
    } else {
      setHighlighted(false);
    }
  }, [isReferenceView, referenceQuestItemId]);

  const [showAddToCollectionPopup, setShowAddToCollectionPopup] =
    useState(false);
  const [isAiPopupOpen, setIsAiPopupOpen] = useState(false);

  return (
    <div
      ref={itemRef}
      className={
        "communityItem" +
        (communityItem?.isPremium ? " premium " : " ") +
        (ShowDatapoint && type !== "sponsor" ? "withDatapoints " : " ") + // type condition added for sponsor items
        (datapointExpand ? "datapointExpand " : " ") +
        (highlighted ? "referenceView " : " ") +
        (type ? type : "") +
        " "
      }
      // style={highlighted? {
      //   filter: isLightMode? "brightness(0.8)": "brightness(2)"
      // }: {}}
    >
      <div className="innerWrapper" onClick={_onClick}>
        <div className="imageAndAiWrapper">
          <div className="imageWrapper" title={domain}>
            <LinkPreviewImage
              link={communityItem.sourceUrl}
              staticImageUrl={communityItem?.imageURL}
              style={{ width: "56px", height: "100%", objectFit: "cover" }}
            />
            {communityItem?.isPremium ? (
              ""
            ) : (
              <div className="urlSnippet">
                <span>{domain}</span>
              </div>
            )}

            {communityItem?.isPremium ? (
              <div className="premiumIcon">{ICONS.diamond}</div>
            ) : (
              ""
            )}
          </div>

          <div
            className="aiAnalysisWrapper"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div
              className="aiAnalyseIcon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  !communityItem?.sourceUrl?.length &&
                  communityItem?.isPremium
                ) {
                  CollectionUtils.showSubscriptionPopup();
                } else {
                  setIsAiPopupOpen(true);
                }
              }}
            >
              <Button
                type={"default"}
                size={"extraSmall"}
                icon={ICONS.sparkleFilledGradient}
                iconPos={"left"}
              />
            </div>

            {isAiPopupOpen ? (
              <AiSummaryViewer
                link={communityItem.sourceUrl}
                onHide={() => setIsAiPopupOpen(false)}
                title={communityItem.title}
                voteStat={communityItem._voteStat}
                resourceUuid={communityItem.uuid}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="middle">
          <div className="itemTitle" title={title}>
            {title}
          </div>

          <div className="timeStamp d-block d-md-none">
            {type === "sponsor"
              ? "Sponsored"
              : moment(communityItem?.createdOn).isValid()
              ? moment(communityItem?.createdOn).format("DD MMM YYYY")
              : ""}
          </div>

          <div className="topics d-none d-md-flex">
            {communityItem?.isPremium ? (
              <div className="topic premiumTopic" title="Premium topic">
                <span>Premium</span>
              </div>
            ) : (
              <></>
            )}
            {communityItem?.dtableSchema?.description?.length ? (
              <div
                className={"type "}
                title={communityItem?.dtableSchema?.description}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  CommunityUtils.addToSelectedCommunityTypes(
                    communityItem?.dtableSchema
                  );
                }}
              >
                <div
                  className="circle"
                  style={{
                    backgroundColor: communityItem?.dtableSchema?.color,
                  }}
                ></div>
                <span>{communityItem?.dtableSchema?.description}</span>
              </div>
            ) : (
              <></>
            )}
            {communityItem?.labels?.map((label) => (
              <div
                className="topic"
                key={label.uuid}
                title={label.displayName}
                onClick={(e) => {
                  if (label.uuid.indexOf("static") > -1) {
                    return;
                  }
                  e.stopPropagation();
                  e.preventDefault();
                  CommunityUtils.addToSelectedCommunityLabels(label);
                }}
              >
                {label.displayName}
              </div>
            ))}
          </div>
        </div>

        <div className="right">
          <div className="timeStamp text-end d-none d-md-block">
            {type === "sponsor"
              ? "Sponsored"
              : moment(communityItem?.createdOn).isValid()
              ? moment(communityItem?.createdOn).format("DD MMM YYYY")
              : ""}
          </div>
          <div className="actionButtons">
            {type === "sponsor" ? (
              <Button
                type={"contrast"}
                size={"extraSmall"}
                text={"Visit now"}
                icon={ICONS.arrowRight}
                iconPos={"right"}
              />
            ) : (
              <>
                <SaveButton
                  saveCount={
                    communityItem?.stat?.saveCount
                      ? communityItem.stat.saveCount
                      : communityItem?.saveCount
                  }
                  alreadySaved={communityItem?.isFollowed}
                  onToggle={_toggleSave}
                  hideCount={hideCount}
                />
                {isQuestItem ? (
                  <div
                    className="commentCount"
                    title="Comments on this item"
                    onClick={_showCommentInReferenceView}
                  >
                    <div className="icon">{ICONS.comment}</div>
                    <div className="text">
                      {communityItem?.stat?.commentCount
                        ? communityItem.stat.commentCount
                        : communityItem?.commentCount}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}

            {/* <div className="addToCollectionWrapper d-flex"> */}
            {canQuickAdd ? (
              <div className="addToCollectionWrapper d-flex">
                <Button
                  type={"default"}
                  size={"extraSmall"}
                  // text={"Open"}
                  icon={ICONS.plus}
                  iconPos={"right"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAddToCollectionPopup(true);
                    QuickAddCollectionItemUtils.setActiveResource(
                      communityItem
                    );
                  }}
                />
                {showAddToCollectionPopup ? (
                  <QuickAddPopup
                    onDismiss={(e) => setShowAddToCollectionPopup(false)}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="labelAndTopicsMobileWrapper d-block d-md-none">
        <div className="topics">
          {communityItem?.isPremium ? (
            <div className="topic premiumTopic" title="Premium topic">
              <span>Premium</span>
            </div>
          ) : (
            <></>
          )}
          {communityItem?.dtableSchema?.description?.length ? (
            <div
              className={"type "}
              title={communityItem?.dtableSchema?.description}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                CommunityUtils.addToSelectedCommunityTypes(
                  communityItem?.dtableSchema
                );
              }}
            >
              <div
                className="circle"
                style={{
                  backgroundColor: communityItem?.dtableSchema?.color,
                }}
              ></div>
              <span>{communityItem?.dtableSchema?.description}</span>
            </div>
          ) : (
            <></>
          )}
          {communityItem?.labels?.map((label) => (
            <div
              className="topic"
              key={label.uuid}
              title={label.displayName}
              onClick={(e) => {
                if (label.uuid.indexOf("static") > -1) {
                  return;
                }
                e.stopPropagation();
                e.preventDefault();
                CommunityUtils.addToSelectedCommunityLabels(label);
              }}
            >
              {label.displayName}
            </div>
          ))}
        </div>

        {type === "sponsor" ? (
          <Button
            type={"contrast"}
            size={"extraSmall"}
            text={"Visit now"}
            icon={ICONS.arrowRight}
            iconPos={"right"}
          />
        ) : (
          <></>
        )}
      </div>

      {communityItem?._voteStat?.length && type !== "sponsor" ? ( // type condition added for sponsor items
        <div className="datapointsWrapper">
          <div className="navigation">
            <div className="left" onClick={() => scroll(-120)}>
              {ICONS.chevronLeft}
            </div>
            <div className="right" onClick={() => scroll(120)}>
              {ICONS.chevronLeft}
            </div>
          </div>
          <div className="inner" ref={ref}>
            {communityItem._voteStat.map((voteStat) => {
              return (
                <Datapoint
                  voteStat={voteStat}
                  dtableSchema={communityItem?.dtableSchema}
                  communityItem={communityItem}
                  isQuestItem={isQuestItem}
                  key={voteStat.columnId}
                />
              );
            })}
          </div>
          {communityItem._voteStat?.length > 1 ? (
            <>
              {/* <div
                className="expandButtonWrapper"
                title={
                  (datapointExpand ? "Collapse" : "Expand") +
                  " ratings of this item"
                }
              >
                <div
                  className="expandButton"
                  onClick={() => {
                    setDatapointExpand(!datapointExpand);
                    // if (!isRatingExpanded) {
                    // }
                  }}
                >
                  {ICONS.chevronLeft}
                </div>
              </div> */}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(CommunityItem);
