import cloneDeep from "clone-deep";
import ApiCalls from "../../shared/api";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import { CommunityStoreActions } from "../../stores/community-store/community-store-actions";
import { store } from "../../stores/store";
import VotingUtils from "../../features/voting/voting-utils";
import GeneralHelper from "../../shared/helper-methods/general-helpers";
import SaveActivities from "../../shared/utils/activity-recorder/activity-helpers/save-activities";
import { CollectionStoreActions } from "../../stores/collection-store/collection-store-actions";
import HomePageCommunityPriorityOrder from "../../constants/home-page-community-priority-order";
import ICONS from "../../assets/quester-icons";
import { EventEmitter } from "../../shared/utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";
import ResourceUtils from "../resource/resource-utils";
import router from "../../shared/top-level-declarations/history";

const CommunityUtils = {
  /************* Public methods start here **************/
  initiateCommunityLoad: async (communityId) => {
    // Check if the community is already loaded
    const { communityStore } = store.getState();
    if (communityStore.activeCommunityId !== communityId) {
      // First clear everything
      CommunityUtils._clearActiveCommunityData();
      // Set the community id
      CommunityUtils._setActiveCommunityId(communityId);
      await Promise.all([
        CommunityUtils._loadCommunity(communityId),
        CommunityUtils._loadCommunityLabels(communityId),
        CommunityUtils._loadCommunityTypes(communityId),
      ]);
      // After loading types and labels, check if the route has selected labels and types
      CommunityUtils._checkRouteForSelectedLabelsAndTypes();
      CommunityUtils._loadCommunityMembers(communityId);
      CommunityUtils._loadCommunityResources(communityId);
      CommunityUtils._loadCommunityCollections(communityId);
    }
  },
  loadNextPageCommunityResources: () => {
    const { communityStore } = store.getState();
    // alert('loadNextPageCommunityResources');
    if (communityStore.activeCommunityResourcesPagination.hasMore) {
      CommunityUtils._loadCommunityResources(communityStore.activeCommunityId);
    }
  },
  loadNextPageCommunityCollections: () => {
    const { communityStore } = store.getState();
    if (communityStore.activeCommunityCollectionsPagination.hasMore) {
      CommunityUtils._loadCommunityCollections(
        communityStore.activeCommunityId
      );
    }
  },
  joinActiveOrg: async () => {
    const { communityStore } = store.getState();
    try {
      const { data } = await ApiCalls.org.private.joinOrg(
        communityStore.activeCommunityId
      );
      // Add roles array in active community
      const activeCommunity = {
        ...communityStore.activeCommunity,
        roles: data.roles,
      };
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunity(activeCommunity)
      );
    } catch (error) {
      console.log(error);
    }
  },
  leaveActiveOrg: async () => {
    const { communityStore } = store.getState();
    try {
      await ApiCalls.org.private.leaveOrg(communityStore.activeCommunityId);
      // Remove roles array in active community
      const activeCommunity = {
        ...communityStore.activeCommunity,
        roles: [],
      };
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunity(activeCommunity)
      );
    } catch (error) {
      console.log(error);
    }
  },
  toggleCollectionSort: (sortField) => {
    // On new sort field, set the sort order to asc
    // On same sort field, toggle the sort order
    const { communityStore } = store.getState();
    const { activeCommunityCollectionsSort } = cloneDeep(communityStore);
    if (activeCommunityCollectionsSort.sortField === sortField) {
      activeCommunityCollectionsSort.sortOrder =
        activeCommunityCollectionsSort.sortOrder === "ASC" ? "DESC" : "ASC";
    } else {
      activeCommunityCollectionsSort.sortField = sortField;
      activeCommunityCollectionsSort.sortOrder = "ASC";
    }
    store.dispatch(
      CommunityStoreActions.staticActions.setActiveCommunityCollectionsSort(
        activeCommunityCollectionsSort
      )
    );
    // Reset and clear collections
    CommunityUtils._clearAndResetCollections();
    // Activate collections loader
    // It's needed so we don't show "No collections found" message
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
    );
    // Load collections
    CommunityUtils._loadCommunityCollections();
  },
  toggleCollectionSave: async (collection) => {
    // Get collection from store
    const { communityStore } = store.getState();
    const { activeCommunityCollections } = cloneDeep(communityStore);
    // Find the collection in the store
    const collectionIndex = activeCommunityCollections.findIndex(
      (item) => item.uuid === collection.uuid
    );
    // If found
    // First update the collection in the store
    if (collectionIndex > -1) {
      activeCommunityCollections[collectionIndex].isFollowed =
        !collection.isFollowed;
      // Increase or decrease the follow count
      if (!activeCommunityCollections[collectionIndex].stat) {
        activeCommunityCollections[collectionIndex].stat = {
          saveCount: 0,
        };
      }
      if (activeCommunityCollections[collectionIndex].isFollowed) {
        // activeCommunityCollections[collectionIndex].saveCount =
        //   activeCommunityCollections[collectionIndex].saveCount + 1;
        activeCommunityCollections[collectionIndex].stat.saveCount =
          parseInt(activeCommunityCollections[collectionIndex].stat.saveCount) +
          1;
      } else {
        // activeCommunityCollections[collectionIndex].saveCount =
        //   activeCommunityCollections[collectionIndex].saveCount - 1;
        activeCommunityCollections[collectionIndex].stat.saveCount =
          parseInt(activeCommunityCollections[collectionIndex].stat.saveCount) -
          1;
      }
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityCollections(
          activeCommunityCollections
        )
      );
      try {
        if (activeCommunityCollections[collectionIndex].isFollowed) {
          // Follow
          await ApiCalls.quest.private.followQuest(collection.uuid);
          GeneralHelper.showToast({
            primaryText: "New collection saved in your profile",
            secondaryText: "Click here to check your saved collections",
            navigateTo: "/my-profile?tab=saved-collections",
            icon: ICONS.heartSolid,
          });
          SaveActivities.addSaveCollectionActivity({
            entityType: "quest",
            entityId: collection.uuid,
            collectionName: collection.listname,
          });
        } else {
          // Unfollow
          await ApiCalls.quest.private.unfollowQuest(collection.uuid);
          // GeneralHelper.showToast({
          //   primaryText:
          //     "You have removed this collection from your saved collections",
          //   secondaryText: "Click here to view your saved collections",
          //   navigateTo: "/my-profile?tab=saved-collections",
          // });
          SaveActivities.addUnsaveCollectionActivity({
            entityType: "quest",
            entityId: collection.uuid,
            collectionName: collection.listname,
          });
        }
        // In case of success, update the collection store also if the active collection is same
        const { collectionStore } = store.getState();
        const { activeCollection } = cloneDeep(collectionStore);
        if (activeCollection.uuid === collection.uuid) {
          activeCollection.isFollowed = !collection.isFollowed;
          if (activeCollection.isFollowed) {
            activeCollection.followersCount =
              activeCollection.followersCount + 1;
          } else {
            activeCollection.followersCount =
              activeCollection.followersCount - 1;
          }
          store.dispatch(
            CollectionStoreActions.staticActions.setActiveCollection(
              activeCollection
            )
          );
        }
      } catch (error) {
        console.log(error);
        // Revert
        const revertActiveCommunityCollections = cloneDeep(
          activeCommunityCollections
        );
        revertActiveCommunityCollections[collectionIndex].isFollowed =
          collection.isFollowed;
        // revertActiveCommunityCollections[collectionIndex].saveCount =
        //   collection.saveCount;
        if (!revertActiveCommunityCollections[collectionIndex].stat) {
          revertActiveCommunityCollections[collectionIndex].stat = {
            saveCount: 0,
          };
        }

        store.dispatch(
          CommunityStoreActions.staticActions.setActiveCommunityCollections(
            revertActiveCommunityCollections
          )
        );
      }
    }
  },
  toggleResourceSave: async (resource) => {
    // Get resource from store
    const { communityStore } = store.getState();
    const { activeCommunityResources } = cloneDeep(communityStore);
    // Find the resource in the store
    const resourceIndex = activeCommunityResources.findIndex(
      (item) => item.uuid === resource.uuid
    );
    // If found
    // First update the resource in the store
    if (resourceIndex > -1) {
      activeCommunityResources[resourceIndex].isFollowed = !resource.isFollowed;
      // Increase or decrease the follow count
      if (activeCommunityResources[resourceIndex].isFollowed) {
        if (
          activeCommunityResources[resourceIndex].stat &&
          activeCommunityResources[resourceIndex].stat.hasOwnProperty(
            "saveCount"
          )
        ) {
          activeCommunityResources[resourceIndex].stat.saveCount =
            activeCommunityResources[resourceIndex].stat.saveCount + 1;
        } else {
          activeCommunityResources[resourceIndex].saveCount =
            activeCommunityResources[resourceIndex].saveCount + 1;
        }
      } else {
        if (
          activeCommunityResources[resourceIndex].stat &&
          activeCommunityResources[resourceIndex].stat.hasOwnProperty(
            "saveCount"
          )
        ) {
          activeCommunityResources[resourceIndex].stat.saveCount =
            activeCommunityResources[resourceIndex].stat.saveCount - 1;
        } else {
          activeCommunityResources[resourceIndex].saveCount =
            activeCommunityResources[resourceIndex].saveCount - 1;
        }
      }
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityResources(
          activeCommunityResources
        )
      );
      try {
        if (activeCommunityResources[resourceIndex].isFollowed) {
          // Follow
          await ApiCalls.org.private.toggleCommunityResourceSave(resource.uuid);
          GeneralHelper.showToast({
            primaryText: "New item saved in your profile",
            secondaryText: "Click here to check your liked items",
            navigateTo: "/my-profile?tab=saved-resources",
            icon: ICONS.heartSolid,
          });
        } else {
          // Unfollow
          await ApiCalls.org.private.toggleCommunityResourceSave(resource.uuid);
        }
      } catch (error) {
        console.log(error);
        // Revert
        activeCommunityResources[resourceIndex].isFollowed =
          !resource.isFollowed;
        store.dispatch(
          CommunityStoreActions.staticActions.setActiveCommunityResources(
            activeCommunityResources
          )
        );
      }
    }
  },
  clearStore: () => {
    CommunityUtils._clearActiveCommunityData();
  },
  addToSelectedCommunityLabels: (label) => {
    const { communityStore } = store.getState();
    const { selectedCommunityLabels } = cloneDeep(communityStore);
    // Check if the label is already selected
    const labelIndex = selectedCommunityLabels.findIndex(
      (item) => item.uuid === label.uuid
    );
    if (labelIndex === -1) {
      selectedCommunityLabels.push(label);
      store.dispatch(
        CommunityStoreActions.staticActions.setSelectedCommunityLabels(
          selectedCommunityLabels
        )
      );
      CommunityUtils._updateRouteWithSelectedLabelsAndTypes();
      // Load collection and resources based on selected labels
      // Reset pagination
      CommunityUtils._resetResourcePagination();
      CommunityUtils._clearResources();
      CommunityUtils._clearAndResetCollections();
      // Activate resources loader
      // It's needed so we don't show "No resources found" message
      store.dispatch(
        CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
          true
        )
      );
      // Activate collections loader
      // It's needed so we don't show "No collections found" message
      store.dispatch(
        CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
      );
      CommunityUtils._loadCommunityCollections();
      CommunityUtils._loadCommunityResources();
    }
  },
  removeFromSelectedCommunityLabels: (label) => {
    const { communityStore } = store.getState();
    const { selectedCommunityLabels } = cloneDeep(communityStore);
    const labelIndex = selectedCommunityLabels.findIndex(
      (item) => item.uuid === label.uuid
    );
    if (labelIndex > -1) {
      selectedCommunityLabels.splice(labelIndex, 1);
      store.dispatch(
        CommunityStoreActions.staticActions.setSelectedCommunityLabels(
          selectedCommunityLabels
        )
      );
    }
    CommunityUtils._updateRouteWithSelectedLabelsAndTypes();
    // Load collection and resources based on selected labels
    CommunityUtils._resetResourcePagination();
    CommunityUtils._clearResources();
    CommunityUtils._clearAndResetCollections();
    // Activate resources loader
    // It's needed so we don't show "No resources found" message
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
        true
      )
    );
    // Activate collections loader
    // It's needed so we don't show "No collections found" message
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
    );
    CommunityUtils._loadCommunityCollections();
    CommunityUtils._loadCommunityResources();
  },
  addToSelectedCommunityTypes: (type) => {
    const { communityStore } = store.getState();
    const { selectedCommunityTypes = [] } = cloneDeep(communityStore);
    // Check if the label is already selected
    const labelIndex = selectedCommunityTypes.findIndex(
      (item) => item.id === type.id
    );
    if (labelIndex === -1) {
      selectedCommunityTypes.push(type);
      store.dispatch(
        CommunityStoreActions.staticActions.setSelectedCommunityTypes(
          selectedCommunityTypes
        )
      );
      CommunityUtils._updateRouteWithSelectedLabelsAndTypes();
      // Load collection and resources based on selected labels
      // Reset pagination
      CommunityUtils._resetResourcePagination();
      CommunityUtils._clearResources();
      CommunityUtils._clearAndResetCollections();

      // Activate resources loader
      // It's needed so we don't show "No resources found" message
      store.dispatch(
        CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
          true
        )
      );
      // Activate collections loader
      // It's needed so we don't show "No collections found" message
      store.dispatch(
        CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
      );
      CommunityUtils._loadCommunityCollections();
      CommunityUtils._loadCommunityResources();
    }
  },
  removeFromSelectedCommunityTypes: (type) => {
    const { communityStore } = store.getState();
    const { selectedCommunityTypes } = cloneDeep(communityStore);
    const labelIndex = selectedCommunityTypes.findIndex(
      (item) => item.id === type.id
    );
    if (labelIndex > -1) {
      selectedCommunityTypes.splice(labelIndex, 1);
      store.dispatch(
        CommunityStoreActions.staticActions.setSelectedCommunityTypes(
          selectedCommunityTypes
        )
      );
    }
    CommunityUtils._updateRouteWithSelectedLabelsAndTypes();
    // Load collection and resources based on selected labels
    CommunityUtils._resetResourcePagination();
    CommunityUtils._clearResources();
    CommunityUtils._clearAndResetCollections();
    // Activate resources loader
    // It's needed so we don't show "No resources found" message
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
        true
      )
    );
    // Activate collections loader
    // It's needed so we don't show "No collections found" message
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
    );
    CommunityUtils._loadCommunityCollections();
    CommunityUtils._loadCommunityResources();
  },
  updateVoteLocally: ({ teamResourceUuid, updatedDrowStats, myDRow }) => {
    // Get resource from store
    const { communityStore } = store.getState();
    const { activeCommunityResources } = cloneDeep(communityStore);
    // Find the resource in the store
    const resourceIndex = activeCommunityResources.findIndex(
      (item) => item.uuid === teamResourceUuid
    );
    if (resourceIndex > -1) {
      // From drow stats, generate voteStat
      const updatedVoteStat = VotingUtils.prepareFormattedVoteData({
        dtableSchema: activeCommunityResources[resourceIndex].dtableSchema,
        drowStats: updatedDrowStats,
        teamResourceUuid,
        myDRow,
      });
      // Update the resource in the store
      activeCommunityResources[resourceIndex]._voteStat = updatedVoteStat;
      activeCommunityResources[resourceIndex].drowStats = updatedDrowStats;
      activeCommunityResources[resourceIndex].myDRow = myDRow;

      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityResources(
          activeCommunityResources
        )
      );
    }
  },
  checkIfSubscribed: () => {
    // Based on roles, check if has a role other than "org_user"
    const { communityStore } = store.getState();
    const { activeCommunity } = communityStore;

    let hasSubscribed = false;
    if (activeCommunity?.roles?.length) {
      for (let role of activeCommunity.roles) {
        if (role !== "org_user") {
          hasSubscribed = true;
        }
      }
    }
    return hasSubscribed;
  },
  checkIfSubscribedFromServer: async () => {
    let hasSubscribed = false;
    // Call the server to check if subscribed
    try {
      const { activeCommunityId } = store.getState().communityStore;
      if (AuthHelper.isLoggedIn()) {
        const { data } = await ApiCalls.org.private.fetchOrgById(
          activeCommunityId
        );
        // Check if has a role other than "org_user"
        if (data?.roles?.length > 0) {
          hasSubscribed = data.roles.some((role) => role.role !== "org_user");
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    return hasSubscribed;
  },
  sortHomePageCommunities: (communities) => {
    // If the user is not logged in, then place the following communitues at the top of the list in same order as in the array
    // HomePageCommunityPriorityOrder contains the uuids of the communities
    // If the user is logged in, no need to sort
    if (!AuthHelper.isLoggedIn()) {
      let sortedCommunities = [];
      const otherCommunities = [];
      for (let uuid of HomePageCommunityPriorityOrder) {
        const community = communities.find((item) => item.uuid === uuid);
        if (community) {
          sortedCommunities.push(community);
        }
      }
      // Now add the rest of the communities
      for (let community of communities) {
        if (!sortedCommunities.find((item) => item.uuid === community.uuid)) {
          otherCommunities.push(community);
        }
      }
      return [...sortedCommunities, ...otherCommunities];
    } else {
      return communities;
    }
  },
  setActiveTab: (tab) => {
    store.dispatch(CommunityStoreActions.staticActions.setActiveTab(tab));
  },
  getTypeByName: (typeName) => {
    const { communityStore } = store.getState();
    const { activeCommunityTypes } = communityStore;
    return activeCommunityTypes.find(
      (type) => type.description.trim().indexOf(typeName?.trim()) > -1
    );
  },
  getTopicByName: (topicName) => {
    const { communityStore } = store.getState();
    const { activeCommunityLabels } = communityStore;
    const topicUniqueName = ResourceUtils._generateTopicUniqueName(topicName);
    return activeCommunityLabels.find(
      (label) => label.uniqueName === topicUniqueName
    );
  },
  getActiveCommunityId: () => {
    const { communityStore } = store.getState();
    return communityStore.activeCommunityId;
  },
  checkIfHasModeratorAccess: () => {
    const { communityStore } = store.getState();
    const { activeCommunity } = communityStore;
    if (activeCommunity?.roles?.length) {
      const allowedRoles = [
        "org_owner",
        "team_owner",
        "org_admin",
        "team_admin",
        "org_moderator",
        "org_expert_user",
      ];
      const hasCurationAccess = activeCommunity.roles.some((role) => {
        return allowedRoles.includes(role);
      });
      return hasCurationAccess;
    }
  },
  checkIfAMember: () => {
    // At least one role (any)
    const { communityStore } = store.getState();
    const { activeCommunity } = communityStore;
    if (activeCommunity?.roles?.length) {
      return true;
    }
  },
  checkIfOwner: () => {
    const { communityStore } = store.getState();
    const { activeCommunity } = communityStore;
    if (activeCommunity?.roles?.length && activeCommunity.roles.includes("org_owner")) {
      return true;
    } 
  },
  checkIfOrgModAndAbove: () => {
    const { communityStore } = store.getState();
    const { activeCommunity } = communityStore;
    if (activeCommunity?.roles?.length) {
      const allowedRoles = [
        "org_owner",
        "org_admin",
        "org_moderator",
      ];
      const hasCurationAccess = activeCommunity.roles.some((role) => {
        return allowedRoles.includes(role);
      });
      return hasCurationAccess;
    }
  },
  showCommunityAddResourceWizard: () => {
    EventEmitter.dispatch(SiteWiseEvents.SHOW_RESOURCE_ADD_WIZARD);
  },
  showAdminPage: () => {
    const { communityStore } = store.getState();
    const { activeCommunity } = communityStore;
    router.navigate(`/c/${activeCommunity.uuid}/admin`);
  },
  /************* Public methods end here **************/
  /************* Private methods start here **************/
  _clearActiveCommunityData: () => {
    store.dispatch(
      CommunityStoreActions.staticActions.clearActiveCommunityData()
    );
  },
  _setActiveCommunityId: (communityId) => {
    store.dispatch(
      CommunityStoreActions.staticActions.setActiveCommunityId(communityId)
    );
  },
  _loadCommunityCollections: async (communityId = null) => {
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
    );
    // Get selected labels
    const selectedLabelIds = CommunityUtils._getSelectedLabelIds();
    const selectedTypeIds = CommunityUtils._getSelectedTypeIds();

    try {
      const { communityStore } = store.getState();
      let {
        activeCommunityId,
        activeCommunityCollections,
        activeCommunityCollectionsPagination,
        activeCommunityCollectionsSort,
      } = communityStore;
      let communityIdToUse = activeCommunityId;
      if (communityId) {
        communityIdToUse = communityId;
      }
      let sortBy = ""; // ex: -${activeCommunityCollectionsSort.sortField} / ${activeCommunityCollectionsSort.sortField}
      if (activeCommunityCollectionsSort.sortField) {
        if (activeCommunityCollectionsSort.sortOrder === "ASC") {
          sortBy = `%2B${activeCommunityCollectionsSort.sortField}`;
        } else {
          sortBy = `-${activeCommunityCollectionsSort.sortField}`;
        }
      }
      let response = {};
      if (AuthHelper.isLoggedIn()) {
        if (CommunityUtils.checkIfSubscribed()) {
          const { data } = await ApiCalls.org.premium.fetchOrgCollections(
            communityIdToUse,
            selectedLabelIds,
            activeCommunityCollectionsPagination.pageSize,
            activeCommunityCollectionsPagination.pageNo,
            selectedTypeIds,
            sortBy
          );
          response = data;
        } else {
          const { data } = await ApiCalls.org.private.fetchOrgCollections(
            communityIdToUse,
            selectedLabelIds,
            activeCommunityCollectionsPagination.pageSize,
            activeCommunityCollectionsPagination.pageNo,
            selectedTypeIds,
            sortBy
          );
          response = data;
        }
      } else {
        const { data } = await ApiCalls.org.public.fetchOrgCollections(
          communityIdToUse,
          selectedLabelIds,
          activeCommunityCollectionsPagination.pageSize,
          activeCommunityCollectionsPagination.pageNo,
          selectedTypeIds,
          sortBy
        );
        response = data;
      }
      const updatedPagination = {
        ...activeCommunityCollectionsPagination,
      };
      if (response?.quests?.length > 0) {
        activeCommunityCollections = [
          ...activeCommunityCollections,
          ...response.quests,
        ];
        store.dispatch(
          CommunityStoreActions.staticActions.setActiveCommunityCollections(
            activeCommunityCollections
          )
        );
        // Update pagination
        updatedPagination.totalElements = response.totalElements;
        if (response?.nextPage !== null) {
          updatedPagination.pageNo = updatedPagination.pageNo + 1;
        } else {
          updatedPagination.hasMore = false;
        }
      } else {
        updatedPagination.hasMore = false;
      }
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityCollectionsPagination(
          updatedPagination
        )
      );
    } catch (error) {
      console.log(error);
    }
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(false)
    );
  },
  _loadCommunityLabels: async (communityId) => {
    try {
      let response = {};
      if (AuthHelper.isLoggedIn()) {
        const { data } = await ApiCalls.org.private.fetchLabelsByOrgId(
          communityId
        );
        response = data;
      } else {
        const { data } = await ApiCalls.org.public.fetchLabelsByOrgId(
          communityId
        );
        response = data;
      }
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityLabels(
          response.labels
        )
      );
    } catch (error) {
      console.log(error);
    }
  },
  _loadCommunityTypes: async (communityId) => {
    try {
      let response = {};
      if (AuthHelper.isLoggedIn()) {
        const { data } = await ApiCalls.org.private.fetchOrgTypes(communityId);
        response = data;
      } else {
        const { data } = await ApiCalls.org.public.fetchTypesByOrgId(
          communityId
        );
        response = data;
      }
      // Add colors to types
      response.types.forEach((type) => {
        type.color = CommunityUtils._generateColorCodeFromText(type.name);
      });
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityTypes(
          response.types
        )
      );
    } catch (error) {
      console.log(error);
    }
  },
  _loadCommunityMembers: async (communityId) => {
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityMembersLoaderStatus(
        true
      )
    );
    try {
      let response = {};
      if (AuthHelper.isLoggedIn()) {
        const { data } = await ApiCalls.org.private.fetchOrgMembers(
          communityId
        );
        response = data;
        store.dispatch(
          CommunityStoreActions.staticActions.setActiveCommunityMembers(
            response
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityMembersLoaderStatus(
        false
      )
    );
  },
  _loadCommunityResources: async (communityId = null) => {
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
        true
      )
    );
    try {
      const { communityStore } = store.getState();
      let {
        activeCommunityResourcesPagination,
        activeCommunityId,
        activeCommunityResources,
      } = communityStore;
      let communityIdToUse = activeCommunityId;
      if (communityId) {
        communityIdToUse = communityId;
      }
      let response = {
        premiumResources: [],
        nonPremiumResources: [],
        totalElements: 0,
        nextPage: null,
      };

      const [premiumResources, nonPremiumResources] = await Promise.allSettled([
        CommunityUtils._fetchPremiumResources(communityIdToUse),
        CommunityUtils._fetchNonPremiumResources(communityIdToUse),
      ]);

      if (
        premiumResources.status === "fulfilled" &&
        premiumResources.value.communityItems.length > 0
      ) {
        const premiumResourcesWithStats =
          premiumResources.value.communityItems.map((resource) => ({
            ...resource,
            _voteStat: VotingUtils.prepareFormattedVoteData({
              dtableSchema: resource.dtableSchema,
              drowStats: resource.drowStats,
              teamResourceUuid: resource.uuid,
              myDRow: resource.myDRow,
            }),
          }));
        response.premiumResources = premiumResourcesWithStats;
      }
      if (
        nonPremiumResources.status === "fulfilled" &&
        nonPremiumResources.value.communityItems.length > 0
      ) {
        const nonPremiumResourcesWithStats =
          nonPremiumResources.value.communityItems.map((resource) => ({
            ...resource,
            _voteStat: VotingUtils.prepareFormattedVoteData({
              dtableSchema: resource.dtableSchema,
              drowStats: resource.drowStats,
              teamResourceUuid: resource.uuid,
              myDRow: resource.myDRow,
            }),
          }));
        response.nonPremiumResources = nonPremiumResourcesWithStats;
        response.totalElements = nonPremiumResources.value.totalElements;
        response.nextPage = nonPremiumResources.value.nextPage;
      }

      let updatedPagination = {
        ...activeCommunityResourcesPagination,
      };

      const combinedResources = CommunityUtils._combineResources(
        response.premiumResources,
        response.nonPremiumResources
      );

      // Add colors to dtableSchema and map resourceSaveCount to saveCount
      combinedResources.forEach((resource) => {
        if (resource?.dtableSchema?.name?.length) {
          resource.dtableSchema.color =
            CommunityUtils._generateColorCodeFromText(
              resource.dtableSchema.name
            );
        }
        // Map resourceSaveCount to saveCount
        if (!resource.stat) {
          resource.stat = {
            saveCount: 0,
          };
        }
        if (resource.stat?.resourceSaveCount) {
          resource.stat.saveCount = resource.stat.resourceSaveCount;
        }
        // Then convert to int
        resource.stat.saveCount = parseInt(resource.stat.saveCount);
      });

      if (combinedResources?.length > 0) {
        activeCommunityResources = [
          ...activeCommunityResources,
          ...combinedResources,
        ];

        store.dispatch(
          CommunityStoreActions.staticActions.setActiveCommunityResources(
            activeCommunityResources
          )
        );
        // Update pagination
        updatedPagination.totalElements = response.totalElements;
        if (response?.nextPage !== null) {
          updatedPagination.pageNo = updatedPagination.pageNo + 1;
        } else {
          updatedPagination.hasMore = false;
        }
      } else {
        updatedPagination.hasMore = false;
      }
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunityResourcesPagination(
          updatedPagination
        )
      );
    } catch (error) {
      console.log(error);
    }
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
        false
      )
    );
  },
  _loadCommunity: async (communityId) => {
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityLoaderStatus(true)
    );
    try {
      let response = {};
      if (AuthHelper.isLoggedIn()) {
        try {
          const { data } = await ApiCalls.org.private.fetchOrgById(communityId);
          response = data;
        } catch (error) {
          const { data } = await ApiCalls.org.public.fetchOrgById(communityId);
          response = data;
        }
      } else {
        const { data } = await ApiCalls.org.public.fetchOrgById(communityId);
        response = data;
      }
      store.dispatch(
        CommunityStoreActions.staticActions.setActiveCommunity(response)
      );
    } catch (error) {
      console.log(error);
    }
    store.dispatch(
      CommunityStoreActions.staticActions.toggleCommunityLoaderStatus(false)
    );
  },
  _getSelectedLabelIds: () => {
    const { communityStore } = store.getState();
    const { selectedCommunityLabels } = communityStore;
    let selectedLabelIds = "";
    selectedCommunityLabels.forEach((label) => {
      selectedLabelIds = selectedLabelIds + label.uuid + ",";
    });
    return selectedLabelIds;
  },
  _getSelectedTypeIds: () => {
    const { communityStore } = store.getState();
    const { selectedCommunityTypes } = communityStore;
    let selectedTypeIds = "";
    selectedCommunityTypes.forEach((type) => {
      selectedTypeIds = selectedTypeIds + type.id + ",";
    });
    return selectedTypeIds;
  },
  _resetResourcePagination: () => {
    store.dispatch(
      CommunityStoreActions.staticActions.setActiveCommunityResourcesPagination(
        {
          pageNo: 0,
          pageSize: 15,
          hasMore: true,
          totalElements: 0,
        }
      )
    );
  },
  _clearResources: () => {
    store.dispatch(
      CommunityStoreActions.staticActions.setActiveCommunityResources([])
    );
  },
  _clearAndResetCollections: () => {
    store.dispatch(
      CommunityStoreActions.staticActions.setActiveCommunityCollections([])
    );
    store.dispatch(
      CommunityStoreActions.staticActions.setActiveCommunityCollectionsPagination(
        {
          pageNo: 0,
          pageSize: 15,
          hasMore: true,
          totalElements: 0,
        }
      )
    );
  },
  _fetchNonPremiumResources: async (communityIdToUse) => {
    let response = {};
    const { communityStore } = store.getState();
    let { activeCommunityResourcesPagination } = communityStore;
    const selectedLabelIds = CommunityUtils._getSelectedLabelIds();
    const selectedTypeIds = CommunityUtils._getSelectedTypeIds();

    if (AuthHelper.isLoggedIn()) {
      if (CommunityUtils.checkIfSubscribed()) {
        const { data } = await ApiCalls.org.private.fetchOrgResources(
          communityIdToUse,
          activeCommunityResourcesPagination.pageSize,
          activeCommunityResourcesPagination.pageNo,
          selectedLabelIds,
          selectedTypeIds,
          "NON_PREMIUM"
        );
        response = data;
      } else {
        const { data } = await ApiCalls.org.private.fetchOrgResources(
          communityIdToUse,
          activeCommunityResourcesPagination.pageSize,
          activeCommunityResourcesPagination.pageNo,
          selectedLabelIds,
          selectedTypeIds,
          "NON_PREMIUM"
        );
        response = data;
      }
    } else {
      const { data } = await ApiCalls.org.public.fetchOrgResources(
        communityIdToUse,
        activeCommunityResourcesPagination.pageSize,
        activeCommunityResourcesPagination.pageNo,
        selectedLabelIds,
        selectedTypeIds,
        "NON_PREMIUM"
      );
      response = data;
    }
    return response;
  },
  _fetchPremiumResources: async (communityIdToUse) => {
    let response = {};
    const { communityStore } = store.getState();
    let { activeCommunityResourcesPagination } = communityStore;
    const selectedLabelIds = CommunityUtils._getSelectedLabelIds();
    const selectedTypeIds = CommunityUtils._getSelectedTypeIds();

    if (AuthHelper.isLoggedIn()) {
      if (CommunityUtils.checkIfSubscribed()) {
        const { data } = await ApiCalls.org.private.fetchOrgResources(
          communityIdToUse,
          activeCommunityResourcesPagination.pageSize - 25,
          activeCommunityResourcesPagination.pageNo,
          selectedLabelIds,
          selectedTypeIds,
          "PREMIUM"
        );
        response = data;
      } else {
        const { data } = await ApiCalls.org.private.fetchOrgResources(
          communityIdToUse,
          activeCommunityResourcesPagination.pageSize - 25,
          activeCommunityResourcesPagination.pageNo,
          selectedLabelIds,
          selectedTypeIds,
          "PREMIUM"
        );
        response = data;
      }
    } else {
      const { data } = await ApiCalls.org.public.fetchOrgResources(
        communityIdToUse,
        activeCommunityResourcesPagination.pageSize - 25,
        activeCommunityResourcesPagination.pageNo,
        selectedLabelIds,
        selectedTypeIds,
        "PREMIUM"
      );
      response = data;
    }
    // Need to set uuid for these resources from
    response.communityItems = response.communityItems.map((resource) => {
      // First check if uuid is not available, then set it
      if (!resource.uuid && resource.organisationResourceId) {
        resource.uuid = resource.organisationResourceId;
      }
      return resource;
    });
    return response;
  },
  _combineResources: (premiumResources, nonPremiumResources) => {
    // Combine in such a way, that premium resources are at random intervals (3-6 non premium resources and then 1 premium resource)
    let combinedResources = [];
    let premiumIndex = 0;
    let nonPremiumIndex = 0;
    let premiumResourcesLength = premiumResources.length;
    let nonPremiumResourcesLength = nonPremiumResources.length;
    while (premiumIndex < premiumResourcesLength) {
      let randomNonPremiumCount = CommunityUtils._getRandomInt(3, 6);
      for (let i = 0; i < randomNonPremiumCount; i++) {
        if (nonPremiumIndex < nonPremiumResourcesLength) {
          combinedResources.push(nonPremiumResources[nonPremiumIndex]);
          nonPremiumIndex++;
        }
      }
      if (premiumIndex < premiumResourcesLength) {
        combinedResources.push(premiumResources[premiumIndex]);
        premiumIndex++;
      }
    }
    while (nonPremiumIndex < nonPremiumResourcesLength) {
      combinedResources.push(nonPremiumResources[nonPremiumIndex]);
      nonPremiumIndex++;
    }
    return combinedResources;
  },
  _getRandomInt: (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  _generateColorCodeFromText: (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash); // Create hash from string
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff; // Extract color component
      color += ("00" + value.toString(16)).slice(-2); // Convert to hex and add to color
    }
    return color;
  },
  _updateRouteWithSelectedLabelsAndTypes: () => {
    const { communityStore } = store.getState();
    const { selectedCommunityLabels, selectedCommunityTypes } = communityStore;
    let labels = "";
    let types = "";
    selectedCommunityLabels.forEach((label) => {
      labels = labels + label.uuid + ",";
    });
    // Remove the last comma
    labels = labels.slice(0, -1);
    selectedCommunityTypes.forEach((type) => {
      types = types + type.id + ",";
    });
    // Remove the last comma
    types = types.slice(0, -1);
    // Whatever the route is, update it with selected labels and types
    const currentPath = window.location.pathname;
    let updatedPath = currentPath;
    if (labels.length > 0) {
      updatedPath = `${updatedPath}?labels=${labels}`;
    }
    if (types.length > 0) {
      if (labels.length === 0) {
        updatedPath = `${updatedPath}?types=${types}`;
      } else {
        updatedPath = `${updatedPath}&types=${types}`;
      }
    }
    window.history.replaceState({}, "", updatedPath);
  },
  _checkRouteForSelectedLabelsAndTypes: () => {
    // Check if the route has selected labels and types
    // If yes, then update the store and load resources and collections
    const currentPath = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const labels = urlParams.get("labels");
    const types = urlParams.get("types");
    if (labels) {
      const labelIds = labels.split(",");
      const { communityStore } = store.getState();
      const { activeCommunityLabels } = communityStore;
      const selectedLabels = activeCommunityLabels.filter((label) =>
        labelIds.includes(label.uuid)
      );
      store.dispatch(
        CommunityStoreActions.staticActions.setSelectedCommunityLabels(
          selectedLabels
        )
      );
    }
    if (types) {
      const typeIds = types.split(",");
      const { communityStore } = store.getState();
      const { activeCommunityTypes } = communityStore;
      const selectedTypes = activeCommunityTypes.filter((type) =>
        typeIds.includes(type.id)
      );
      store.dispatch(
        CommunityStoreActions.staticActions.setSelectedCommunityTypes(
          selectedTypes
        )
      );
    }
    // // Load collection and resources based on selected labels
    // CommunityUtils._resetResourcePagination();
    // CommunityUtils._clearResources();
    // CommunityUtils._clearAndResetCollections();
    // // Activate resources loader
    // // It's needed so we don't show "No resources found" message
    // store.dispatch(
    //   CommunityStoreActions.staticActions.toggleCommunityResourcesLoaderStatus(
    //     true
    //   )
    // );
    // // Activate collections loader
    // // It's needed so we don't show "No collections found" message
    // store.dispatch(
    //   CommunityStoreActions.staticActions.toggleCollectionsLoaderStatus(true)
    // );
    // CommunityUtils._loadCommunityCollections();
    // CommunityUtils._loadCommunityResources();
  },
  /************* Private methods end here **************/
};

export default CommunityUtils;
