import React, { Component, useState } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import SaveButton from "../../../../components/save-button/save-button";
import CollectionIcon from "../collection-icon/collection-icon";
import useKeyCloakSafe from "../../../../shared/hooks/useSafeKeycloak";
import AuthHelper from "../../../../shared/helper-methods/auth-helper";
import CommunityUtils from "../../../community/community-utils";
import { useSelector } from "react-redux";
import CollectionUtils from "../../collection-utils";
import router from "../../../../shared/top-level-declarations/history";

const CollectionCard = ({ isPremium = false, collection }) => {
  const [showDesc, setShowDesc] = useState(false);
  const { keycloak } = useKeyCloakSafe();
  const { activeCollectionId } = useSelector((state) => state.collectionStore);
  const { activeCommunityId } = useSelector((state) => state.communityStore);

  const _onSaveToggle = (e) => {
    e.stopPropagation();
    if (!keycloak?.authenticated) {
      AuthHelper.login();
      return;
    }
    CommunityUtils.toggleCollectionSave(collection);
  };

  const _openCollection = (e) => {
    e.stopPropagation();
    if (collection?.questUuid === activeCollectionId) {
      // Deselect
      CollectionUtils.clearCollectionData();
      router.navigate(`/c/${activeCommunityId}`);
    } else {
      router.navigate(`/c/${activeCommunityId}/q/${collection?.questUuid}`);
    }
  };

  return (
    <div
      className={"collectionCard " + (showDesc ? "showDesc" : "")}
      onClick={_openCollection}
    >
      <div className="inner">
        <div className="imageWrapper">
          <CollectionIcon collectionImageLink={collection?.questImage} />
          {collection?.description?.length ? (
            <div
              className="seeDescBtn d-none d-md-flex"
              onClick={(e) => {
                e.stopPropagation();
                setShowDesc(true);
              }}
            >
              {ICONS.eye} <span>See description</span>
            </div>
          ) : (
            <></>
          )}

          {collection?.isPremium ? (
            <div className="premiumBadge">
              {ICONS.diamond} <span>Premium Collection</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="cardTitle" title={collection?.listname}>
          <span>{collection?.listname}</span>
        </div>

        <div className="cardFooter">
          <div className="left">
            {/* <div className="icon resourceNumber" title="Number of resources">
              {" "}
              <span>2</span> {ICONS.link}{" "}
            </div> */}
            {collection?.stat?.commentCount > 0 ||
            collection?.commentCount > 0 ? (
              <>
                <div className="icon commentNumber" title="Number of comments">
                  {" "}
                  <span>
                    {collection?.stat?.commentCount ||
                      collection?.commentCount ||
                      "0"}
                  </span>{" "}
                  {ICONS.comment}{" "}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="right">
            <SaveButton
              saveCount={collection?.stat?.saveCount}
              alreadySaved={collection?.isFollowed}
              onToggle={_onSaveToggle}
            />
          </div>
        </div>

        <div className="cardButtons d-block d-md-none">
          <Button
            size={"extraSmall"}
            type={"default"}
            icon={ICONS.eye}
            iconPos={"left"}
            text={"See description"}
            extraClasses={"w-100"}
            onClick={(e) => {
              e.stopPropagation();
              setShowDesc(true);
            }}
          />
        </div>
      </div>

      <div className="descInner" onMouseLeave={(e) => setShowDesc(false)}>
        <div className="text">{collection?.description}</div>

        <div className="collabs">
          {/* <div className="images">
            <img src="https://loremflickr.com/640/360" alt="" />
            <img src="https://loremflickr.com/640/360" alt="" />
            <img src="https://loremflickr.com/640/360" alt="" />
            <img src="https://loremflickr.com/640/360" alt="" />
            <img src="https://loremflickr.com/640/360" alt="" />
          </div> */}

          {/* <span className="collabNumber">+12 <span className="d-none d-md-inline-block">Collaborators</span> <span className="d-inline-block d-md-none">Collabs</span></span> */}
          {/* <span className="collabNumber">+12 Collabs</span> */}
        </div>

        <div className="buttons d-none d-md-block">
          <Button
            size={"extraSmall"}
            type={"default"}
            text={"Open"}
            extraClasses={"w-100"}
          />
        </div>

        <div className="buttons d-grid d-md-none">
          <Button
            size={"extraSmall"}
            type={"contrast"}
            text={"Open"}
            extraClasses={"w-100"}
            onClick={_openCollection}
          />

          <Button
            size={"extraSmall"}
            type={"default"}
            icon={ICONS.eyeClosed}
            iconPos={"left"}
            text={"Hide description"}
            extraClasses={"w-100"}
            onClick={(e) => {
              e.stopPropagation();
              setShowDesc(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CollectionCard);
