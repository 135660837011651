import ActivityRecorder from "..";
import GtagEventLogger from "../../gtag-event-logger";

const SponsorItemActivities = {
  onItemClick: ({ itemType = "header", link, sponsorName }) => {
    const metaData = ActivityRecorder.generateActivityMetaData();
    const activity = {
      entityType: "sponsor-item",
      entityId: sponsorName,
      entityValue1: itemType,
      entityValue2: link,
      activityType: `sponsor-item-click`,
      createdOn: +new Date(),
      ...metaData,
    };
    ActivityRecorder.session.activities.push(activity);
    setTimeout(() => {
      ActivityRecorder.writeToDB();
      GtagEventLogger.logEvent(`sponsor-item-click`, activity);
    }, 200);
  },
};

export default SponsorItemActivities;
