import React, { useState, useEffect } from "react";
import "./quick-add-popup.scss";
import ICONS from "../../../../assets/quester-icons";
import OutsideClickHandler from "react-outside-click-handler";
import QuickAddSearch from "../quick-add-search/quick-add-search";
import QuickAddList from "../quick-add-list/quick-add-list";
import MobileDrawer from "../../../../components/mobile-drawer/mobile-drawer";
import QuickAddCollectionItemUtils from "../../utils/quick-add-collection-item-utils";

const QuickAddPopup = ({ onDismiss = () => {} }) => {
  const isMobile = window.innerWidth < 768;

  const _onDismiss = () => {
    QuickAddCollectionItemUtils.resetSearch();
    onDismiss();
  };

  const _renderInner = () => {
    return (
      <div
        className="addToCollectionPopup show"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="inner">
          <QuickAddSearch />

          <QuickAddList onDismiss={_onDismiss} />

          <div className="createNew"
            onClick={() => {
              QuickAddCollectionItemUtils.createNewCollectionWithItem();
            }}
          >
            <span className="d-block d-md-none">{ICONS.list2}</span>
            <span>Create new collection</span>
            <span className="d-none d-md-block">{ICONS.newTab}</span>
          </div>
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <MobileDrawer
        isActive={true}
        onClose={(e) => _onDismiss()}
        headerTitle="add to collection"
        headerIcon={ICONS.plus}
        extraClass="addToCollectionDrawer"
      >
        {_renderInner()}
      </MobileDrawer>
    );
  } else {
    return (
      <OutsideClickHandler onOutsideClick={() => _onDismiss()}>
        {_renderInner()}
      </OutsideClickHandler>
    );
  }
};

export default QuickAddPopup;
