import React from "react";
import ICONS from "../../assets/quester-icons";
import "./save-button.scss";
import HeartSound from "../../assets/audio/sparking_joy_sound.wav";

const SaveButton = ({ saveCount, alreadySaved, onToggle = () => {}, hideCount = false }) => {
  const playAudio = () => {
    if (!alreadySaved) {
      new Audio(HeartSound).play();
    }
  }


  return (
    <div
      className={"saveButton " + (alreadySaved ? "saved" : "")}
      onClick={onToggle}
      title={alreadySaved ? "Unsave" : "Save"}
    >
      {/* <div className="icon">{ICONS.heartSolid}</div> */}

      <div className="icon" onClick={(e) => playAudio()}>
        <div className={"heart " + (alreadySaved ? "is-active" : "")}></div>
      </div>

      {
        hideCount? <></>: <div className="text">{saveCount > 0? saveCount: 0}</div>
      }
      
    </div>
  );
};

export default SaveButton;
