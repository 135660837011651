import { isbot } from "isbot";
import { EventEmitter } from "../utils/event-emitter";
import SiteWiseEvents from "../../declarations/site-wise-events";

/* eslint-disable default-case */
const GeneralHelper = {
  prepareQueryParams: (
    paramsList = {
      param1: "value1",
      param2: ["val1", "val2"],
    }
  ) => {
    let queryParams = "";
    if (paramsList) {
      Object.keys(paramsList).forEach((param, paramIndex) => {
        if (paramIndex > 0) {
          queryParams = `${queryParams}&`;
        }
        switch (typeof paramsList[param]) {
          case "boolean":
          case "string":
          case "number": {
            queryParams = `${queryParams}${param}=${paramsList[param]}`;
            break;
          }
          case "object": {
            paramsList[param].forEach((value, index) => {
              if (index > 0) {
                queryParams = `${queryParams}&`;
              }
              queryParams = `${queryParams}${param}=${value}`;
            });
          }
        }
      });
    }
    return queryParams;
  },
  isTwinklSite: (link) => {
    let isTwinkl = false;
    if (link?.length && link.indexOf(".twinkl.") > -1) {
      isTwinkl = true;
    }
    return isTwinkl;
  },
  extractQueryParams: () => {
    let {
      location: { search: queryParamString },
    } = window;
    let params = {};
    if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
      queryParamString = queryParamString.replace("?", "");
      if (queryParamString.indexOf("&") === -1) {
        // Contains only one param
        const paramParts = queryParamString.split("=");
        params[paramParts[0]] = paramParts[1];
      } else {
        // Contains multiple params
        const queryParams = queryParamString.split("&");
        queryParams.forEach((queryParam) => {
          const paramParts = queryParam.split("=");
          params[paramParts[0]] = paramParts[1];
        });
      }
    }
    return params;
  },
  isCrawler: () => isbot(navigator.userAgent),
  checkIfMobile: () => {
    return window.innerWidth < 768;
  },
  maskUserName: (userName) => {
    if (GeneralHelper.validateEmail(userName)) {
      return userName.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");
    }
    return userName;
  },
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  generateRandomColor: (index = GeneralHelper.getRandomArbitrary(0, 7)) => {
    const colorBank = [
      "#FFB800",
      "#60C6FF",
      "#00EE98",
      "#FF7B7B",
      "#B28EFF",
      "#FF9EDE",
      "#999999",
    ];
    if (index > colorBank.length - 1) {
      index = index % (colorBank.length - 1);
    }
    return colorBank[index];
  },
  getRandomArbitrary: (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  getStringAsciSum: (string) => {
    let asciSum = 0;
    if (string?.length) {
      for (let i = 0; i < string?.length; i++) {
        asciSum += string[i].charCodeAt(0);
      }
    }
    return asciSum;
  },
  showToast: (
    {
      primaryText,
      secondaryText,
      navigateTo,
      dismissAfterInSecond,
      additionalClass,
      icon,
    } = {
      primaryText: "",
      secondaryText: "",
      navigateTo: "",
      dismissAfterInSecond: 4,
      additionalClass: "",
      icon: null,
    }
  ) => {
    EventEmitter.dispatch(SiteWiseEvents.showToast, {
      primaryText,
      secondaryText,
      navigateTo,
      dismissAfterInSecond,
      additionalClass,
      icon,
    });
  },
  trimDomainOrSubdomain: (fullLink) => {
    // Only show the domain name: facebook.com
    const domainName = fullLink
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "");
    return domainName.split("/")[0];
  },
  hashCode: (str) => {
    // Create a hash from the string
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      var char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },
  generateRandomNumberBasedOnAString: (str, min, max) => {
    // Generate a consistent hash for the string
    var hash = GeneralHelper.hashCode(str);

    // Convert the hash into a number within the given range
    var range = max - min;
    var result = (Math.abs(hash) % range) + min;

    return result;
  },
  sleep: (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  copyToClipboard: (text) => {
    try {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    } catch (error) {
      console.log("error :>> ", error);
    }
  },
  triggerNativeShare: (title, text, url) => {
    if (navigator.share) {
      navigator
        .share({
          title,
          text,
          url,
        })
        .then(() => {
          // console.log("Thanks for sharing!");
        })
        .catch(console.error);
    }
  },
  isNativeShareSupported: () => {
    try {
      const browserTypes = GeneralHelper.detectBrowserType();
      return window?.navigator?.share && !browserTypes?.isEdgeChromium;
    } catch (error) {
      return false;
    }
  },
  detectBrowserType: () => {
    if (window) {
      // Opera 8.0+
      const isOpera =
        // eslint-disable-next-line no-undef
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;

      // Firefox 1.0+
      const isFirefox = typeof InstallTrigger !== "undefined";

      // Safari 3.0+ "[object HTMLElementConstructor]"
      const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        );

      // Internet Explorer 6-11
      const isIE = /*@cc_on!@*/ false || !!document.documentMode;

      // Edge 20+
      const isEdge = !isIE && !!window.StyleMedia;

      // Chrome 1 - 79
      const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);

      // Edge (based on chromium) detection
      const isEdgeChromium =
        isChrome && navigator.userAgent.indexOf("Edg") != -1;

      // Blink engine detection
      const isBlink = (isChrome || isOpera) && !!window.CSS;
      return {
        isOpera,
        isFirefox,
        isSafari,
        isIE,
        isEdge,
        isChrome,
        isEdgeChromium,
        isBlink,
      };
    } else {
      return {};
    }
  },
  updateMobileFooterOnScrollDown: () => {
    // Add a class to the footer to hide it
    const footer = document.querySelector(".mobileFooter ");
    if (footer) {
      footer.classList.add("hide");
      document.body.classList.add("mobileFooterIsHidden");
    }
  },
  updateMobileFooterOnScrollUp: () => {
    // Remove the class to show the
    const footer = document.querySelector(".mobileFooter ");
    if (footer) {
      footer.classList.remove("hide");
      document.body.classList.remove("mobileFooterIsHidden");
    }
  },
  restoreMobileFooter: () => {
    // Remove the class to show the
    const footer = document.querySelector(".mobileFooter ");
    if (footer) {
      footer.classList.remove("hide");
      document.body.classList.remove("mobileFooterIsHidden");
    }
  }
};

export default GeneralHelper;
