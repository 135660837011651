import { HttpClient } from "../clients/http-client";
import GeneralHelper from "../helper-methods/general-helpers";

const {
  ProtectedHttpClient,
  PublicHttpClient,
  PublicCommentManagementHttpClient,
  ProtectedCommentManagementHttpClient,
  PublicV2Client,
  ProtectedV2Client
} = HttpClient;

const QuestApi = {
  public: {
    fetchQuest: (questUuid) =>
      PublicHttpClient.get(`/quests/${questUuid}?includeFollowQuestCount=true`),
    fetchQuestItems: (questUuid) =>
    PublicV2Client.get(
        `/collection-items?questId=${questUuid}&pageSize=200&pageNo=0&sortBy=-stat.saveCount`
      ),
    fetchCommunityQuestItems: ({
      pageNo = 0,
      pageSize = 500,
      questUuid,
      ...rest
    }) =>
      PublicHttpClient.get(
        `/quest-items/community-view?${GeneralHelper.prepareQueryParams({
          pageNo,
          pageSize,
          questUuid,
          ...rest,
        })}`
      ),
    fetchQuestComments: (questUuid) =>
      PublicCommentManagementHttpClient.get(
        `/comments?questId=${questUuid}&pageSize=1000&pageNo=0&treeDepth=1000`
      ),
    fetchQuestCommentsWithReference: (questUuid, questItemId) =>
      PublicCommentManagementHttpClient.get(
        `/comments?questId=${questUuid}&pageSize=1000&pageNo=0&treeDepth=1&questItemId=${questItemId}`
      ),
  },
  private: {
    followQuest: (questUuid) => ProtectedHttpClient.post(`/quests/${questUuid}/follow`),
    unfollowQuest: (questUuid) => ProtectedHttpClient.post(`/quests/${questUuid}/unfollow`),
    fetchQuestItems: (questUuid) =>
    ProtectedV2Client.get(
        `/collection-items?questId=${questUuid}&pageSize=200&pageNo=0&sortBy=-stat.saveCount`
      ),
    updateQuest: ({ questUuid, quest } = {}) =>
      ProtectedHttpClient.put(`/quests/${questUuid}`, quest),
    bulkUpdateQuestItems: ({ questUuid, payload } = {}) =>
      ProtectedHttpClient.post(
        `/bff/quests/${questUuid}/upload/quest-items`,
        payload
      ),
    addNewSuggestedQuestItem: (payload) =>
      ProtectedHttpClient.post(`/suggested-questItems`, payload),
    approveSuggestedQuestItem: ({ suggestedQuestItemUuid }) =>
      ProtectedHttpClient.put(
        `/suggested-questItems/${suggestedQuestItemUuid}/approve`
      ),
    rejectSuggestedQuestItem: ({ suggestedQuestItemUuid }) =>
      ProtectedHttpClient.put(
        `/suggested-questItems/${suggestedQuestItemUuid}/reject`
      ),
    fetchAllSuggestedQuestItems: ({ questUuid }) =>
      ProtectedHttpClient.get(
        `/suggested-questItems?questId=${questUuid}&suggestionStatus=PENDING`
      ),
    fetchAllSuggestedQuestItemsForOrg: ({ orgUuid }) =>
      ProtectedHttpClient.get(
        `/suggested-questItems?organisationId=${orgUuid}&suggestionStatus=PENDING`
      ),
    fetchCommunityQuestItems: ({
      pageNo = 0,
      pageSize = 500,
      questUuid,
      ...rest
    }) =>
      ProtectedHttpClient.get(
        `/quest-items/community-view?${GeneralHelper.prepareQueryParams({
          pageNo,
          pageSize,
          questUuid,
          ...rest,
        })}`
      ),
    fetchQuestComments: (questUuid) =>
      ProtectedCommentManagementHttpClient.get(
        `/comments?questId=${questUuid}&pageSize=1000&pageNo=0&treeDepth=1000`
      ),
    addQuestComment: (payload) =>
      ProtectedCommentManagementHttpClient.post(`/comments`, payload),
    deleteQuestComment: (commentUuid) =>
      ProtectedCommentManagementHttpClient.delete(`/comments/${commentUuid}`),
    updateQuestComment: (commentUuid, payload) =>
      ProtectedCommentManagementHttpClient.put(
        `/comments/${commentUuid}`,
        payload
      ),
    toggleQuestCommentLike: (commentUuid) =>
      ProtectedCommentManagementHttpClient.post(
        `/comments/${commentUuid}/like-toggle`
      ),
    fetchQuestCommentsWithReference: (questUuid, questItemId) =>
      ProtectedCommentManagementHttpClient.get(
        `/comments?questId=${questUuid}&pageSize=1000&pageNo=0&treeDepth=1&questItemId=${questItemId}`
      ),
    getOwnPendingSuggestions: (questUuid) =>
      ProtectedHttpClient.get(
        `/suggested-questItems/me?questId=${questUuid}&suggestionStatus=PENDING`
      ),
    updatePendingSuggestion: (suggestedQuestItemUuid, payload) =>
      ProtectedHttpClient.put(
        `/suggested-questItems/${suggestedQuestItemUuid}`,
        payload
      ),
    deletePendingSuggestion: (suggestedQuestItemUuid) =>
      ProtectedHttpClient.delete(
        `/suggested-questItems/${suggestedQuestItemUuid}`
      ),
    getAllQuestsByUser: (
      { pageNo = 0, pageSize = 1000 } = {
        pageNo: 0,
        pageSize: 1000,
      }
    ) =>
      ProtectedHttpClient.get(`/quests/me?includeQuestItemCount=true&pageSize=${pageSize}&pageNo=${pageNo}
    `),
    createNewPrivateQuest: (payload) =>
      ProtectedHttpClient.post(`/quest/private`, payload),
      followQuestItem: (questItemId) => ProtectedHttpClient.post(`/quest-items/${questItemId}/follow`),
      unfollowQuestItem: (questItemId) => ProtectedHttpClient.post(`/quest-items/${questItemId}/unfollow`),
  },
};
export default QuestApi;
