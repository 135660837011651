import React, { useEffect, useState } from "react";

const DEFAULT_IMAGE = "https://quester.io/pwa-icons/icon-512x512.png";

const Image = ({
  src,
  defaultImg = DEFAULT_IMAGE,
  hideOnError = false,
  onLoadError = () => {},
  ...rest
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  const _generateThumbnail = () => {
    let thumbnail = "";
    if (src) {
      // Check if has http or https
      if (src.indexOf("http") > -1) {
        thumbnail = src;
      } else {
        thumbnail = `${window.REACT_APP_CDN_BASE_URL}${src}`;
      }
    }
    if (!thumbnail?.length) {
      thumbnail = defaultImg;
    }
    setImageSrc(thumbnail);
  };

  const handleImageError = (e) => {
    setImageSrc(defaultImg);
    onLoadError(e);
  };

  useEffect(() => {
    if (src?.length) {
      _generateThumbnail();
    }
  }, [src]);

  if (imageSrc === defaultImg && hideOnError) return <></>;

  return (
    <img src={imageSrc} alt="error" onError={handleImageError} {...rest} />
  );
};

export default Image;
