import React, { Component } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import SaveButton from "../../../../components/save-button/save-button";
import CollectionIcon from "../collection-icon/collection-icon";
import useCollection from "../../../../shared/hooks/useCollection";

const CollectionView = ({
  collection,
  hideSaveButton = false,
  hideShareButton = false,
  isSaved = false,
  onUnfollow = () => {},
}) => {
  const { navigateToCollection } = useCollection(collection);

  return (
    <div
      className="collectionBigCard"
      onClick={(e) => {
        navigateToCollection();
      }}
    >
      <div className="collectionDetailsWrapper">
        <div className="inner">
          <div className="detailWrapper">
            <div className="d-inline-block d-md-none">
              <span className="collectionTag ">
                <div className="innerTag">
                  {/* {ICONS.diamondGradient} */}
                  <b>Collection</b>
                </div>
              </span>
            </div>


            <div className="imageWrapper">
              {/* <img src="https://placehold.co/100x100" alt="" /> */}
              <CollectionIcon collectionImageLink={collection?.questImage} />
            </div>

            <div className="descWrapper">
              <div className="topHeader">
                <div className="left">
                  <div className="collectionTitle">
                    {collection.listname?.length
                      ? collection.listname
                      : "Untitled Collection"}
                  </div>
                </div>
                <div className="buttons">
                  {!hideShareButton ? (
                    <></>
                  ) : (
                    <Button
                      size={"extraSmall"}
                      icon={ICONS.share}
                      iconPos={"left"}
                      type={"default"}
                      text="Share"
                    />
                  )}
                  {hideSaveButton ? (
                    <></>
                  ) : (
                    <SaveButton alreadySaved={isSaved} onToggle={onUnfollow} saveCount={
                      collection.followersCount
                    } 
                      hideCount={true}
                    />
                  )}
                </div>
              </div>

              <div className="collectionMeta">
                <div className="top">
                  <div className="d-none d-md-inline-flex">
                    <span className="collectionTag "> {/* add premium class */}
                      <div className="innerTag">
                        {/* {ICONS.diamondGradient} */}
                        <b>Collection</b>
                      </div>
                    </span>
                  </div>
                  <span className="d-none d-md-inline-block">·</span>
                  <span>
                    {collection.creatorName?.length ? (
                      <>CREATED BY {collection.creatorName}</>
                    ) : (
                      <></>
                    )}
                  </span>
                  {collection.creatorName?.length &&
                  collection.questItemCount > 0 ? (
                    <span className="d-none d-md-inline-block">·</span>
                  ) : (
                    <></>
                  )}
                  {collection.questItemCount > 0 ? (
                    <>
                      <span className="d-none d-md-inline-block">
                        {collection.questItemCount === 1
                          ? `1 ITEM`
                          : `${collection.questItemCount} ITEMS`}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="collectionDesc">
                <div className="texts">{collection.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionView;
