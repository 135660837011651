import React, { useEffect } from "react";
import CommunityItem from "../../../../components/community-item/community-item";
import { useSelector } from "react-redux";
import UserUtils from "../../user-utils";

const FollowingResourcesViewer = () => {
  const { followingMyResources, profileData } = useSelector((state) => state.userStore);

  const _initiateLoad = () => {
    UserUtils.loadFollowingResources();
  };

  const _unfollow = (e, resource) => {
    e.stopPropagation();
    UserUtils.unfollowResource(resource);
  }

  useEffect(() => {
    if (profileData?.uuid) _initiateLoad();
  }, [profileData?.uuid]);
  return (
    <div
      className="tab-pane fade show active"
      id="savedItems"
      role="tabpanel"
      aria-labelledby="saved-items-tab"
    >
      {followingMyResources?.map((resource) => (
        <CommunityItem
          key={resource.id}
          hideCount={true}
          communityItem={resource}
          isMyResource={true}
          onUnfollow={e => _unfollow(e, resource)}
        />
      ))}
    </div>
  );
};

export default FollowingResourcesViewer;
