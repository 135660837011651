/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import UserUtils from "../../entities/user/user-utils";
import useKeyCloakSafe from "../hooks/useSafeKeycloak";
import ActivityRecorder from "../utils/activity-recorder";
import NotificationUtils from "../../features/notifications/notification-utils";
import MetaUtils from "../utils/meta-utils";
import EmailOptPopup from "../../components/email-opt-popup/email-opt-popup";
import { useLocation } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import GeneralHelper from "../helper-methods/general-helpers";

/*
    This is maining for calling initial requests to the server
*/

const AppInitProvider = ({ children }) => {
  const { keycloak, initialized } = useKeyCloakSafe();
  const [isProfileInitialized, setIsProfileInitialized] = React.useState(false);
  const location = useLocation();

  const _initiliazeUser = async () => {
    await UserUtils.initiliazeUser();
    NotificationUtils.initiateMessaging();
    setIsProfileInitialized(true);
  };

  const path = location.pathname;
  // On route path change
  useEffect(() => {
    GeneralHelper.restoreMobileFooter();
  }, [path])

  useEffect(() => {
    if (initialized) {
      if (keycloak?.authenticated) {
        _initiliazeUser();
      } else {
        setIsProfileInitialized(true);
        UserUtils.clearStore();
      }
      keycloak.onTokenExpired = () => {
        // The minValidity is the time in seconds before the token expires that Keycloak will refresh the token.
        // So no api call will have to wait for the token to be refreshed.
        keycloak
          .updateToken(50)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Token refreshed" + refreshed);
            } else {
              console.log(
                "Token not refreshed, valid for " +
                  Math.round(
                    keycloak.tokenParsed.exp +
                      keycloak.timeSkew -
                      new Date().getTime() / 1000
                  ) +
                  " seconds"
              );
            }
          })
          .catch(() => {
            console.log(
              "Failed to refresh the token, or the session has expired"
            );
          });
      };
      MetaUtils.runActionForNextAppLoad();
    }
  }, [initialized, keycloak?.authenticated]);

  useEffect(() => {
    if (isProfileInitialized) {
      ActivityRecorder.initiateSession();
      if (keycloak?.authenticated) {
        UserUtils.loadUserOrgs();
      }
    }
  }, [isProfileInitialized, keycloak?.authenticated]);

  return (
    <>
      {children}
      <EmailOptPopup />
    </>
  );
};

export default AppInitProvider;
