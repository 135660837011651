import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserImageRenderer from "../../../../components/user-image-renderer/user-image-renderer";
import Image from "../../../../components/core/image/image";
import CommunityIcon from "../community-icon/community-icon";

const CommunityMembersViewer = () => {
  const [admins, setAdmins] = useState([]);
  const [owners, setOwners] = useState([]);
  const [moderators, setModerators] = useState([]);
  const [collaborators, setCollaborators] = useState([]);

  const {
    activeCommunityMembers,
    isCommunityResourcesLoaderActive,
    activeCommunity,
  } = useSelector((state) => state.communityStore);

  /*
       {
        "uuid": "eJRq1yZ0zJn",
        "roles": [
            "org_user"
        ],
        "owningOrganizationId": "C7CWaIHtlnD",
        "memberDisplayName": "kes@quester.io",
        "versionNumber": 0,
        "visibility": "PUBLIC",
        "ownerId": "kes@quester.io",
        "createdOn": "2022-01-20T15:50:23.234+00:00"
    },
      */
  useEffect(() => {
    if (activeCommunityMembers?.length) {
      // Max 20 chars
      const membersWithTrimmedName = activeCommunityMembers.map((member) => {
        const trimmedName =
          member.ownerId.length > 7
            ? member.ownerId.substring(0, 7) + "..."
            : member.ownerId;
        return {
          ...member,
          trimmedName: trimmedName,
        };
      });
      const admins = membersWithTrimmedName.filter((member) =>
        member.roles.includes("org_admin")
      );
      const moderators = membersWithTrimmedName.filter((member) =>
        member.roles.includes("org_moderator")
      );
      const collaborators = membersWithTrimmedName.filter((member) =>
        member.roles.includes("org_collaborator")
      );
      const owner = membersWithTrimmedName.filter((member) =>
        member.roles.includes("org_owner")
      );
      setAdmins(admins);
      setModerators(moderators);
      setCollaborators(collaborators);
      setOwners(owner);
    }
  }, [activeCommunityMembers?.length]);

  return (
    <>
      <div className="infoPopup">
        <div className="popupIinner">
          <div className="popupHeader">About this community</div>

          <div className="popupContent">
            <div className="aboutDesc">
              <div className="aboutImageWrapper">
                {}
                <CommunityIcon
                  orgIconImageURL={activeCommunity.orgIconImageURL}
                />
                {/* <Image src={activeCommunity.orgIconImageURL} alt="" /> */}
              </div>
              <div className="desc">
                {activeCommunity.orgDescription?.length
                  ? activeCommunity.orgDescription
                  : activeCommunity.organisationDisplayName}
              </div>
            </div>
            {isCommunityResourcesLoaderActive ? (
              <>Loading</>
            ) : (
              <>
                {activeCommunityMembers?.length ? (
                  <>
                    {owners?.length ? (
                      <div className="peopleSection">
                        <div className="label">Owners</div>
                        <div className="users">
                          {owners?.map((member, index) => (
                            <div
                              className="bigUserBadge"
                              key={`${member.uuid} + ${index}`}
                              title={`${member.ownerId}`}
                            >
                              <div className="bigImageWrapper">
                                <UserImageRenderer
                                  userName={member.ownerId}
                                  photoUrl={member.photoUrl}
                                />
                              </div>
                              <div className="userid">
                                @{member.trimmedName}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {moderators?.length ? (
                      <div className="peopleSection">
                        <div className="label">Admins</div>
                        <div className="users">
                          {admins?.map((member, index) => (
                            <div
                              className="bigUserBadge"
                              key={`${member.uuid} + ${index}`}
                              title={`${member.ownerId}`}
                            >
                              <div className="bigImageWrapper">
                                <UserImageRenderer
                                  userName={member.ownerId}
                                  photoUrl={member.photoUrl}
                                />
                              </div>
                              <div className="userid">
                                @{member.trimmedName}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {/* <div className="peopleSection">
                  <div className="label">Collaborators</div>
                  <div className="users">
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                    <div className="smallUserBadge" title="Collaborator name">
                      <img src={require("../../../../assets/images/sushi.png")} alt="" />
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityMembersViewer;
