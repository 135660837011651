import { default as React, useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ICONS from "../../assets/quester-icons";
import SiteWiseEvents from "../../declarations/site-wise-events";
import CollectionUtils from "../../entities/collection/collection-utils";
import CollectionIcon from "../../entities/collection/components/collection-icon/collection-icon";
import CommunityUtils from "../../entities/community/community-utils";
import CommunityLabelsViewer from "../../entities/community/components/community-labels-viewer/community-labels-viewer";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import router from "../../shared/top-level-declarations/history";
import { EventEmitter } from "../../shared/utils/event-emitter";
import Spinner from "../core/spinner/spinner";
import SaveButton from "../save-button/save-button";
import "./discovery-bar.scss";
import TypeFilter from "../type-filter/type-filter";
import CommunityTypesViewer from "../../entities/community/components/community-types-viewer/community-types-viewer";

const Collection = ({ collection, onClick = () => {} }) => {
  const { keycloak } = useKeyCloakSafe();
  let { communityId } = useParams();
  const { activeCollectionId } = useSelector((state) => state.collectionStore);
  const { activeCommunityId } = useSelector((state) => state.communityStore);
  var Active = activeCollectionId === collection?.uuid;

  const sparkleRef = useRef(null);

  const _onSaveToggle = (e) => {
    e.stopPropagation();
    if (!keycloak?.authenticated) {
      AuthHelper.login();
      return;
    }
    CommunityUtils.toggleCollectionSave(collection);
  };

  const _selectCollection = (e) => {
    e.stopPropagation();
    onClick();
    if (collection?.questUuid === activeCollectionId) {
      // Deselect
      CollectionUtils.clearCollectionData();
      router.navigate(`/c/${activeCommunityId}`);
    } else {
      router.navigate(`/c/${activeCommunityId}/q/${collection?.questUuid}`);
    }
  };

  return (
    <div
      className={
        "collection " +
        (collection?.isPremium ? "premium" : "") +
        " " +
        (Active ? "active" : "")
      }
      // className={"collection " + (Active ? "active" : "")}
      // onMouseEnter={(e) => sparkleRef.current.play()}
      onClick={_selectCollection}
    >
      <div className="imageWrapper">
        <CollectionIcon collectionImageLink={collection?.questImage} />
        {collection?.isPremium ? (
          <div className="premiumIcon">
            {ICONS.diamond}
            {/* <Player
                  src={require("../../assets/animations/sparkle.lottie.json")}
                  className="sparklePremium"
                  loop={false}
                  autoplay={false}
                  ref={sparkleRef}
              /> */}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="right">
        <div className="collectionName" title={collection?.listname}>
          {collection?.listname}
        </div>
        <div className="actions">
          {" "}
          <SaveButton
            saveCount={collection?.saveCount}
            alreadySaved={collection?.isFollowed}
            onToggle={_onSaveToggle}
          />{" "}
        </div>
      </div>
    </div>
  );
};

const SortItem = ({ text = "Untitled", onClick = () => {}, isAsc = true }) => {
  return (
    <div
      className={"sortItem " + (isAsc ? "bold" : "")}
      onClick={(e) => onClick(!isAsc)}
    >
      {isAsc ? ICONS.ascSort : ICONS.descSort} <span>{text}</span>
    </div>
  );
};

const DiscoveryBar = ({
  open = false,
  closeParentDrawer = () => {},
  withinDrawer = false,
}) => {
  const [discoveryBarOpen, setDiscoveryBarOpen] = useState(open);
  const [discoveryBarOpenAnimation, setDiscoveryBarOpenAnimation] =
    useState("");
  const {
    activeCommunityCollections,
    isCollectionsLoaderActive,
    activeCommunityCollectionsPagination,
    isCommunityResourcesLoaderActive,
    activeCommunityCollectionsSort,
    activeCommunityLabels,
  } = useSelector((state) => state.communityStore);

  const _openCollection = (e) => {
    router.navigate("/c/1/q/2");
  };

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: isCollectionsLoaderActive,
    hasNextPage: activeCommunityCollectionsPagination.hasMore,
    onLoadMore: CommunityUtils.loadNextPageCommunityCollections,
    disabled: false,
    rootMargin: "0px 0px 0px 0px",
  });

  const _registerRequiredEventListners = () => {
    EventEmitter.subscribe(SiteWiseEvents.openCommentBar, () => {
      // Check if screen width is less than 1200px
      if (window.innerWidth < 1200 && !withinDrawer) {
        setDiscoveryBarOpen(false);
        setDiscoveryBarOpenAnimation("");
      }
    });
  };

  useEffect(() => {
    _registerRequiredEventListners();
  }, []);

  const [typeExpanded, setTypeExpanded] = useState(false);

  return (
    <div
      className={
        "discoveryBar " +
        (discoveryBarOpen ? "open" : "close") +
        " " +
        (discoveryBarOpenAnimation == "addAnimation" ? "openAnimation" : "")
      }
      ref={rootRef}
    >
      <div className="discoveryBarInner">
        <div className="discoveryBarHeader">
          <div className="title">Discover</div>
          <div
            className="icon"
            title={discoveryBarOpen ? "Collapse discovery" : "Expand discovery"}
            onClick={(e) => {
              if (!discoveryBarOpen) {
                EventEmitter.dispatch(SiteWiseEvents.openDiscoveryBar);
              }
              setDiscoveryBarOpen(!discoveryBarOpen);
              setDiscoveryBarOpenAnimation("addAnimation");
            }}
          >
            {discoveryBarOpen ? ICONS.chevronLeft : ICONS.compassIcon}
            {discoveryBarOpen ? "" : <div className="newIndicator"></div>}
          </div>
        </div>

        <div className="discoveryBarContent">
          {/* <div className="searchBar">
              <div className="icon">{ICONS.searchIcon}</div>
              <input type="text" className="form-control" placeholder='Search community' />
          </div> */}

          <div className="filtersSection">
            {/*************************************************** TOPICS SECTIONS */}
            <CommunityLabelsViewer />
            {/*************************************************** /TOPICS SECTIONS */}

            {/*************************************************** TYPES SECTIONS */}
            <CommunityTypesViewer />
            {/*************************************************** /TYPES SECTIONS */}
          </div>

          {/* <div className="sectionDivider"></div> */}

          {/*************************************************** COLLECTIONS */}
          {/* <div className="collectionsSection">
            <div className="sectionHeading mb-0">
              <span>Collections</span>
              <div className="tooltipWrap">
                <div className="icon">{ICONS.infoCircle}</div>
                <div className="tooltip">
                  Browse lists of items curated by community members
                </div>
              </div>
            </div>

            <div className="sortWrapper">
              <SortItem
                isAsc={
                  activeCommunityCollectionsSort.sortField === "createdOn" &&
                  activeCommunityCollectionsSort.sortOrder === "ASC"
                }
                text={"Recency"}
                onClick={() => {
                  CommunityUtils.toggleCollectionSort("createdOn");
                }}
              />
              <SortItem
                text={"Alphabetical"}
                isAsc={
                  activeCommunityCollectionsSort.sortField === "listname" &&
                  activeCommunityCollectionsSort.sortOrder === "ASC"
                }
                onClick={() => {
                  CommunityUtils.toggleCollectionSort("listname");
                }}
              />
            </div>

            <div className="collections">
              <>
                {activeCommunityCollections?.map((collection, index) => {
                  return (
                    <Collection
                      key={index}
                      collection={collection}
                      onClick={closeParentDrawer}
                    />
                  );
                })}
                {activeCommunityCollectionsPagination.hasMore ? (
                  <div
                    ref={infiniteRef}
                    style={{
                      height: "100px",
                      textAlign: "center",
                      marginBottom: "10vh",
                      marginTop: "4vh",
                    }}
                  >
                    <Spinner withContainer={false} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            </div>
          </div> */}
          {/*************************************************** /COLLECTIONS */}
        </div>
      </div>
    </div>
  );
};

export default DiscoveryBar;
