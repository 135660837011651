import React from "react";
import KeycloakProvider from "./keycloak-provider/keycloak-provider";
import { Provider } from "react-redux";
import { persistor, store } from "../../stores/store";
import { PersistGate } from "redux-persist/integration/react";
import ThemeProvider from "./theme-provider";
import AppInitProvider from "./app-init-provider";
import MaintainceModeProvider from "./maintaince-mode-provider";

const TopLevelProviders = ({ children }) => {
  return (
    <>
      <KeycloakProvider>
        <Provider store={store}>
          <PersistGate loading={<div></div>} persistor={persistor}>
            <ThemeProvider>
              <MaintainceModeProvider>
                <AppInitProvider>{children}</AppInitProvider>
              </MaintainceModeProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </KeycloakProvider>
    </>
  );
};

export default TopLevelProviders;
