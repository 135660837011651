import React from "react";
import MaintenanceViewer from "../../features/maintenance-mode/components/maintenance-mode-viewer/maintenance-mode-viewer";
import { getFirestore } from "firebase/firestore";
import { onSnapshot, doc } from "firebase/firestore";
import firebaseApp from "./firebase-provider/firebase-utils";

const db = getFirestore(firebaseApp);
let unsubscribe = null;

const MaintainceModeProvider = ({ children }) => {
  const [settings, setSettings] = React.useState({});

  const _listenToMaintainceMode = async () => {
    // Listen to maintaince mode
    const documentRef = doc(db, "siteSettings", "settings");

    unsubscribe = onSnapshot(documentRef, (doc) => {
      if (doc.exists()) {
        // console.log("Current data: ", doc.data());
        const data = doc.data();
        setSettings(data || {});
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!");
      }
    });
  };

  React.useEffect(() => {
    _listenToMaintainceMode();
  }, []);

  return (
    <>
      {settings?.isMaintenanceModeEnabled ? (
        <MaintenanceViewer />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default MaintainceModeProvider;
