import React, { useEffect, useState } from "react";
import ICONS from "../../assets/quester-icons";
import "./daily-streak.scss";
import useKeyCloakSafe from "../../shared/hooks/useSafeKeycloak";
import StreakHelper from "../../features/daily-streak/streak-helper";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";

const DailyStreak = (props) => {
  // const { userData } = props;
  const { profileData } = useSelector((state) => state?.userStore);

  const [isHovered, setIsHovered] = useState(false);
  const [userStreak, setUserStreak] = useState(null);
  const { keycloak, initialized } = useKeyCloakSafe();

  useDeepCompareEffect(() => {
    if (keycloak?.authenticated && initialized && profileData?.ownerId) {
      _initiate();
    }
  }, [keycloak, initialized, profileData]);

  const _initiate = async () => {
    const userStreak = await StreakHelper.getUserStreakInfo();
    if (userStreak) {
      setUserStreak(userStreak);
    }
  };
  if (userStreak) {
    return (
      <div
        className="dailyStreak"
        title={
          userStreak?.currentStreakCount >= userStreak?.longestStreakCount
            ? `This is your highest streak so far 🎉`
            : `Your highest streak count is ${userStreak.longestStreakCount}`
        }
      >
        {ICONS.fire}
        <span className="number">{userStreak.currentStreakCount}</span>
      </div>
    );
  } else {
    return <></>;
  }
};

export default DailyStreak;
