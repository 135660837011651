/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import router from "../top-level-declarations/history";
import GeneralHelper from "../helper-methods/general-helpers";

const useResource = (
  { resource, isMyResource = false } = {
    resource: null,
    isMyResource: false,
  }
) => {
  const [sourceUrl, setSourceUrl] = useState("");
  const [domain, setDomain] = useState("");
  const [title, setTitle] = useState("");

  const _generateResourceUrl = () => {
    if (resource?.sourceUrl?.length) {
      setSourceUrl(resource.sourceUrl);
    } else if (resource?.resourceDTO?.sourceUrl?.length) {
      setSourceUrl(resource.resourceDTO.sourceUrl);
    }
  };

  const _generateTitle = () => {
    if (resource?.title?.length) {
      setTitle(resource.title);
    } else if (resource?.resourceDTO?.title?.length) {
      setTitle(resource.resourceDTO.title);
    }
  };

  const _generateDomain = () => {
    let sourceUrl = "";
    if (resource?.sourceUrl?.length) {
      sourceUrl = resource.sourceUrl;
    } else if (resource?.resourceDTO?.sourceUrl?.length) {
      sourceUrl = resource.resourceDTO.sourceUrl;
    }
    const domain = GeneralHelper.trimDomainOrSubdomain(sourceUrl);
    setDomain(domain);
  };

  const openResource = () => {
    // Open in new tab
    window.open(sourceUrl, "_blank");
  };

  useEffect(() => {
    _generateResourceUrl();
    _generateTitle();
    _generateDomain();
  }, [resource?.uuid]);

  return {
    sourceUrl,
    title,
    domain,
    openResource,
  };
};

export default useResource;
