import React from "react";
import QuestCommentsHelper from "../../quest-comments-helper";
import "./quest-comment-text-viewer.scss";
import { useSelector } from "react-redux";
import CommunityCommentsHelper from "../../community-comments-helper";
import router from "../../../../shared/top-level-declarations/history";

const RenderValue = ({ item, onReferenceClick, isReferenceView = false }) => {
  const { referenceQuestItemId = null } = useSelector(
    (state) => state.commentStore || {}
  );

  if (item.e === "text") {
    return <span>{item.l}</span>;
  } else {
    return (
      <span
        className={`resourceTag ${
          isReferenceView && referenceQuestItemId === item.v ? "active" : ""
        }`}
        title={item.l}
        onClick={(e) => onReferenceClick(item)}
      >
        @{item.l}
      </span>
    );
  }
};

const QuestCommentTextViewer = ({
  jsonValue,
  isReferenceView = false,
  isLocked = false,
  onReferenceClick = () => {},
}) => {
  const _onReferenceClick = (item) => {
    onReferenceClick(item.v);
  };

  if (isLocked) {
    return (
      <span className="d-inline">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </span>
    );
  } else {
    return (
      <>
        {jsonValue?.map((item, index) => (
          <span
            className={item.e === "text" ? "d-inline" : "d-inline-flex"}
            key={index}
          >
            <RenderValue
              item={item}
              onReferenceClick={_onReferenceClick}
              isReferenceView={isReferenceView}
            />
          </span>
        ))}
      </>
    );
  }
};

export default QuestCommentTextViewer;
