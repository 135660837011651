import React from "react";
import ICONS from "../../../../assets/quester-icons";
import DebouncedInput from "../../../../components/core/debounce-input/debounce-input";
import { useSelector } from "react-redux";
import QuickAddCollectionItemUtils from "../../utils/quick-add-collection-item-utils";

const QuickAddSearch = () => {
  const searchText = useSelector(
    (state) => state?.quickAddCollectionItemStore?.searchText
  );
  return (
    <>
      <div className="searchBarWrap">
        {ICONS.searchIcon}
        <DebouncedInput
          type="text"
          className="form-control"
          placeholder="Search"
          value={searchText}
          onInputChange={(value) =>
            QuickAddCollectionItemUtils.updateSearchText(value)
          }
        />
      </div>
    </>
  );
};

export default QuickAddSearch;
