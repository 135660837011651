const DemoResource = {
  uuid: "9bgot5cquz3",
  organisationResourceId: "9bgot5cquz3",
  sourceUrl:
    "https://drive.google.com/drive/folders/1Cqvxzoz2TFCx2fen_CrFc0zKIP75Xg3Z?usp=share_link",
  title: "Dummy content",
  creator: null,
  imageURL:
    "https://images.unsplash.com/photo-1432821596592-e2c18b78144f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1Nzc2OTN8MHwxfGFsbHx8fHx8fHx8fDE3MTAxNzY2Njl8&ixlib=rb-4.0.3&q=80&w=1080",
  typeId: "PyF3MKlzASI_notes",
  typeName: null,
  typeColor: null,
  saveCount: 0,
  commentCount: 0,
  versionNumber: 0,
  labels: null,
  isFollowed: true,
  questItemId: null,
  myDRow: {
    cells: {},
    dtableSchemaId: "PyF3MKlzASI_notes",
  },
  createdOn: "2023-08-25T16:36:27.652+00:00",
  photoUrl: null,
  ownerId: "sharkz",
  displayName: null,
  myResourceId: "2ZW2YYKPK0L",
  stat: {
    commentCount: "0",
    loveCount: "8",
    modifiedOn: "2024-06-05T12:07:13.893Z",
    myOrgResourceCount: "18.0",
    resourceSaveCount: "558",
    saveCount: 558,
    versionNumber: "180.0",
  },
  isPremium: null,
  owningOrganizationId: "PyF3MKlzASI",
  drowStats: {
    cells: {
      "well-organised": [
        {
          value: "True",
          percentage: 71.42857,
          count: 5,
          freeTextUserImageUrl: null,
          freeTextUserDisplayName: null,
        },
        {
          value: "False",
          percentage: 28.571428,
          count: 2,
          freeTextUserImageUrl: null,
          freeTextUserDisplayName: null,
        },
      ],
    },
    dtableSchemaId: "PyF3MKlzASI_notes",
  },
  dtableSchema: {},
  dtableSchemaId: "PyF3MKlzASI_notes",
  _voteStat: [
    {
      cellTitle: "Well-Organised",
      cellDescription: "Are the notes well-organised?",
      columnId: "well-organised",
      positiveValue: {
        value: "True",
        percentage: 71.42857,
        count: 5,
        freeTextUserImageUrl: null,
        freeTextUserDisplayName: null,
        hasVoted: true,
      },
      negativeValue: {
        value: "False",
        percentage: 28.571428,
        count: 2,
        freeTextUserImageUrl: null,
        freeTextUserDisplayName: null,
        hasVoted: false,
      },
    },
    {
      cellTitle: "Insightful",
      cellDescription: "Are the notes insightful?",
      columnId: "insightful",
      positiveValue: {
        value: "True",
        percentage: 0,
        count: 0,
        hasVoted: false,
      },
      negativeValue: {
        value: "False",
        percentage: 0,
        count: 0,
        hasVoted: false,
      },
    },
  ],
};

export default DemoResource;
