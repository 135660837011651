import React, { useState, useEffect } from "react";
import Button from "../../../../components/buttons/buttons";
import ICONS from "../../../../assets/quester-icons";
import DebouncedInput from "../../../../components/core/debounce-input/debounce-input";
import { useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import CollectionEditorUtils from "../../collection-editor-utils";
import ImagePicker from "../../../../components/image-picker/image-picker";

const CollectionMetaEditor = () => {
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [localImageObjectURL, setLocalImageObjectURL] = useState("");
  const [isImagePickerVisible, setIsImagePickerVisible] = React.useState(false);
  const { isCollectionMetaEditorActive, editorCollection } = useSelector(
    (state) => state.collectionStore
  );

  const _initializeCollectionMeta = () => {
    const { listname, description, newImage, questImage } = editorCollection;
    setCollectionTitle(listname);
    setCollectionDescription(description);
    if (newImage) {
      setLocalImageObjectURL(newImage);
    } else {
      setLocalImageObjectURL(questImage);
    }
  };

  const _saveCollectionMeta = () => {
    CollectionEditorUtils.updateCollectionProperty({
      propertyName: "listname",
      propertyValue: collectionTitle,
    });
    CollectionEditorUtils.updateCollectionProperty({
      propertyName: "description",
      propertyValue: collectionDescription,
    });
    CollectionEditorUtils.updateCollectionProperty({
      propertyName: "newImage",
      propertyValue: localImageObjectURL,
    });
    CollectionEditorUtils.toggleCollectionMetaEditor(false);
  };

  const _updateImage = (image) => {
    setLocalImageObjectURL(image);
  };

  const _discardLocalChanges = () => {
    _initializeCollectionMeta();
    CollectionEditorUtils.toggleCollectionMetaEditor(false);
  };

  useEffect(() => {
    _initializeCollectionMeta();
  }, [isCollectionMetaEditorActive]);

  return (
    <>
      {isCollectionMetaEditorActive ? (
        <>
          <div className="section editCollectionSection">
            <div className="topHeading">EDITING COLLECTION</div>

            <div className="editInputBoxes">
              <div
                className="imageWrapper"
                // onClick={() => document.getElementById("file").click()}
                onClick={() => setIsImagePickerVisible(true)}
              >
                <img
                  src={localImageObjectURL || require("../../../../assets/images/grey_rectangle.png")}
                  alt=""
                />
                {localImageObjectURL ? (
                  <></>
                ) : (
                  <div className="icon">{ICONS.imageIcon}</div>
                )}
                <ImagePicker
                  closePopup={() => setIsImagePickerVisible(false)}
                  onUpdateImage={_updateImage}
                  isVisible={isImagePickerVisible}
                />
              </div>

              <div className="right">
                <div className="inputGroup">
                  <label htmlFor="">Title</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Title of collection"
                  /> */}
                  <DebouncedInput
                    type="text"
                    className="form-control"
                    placeholder="Title of collection"
                    debounceTime={300}
                    value={collectionTitle}
                    onInputChange={setCollectionTitle}
                  />
                </div>
                <div className="inputGroup">
                  <label htmlFor="">Description</label>
                  {/* <textarea
                    name=""
                    rows="2"
                    className="form-control"
                    placeholder="Something to describe this collection"
                  >
                    We gather resources to pass IB exams. All resources you will
                    find here are free. df dd We gather resources to pass IB
                    exams. All resources you will find here are free. df dd
                  </textarea> */}
                  <DebouncedInput
                    type="text"
                    className="form-control"
                    placeholder="Title of collection"
                    textArea={true}
                    debounceTime={500}
                    value={collectionDescription}
                    onInputChange={setCollectionDescription}
                  />
                </div>

                <div className="buttons">
                  <Button
                    size={"extraSmall"}
                    type={"danger"}
                    icon={ICONS.corssCircle}
                    iconPos={"left"}
                    text={"Cancel"}
                    onClick={_discardLocalChanges}
                  />
                  <Button
                    size={"extraSmall"}
                    type={"success"}
                    icon={ICONS.checkCircle}
                    iconPos={"left"}
                    text={"Confirm changes"}
                    onClick={_saveCollectionMeta}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="section editCollectionSection viewMode">
            <div className="topHeading">EDITING COLLECTION</div>

            <div className="editInputBoxes">
              <div className="imageWrapper">
                <img
                  src={
                    editorCollection?.newImage ||
                    editorCollection?.questImage ||
                    require("../../../../assets/images/grey_rectangle.png")
                  }
                  alt=""
                />
                {/* {ICONS.imageIcon} */}
              </div>

              <div className="right">
                <div className="inputGroup">
                  <label htmlFor="">Title</label>
                  <div className="title">{editorCollection.listname}</div>
                </div>
                <div className="inputGroup">
                  <label htmlFor="">Description</label>
                  <div className="desc">{editorCollection.description}</div>
                </div>

                <div className="buttons">
                  <Button
                    size={"extraSmall"}
                    type={"default"}
                    icon={ICONS.edit}
                    iconPos={"left"}
                    text={"Edit"}
                    onClick={() =>
                      CollectionEditorUtils.toggleCollectionMetaEditor(true)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CollectionMetaEditor;
