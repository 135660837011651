import React, { Component } from "react";
import "./spinner.scss";
const SpinnerElement = ({ width = "48px", height = "48px" }) => (
  <span
    className="loader"
    style={{
      width: width,
      height: height,
    }}
  ></span>
);

const Spinner = ({ withContainer = true, height = "10vh", loaderSize = '48px', ...rest }) => {
  if (withContainer) {
    return (
      <div
        className="spinner-container"
        style={{
          height: height,
        }}
        {...rest}
      >
        <SpinnerElement 
          width={loaderSize}
          height={loaderSize}
        />
      </div>
    );
  } else {
    return <SpinnerElement 
      width={loaderSize}
      height={loaderSize}
    />;
  }
};

export default Spinner;
