import React, { useState, useEffect } from "react";
import CollectionItemsViewer from "../collection-items-viewer/collection-items-viewer";
import CollectionInfoViewer from "../collection-info-viewer/collection-info-viewer";

const CollectionViewer = () => {
  return (
    <>
      <CollectionInfoViewer />

      <CollectionItemsViewer />
    </>
  );
};

export default CollectionViewer;
