import React, { useState, useRef } from "react";
import ICONS from "../../assets/quester-icons";
import "./datapoint.scss";
import VotingUtils from "../../features/voting/voting-utils";
import CommunityUtils from "../../entities/community/community-utils";
import CollectionUtils from "../../entities/collection/collection-utils";
import AuthHelper from "../../shared/helper-methods/auth-helper";
import VoteActivities from "../../shared/utils/activity-recorder/activity-helpers/vote-activities";
import cloneDeep from "clone-deep";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const Datapoint = ({
  voteStat,
  dtableSchema,
  communityItem,
  isQuestItem = false,
}) => {
  const [agreeButtonActive, setAgreeButtonActive] = useState(false);
  const [disagreeButtonActive, setDisagreeButtonActive] = useState(false);
  const [isVoting, setIsVoting] = useState(false);

  const Agree = () => {
    return <div className="agree">{ICONS.thumbsUpNew}</div>;
  };
  const Disagree = () => {
    return <div className="disagree">{ICONS.thumbsDownNew}</div>;
  };

  const _generateAgreeValue = () => {
    let agreeValue = 50;
    if (voteStat?.positiveValue && voteStat.positiveValue.percentage > 0) {
      agreeValue = voteStat?.positiveValue.percentage;
    } else if (
      voteStat?.negativeValue &&
      voteStat.negativeValue.percentage > 0
    ) {
      agreeValue = 100 - voteStat?.negativeValue.percentage;
    }
    return `${agreeValue}%`;
  };

  const _vote = async (e, voteValue) => {
    e.preventDefault();
    e.stopPropagation();
    if (!AuthHelper.isLoggedIn()) {
      AuthHelper.login();
      return;
    }
    setIsVoting(true);
    const voteActivityPayload = {
      entityType: "quest-item",
      entityId: "",
      dataPointLabel: voteStat?.cellTitle,
      dataPointColumnId: voteStat?.columnId,
      dataPointValue: voteValue.value,
    };
    try {
      const { updatedDrowStats, myDRow } = await VotingUtils.vote({
        columnId: voteStat.columnId,
        teamResourceUuid: communityItem.uuid,
        value: voteValue.value,
        dtableSchema,
        drowStats: cloneDeep(communityItem.drowStats),
        myDRow: cloneDeep(communityItem.myDRow),
      });
      if (isQuestItem) {
        CollectionUtils.updateVoteLocally({
          teamResourceUuid: communityItem.uuid,
          updatedDrowStats,
          myDRow,
        });
      } else {
        CommunityUtils.updateVoteLocally({
          teamResourceUuid: communityItem.uuid,
          updatedDrowStats,
          myDRow,
        });
      }
      VoteActivities.addVoteActivity(voteActivityPayload);
    } catch (error) {
      console.log(error);
    }
    setIsVoting(false);
  };

  const agreeValue = _generateAgreeValue();

  return (
    // <div className="datapoint" title={voteStat.cellDescription}>
    <div className="datapoint" data-tooltip-id="tooltipDatapointInfo" data-tooltip-content={voteStat.cellDescription}>
      <div className="datapointTop">
        <span>{voteStat.cellTitle}</span>

        <div className="datapointRight d-none d-md-block">
          <div className="info">
            {/* {ICONS.infoCircle} */}
          </div>

          {/* <div className="curatorVote">
            <div className="imageWrapper" title="Curator name"><img src={require("../../assets/images/cat-cute.jpg")} alt="" /></div>
            <span className="name">Curator name</span>
            <div className="selectedValue"><Agree /></div>
          </div> */}
        </div>
      </div>

      <div className="voteSection">
        <div className="datapointVoteBar">
          <div className="voteBarInner">
            <span className="agreeValue" style={{ width: agreeValue }}></span>
          </div>
        </div>

        <div
          className="datapointVoteOptions"
          style={isVoting ? { opacity: 0.5, pointerEvents: "none" } : {}}
        >
          <div
            className={
              "agreeButton " +
              (voteStat.positiveValue?.hasVoted ? "active" : "")
            }
            title="Vote if you agree"
            onClick={(e) => _vote(e, voteStat.positiveValue)}
          >
            <div className="icon">
              <Agree />
            </div>
            <div className="voteValue">({voteStat?.positiveValue?.count})</div>
          </div>
          <div
            className={
              "disagreeButton " +
              (voteStat.negativeValue?.hasVoted ? "active" : "")
            }
            title="Vote if you disagree"
            onClick={(e) => _vote(e, voteStat.negativeValue)}
          >
            <div className="icon">
              <Disagree />
            </div>
            <div className="voteValue">({voteStat?.negativeValue?.count})</div>
          </div>
        </div>
      </div>



      <Tooltip
        id="tooltipDatapointInfo"
        place="bottom"
        // noArrow
      />

    </div>
  );
};

export default React.memo(Datapoint);
