/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCurrentUser = () => {
  const [currentUserDisplayName, setCurrentUserDisplayName] =
    useState("Anonymous");
  const { profileData } = useSelector((state) => state?.userStore || {});

  const _generateUserName = () => {
    if (profileData?.displayName?.length) {
      setCurrentUserDisplayName(profileData?.displayName);
    } else if (profileData?.ownerId?.length) {
      setCurrentUserDisplayName(profileData?.ownerId);
    }
  };

  useEffect(() => {
    _generateUserName();
  }, [profileData?.uuid]);

  return {
    currentUserDisplayName,
    currentUser: profileData || {},
  };
};

export default useCurrentUser;
