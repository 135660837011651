import React, { Component, useState } from "react";
import ICONS from "../../assets/quester-icons";
import Button from "../buttons/buttons";
import "./confirmation-popup.scss";

const ConfirmationPopup = ({
  headerTitle = "untitled",
  description = "",
  positiveActionClass = "contrast",
  onNegativeAction = () => {},
  onPositiveAction = () => {},
  positiveButtonText = "Save as draft",
  negativeButtonText = "No, thanks!",
  isVisible = false,
}) => {
  if (!isVisible) {
    return <></>;
  }
  return (
    <div className="confirmationPopup show">
      {" "}
      {/* remove or add "show" class to hide and unhide the modal */}
      <div className="confirmationPopupOverlay"></div>
      <div className="confirmationPopupInner">
        <div className="confirmationPopupHeader"> {headerTitle} </div>

        <div className="confirmationPopupContent">{description}</div>

        <div className="confirmationPopupButtons">
          <Button
            size={"extraSmall"}
            type={"default"}
            text={negativeButtonText}
            extraClasses={"cancelDraftBtn"}
            onClick={onNegativeAction}
          />

          <Button
            size={"extraSmall"}
            type={positiveActionClass}
            text={positiveButtonText}
            extraClasses={"saveDraftBtn"}
            onClick={onPositiveAction}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
