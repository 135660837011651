import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import CommunityItem from "../../../../components/community-item/community-item";
import CollectionEditorUtils from "../../collection-editor-utils";
import useDeepCompareEffect from "use-deep-compare-effect";
import CollectionItemsEditorActionButtons from "../collection-items-editor-action-buttons/collection-items-editor-action-buttons";

const CollectionEditorItemsRemover = () => {
  const [localItems, setLocalItems] = useState([]);
  const [localItemsToRemove, setLocalItemsToRemove] = useState([]);

  const { editorCollectionItems } = useSelector(
    (state) => state.collectionStore
  );

  const _deleteLocalItem = (item) => {
    const newItems = localItems.filter((i) => {
      if (i.questItemId) {
        return i.questItemId !== item.questItemId;
      } else {
        return i.sourceUrl !== item.sourceUrl;
      }
    });
    setLocalItems(newItems);
    setLocalItemsToRemove([...localItemsToRemove, item]);
  };

  const _onConfirm = () => {
    localItemsToRemove.forEach((item) => {
      CollectionEditorUtils.deleteCollectionItem(item);
    });
    CollectionEditorUtils.disableItemsEditor();
  };

  const _onCancel = () => {
    CollectionEditorUtils.disableItemsEditor();
  };

  useDeepCompareEffect(() => {
    setLocalItems(editorCollectionItems);
  }, [editorCollectionItems || []]);

  return (
    <>
      <CollectionItemsEditorActionButtons
        onCancel={_onCancel}
        onConfirm={_onConfirm}
      />
      <div className="addedResources">
        {localItems?.map((item, index) => (
          <div className="communityItemWrapper" key={index}>
            <div
              className="deleteIcon"
              onClick={(e) => {
                e.stopPropagation();
                _deleteLocalItem(item);
              }}
            >
              {ICONS.trash2}
            </div>
            <div className="itemWrapper">
              <CommunityItem
                datapoints={true}
                communityItem={item}
                isQuestItem={true}
              />
            </div>
          </div>
        ))}
        {/* <div className="communityItemWrapper">
          <div className="dragIcon">{ICONS.move}</div>
          <div className="deleteIcon">{ICONS.trash2}</div>
          <div className="itemWrapper"></div>
        </div>

        <div className="communityItemWrapper">
          <div className="dragIcon">{ICONS.move}</div>
          <div className="deleteIcon">{ICONS.trash2}</div>
          <div className="itemWrapper"></div>
        </div> */}
      </div>
    </>
  );
};

export default CollectionEditorItemsRemover;
