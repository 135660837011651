import NotificationStoreActionTypes from "./notification-store-action-types";

export const NotificationStoreActions = {
  staticActions: {
    toggleLoader: (loaderState) => ({
      type: NotificationStoreActionTypes.TOGGLE_LOADER,
      payload: loaderState,
    }),
    setUserSettings: (userSettings) => ({
      type: NotificationStoreActionTypes.SET_USER_SETTINGS,
      payload: userSettings,
    }),
    setFcmToken: (token) => ({
      type: NotificationStoreActionTypes.SET_FCM_TOKEN,
      payload: token,
    }),
    setFcmNotificationEnabled: (isNotificationEnabled) => ({
      type: NotificationStoreActionTypes.SET_FCM_NOTIFICATION_ENABLED,
      payload: isNotificationEnabled,
    }),
    setFcmUserId: (userId) => ({
      type: NotificationStoreActionTypes.SET_FCM_USERID,
      payload: userId,
    }),
    setFcmRefreshedOn: (refreshedOn) => ({
      type: NotificationStoreActionTypes.SET_FCM_REFRESHED_ON,
      payload: refreshedOn,
    }),
    setFcmBrowserType: (browserType) => ({
      type: NotificationStoreActionTypes.SET_FCM_BROWSER_TYPE,
      payload: browserType,
    }),
    setNotifications: (notifications) => ({
      type: NotificationStoreActionTypes.SET_NOTIFICATIONS,
      payload: notifications,
    }),
    setNotificationPagination: (pagination) => ({
      type: NotificationStoreActionTypes.SET_NOTIFICATION_PAGINATION,
      payload: pagination,
    }),
    setUnreadNotificationCount: (count) => ({
      type: NotificationStoreActionTypes.SET_UNREAD_NOTIFICATION_COUNT,
      payload: count,
    }),
    clearStore: () => ({
      type: NotificationStoreActionTypes.CLEAR_STORE,
    }),
  },
};
