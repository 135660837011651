import moment from "moment";
import { store } from "../../stores/store";
import firebase from "../../shared/top-level-providers/firebase-provider/firebase-utils";
import { getFirestore } from "firebase/firestore";
import { setDoc, doc, getDoc } from "firebase/firestore"; 

// import { store } from "../redux/store";
// import firebase from "./firebase-helper";

// const db = firebaseApp.firestore();
// const userStreakRef = db.collection("user-streak");
const db = getFirestore(firebase);

class StreakHelper {
  static userStreak = null;

  static async getUserStreakInfo() {
    if (StreakHelper.userStreak) {
      return StreakHelper.userStreak;
    } else {
      const {
        userStore: { profileData },
      } = store.getState();
      if (profileData?.ownerId) {
        const userStreakSnap = await getDoc(doc(db, "user-streak", profileData.ownerId));
        if (userStreakSnap.exists()) {
          const userStreak = userStreakSnap.data();
          StreakHelper.userStreak = StreakHelper._updateStreak(userStreak);
          return StreakHelper.userStreak;
        } else {
          StreakHelper.userStreak = StreakHelper._createStreak();
          return StreakHelper.userStreak;
        }
        // const userStreakSnap = await userStreakRef
        //   .doc(profileData.ownerId)
        //   .get();
        // if (userStreakSnap.exists) {
        //   const userStreak = userStreakSnap.data();
        //   StreakHelper.userStreak = StreakHelper._updateStreak(userStreak);
        //   return StreakHelper.userStreak;
        // } else {
        //   StreakHelper.userStreak = StreakHelper._createStreak();
        //   return StreakHelper.userStreak;
        // }
      }
    }
  }

  static async _updateStreak(userStreak) {
    if (userStreak.lastActiveDate !== moment().format("DD-MM-YYYY")) {
      if (
        moment().diff(
          moment(userStreak.lastActiveDate, "DD-MM-YYYY"),
          "days"
        ) === 1
      ) {
        // Consecuitive day
        userStreak.currentStreakCount = userStreak.currentStreakCount + 1;
        if (userStreak.currentStreakCount > userStreak.longestStreakCount) {
          userStreak.longestStreakCount = userStreak.currentStreakCount;
        }
      } else {
        userStreak.currentStreakCount = 1;
      }
      userStreak.lastActiveDate = moment().format("DD-MM-YYYY");
    }
    StreakHelper._setStreakData(userStreak);
    return userStreak;
  }

  static async _setStreakData(streak) {
    const {
      userStore: { profileData },
    } = store.getState();
    if (profileData?.ownerId) {
      if (streak) {
        // userStreakRef.doc(profileData.ownerId).set(streak);
        await setDoc(doc(db, "user-streak", profileData.ownerId), streak);
      }
    }
  }

  static async _createStreak() {
    const {
      userStore: { profileData },
    } = store.getState();
    if (profileData?.ownerId) {
      //   User streak doc is not present, so create a new one
      const streak = {
        currentStreakCount: 1,
        longestStreakCount: 1,
        lastActiveDate: moment().format("DD-MM-YYYY"),
      };
      // userStreakRef.doc(profileData.ownerId).set(streak);
      await setDoc(doc(db, "user-streak", profileData.ownerId), streak);
      return streak;
    }
  }
}

export default StreakHelper;
