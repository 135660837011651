import React from "react";
import { useSelector } from "react-redux";
import ICONS from "../../../../assets/quester-icons";
import "./community-filters.scss";
import CommunityUtils from "../../community-utils";

const CommunityFilters = () => {
  const { selectedCommunityLabels, selectedCommunityTypes } = useSelector(
    (state) => state.communityStore
  );
  return (
    <>
      {selectedCommunityLabels?.length || selectedCommunityTypes?.length ? (
        <>
          <div className="communityUnderHeader">
            <div className="count">Filters applied: </div>
            <div className="appliedFilters">
              {selectedCommunityTypes?.map((type) => (
                <div className={"filterItemType type " + "blue"}>
                  <div className="text">
                    <div
                      className="circle"
                      style={{
                        backgroundColor: type.color || "white",
                      }}
                    ></div>
                    <span>{type.description}</span>
                  </div>
                  <div
                    className="remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      CommunityUtils.removeFromSelectedCommunityTypes(type);
                    }}
                  >
                    {" "}
                    {ICONS.crossIcon}{" "}
                  </div>
                </div>
              ))}

              {selectedCommunityLabels?.map((label) => (
                <div
                  className="filterItem"
                  key={label?.uuid}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    CommunityUtils.removeFromSelectedCommunityLabels(label);
                  }}
                >
                  <span>{label?.displayName}</span>
                  <div className="remove"> {ICONS.crossIcon} </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CommunityFilters;
