import Keycloak from "keycloak-js";
// Setup Keycloak instance as needed

// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloak = new Keycloak({
  url: `${window.REACT_APP_PUBLIC_ENDPOINT_BASE_URL}/auth`,
  clientId: "resource-management",
  realm: window.REACT_APP_PUBLIC_REALM,
});

export default keycloak;