import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import COMMUNITY_TABS from "../../../../constants/community-tabs";
import CommunityUtils from "../../community-utils";
import useCommunityStats from "../../../../shared/hooks/useCommunityStats";

const CommunityTabView = ({ onTabSwitch = () => {} }) => {
  const { activeTab = COMMUNITY_TABS.all } = useSelector(
    (state) => state.communityStore
  );
  const { totalResources, totalCollections } = useCommunityStats();
  const _onSelect = (tab) => {
    onTabSwitch(tab);
    setTimeout(() => {
      CommunityUtils.setActiveTab(tab);
    }, 100);
  };
  const [tabCounts, setTabCounts] = React.useState({
    all: totalResources + totalCollections,
    recommendation: totalResources,
    collections: totalCollections,
  });

  useEffect(() => {
    setTabCounts({
      all: totalResources + totalCollections,
      recommendation: totalResources,
      collections: totalCollections,
    });
  }, [totalResources, totalCollections]);

  return (
    <div className="communityContentTabs">
      {Object.keys(COMMUNITY_TABS).map((tab) => (
        <div
          className={`communityContentTab ${tab}Content ${
            activeTab === COMMUNITY_TABS[tab] ? "active" : ""
          }`}
          onClick={() => _onSelect(COMMUNITY_TABS[tab])}
          key={tab}
        >
          {COMMUNITY_TABS[tab]} ({tabCounts[tab]})
        </div>
      ))}
    </div>
  );
};

export default React.memo(CommunityTabView);
