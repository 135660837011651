/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect, useState } from "react";

const ProfileImage = ({ path, ...rest }) => {
  const [formattedPath, setFormattedPath] = useState("https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg");

  const _prepareFormattedPath = () => {
    setFormattedPath(`${window.REACT_APP_CDN_BASE_URL}${path}`);
  };

  useEffect(() => {
    if (path?.length > 0) {
      _prepareFormattedPath();
    }
  }, [path]);

  return <img src={formattedPath} {...rest} />;
};

export default ProfileImage;
