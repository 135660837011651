import React, { Component, useEffect, useState } from "react";
import SideBar from "../components/sidebar/sidebar";
import MobileFooter from "../components/mobile-footer/mobile-footer";
import FullscreenPopup from "../components/fullscreen-popup/fullscreen-popup";
import ICONS from "../assets/quester-icons";
import "./layout.scss";
import router from "../shared/top-level-declarations/history";
import Toast from "../components/toast/toast";
import FeedbackModal from "../features/feedback-collector/feedback-modal/feedback-modal";

const Layout = ({ children }) => {
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  // Show it based on a localStorage value
  const storageValue = localStorage.getItem("cookieBannerDisplayed");

  const showCookie = showCookieBanner && storageValue !== "true";

  const _onCloseCookieBanner = (e) => {
    setShowCookieBanner(false);
    localStorage.setItem("cookieBannerDisplayed", true);
  };

  return (
    <>
      <div className="mainWrapper">
        <SideBar />
        {children}
      </div>

      <MobileFooter />

      {/* ************************************************* NEWS LETTER */}
      
      {/* ************************************************* /NEWS LETTER */}

      {/* ************************************************* COOKIE BANNER */}
      {showCookie ? (
        <FullscreenPopup
          headerTitle="We use cookies. Surprise!"
          headerImage={require("../assets/images/cookie.png")}
          maxHeight="600px"
          maxWidth="300px"
          successBtnIcon={ICONS.checkCircle}
          successBtnText="Accept cookies"
          // cancelBtnIcon={ICONS.close}
          cancelBtnText="Keep only the essential cookies"
          isActive={showCookieBanner}
          onClose={_onCloseCookieBanner}
          onConfirm={_onCloseCookieBanner}
          extraclassName="cookieBannerPopup"
        >
          <div className="textInner">
            <p>
              We use cookies to ensure you get the best experience on our
              website.
            </p>
            <p>
              Check our <span className="link">Privacy Policy</span>
            </p>
          </div>
        </FullscreenPopup>
      ) : (
        <></>
      )}
      <Toast />
      {/* ************************************************* /COOKIE BANNER */}
      <FeedbackModal />
    </>
  );
};

export default Layout;
