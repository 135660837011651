import React, { useEffect, useRef, useState } from "react";
import "./feedback-modal.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import Button from "../../../components/buttons/buttons";
import { EventEmitter } from "../../../shared/utils/event-emitter";
import SiteWiseEvents from "../../../declarations/site-wise-events";
import OutsideClickHandler from "react-outside-click-handler";
import FeedCollectorUtils from "../feedback-collector-utils";
import MobileDrawer from "../../../components/mobile-drawer/mobile-drawer";
import ICONS from "../../../assets/quester-icons";

const ALL_FEEDBACK_TYPES = [
  {
    label: "I love quester!",
    value: "i love quester",
  },
  {
    label: "Needs improvement",
    value: "needs improvement",
  },
  {
    label: "I found a bug",
    value: "i found a bug",
  },
  {
    label: "I have a feature request",
    value: "i have a feature request",
  },
  {
    label: "Other",
    value: "other",
  },
];

const VIEW_MODES = {
  FEEDBACK_FORM: "feedback_form",
  FEEDBACK_SENT: "feedback_sent",
};

const FeedbackModal = () => {
  const messageIconRef = useRef(null);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODES.FEEDBACK_FORM);
  const [isMobile, setIsMobile] = useState(false);

  const _sendFeedback = () => {
    setViewMode(VIEW_MODES.FEEDBACK_SENT);
    FeedCollectorUtils.addFeedbackRequestToFirestore({
      feedbackText,
      feedbackType,
      email: FeedCollectorUtils.tryToGrabEmailFromProfile(),
    });
    setTimeout(() => {
      _close();
    }, 7000);
  };

  const _registerEventListeners = () => {
    EventEmitter.subscribe(SiteWiseEvents.OPEN_FEEDBACK_MODAL, () => {
      _resetModal();
      setViewMode(VIEW_MODES.FEEDBACK_FORM);
      setIsModalVisible(true);
    });
    EventEmitter.subscribe(SiteWiseEvents.OPEN_FEEDBACK_MODAL_MOBILE, () => {
      _resetModal();
      setIsMobile(true);
      setViewMode(VIEW_MODES.FEEDBACK_FORM);
      setIsModalVisible(true);
    });
  };

  const _resetModal = () => {
    setFeedbackText("");
    setFeedbackType("");
    setViewMode(VIEW_MODES.FEEDBACK_FORM);
    setIsMobile(false);
  };

  const _close = () => {
    setIsModalVisible(false);
    _resetModal();
  };

  const _renderInner = () => {
    return (
      <>
        <div
          className="overlayForMobile d-md-none d-block"
          onClick={(e) => _close()}
        ></div>
        <div className="feedbackPopupInner">
          {viewMode === VIEW_MODES.FEEDBACK_FORM ? (
            <>
              <div className="topSection">
                <div className="popupIcon">📣</div>
                <div className="popupText">
                  <div className="title">Send us feedback ❤️</div>
                  <div className="subtitle">Your input helps us improve.</div>
                </div>
              </div>

              <div className="questionSection">
                <div className="radioButtons">
                  <div className="bigLabel">Type of feedback</div>
                  {ALL_FEEDBACK_TYPES.map((feedbackType, index) => {
                    return (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="feedbackType"
                          id={"feedbackType" + index}
                          value={feedbackType.value}
                          onChange={(e) => {
                            setFeedbackType(feedbackType.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"feedbackType" + index}
                        >
                          {feedbackType.label}
                        </label>
                      </div>
                    );
                  })}
                </div>

                <div className="textarea">
                  <div className="bigLabel">
                    Tell us details about your feedback
                  </div>
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    className="form-control"
                    placeholder="I want you to know..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  />
                </div>
              </div>

              <div className="buttons">
                <Button
                  size={"small"}
                  type={"contrast"}
                  disabled={!feedbackType?.length || !feedbackText?.length}
                  text={"Send feedback"}
                  extraClasses={"w-100"}
                  onClick={(e) => {
                    _sendFeedback();
                  }}
                  style={
                    feedbackType?.length && feedbackText?.length
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed", opacity: 0.5 }
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="feedbackSentMessage">
                {/* <div className="imageWrapper"><img src={require("../../assets/images/message.png")} alt="" /></div> */}
                <div className="imageWrapper">
                  <Player
                    src={require("../../../assets/animations/message.json.json")}
                    className="messageIcon"
                    loop={false}
                    autoplay={true}
                    keepLastFrame={true}
                    ref={messageIconRef}
                  />
                </div>
                <div className="popupText">
                  <div className="title">Thank you!</div>
                  <div className="subtitle">
                    Your feedback was received. We will check it out!
                  </div>
                </div>

                <div className="buttons">
                  <Button
                    size={"small"}
                    type={"contrast"}
                    text={"Close"}
                    extraClasses={"w-100"}
                    onClick={(e) => {
                      _close();
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    _registerEventListeners();
  }, []);

  if (isModalVisible) {
    if (isMobile) {
      return (
        <MobileDrawer
          isActive={true}
          onClose={_close}
          headerTitle="Feedback"
          headerIcon={ICONS.megaPhone}
          extraclassName="feedbackDrawer"
        >
          <div className={"feedbackPopup show"}>{_renderInner()}</div>
        </MobileDrawer>
      );
      // return (
      //   <div
      //     className={"feedbackPopup d-md-none d-grid show"}
      //     onClick={(e) => e.stopPropagation()}
      //   >
      //     {_renderInner()}
      //   </div>
      // );
    } else {
      return (
        <OutsideClickHandler onOutsideClick={_close}>
          <div className={"feedbackPopup show"}>{_renderInner()}</div>
        </OutsideClickHandler>
      );
    }
  }
};

export default FeedbackModal;
