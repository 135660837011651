/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect, useState } from "react";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import CommunityLabel from "../community-label/community-label";

const CommunityLabelsViewer = () => {
  const [topicsExpand, setTopicsExpand] = useState(true);
  const [formattedInitalLabels, setFormattedInitalLabels] = useState([]);
  const [formattedExtendedLabels, setFormattedExtendedLabels] = useState([]);

  const { selectedCommunityLabels, activeCommunityLabels } = useSelector(
    (state) => state.communityStore
  );

  const _prepareFormattedLabels = () => {
    const selectedLabelsIdMap = {};
    selectedCommunityLabels?.forEach((label) => {
      selectedLabelsIdMap[label?.uuid] = label;
    });
    const formattedLabels = activeCommunityLabels?.map((label) => {
      return {
        ...label,
        selected: !!selectedLabelsIdMap[label?.uuid],
      };
    });
    // Store first max 6 labels in initial labels
    const formattedInitalLabels = formattedLabels?.slice(0, 6);
    // Store rest of the labels in extended labels
    const formattedExtendedLabels = formattedLabels?.slice(6);
    setFormattedInitalLabels(formattedInitalLabels);
    setFormattedExtendedLabels(formattedExtendedLabels);
  };

  useEffect(() => {
    _prepareFormattedLabels();
  }, [selectedCommunityLabels?.length, activeCommunityLabels?.length]);

  return (
    <>
      {formattedInitalLabels?.length === 0 ? "" : 
        <div className="topicsSection">
          <div className="sectionHeading">
            <span>Topics</span>
            <div className="tooltipWrap">
              <div className="icon">{ICONS.infoCircle}</div>
              <div className="tooltip">Narrow your search with topic-specific filters</div>
            </div>
          </div>

          <div className="topics">
            {formattedInitalLabels?.map((label, index) => (
              <CommunityLabel label={label} key={label?.uuid} index={index} />
            ))}
            {topicsExpand ? (
              <>
                {formattedExtendedLabels?.map((label, index) => (
                  <CommunityLabel label={label} key={label?.uuid} index={index} />
                ))}
              </>
            ) : (
              ""
            )}
          </div>
          {formattedExtendedLabels?.length ? (
            <div
              className="seeMore"
              onClick={(e) => setTopicsExpand(!topicsExpand)}
            >
              {topicsExpand ? "See less" : "See more"}
            </div>
          ) : (
            <></>
          )}
        </div>
      }
    </>
  );
};

export default CommunityLabelsViewer;
