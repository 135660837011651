import { CollectionStoreEditorActions } from "../../stores/collection-store/collection-store-editor-actions";
import { store } from "../../stores/store";
import ApiCalls from "../../shared/api/index";
import CDNUploader from "../../shared/utils/cdn-uploader";
import router from "../../shared/top-level-declarations/history";
import cloneDeep from "clone-deep";
import CollectionUtils from "./collection-utils";
import ResourceUtils from "../resource/resource-utils";
import cuid from "cuid";
import CollectionActivities from "../../shared/utils/activity-recorder/activity-helpers/collection-activities";

const CollectionEditorUtils = {
  enableCollectionCreator: (
    {
      uiMode = "desktop", // Can be "desktop" or "mobile"
      resource = null,
    } = {
      uiMode: "desktop", // Can be "desktop" or "mobile"
      resource: null,
    }
  ) => {
    CollectionStoreEditorActions.staticActions.toggleEditingNew(true);
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(false);
    CollectionStoreEditorActions.staticActions.toggleCollectionMetaEditorStatus(
      true
    );
    if (uiMode === "desktop") {
      // Take to new page /cq
      let route = `${router.state.location.pathname}/cq`;
      if (resource) {
        route += `?resource=${resource.sourceUrl}`;
      }
      router.navigate(route);
    } else {
      // Open drawer
      CollectionStoreEditorActions.staticActions.toggleCollectionAddMobilePopup(
        true
      );
    }
  },
  enableCollectionEditor: () => {
    // Prepare state
    CollectionStoreEditorActions.staticActions.toggleEditingNew(false);
    // Copy collection and collection items to editor
    const { collectionStore } = store.getState();
    const { activeCollection, activeCollectionItems } = collectionStore;
    CollectionStoreEditorActions.staticActions.setEditorCollection(
      activeCollection
    );
    CollectionStoreEditorActions.staticActions.setEditorCollectionItems(
      activeCollectionItems
    );
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(false);
    CollectionStoreEditorActions.staticActions.toggleEditor(true);
  },
  dismissCollectionCreator: () => {
    CollectionStoreEditorActions.staticActions.resetEditor();
  },
  dismissCollectionEditor: () => {
    CollectionStoreEditorActions.staticActions.toggleEditingNew(false);
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(false);
  },
  updateCollectionProperty: ({ propertyName, propertyValue }) => {
    // Get collection from store
    const { editorCollection } = CollectionEditorUtils._getCollectionStore();
    // Update collection property
    editorCollection[propertyName] = propertyValue;
    // Update collection in store
    CollectionStoreEditorActions.staticActions.setEditorCollection(
      editorCollection
    );
  },
  toggleCollectionMetaEditor: (status) => {
    CollectionStoreEditorActions.staticActions.toggleCollectionMetaEditorStatus(
      status
    );
    if (!status) {
      CollectionEditorUtils._validateCollection();
    }
  },
  submitCollection: async ({ mode = "draft" }) => {
    // Determine if collection is new or existing
    const errorMessage = CollectionEditorUtils._validateCollection();
    if (errorMessage?.length) return;
    const { isEditingNew } = CollectionEditorUtils._getCollectionStore();
    // Submit collection
    if (isEditingNew) {
      await CollectionEditorUtils._submitNewCollection({ mode });
    } else {
      await CollectionEditorUtils._submitExistingCollection({ mode });
    }
  },
  dismissEditor: () => {
    // If new go back to community page else go back to collection page
    const { isEditingNew } = CollectionEditorUtils._getCollectionStore();
    if (isEditingNew) {
      router.navigate(router.state.location.pathname.replace("/cq", ""));
    } else {
      // Reset editor
      CollectionStoreEditorActions.staticActions.resetEditor();
    }
  },
  moveToCollectionCreatePage: () => {
    router.navigate(`${router.state.location.pathname}/cq`);
  },
  deleteCollection: async () => {
    // Check if collection is new
    const { isEditingNew } = CollectionEditorUtils._getCollectionStore();
    if (isEditingNew) {
      // If new go back to community page
      router.navigate(router.state.location.pathname.replace("/cq", ""));
      // Reset editor
      CollectionUtils.clearCollectionData();
    } else {
      // Enable loader
      CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(true);
      // Delete all collection items
      await CollectionEditorUtils._deleteAllCollectionItems();
      // Delete collection
      await CollectionEditorUtils._deleteCollection();
      // Go back to community page
      // Get community id
      const { communityStore } = store.getState();
      const { activeCommunityId } = communityStore;
      window.location.href = `/c/${activeCommunityId}`;
      // Reset editor
      CollectionUtils.clearCollectionData();
    }
  },
  addNewItemToCollection: async (newResource) => {
    CollectionStoreEditorActions.staticActions.setNewResourcePending(false);
    CollectionEditorUtils._validateCollection();
    // Only add to editorCollectionItems
    const { editorCollectionItems } =
      CollectionEditorUtils._getCollectionStore();
    // Prepare final object
    const finalObject =
      CollectionEditorUtils._prepareFinalCollectionItemObject(newResource);
    // Add to editorCollectionItems
    CollectionStoreEditorActions.staticActions.setEditorCollectionItems([
      ...editorCollectionItems,
      finalObject,
    ]);
  },
  submitNewItemToCollection: async (newResource) => {
    // This will be submitting the resource to the server
    // First if needed upload image
    const { imageFile } = newResource;
    let image = "";
    if (imageFile) {
      // ImageFile is a createObjectURL, so we need to convert it to a file
      const file = await fetch(imageFile).then((r) => r.blob());
      image = await CollectionEditorUtils._uploadCollectionItemImage(file);
    }
    // Then prepare the final resource object
    const finalObject = CollectionEditorUtils._prepareFinalCollectionItemObject(
      newResource,
      image
    );
    // Then submit the resource
    try {
      const { data } = await ApiCalls.org.private.addCommunityResource(
        finalObject
      );
      ResourceUtils.recordFeedback({
        entityType: "collection-item",
        feedback: "no-response",
        publishStatus: "published",
      });
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  deleteCollectionItem: async (item) => {
    // Delete from editorCollectionItems
    const { editorCollectionItems, editorItemsToRemove } =
      CollectionEditorUtils._getCollectionStore();
    const updatedItems = editorCollectionItems.filter((editorItem) => {
      if (editorItem.questItemId) {
        return editorItem.questItemId !== item.questItemId;
      } else {
        return editorItem.sourceUrl !== item.sourceUrl;
      }
    });
    CollectionStoreEditorActions.staticActions.setEditorCollectionItems(
      updatedItems
    );
    // Add to deletedItems
    editorItemsToRemove.push(item);
    CollectionStoreEditorActions.staticActions.setEditorItemsToRemove(
      editorItemsToRemove
    );
  },
  enableItemsEditor: () => {
    CollectionStoreEditorActions.staticActions.setItemsEditorMode("edit");
  },
  disableItemsEditor: () => {
    CollectionStoreEditorActions.staticActions.setItemsEditorMode("view");
  },
  enableItemsRemover: () => {
    CollectionStoreEditorActions.staticActions.setItemsEditorMode("remove");
  },
  disableItemsRemover: () => {
    CollectionStoreEditorActions.staticActions.setItemsEditorMode("view");
  },
  updateItemsOrders: (items) => {
    CollectionStoreEditorActions.staticActions.setEditorCollectionItems(items);
  },
  checkIfUrlExistsInCollection: (url) => {
    const { editorCollectionItems } =
      CollectionEditorUtils._getCollectionStore();
    const found = editorCollectionItems.find((item) => item.sourceUrl === url);
    return found ? true : false;
  },
  markPendingResource: () => {
    CollectionStoreEditorActions.staticActions.setNewResourcePending(true);
  },
  showCancelPopup: () => {
    CollectionStoreEditorActions.staticActions.toggleCancelPopup(true);
  },
  hideCancelPopup: () => {
    CollectionStoreEditorActions.staticActions.toggleCancelPopup(false);
  },
  /*      Private methods below        */
  _prepareFinalCollectionItemObject: (newResource, image) => {
    // Prepare final object
    const finalObject = {
      ...newResource,
      imageURL: image,
      _isNew: true,
    };
    delete finalObject.owningOrganizationId;
    return finalObject;
  },
  _submitNewCollection: async ({ mode = "draft" }) => {
    // Prepare formatted collection payload
    const collectionPayload = CollectionEditorUtils._prepareCollectionPayload({
      mode,
      type: "new",
    });
    // Submit collection
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(true);
    let newCollection = null;
    try {
      // Submit collection in draft mode
      const { data } = await ApiCalls.collection.private.createCollection(
        collectionPayload
      );
      newCollection = data;
      // Upload image if new image is set (Doing this after collection creation to get collection id)
      const imageLink = await CollectionEditorUtils._uploadCollectionImage(
        newCollection.uuid
      );
      if (imageLink?.length) {
        // Update the collection with the image link
        newCollection.questImage = imageLink;
        // Submit collection changes
        await ApiCalls.collection.private.updateCollection({
          collectionUuid: newCollection.uuid,
          collection: newCollection,
        });
      }
      await CollectionEditorUtils._submitNewCollectionItems(newCollection.uuid);
      // Fetch collection
    } catch (error) {
      console.log("error :>> ", error);
    }
    // Add collection add activity
    const { editorCollectionItems } =
      CollectionEditorUtils._getCollectionStore();
    CollectionActivities.addNewCollection({
      collectionName: collectionPayload.listname,
      collectionDescription: collectionPayload.description,
      collectionResourceCount: editorCollectionItems.length,
      collectionId: newCollection.uuid,
    });
    // Wait for a while before fetching the collection
    await new Promise((resolve) => setTimeout(resolve, 600));
    CollectionEditorUtils._loadCollectionPage(newCollection.uuid);
    // Toggle loader
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(false);
  },
  _loadCollectionPage: async (collectionUuid) => {
    // Reset editor
    const newPath = router.state.location.pathname.replace(
      "/cq",
      `/q/${collectionUuid}`
    );
    window.location.href = newPath;
  },
  _submitExistingCollection: async ({ mode = "draft" }) => {
    // Prepare formatted collection payload
    const collectionPayload = CollectionEditorUtils._prepareCollectionPayload({
      mode,
    });
    // Submit collection
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(true);
    try {
      // Upload image if new image is set
      const imageLink = await CollectionEditorUtils._uploadCollectionImage(
        collectionPayload.uuid
      );
      // Update the collection with the image link
      collectionPayload.questImage = imageLink;
      await ApiCalls.collection.private.updateCollection({
        collectionUuid: collectionPayload.uuid,
        collection: collectionPayload,
      });
      // Submit new items
      await CollectionEditorUtils._submitNewCollectionItems();
      // Submit items to delete
      await CollectionEditorUtils._submitItemsToDelete();
      // Update items order
      await CollectionEditorUtils._updateItemsOrdersInServer();
      // Fetch collection
      // await CollectionEditorUtils.fetchCollection();
    } catch (error) {
      console.log("error :>> ", error);
    }
    await CollectionEditorUtils._loadCollectionPage(collectionPayload.uuid);
    // Toggle loader
    CollectionStoreEditorActions.staticActions.toggleEditorLoaderStatus(false);
  },
  _getCollectionStore: () => {
    const { collectionStore } = store.getState();
    return cloneDeep(collectionStore);
  },
  _uploadCollectionImage: async (collectionId) => {
    const { editorCollection } = CollectionEditorUtils._getCollectionStore();
    if (editorCollection?.newImage) {
      // Check if it's a file or a link
      if (editorCollection.newImage.indexOf("http") > -1) {
        return editorCollection.newImage;
      }
      // convert the object url to file
      const file = await fetch(editorCollection.newImage).then((r) => r.blob());
      const cdnLink = await CDNUploader.uploadImage({
        imageFile: file,
        imageFor: "quest_icon",
        id: collectionId,
      });
      return cdnLink;
    }
  },
  _uploadCollectionItemImage: async (imageFile) => {
    const cdnLink = await CDNUploader.uploadImage({
      imageFile,
      imageFor: "resource_icon",
      id: cuid(),
    });
    return cdnLink;
  },
  _prepareCollectionPayload: ({ mode, type = "new" }) => {
    // Get organization id from community store
    const { communityStore } = store.getState();
    const { userStore } = store.getState();
    const { activeCommunityId } = communityStore;
    const { editorCollection } = CollectionEditorUtils._getCollectionStore();
    if (type === "new") {
      const newCollectionPayload = {
        ...editorCollection,
        isPublished: mode === "publish",
        isPremium: false,
        owningOrganizationId: activeCommunityId,
      };
      if (userStore?.profileData?.ownerId?.length) {
        newCollectionPayload.createdBy = userStore.profileData.ownerId || "";
        newCollectionPayload.creatorName =
          userStore.profileData.displayName || "";
        newCollectionPayload.creatorBio = userStore.profileData.bio || "";
        newCollectionPayload.creatorPhoto =
          userStore.profileData.photoUrl || "";
      }
      return newCollectionPayload;
    } else {
      return {
        ...editorCollection,
        isPublished: mode === "publish",
      };
    }
  },
  _deleteAllCollectionItems: async () => {
    const { editorCollectionItems, editorCollection } =
      CollectionEditorUtils._getCollectionStore();
    const deletePromises = editorCollectionItems.map(async (item) => {
      await ApiCalls.collection.private.deleteCollectionItem({
        collectionItemUuid: item.questItemId,
        collectionUuid: editorCollection.uuid,
      });
    });
    await Promise.all(deletePromises);
  },
  _deleteCollection: async () => {
    const { editorCollection } = CollectionEditorUtils._getCollectionStore();
    await ApiCalls.collection.private.deleteCollection(editorCollection.uuid);
  },
  _submitNewCollectionItems: async (collectionId = null) => {
    const { editorCollectionItems, editorCollection } =
      CollectionEditorUtils._getCollectionStore();
    let collectionIdForApi = collectionId;
    if (collectionId === null) collectionIdForApi = editorCollection.uuid;
    const newItems = editorCollectionItems.filter((item) => item._isNew);
    const submitPromises = newItems.map((item) =>
      ApiCalls.collection.private.addCollectionItem({
        collectionUuid: collectionIdForApi,
        collectionItem: item,
      })
    );
    await Promise.all(submitPromises);
  },
  _submitItemsToDelete: async () => {
    // Get items to delete
    const { editorItemsToRemove, editorCollection } =
      CollectionEditorUtils._getCollectionStore();
    // If doesn't have uuid, then it's not saved in the server
    const itemsToDelete = editorItemsToRemove.filter((item) => item.uuid);
    // Prepare delete promises
    const deletePromises = itemsToDelete.map(async (item) => {
      if (item.questItemId) {
        await ApiCalls.collection.private.deleteCollectionItem({
          collectionItemUuid: item.questItemId,
          collectionUuid: editorCollection.uuid,
        });
      }
    });
    try {
      await Promise.all(deletePromises);
    } catch (error) {
      console.log("error :>> ", error);
    }
  },
  _validateCollection: () => {
    const errorTypes = {
      noTitle: false,
      editorNotConfirmed: false,
      resourcePending: false,
    };
    const {
      editorCollection,
      isCollectionMetaEditorActive,
      newResourcePending,
    } = CollectionEditorUtils._getCollectionStore();
    // Check if collection has a title
    if (!editorCollection.listname) {
      errorTypes.noTitle = true;
    }
    // Check if collection editor is active
    if (isCollectionMetaEditorActive) {
      errorTypes.editorNotConfirmed = true;
    }
    // Check if resource is pending
    if (newResourcePending) {
      errorTypes.resourcePending = true;
    }
    let errorMessage = "";
    if (errorTypes.editorNotConfirmed) {
      errorMessage = "Please confirm your collection details before continuing";
    } else if (errorTypes.noTitle) {
      errorMessage = "Please provide a title for the collection.";
    } else if (errorTypes.resourcePending) {
      errorMessage = "Please add the item to the collection before continuing.";
    }
    if (errorTypes.resourcePending && errorTypes.editorNotConfirmed) {
      errorMessage =
        "Please confirm your collection details and add the item to the collection before continuing.";
    }
    CollectionStoreEditorActions.staticActions.setEditorErrorMessage(
      errorMessage
    );
    return errorMessage;
  },
  _updateItemsOrdersInServer: async () => {
    const { editorCollectionItems, editorCollection } =
      CollectionEditorUtils._getCollectionStore();
    const idArray = editorCollectionItems.map((item) => item.questItemId);
    let isValid = true;
    editorCollectionItems.forEach((item) => {
      if (!item.questItemId) {
        isValid = false;
      }
    });
    if (isValid) {
      try {
        await ApiCalls.collection.private.updateCollectionItemsOrder({
          collectionUuid: editorCollection.uuid,
          collectionItemUuids: idArray,
        });
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  },
};

export default CollectionEditorUtils;
