import { HttpClient } from "../clients/http-client";

const { ProtectedV2Client } = HttpClient;

const SubscriptionApi = {
  public: {},
  private: {
    getStripeSessionId: (payload) =>
      ProtectedV2Client.post(`/subscriptions/checkout-page`, payload),
    getBillingPortalUrl: (returnUrl) =>
      ProtectedV2Client.post(
        `/subscriptions/customer-billing-portal-session-url`,
        {
          returnUrl,
        }
      ),
  },
};
export default SubscriptionApi;
