/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ICONS from "../../assets/quester-icons";
import SiteWiseEvents from "../../declarations/site-wise-events";
import router from "../../shared/top-level-declarations/history";
import { EventEmitter } from "../../shared/utils/event-emitter";
import "./toast.scss";

const DEFAULT_DISMISS_AFTER = 4;
const setTimeoutRef = null;

const Toast = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [primaryText, setPrimaryText] = useState("");
  const [additionalClass, setAdditionalClass] = useState("");
  const [icon, setIcon] = useState(ICONS.heartSolid);
  const [secondaryText, setSecondaryText] = useState("");
  const [navigateTo, setNaviagteTo] = useState("");

  const _resetToast = () => {
    setShowNotification(false);
    setPrimaryText("");
    setSecondaryText("");
    setNaviagteTo("");
    setAdditionalClass("");
    setIcon(ICONS.heartSolid);
    if (setTimeoutRef) {
      clearTimeout(setTimeoutRef);
    }
  };

  const _closeToastAfter = (dismissAfterInSecond) => {
    setTimeout(() => {
      _resetToast();
    }, dismissAfterInSecond * 1000);
  };

  const _listenToToastEvents = () => {
    EventEmitter.subscribe(
      SiteWiseEvents.showToast,
      ({
        primaryText,
        secondaryText,
        navigateTo,
        dismissAfterInSecond,
        additionalClass,
        icon,
      }) => {
        _resetToast();
        setShowNotification(true);
        setPrimaryText(primaryText);
        setSecondaryText(secondaryText);
        if (navigateTo) {
          setNaviagteTo(navigateTo);
        }
        if (additionalClass) {
          setAdditionalClass(additionalClass);
        }
        if (icon) {
          setIcon(icon);
        }
        _closeToastAfter(dismissAfterInSecond || DEFAULT_DISMISS_AFTER);
      }
    );
  };

  useEffect(() => {
    _listenToToastEvents();
  }, []);

  return (
    <div
      className={`notificationPopupWrapper ${additionalClass} ${
        showNotification ? "show" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        if (navigateTo?.length) {
          router.navigate(navigateTo);
        }
        _resetToast();
      }}
    >
      <div className="notificationInner">
        {/* <div className="icon">{ICONS.heartSolid}</div> */}
        <div className="icon">{icon}</div>

        <div className="notificationInfo">
          <div className="infoText">{primaryText}</div>
          <div
            className="actionText"
            // onClick={(e) => {
            //   _resetToast();
            // }}
          >
            {secondaryText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toast;
