import React, { useState, useEffect } from "react";
import ICONS from "../../../../assets/quester-icons";
import { useSelector } from "react-redux";
import CommunityItem from "../../../../components/community-item/community-item";
import CollectionItemsEditorActionButtons from "../collection-items-editor-action-buttons/collection-items-editor-action-buttons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useDeepCompareEffect from "use-deep-compare-effect";
import CollectionEditorUtils from "../../collection-editor-utils";
const CollectionEditorItemsReorderer = () => {
  const [localItems, setLocalItems] = useState([]);

  const { editorCollectionItems } = useSelector(
    (state) => state.collectionStore
  );

  useDeepCompareEffect(() => {
    setLocalItems(editorCollectionItems);
  }, [editorCollectionItems || []]);

  const _updateLocalItems = ({
    sourceIndex,
    destinationIndex,
    sourceUrl,
    questItemId,
  }) => {
    const items = Array.from(localItems);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    setLocalItems(items);
  };

  return (
    <>
      <CollectionItemsEditorActionButtons
        onCancel={() => {
          CollectionEditorUtils.disableItemsEditor();
        }}
        onConfirm={() => {
          CollectionEditorUtils.updateItemsOrders(localItems);
          CollectionEditorUtils.disableItemsEditor();
        }}
      />
      <DragDropContext
        onDragEnd={(result) => {
          // dropped outside the list
          if (!result.destination) {
            return;
          }
          //source.index & destination.index & draggableId
          if (result.source.index === result.destination.index) {
            return;
          } else {
            const parts = result.draggableId.split("_");
            _updateLocalItems({
              sourceIndex: result.source.index,
              destinationIndex: result.destination.index,
              sourceUrl: parts[0],
              questItemId: parts[1],
            });
          }
        }}
      >
        <Droppable droppableId="collectionItems">
          {(provided, snapshot) => (
            <div
              className="addedResources"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {localItems?.map((item, index) => (
                <Draggable
                  key={`${item.sourceUrl}_${item.questItemId}`}
                  draggableId={`${item.sourceUrl}_${item.questItemId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="communityItemWrapper"
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="dragIcon">{ICONS.move}</div>
                      <div className="itemWrapper">
                        <CommunityItem
                          datapoints={true}
                          communityItem={item}
                          isQuestItem={true}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default CollectionEditorItemsReorderer;
