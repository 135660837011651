import { HttpClient } from "../clients/http-client";
const { AiInsightsHttpClient, ResourceExternalHttpClient } = HttpClient;
const ResourceAPI = {
  public: {
    autoAssignTopicsAndType: (path, topics, types) =>
      AiInsightsHttpClient.get(`/extract-insight`, {
        params: {
          link: path,
          allTopics: topics,
          allTypes: types,
        },
      }),
    getSummary: (path) =>
      AiInsightsHttpClient.get(`/link-summary`, {
        params: {
          link: path,
        },
      }),
  },
  private: {
    scrapeLink: (url) =>
      ResourceExternalHttpClient.get(
        `/url-meta-parser?checkInternalFirst=true&sourceUrl=${url}`
      ),
  },
};
export default ResourceAPI;
