import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseApp from "../../top-level-providers/firebase-provider/firebase-utils";



const analytics = getAnalytics(firebaseApp);
const GtagEventLogger = {
    logEvent: (eventName, eventParams) => {
        // console.log("GtagEventLogger.logEvent", eventName, eventParams);
        logEvent(analytics, eventName, eventParams);
    },
};

export default GtagEventLogger;
